import React, { useEffect, useState, useRef } from "react";
import * as bootstrap from 'bootstrap';
import DatePicker from "react-datepicker";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IMeeting } from "../../types/meeting.type";
import { toast } from 'react-toastify';
import { Wrapper } from "@googlemaps/react-wrapper";
import moment from 'moment';
import {
  deleteMeeting,
  getMeetingQuestions,
  meetingGetByID,
  meetingQuestionSendViaEmail,
  meetingStatusUpdate,
  meetingupdateByID
} from "../../services/meeting.service";
import ShareMeetingDetails from '../components/ShareMeetingDetails';
import { useTranslation } from "react-i18next";
import { getAllPollsQuestionsAnswerByMeetingId, getPollsForMeeting } from "../../services/poll.service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { currentMeetingHandler } from "../store/slices/meetingSlice";
import { Modal } from "react-bootstrap";
import { meetingGetByCodeHandler } from "../store/slices/joinMeetingSlice";
import MeetingList from "./MeetingList";
import MeetingCard from "./MeetingCard";

const Meeting = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  // Initialize and add the map
  const [newLatitude, setNewLatitude] = useState<number>(0);
  const [newLongitude, setNewLongitude] = useState<number>(0);
  const [mapZoom, setMapZoom] = useState<any>(16);
  const { user } = useSelector((state: RootState) => state.auth)
  const userId = user?.user?.id


  function initMap(): void {
    // The location of Uluru

    var currentLocation = { lat: newLatitude, lng: newLongitude };
    // The map, centered at Uluru
    const map = new google.maps.Map(
      document.getElementById("editMap") as HTMLElement,
      {
        zoom: mapZoom,
        center: currentLocation,
      }
    );
    // The marker, positioned at Uluru
    const marker = new google.maps.Marker({
      position: currentLocation,
      draggable: true,
      map: map,
    });

    google.maps.event.addListener(marker, 'dragend', function (marker: { latLng: any; }) {
      var latLng = marker.latLng;
      setNewLatitude(latLng.lat())
      setNewLongitude(latLng.lng())
      var zoom = map.getZoom();
      setMapZoom(zoom);
    });

  }
  useEffect(() => {
    setTimeout(() => {
      initMap()
    }, 500);
  }, [newLatitude, newLongitude])


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingCode, setMeetingCode] = useState("");
  const [meetingPassword, setMeetingPassword] = useState("");
  const [errorActiveClass, setErrorActiveClass] = useState("");
  const all = {
    all: 'all'
  }
  const [meetingPollsList, setMeetingPollsList] = useState<any[]>([]);
  const [meetingAllPollsAnswerList, setMeetingAllPollsAnswerList] = useState<any[]>([]);
  const [passwordProtected, setPasswordProtected] = useState<boolean>(true);
  const [geoFenceProtected, setGEOFenceProtected] = useState<boolean>(false);
  const [sponsorsFiles, setSponsorsFiles] = useState<any[]>([]);
  const [brandFiles, setBrandFiles] = useState<any[]>([]);
  const [fileValidationError, setFileValidationError] = useState(false);
  const [sponserfileValidationError, setSponserFileValidationError] = useState(false);
  const [fileSizevalidation, setFileSizevalidation] = useState(false);
  const [sponserfileSizevalidation, setsponserFileSizevalidation] = useState(false);
  const [brandErr, setBrandErr] = useState(false)
  const [sponsorErr, setSponsorError] = useState(false)
  const inputReference = useRef<HTMLInputElement>(null);
  const inputReferenced = useRef<HTMLInputElement>(null);
  const [emailProtected, setEmailProtected] = useState<boolean>(false);
  const newmeetingTitle = meetingTitle.trim();
  const newMeetingCode = meetingCode.trim();

  let initialValues: IMeeting = {
    title: newmeetingTitle,
    code: newMeetingCode,
    password: meetingPassword,
    passwordProtected: passwordProtected,
    startTime: new Date(startDate),
    endTime: new Date(endDate),
  };

  const [updateData, setupdateData] = useState(initialValues);
  const [shareData, setShareData] = useState<any>([]);
  const [pastMeetingDetails, setpastMeetingDetails] = useState<any>([]);
  const [pastMeetingDetailsQuestion, setMeetingDetailsQuestions] = useState<any>([]);
  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [updateMeetingState, setupdateMeetingState] = useState<boolean>(false);
  const [filterData, setfilterData] = useState(""); // recent, past, all
  const [viewType, setviewType] = useState(1); // recent, past, all
  const meetingId = localStorage.getItem("meetingId")
  const [shareMeetingModal, setShareMeetingModal] = useState<boolean>(false);
  const [meetingDetailsModal, setMeetingDetailsModal] = useState<boolean>(false);
  const [editMeetingModal, setEditMeetingModal] = useState<boolean>(false);

  useEffect(() => {
    if (meetingId == null) {
      setupdateMeetingState(!updateMeetingState)
    }
  }, [meetingId])

  const tabTabchange = (e: any) => {
    setviewType(e)
  }

  const btnMeetingQuestionSendViaEmail = (id: any) => {
    meetingQuestionSendViaEmail(id).then((response) => {
      if (response.status === 201) {
        toast.success(t('toastMessage.emailSendSuccessfully'), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      }

    );
  }

  const meetingEdit = async (e: any, id: any) => {
    const meetingData = await meetingGetByID(id);
    setupdateData(meetingData);
    setMeetingTitle(meetingData.title)
    setMeetingCode(meetingData.code)
    setMeetingPassword(!!meetingData.password ? meetingData.password : '')
    setStartDate(new Date(meetingData.startTime));
    setEndDate(new Date(meetingData.endTime));
    setEmailProtected(meetingData.isEmailProtected)
    setPasswordProtected(meetingData.isPasswordProtected)
    setGEOFenceProtected(meetingData.isGeoFenceProtected)
    setNewLatitude(meetingData.location.coordinates[1])
    setNewLongitude(meetingData.location.coordinates[0])
    setEditMeetingModal(true)
  };

  const startMeetingHandler = async (id: any, status: string) => {
    await meetingStatusUpdate(id, status)
    setupdateMeetingState(!updateMeetingState)
  }

  const joinMeetingHandler = async (id: any, status: string) => {
    await meetingStatusUpdate(id, status).then((response) => {
      if (response.status === 200) {
        setupdateMeetingState(!updateMeetingState)
        dispatch(meetingGetByCodeHandler({ code: response?.data?.code, userId: 0 })).then((meetDetails: any) => {
          if (meetDetails?.payload.status === 200) {
            dispatch(currentMeetingHandler(meetDetails?.payload?.data));
            navigate('/joinMeeting/' + response.data.code)
          }
        })

      }
    })
  }
  const deleteMeetingHandler = async (id: any) => {
    let deletemeeting = await deleteMeeting(id)
    if (deletemeeting.status === 200) {
      setupdateMeetingState(!updateMeetingState)
      toast.success(t('toastMessage.meetingDelete'), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  }

  const shareMeeting = async (e: any, id: any) => {
    const meetingData = await meetingGetByID(id);
    setShareData(meetingData);
    setShareMeetingModal(true)
  };
  const getMeetingDetails = async (id: any) => {
    const meetingDetailsData = await meetingGetByID(id);
    let meetingDetailsQuestion = await getMeetingQuestions(id, all)
    setMeetingDetailsQuestions(meetingDetailsQuestion.data.data)
    setMeetingDetailsModal(true)

    let pollsListData = await getPollsForMeeting(id)
    if (pollsListData.status === 200) {
      if (pollsListData.data.length > 0) {
        setMeetingPollsList(pollsListData.data)
      } else {
        setMeetingPollsList([])
      }
    }

    getAllPollsQuestionsAnswerByMeetingId(id).then(
      (response) => {
        if (response.status === 200) {
          setMeetingAllPollsAnswerList(response.data)
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("resMessage ==>", resMessage)
      }
    )

    setpastMeetingDetails(meetingDetailsData);
  };
  const getTotalPercentage = (pollId: number, questionId: number, questionOptionId: number) => {
    let totalPercentage = 0;
    let totalAnswerdUser = meetingAllPollsAnswerList.filter((x: any) => (x.pollId === pollId && x.questionId === questionId && x.questionOptionId === questionOptionId))
    let totalQuestionAnswerdUser = meetingAllPollsAnswerList.filter((x: any) => (x.pollId === pollId && x.questionId === questionId))

    totalPercentage = ((totalAnswerdUser.length * 100) / totalQuestionAnswerdUser.length);

    let tempTotalpercentage = Number(totalPercentage.toFixed(2))

    return totalPercentage > 0 ? tempTotalpercentage : 0
  }

  const getTotalVote = (pollId: number, questionId: number) => {
    let totalQuestionAnswerdUser = meetingAllPollsAnswerList.filter((x: any) => (x.pollId === pollId && x.questionId === questionId))
    return totalQuestionAnswerdUser.length
  }

  const filterPassedEndTime = (time: any) => {
    const meetingStartDate = new Date(startDate);
    const selectedDate = new Date(time);

    return meetingStartDate.getTime() < selectedDate.getTime();
  };

  const filterPassedStartTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const today = new Date();
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(t('validation.requiredField'))
      .transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .strict(true)
      .test(
        "len",
        (t('validation.minlengthTitle')),
        (val: any) => val && val.toString().length >= 3
      ),
    code: Yup.string()
      .required(t('validation.requiredField'))
      .transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .strict(true)
      .test(
        "len",
        (t('validation.minlengthCode')),
        (val: any) => val && val.toString().length >= 3
      ),
    passwordProtected: Yup.boolean(),
    password: Yup.string().when("passwordProtected", {
      is: true,
      then: Yup.string()
        .required(t('validation.requiredField'))
        .trim(t('trimMessage.trimCode'))
        .matches(/^[A-Za-z0-9#@!%^&*()_+\-=[\]{}|;:'",.<>?/\\`~]+$/, (t('validation.specialcharacterMeetingPassword')))
        .strict(true)
        .test(
          "len",
          t('validation.minlengthPassword'),
          (val: any) =>
            val &&
            val.toString().length >= 3
        )
        .oneOf([Yup.ref("password"), null], "Must match new password."),
    }),

    startTime: Yup.date()
      .required(t('validation.requiredField'))
      .min(new Date(), t('validation.startTime')),

    endTime: Yup.date()
      .required(t('validation.requiredField'))
      .when('startTime', (startTime, schema) => {
        const expiryDate = moment(startTime);
        const enteredDate = moment(endDate);
        if (!enteredDate.isAfter(expiryDate)) {
          const currentDay: any = new Date(startTime.getTime());
          return schema
            .min(currentDay, t('validation.endTime'))
        } else {
          return schema;
        }
      })
  });
  const brandUploadCount = process.env.REACT_APP_NUMBER_OF_BRAND_UPLOAD_IMAGES ?? 'defaultBrandCount';
  const updatedBrandFilesValidation = t('deafultLeftLayout.hostMeeting.lblBrandFilesValidation', { brandUploadCount })
    .replace('{BrandUploadCount}', brandUploadCount)
    .replace('{BrandUploadCount}', brandUploadCount)

  const sponsorUploadCount = process.env.REACT_APP_NUMBER_OF_SPONSORS_UPLOAD_IMAGES ?? 'defaultBrandCount';
  const updatedSponsorFilesValidation = t('deafultLeftLayout.hostMeeting.lblSponsorFilesValidation', { brandUploadCount })
    .replace('{SponsorUploadCount}', sponsorUploadCount)
    .replace('{SponsorUploadCount}', sponsorUploadCount)

  const handleMessageClear = () => {
    setBrandFiles([]);
    setSponsorsFiles([]);
    setStartDate(new Date());
    setEndDate(new Date());
    setBrandErr(false);
    setSponsorError(false);
    setMessage('')
    setFileValidationError(false);
    setSponserFileValidationError(false);
    setFileSizevalidation(false);
    setsponserFileSizevalidation(false);
    const inputElement = inputReference.current;
    const inputElement1 = inputReferenced.current;
    if (inputElement) {
      inputElement.value = '';
      inputElement.focus();
    }
    if (inputElement1) {
      inputElement1.value = '';
      inputElement1.focus();
    }
    if (inputElement) {
      inputElement.value = '';
      inputElement.focus();
    }
    if (inputElement1) {
      inputElement1.value = '';
      inputElement1.focus();
    }
  }
  const handleMeetingUpdate = (formValue: IMeeting) => {
    const { title, code, password, startTime, endTime } = formValue;
    const newTitle = title.trim();
    const newCode = code.trim();
    const rawSponsorMaxFileCount = process.env.REACT_APP_NUMBER_OF_SPONSORS_UPLOAD_IMAGES;
    const sponsorMaxFileCount = rawSponsorMaxFileCount ? parseInt(rawSponsorMaxFileCount, 10) : 0;
    const meetingPassword = passwordProtected ? password : '';
    if (fileValidationError == false && sponserfileValidationError == false && fileSizevalidation == false && sponserfileSizevalidation == false) {
      meetingupdateByID(updateData['id'], newTitle, newCode, meetingPassword, startDate, endDate, emailProtected, passwordProtected, geoFenceProtected, newLongitude, newLatitude, brandFiles, sponsorsFiles).then(
        (response) => {
          setupdateMeetingState(!updateMeetingState)
          toast.success(t('toastMessage.meetingUpdate'), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setMessage(response.data.message);
          setSuccessful(true);
          setEditMeetingModal(false)
          setErrorActiveClass("");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="font-26 fw-semibold mb-3 mb-md-4">{t('myMeeting.header')}</div>

        <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">

          <div className="custom-tab d-flex flex-fill">
            {/** Commented by POOJA for future purpose if any */}
            {/* <ul className="nav nav-pills" id="pills-tab" role="tablist"> */}
            {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link active w-100"
                  id="pills-recent-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-recent"
                  type="button"
                  role="tab"
                  aria-controls="pills-recent"
                  aria-selected="true"
                  onClick={(e) => { onChangeFilterHanlder("recent") }}
                >
                  {t('myMeeting.btnRecent')}
                </button>
              </li> */}
            {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link active w-100"
                  id="pills-all-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-recent"
                  type="button"
                  role="tab"
                  aria-controls="pills-all"
                  aria-selected="true"
                  onClick={(e) => { onChangeFilterHanlder("all") }}
                >
                  {t('myMeeting.btnRecent')}
                </button>
              </li> */}
            {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link w-100"
                  id="pills-past-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-past"
                  type="button"
                  role="tab"
                  aria-controls="pills-past"
                  aria-selected="false"
                  onClick={(e) => { onChangeFilterHanlder("past") }}
                >
                  {t('myMeeting.btnPast')}
                </button>
              </li> */}
            {/* </ul> */}
          </div>
          <nav>
            <div className="btn-group nav border-0" id="nav-tab" role="tablist">
              <button type="button" onClick={(e) => { tabTabchange("1") }} className="btn btn-outline-primary border-0 rounded list_view active" id="nav-home-btn" data-bs-toggle="tab" data-bs-target="#nav-list_view" role="tab" aria-controls="nav-list_view" aria-selected="true">
                <i className="fa fa-list" aria-hidden="true"></i>
              </button>
              <button type="button" onClick={(e) => { tabTabchange("2") }} className="btn btn-outline-primary border-0 rounded grid_view" id="nav-profile-btn" data-bs-toggle="tab" data-bs-target="#nav-grid_view" role="tab" aria-controls="nav-grid_view" aria-selected="false">
                <i className="fa fa-th-large" aria-hidden="true"></i>
              </button>
            </div>
          </nav>
        </div>
        <div className="tab-content" id="nav-tabContent">
          <div className="mb-3 tab-pane fade active show" id="nav-list_view" role="tabpanel" aria-labelledby="nav-list_view-tab">
            <div className="tab-content" id="nav-tabContent-sub">
              <div className="mb-3 tab-pane fade active show" id="pills-recent" role="tabpanel" aria-labelledby="nav-list_view-tab">
                <div className="card border-0 rounded-bottom">
                  <div className="card-body">
                    <MeetingList title={t('myMeeting.titleMeetingList')} joinMeetingHandler={joinMeetingHandler} deleteMeetingHandler={deleteMeetingHandler} meetingEdit={meetingEdit} startMeetingHandler={startMeetingHandler} updateMeetingState={updateMeetingState} shareMeeting={shareMeeting} viewType={viewType} selectFilterData={filterData} getMeetingDetails={getMeetingDetails} btnMeetingQuestionSendViaEmail={btnMeetingQuestionSendViaEmail} />
                  </div>
                </div>
              </div>
              <div className="mb-3 tab-pane fade" id="pills-past" role="tabpanel" aria-labelledby="nav-list_view-tab">
                <div className="card border-0 rounded-bottom">
                  {/** Commented by POOJA for future use if any*/}
                  {/* <div className="card-body">
                    <MeetingPastList title={t('myMeeting.titleMeetingList')} getMeetingDetails={getMeetingDetails} btnMeetingQuestionSendViaEmail={btnMeetingQuestionSendViaEmail} shareMeeting={shareMeeting} selectFilterData={filterData} />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade mb-3" id="nav-grid_view" role="tabpanel" aria-labelledby="nav-grid_view-tab">
            <MeetingCard title="" joinMeetingHandler={joinMeetingHandler} deleteMeetingHandler={deleteMeetingHandler} startMeetingHandler={startMeetingHandler} updateMeetingState={updateMeetingState} meetingEdit={meetingEdit} viewType={viewType} getMeetingDetails={getMeetingDetails} shareMeeting={shareMeeting} selectFilterData={filterData} btnMeetingQuestionSendViaEmail={btnMeetingQuestionSendViaEmail} />
          </div>
        </div>
      </div>
      <Modal centered show={meetingDetailsModal} onHide={() => setMeetingDetailsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('pastMeetingDetails.header')} {pastMeetingDetails.title} - {pastMeetingDetails.code}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="meet-detail">
            <div className="custom-tab meeting-poll d-flex justify-content-between align-items-center flex-fill mb-4">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-question-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-question"
                    type="button"
                    role="tab"
                    aria-controls="pills-question"
                    aria-selected="true"
                  >
                    {t('pastMeetingDetails.btnQuestion')}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-poll-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-poll"
                    type="button"
                    role="tab"
                    aria-controls="pills-poll"
                    aria-selected="false"
                  >
                    {t('common.Poll')}
                  </button>
                </li>
              </ul>
              <div>
                <button
                  className="btn btn-outline-primary d-flex align-items-center gap-2"
                  onClick={() => btnMeetingQuestionSendViaEmail(pastMeetingDetails.id)}
                  title={t('pastMeetingDetails.btnSendEmail')}
                >
                  <i className="fas fa-envelope"></i>
                  <span className="d-none d-sm-inline text-nowrap">{t('pastMeetingDetails.btnSendEmail')}</span>
                </button>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-question"
                role="tabpanel"
                aria-labelledby="pills-question-tab"
              >
                {!!pastMeetingDetailsQuestion && pastMeetingDetailsQuestion.length > 0 && pastMeetingDetailsQuestion.map((pastMeetingDetailsQuestion: any) => {
                  let userFullName = ""

                  if (!!pastMeetingDetailsQuestion.joinUser && !!pastMeetingDetailsQuestion.userId) {
                    userFullName = pastMeetingDetailsQuestion.joinUser?.firstName + " " + pastMeetingDetailsQuestion.joinUser?.lastName
                  }
                  if (!!pastMeetingDetailsQuestion.joinGuestUser && !!pastMeetingDetailsQuestion.guestUserId) {
                    if (pastMeetingDetailsQuestion.joinGuestUser.isAnonymous === true) {
                      userFullName = t('common.lblAnonymous')
                    } else {
                      userFullName = pastMeetingDetailsQuestion.joinGuestUser?.name
                    }
                  }
                  return (
                    <div key={pastMeetingDetailsQuestion.id} className="border-bottom mb-2 pb-2">
                      <div className={`p-2 meeting-questioncard ${userId === pastMeetingDetailsQuestion.userId ? 'meeting-host mb-2' : ''} ${pastMeetingDetailsQuestion.isArchive ? 'unread' : 'read'}`}>
                        <div className="d-inline-flex align-items-center gap-2 mb-1 meeting-user-badge">
                          <i className="meeting-user-icon fa-solid fa-circle-user"></i>
                          <div className="fw-medium font-18">
                            {userFullName}
                          </div>

                        </div>
                        <div className="font-18 fw-medium"><pre className='font-18 fw-medium mb-0'>{pastMeetingDetailsQuestion.question}</pre></div>
                        <div>
                          {!!pastMeetingDetailsQuestion.meetingQuestionsReplyDetails &&
                            pastMeetingDetailsQuestion.meetingQuestionsReplyDetails.length > 0 &&
                            pastMeetingDetailsQuestion.meetingQuestionsReplyDetails.map((meetingQuestionsReplyValue: any, index: any) => {
                              let userFullNameReply = ""

                              if (!!meetingQuestionsReplyValue.userDetails && !!meetingQuestionsReplyValue.userId) {
                                userFullNameReply = meetingQuestionsReplyValue.userDetails?.firstName + " " + meetingQuestionsReplyValue.userDetails?.lastName
                              }
                              if (!!meetingQuestionsReplyValue.joinGuestUser && !!meetingQuestionsReplyValue.guestUserId) {
                                if (meetingQuestionsReplyValue.joinGuestUser.isAnonymous === true) {
                                  userFullNameReply = t('common.lblAnonymous')
                                } else {
                                  userFullNameReply = meetingQuestionsReplyValue.joinGuestUser?.name
                                }
                              }

                              return (
                                <div className={`p-2 mt-2 rounded question-reply ${userId === meetingQuestionsReplyValue.userId ? 'meeting-host mb-2' : ''}`} key={'meetingQuestionsReply_' + meetingQuestionsReplyValue.id}>
                                  <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                    <div className="d-flex align-items-center gap-2 mb-1 meeting-user-badge">
                                      <i className="meeting-user-icon fa-solid fa-circle-user"></i>
                                      <div className="fw-medium">{userFullNameReply}</div>
                                    </div>
                                  </div>
                                  <div className="font-18 fw-medium"><pre className='font-18 fw-medium mb-0'>{meetingQuestionsReplyValue.questionReply}</pre></div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div
                className="tab-pane fade"
                id="pills-poll"
                role="tabpanel"
                aria-labelledby="pills-poll-tab"
              >
                {!!meetingPollsList &&
                  meetingPollsList.length > 0 &&
                  meetingPollsList.map((pollValue: any, i: any) => {
                    return (
                      <div className='border-bottom mb-3 border-primary' key={'pollAnswer_' + pollValue.pollDetails.id}>
                        <div className="fw-medium mb-2" >{pollValue.pollDetails.title} </div>

                        {!!pollValue.pollDetails.pollsQuestionDetails &&
                          pollValue.pollDetails.pollsQuestionDetails.length > 0 &&
                          pollValue.pollDetails.pollsQuestionDetails.map((pollsQuestionValue: any, j: any) => {
                            return (
                              <div className="ps-3" key={'pollAnswer_question' + pollsQuestionValue.questionDetails.id}>
                                <div className="d-flex justify-content-between flex-wrap mb-2">
                                  <div className="fw-medium flex-fill " >{pollsQuestionValue.questionDetails.title}
                                  </div>
                                  <span>{t('pastMeetingDetails.lblTotalVote')} {getTotalVote(pollValue.pollDetails.id, pollsQuestionValue.questionDetails.id)}</span>
                                </div>

                                {!!pollsQuestionValue.questionDetails.questionOptions &&
                                  pollsQuestionValue.questionDetails.questionOptions.length > 0 &&
                                  pollsQuestionValue.questionDetails.questionOptions.map((questionOptionsValue: any, k: any) => {
                                    return (
                                      <div className="d-flex ps-3 flex-wrap align-items-center gap-2 mb-4" key={'pollAnswer_question_option' + questionOptionsValue.id}>
                                        <div className="flex-fill">
                                          <div className="d-flex justify-content-between">
                                            <div>{questionOptionsValue.optionName}</div>
                                            <small>{getTotalPercentage(pollValue.pollDetails.id, pollsQuestionValue.questionDetails.id, questionOptionsValue.id)} %</small>
                                          </div>
                                          <div className="progress">
                                            <div className="progress-bar" role="progressbar" aria-label="Basic example" aria-valuenow={getTotalPercentage(pollValue.pollDetails.id, pollsQuestionValue.questionDetails.id, questionOptionsValue.id)} style={{ width: getTotalPercentage(pollValue.pollDetails.id, pollsQuestionValue.questionDetails.id, questionOptionsValue.id) + "%" }}>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })
                                }

                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal centered show={shareMeetingModal} onHide={() => setShareMeetingModal(false)}>
        <Modal.Header closeButton>
          <h2 className="fw-bold mb-0">{t('shareMeeting.header')}</h2>
        </Modal.Header>
        <Modal.Body>
          <ShareMeetingDetails userId={userId} currentMeeting={shareData} />
        </Modal.Body>
      </Modal>

      <Modal centered show={editMeetingModal} onHide={() => { setEditMeetingModal(false); handleMessageClear() }}>
        <Modal.Header closeButton >
          <h2 className="fw-bold mb-0">{t('deafultLeftLayout.hostMeeting.header')}</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="font-22 fw-medium mb-4">
            {t('deafultLeftLayout.hostMeeting.updateMeetingTitle')}
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleMeetingUpdate}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="mb-4">
                  <label htmlFor="meet-title" className="form-label">
                    {t('deafultLeftLayout.hostMeeting.lblTitle')}
                  </label>

                  <Field
                    type="text"
                    name="title"
                    value={meetingTitle}
                    onChange={(e: any) => { setMeetingTitle(e.target.value) }}
                    className="form-control"
                    id="meet-title"
                    placeholder={t('deafultLeftLayout.hostMeeting.placeholderMeetingTitle')}
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-danger txt-error"
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="meet-code" className="form-label">
                    {t('deafultLeftLayout.hostMeeting.lblMeetingCode')}
                  </label>
                  <Field
                    type="text"
                    name="code"
                    value={meetingCode}
                    onChange={(e: any) => { setMeetingCode(e.target.value) }}
                    className="form-control"
                    id="meet-code"
                    placeholder={t('deafultLeftLayout.hostMeeting.placeholderMeetingCode')}
                  />
                  <ErrorMessage
                    name="code"
                    component="div"
                    className="text-danger txt-error"
                  />
                  {message && (
                    <div className="form-group">
                      <div
                        className={
                          successful ? "alert alert-success" : "alert alert-danger p-2 rounded-0 border-0"
                        }
                        role="alert"
                      >
                        {message}
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <label className='form-label mb-3'>{t('deafultLeftLayout.hostMeeting.lblEmailProtected')}
                    <input className='ms-1' checked={emailProtected} onChange={(e: any) => setEmailProtected(e.target.checked)} type="checkbox" />
                  </label>
                </div>
                <div className='d-flex justify-content-between flex-wrap'>
                  <label className='form-label mb-3'>{t('common.lblPasswordProtected')} <input className='ms-1' checked={passwordProtected} onChange={(e: any) => setPasswordProtected(e.target.checked)} type="checkbox" /></label>
                  <label className='form-label mb-3'>{t('common.lblGEOProtected')}
                    <input
                      type="checkbox"
                      className='ms-1'
                      checked={geoFenceProtected}
                      onChange={(e: any) => {
                        setGEOFenceProtected(e.target.checked);
                        if (geoFenceProtected !== true) {
                          if ("geolocation" in navigator) {
                            navigator.geolocation.getCurrentPosition(function (position) {
                              setNewLatitude(position.coords.latitude)
                              setNewLongitude(position.coords.longitude)
                              setTimeout(() => { initMap() }, 100);
                              function initMap(): void {
                                var currentLocation = { lat: newLatitude, lng: newLongitude };
                                // The map, centered at Uluru
                                const map = new google.maps.Map(
                                  document.getElementById("editMap") as HTMLElement,
                                  {
                                    zoom: mapZoom,
                                    center: currentLocation
                                  }
                                );
                                // The marker, positioned at Uluru
                                const marker = new google.maps.Marker({
                                  position: currentLocation,
                                  draggable: true,
                                  map: map,
                                });

                                google.maps.event.addListener(marker, 'dragend', function (marker: { latLng: any; }) {
                                  var latLng = marker.latLng;
                                  setNewLatitude(latLng.lat())
                                  setNewLongitude(latLng.lng())
                                  var zoom = map.getZoom();
                                  setMapZoom(zoom);
                                });
                              }
                            },
                              function (error) {
                                if (error.code == error.PERMISSION_DENIED)
                                  toast.warning(t('toastMessage.enableLocationCreateMeeting'), {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 3000,

                                  });
                              });
                          }
                        }
                      }}
                    />
                  </label>
                </div>
                {passwordProtected && (
                  <div className="mb-4">
                    <label
                      htmlFor="meet-host-password"
                      className="form-label"
                    >
                      {t('common.Password')}
                    </label>
                    <Field
                      type="text"
                      name="password"
                      onChange={(e: any) => { setMeetingPassword(e.target.value) }}
                      className="form-control"
                      id="meet-host-password"
                      placeholder={t('deafultLeftLayout.hostMeeting.placeholderMeetingPassword')}

                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-danger txt-error"
                    />
                  </div>
                )}
                <div style={{ height: '250px', width: '100%' }}>
                  <Wrapper apiKey="AIzaSyD-ge9d_DPPLLsEkjlGCNCZhfX69Lr4wjk">
                    <div style={{ height: '250px', width: '100%' }} id="editMap" />
                  </Wrapper>
                </div>
                <div className='border-top py-3 mt-3'>
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button font-22 fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#meetingTimeBlock" aria-expanded="true" aria-controls="meetingTimeBlock">
                      <span>{t('deafultLeftLayout.hostMeeting.lblMeetingTime')} </span>
                      <span className='ms-2'>
                        <i className='fa fa-edit'></i>
                      </span>
                    </button>
                  </h2>
                </div>
                <div className={`row collapse ${errors.startTime ? setErrorActiveClass("show") : "c"} ${errorActiveClass}`} id='meetingTimeBlock'>
                  <div className="col-md-6 mb-4">
                    <label
                      htmlFor="meeting-start-time"
                      className="form-label"
                    >
                      {t('deafultLeftLayout.hostMeeting.lblMeetingStartTime')}
                    </label>
                    <DatePicker className='form-control' name='startTime'
                      selected={startDate}
                      onChange={(date: Date) => setStartDate(date)}
                      minDate={new Date()}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      filterTime={filterPassedStartTime}
                      dateFormat="MMM dd, yyyy hh:mm aa"
                      showTimeSelect
                    />
                    <ErrorMessage
                      name="startTime"
                      component="div"
                      className="text-danger txt-error"
                    />

                  </div>
                  <div className="col-md-6 mb-4">
                    <label
                      htmlFor="meeting-end-time"
                      className="form-label"
                    >
                      {t('deafultLeftLayout.hostMeeting.lblMeetingEndTime')}
                    </label>
                    <DatePicker className='form-control' name='endTime'
                      selected={endDate}
                      onChange={(date: Date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      filterTime={filterPassedEndTime}
                      dateFormat="MMM dd, yyyy hh:mm aa"
                      showTimeSelect
                    />
                    <ErrorMessage
                      name="endTime"
                      component="div"
                      className="text-danger txt-error"
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <div className='d-flex align-items-center justify-content-between'>
                    <label htmlFor="meeting-host-formFile-brand" className="form-label">{t('deafultLeftLayout.hostMeeting.lblMeetingUploadBrand')}</label>
                    <span><small>{t('deafultLeftLayout.hostMeeting.lblUploadOptional')}</small></span>
                  </div>
                  <input ref={inputReference} className="form-control" type="file" accept=".jpg, .jpeg, .png" id="meeting-host-formFile-brand" multiple={true} onChange={(event: any) => {
                    const bfiles = event.target.files;
                    let mybFiles: any = Array.from(bfiles);
                    const brandimge = process.env.REACT_APP_NUMBER_OF_BRAND_UPLOAD_IMAGES;
                    if (mybFiles.length == 0) {
                      setFileValidationError(false)
                      setFileSizevalidation(false)
                    }
                    if (mybFiles.length > Number(brandimge)) {
                      setBrandErr(true);
                    } else {
                      for (let i = 0; i < mybFiles.length; i++) {
                        const fileType: any = mybFiles[i].type;
                        const fileSize: number = mybFiles[i].size;
                        if (fileSize > 2 * 1024 * 1024) {
                          setFileSizevalidation(true)
                          break;
                        } else {
                          setFileSizevalidation(false)
                        }

                        if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg') {
                          setFileValidationError(true)
                        } else {
                          setFileValidationError(false)
                        }
                      }
                      setBrandErr(false);
                    }
                    setBrandFiles(mybFiles)
                  }} />
                  <span style={{ color: "red" }}>
                    {fileValidationError ? t("validation.FormatNotAllowed")
                      : brandErr ? updatedBrandFilesValidation : fileSizevalidation ? t("validation.FileSizeValidation") : ""}
                  </span>
                </div>
                {!!updateData &&
                  !!updateData.brandImageUrls &&
                  updateData.brandImageUrls.length > 0 &&
                  updateData.brandImageUrls.map((imageData: any) => {
                    return (
                      <img src={imageData.brandImageUrl} key={imageData.uuid} className="img-thumbnail image-profile-page" />
                    )
                  })
                }
                <div className="mb-4">
                  <div className='d-flex align-items-center justify-content-between'>
                    <label htmlFor="meeting-host-formFile-sponsors" className="form-label">{t('deafultLeftLayout.hostMeeting.lblMeetingUploadSponsors')}</label>
                    <span><small>{t('deafultLeftLayout.hostMeeting.lblUploadOptional')}</small></span>
                  </div>
                  <input ref={inputReferenced} className="form-control" type="file" accept=".jpg, .jpeg, .png" id="meeting-host-formFile-sponsors" multiple={true} onChange={(event: any) => {
                    const sfiles = event.target.files;
                    let mysFiles: any = Array.from(sfiles);
                    const rawSponsorMaxFileCount = process.env.REACT_APP_NUMBER_OF_SPONSORS_UPLOAD_IMAGES;
                    const sponsorMaxFileCount = rawSponsorMaxFileCount ? parseInt(rawSponsorMaxFileCount, 10) : 0;
                    if (mysFiles.length == 0) {
                      setSponserFileValidationError(false)
                      setsponserFileSizevalidation(false)
                    }
                    if (mysFiles.length > sponsorMaxFileCount) {
                      setSponsorError(true);
                    } else {
                      for (let i = 0; i < mysFiles.length; i++) {
                        const fileType: any = mysFiles[i].type;
                        const fileSize: number = mysFiles[i].size;
                        if (fileSize > 2 * 1024 * 1024) {
                          setsponserFileSizevalidation(true);
                          break;
                        } else {
                          setSponsorsFiles(mysFiles);
                          setsponserFileSizevalidation(false);
                        }
                        if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg') {
                          setSponserFileValidationError(true);
                        } else {
                          setSponsorsFiles(mysFiles);
                          setSponserFileValidationError(false);
                        }
                      }
                      setSponsorError(false);
                    }
                  }} />
                  <span style={{ color: "red" }}>
                    {sponserfileValidationError
                      ? t("validation.FormatNotAllowed")
                      : sponsorErr
                        ? updatedSponsorFilesValidation : sponserfileSizevalidation ? t("validation.FileSizeValidation")
                          : ""}
                  </span>
                </div>
                {!!updateData &&
                  !!updateData.sponsorImageUrls &&
                  updateData.sponsorImageUrls.length > 0 &&
                  updateData.sponsorImageUrls.map((imageData: any) => {
                    return (
                      <img src={imageData.sponsorImageUrl} key={imageData.uuid} className="img-thumbnail image-profile-page" />
                    )
                  })
                }
                <div className="d-flex align-items-center justify-content-center">
                  <button type="submit" className="btn btn-primary mw-120">{t('common.update')}</button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Meeting;
