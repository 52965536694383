import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { interestedListModalHandler } from '../../store/slices/modalSlice'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const InterestedListModal = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    const { interestedList } = useSelector((state: RootState) => state.meeting)
    const { interestedListModal } = useSelector((state: RootState) => state.modal)
    const hideModal = () => {
        dispatch(interestedListModalHandler(false))
    }

    return (
        <Modal show={interestedListModal} centered onHide={hideModal}>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='position-relative fw-semibold'>
                    <span>{t('joinMeeting.intrestedModel.lblInterestedUser')}</span>
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-notification bg-danger">{interestedList?.length}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {interestedList?.length === 0 &&
                    <div className="font-20 text-center" >{t('joinMeeting.intrestedModel.lblNoDataFound')}</div>
                }

                {!!interestedList &&
                    interestedList?.length > 0 &&
                    interestedList.map((intrestedUser: any) => {
                        let intrestedUserFullName = ""
                        let intrestedUserEmail = ""

                        if (!!intrestedUser.userDetails && !!intrestedUser.userId) {
                            intrestedUserFullName = intrestedUser.userDetails?.firstName + " " + intrestedUser.userDetails?.lastName
                            intrestedUserEmail = intrestedUser.userDetails?.email
                        }
                        if (!!intrestedUser.guestUserDetails && !!intrestedUser.guestUserId) {
                            intrestedUserEmail = intrestedUser.guestUserDetails?.email
                            if (intrestedUser.guestUserDetails.isAnonymous === true) {
                                intrestedUserFullName = t('common.lblAnonymous')
                            } else {
                                intrestedUserFullName = intrestedUser.guestUserDetails?.name
                            }
                        }
                        return (
                            <div className='mb-2 pb-2 border-bottom' key={'intrestedUser_' + intrestedUser.id}>
                                {intrestedUserFullName}
                                <div>{intrestedUserEmail}</div>
                            </div>
                        )
                    })}
            </Modal.Body>
            <Modal.Footer className='border-0'>
                <button type="button" className="btn btn-outline-danger rounded-45 mw-120" onClick={hideModal}
                >
                    {t('joinMeeting.intrestedModel.btnClose')}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default InterestedListModal