import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getMeetingNearbyMe, meetingCreate, meetingGetByCodeForCheckMeetingPassword, meetingStatusUpdate, setMeetingSession } from '../../../services/meeting.service';
import { t } from 'i18next'
import { IMeeting } from '../../../types/meeting.type';
import { get } from 'lodash';
import { checkUserAccess } from '../../../services/guestUser.service';
import { stat } from 'fs/promises';
import { attendeeWaitingList } from '../../../services/waitingList.service';
/* eslint-disable @typescript-eslint/no-unused-expressions */

export interface MeetingState {
    nearMeetingList: any,
    LocationdisbleMessage: string,
    currentMeeting: IMeeting | null
    brandErr: boolean
    sponsorErr: boolean
    createdMeeting: any
    codeExistMessage: string
    successful: boolean
    randomCodes: any[]
    passwordProtectedJoin: boolean
    joinMessage: string
    joinSuccessful: boolean
    updateMeetingState: boolean
    isCurrentMeeting: boolean
    isAllowed: boolean
    waitingList: any[]
    interestedList: any[]
    activeQuizId: number
    activeTab: number
    quizResultBtn: boolean
    activeQuizIdGuest: number
    activeQuizIdAttendee: number
}

const initialState: MeetingState = {
    nearMeetingList: [],
    LocationdisbleMessage: "",
    currentMeeting: <IMeeting>{},
    brandErr: false,
    sponsorErr: false,
    createdMeeting: [],
    codeExistMessage: '',
    successful: false,
    randomCodes: [],
    passwordProtectedJoin: false,
    joinMessage: '',
    joinSuccessful: false,
    updateMeetingState: false,
    isCurrentMeeting: false,
    isAllowed: false,
    waitingList: [],
    interestedList: [],
    activeQuizId: 0,
    activeTab: 0,
    quizResultBtn: false,
    activeQuizIdGuest: 0,
    activeQuizIdAttendee: 0
}
export interface INearByMeeting {
    latitude: number,
    longitude: number
}
export interface ICreateMeeting {
    newTitle: string,
    newCode: string,
    meetingPassword: string,
    startTime: Date,
    endTime: Date,
    emailProtected: boolean,
    passwordProtected: boolean,
    geoFenceProtected: boolean,
    newLongitude: number, newLatitude: number, brandFiles: any[], sponsorsFiles: any[]
}
export interface IMeetingCheckHandle {
    code: string,
    loggedInUser: any
}
export interface ICheckUserAccess {
    meetingCodeHome: any,
    joinPassword: any,
    newLongitudeJoin: any,
    newLatitudeJoin: any,
    loggedInUser: any
}
export interface ISetMeetingSession {
    meetingCodeHome: any,
    userId: number,
    guestUserId: number
}
export interface IAttendeeWaitingList {
    meetingId: number,
    hostId: number,
    userId: number,
    guestUserId: number
}
export interface IMeetingStatusUpdate {
    id: number,
    status: any
}



export const meetingHandler: any = createAsyncThunk('nearByMeeting', (data: INearByMeeting) =>
    getMeetingNearbyMe(data?.latitude, data?.longitude)
);
export const meetingCreateHandler: any = createAsyncThunk('createMeeting', (data: ICreateMeeting, { rejectWithValue }) =>
    meetingCreate(data?.newTitle, data?.newCode, data?.meetingPassword, data?.startTime, data?.endTime, data?.emailProtected, data?.passwordProtected, data?.geoFenceProtected, data?.newLongitude, data?.newLatitude, data?.brandFiles, data?.sponsorsFiles).catch((error) => error && rejectWithValue(error))
);
export const meetingGetByCodeCheckingHandler: any = createAsyncThunk('checkingCode', (data: IMeetingCheckHandle) =>
    meetingGetByCodeForCheckMeetingPassword(data.code, data.loggedInUser)
);
export const checkUserAccessHandler: any = createAsyncThunk('checkUserAccess', (data: ICheckUserAccess) =>
    checkUserAccess(data?.meetingCodeHome, data?.joinPassword, data?.newLongitudeJoin, data?.newLatitudeJoin, data?.loggedInUser)
);
export const setMeetingSessionHandler: any = createAsyncThunk('setMeetingSession', (data: ISetMeetingSession) =>
    setMeetingSession(data?.meetingCodeHome, data?.userId, data?.guestUserId)
);
export const attendeeWaitingListHandler: any = createAsyncThunk('attendeeWaitingList', (data: IAttendeeWaitingList) =>
    attendeeWaitingList(data?.meetingId, data?.hostId, data?.userId, data?.guestUserId)
);
export const meetingStatusUpdateHandler: any = createAsyncThunk('meetingStatusUpdate', (data: IMeetingStatusUpdate) =>
    meetingStatusUpdate(data?.id, data?.status)
);



export const meetingSlice = createSlice({
    name: 'meetingSlice',
    initialState,
    reducers: {
        currentMeetingHandler: (state, { payload }: PayloadAction<any>) => {
            state.currentMeeting = payload
            // payload === null ? state.isCurrentMeeting = false : state.isCurrentMeeting = true
            if (payload === null) { state.isAllowed = false, state.activeQuizId = initialState.activeQuizId }
        },
        joinStatusHandler: (state, { payload }: PayloadAction<any>) => {
            state.joinSuccessful = payload
        },
        joinMessageHandler: (state, { payload }: PayloadAction<any>) => {
            state.joinMessage = payload
            state.joinSuccessful = false
        },
        updateMeetingStateHandler: (state, { payload }: PayloadAction<any>) => {
            state.updateMeetingState = payload
        },
        messageHandler: (state, { payload }: PayloadAction<string>) => {
            state.codeExistMessage = payload
        },
        isAllowedHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isAllowed = payload
        },
        waitingListHandler: (state, { payload }: PayloadAction<any>) => {
            state.waitingList = payload
        },
        interestedListHandler: (state, { payload }: PayloadAction<any>) => {
            state.interestedList = payload
        },
        activeQuizIdHandler: (state, { payload }: PayloadAction<number>) => {
            state.activeQuizId = payload;
        },
        activeQuizIdGuestHandler: (state, { payload }: PayloadAction<number>) => {
            state.activeQuizIdGuest = payload;
        },
        activeQuizIdAttendeeHandler: (state, { payload }: PayloadAction<number>) => {
            state.activeQuizIdAttendee = payload;
        },
        activeTabHandler: (state, { payload }: PayloadAction<number>) => {
            state.activeTab = payload;
        },
        quizResultBtnHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.quizResultBtn = payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(meetingHandler.pending, (state, action) => { }),
            builder.addCase(meetingHandler.fulfilled, (state, action) => {
                state.nearMeetingList = action.payload
                if (action.payload.length == 0) {
                    state.LocationdisbleMessage = t('toastMessage.notFoundMeetingNearBy')
                }
            })
        builder.addCase(meetingHandler.rejected, (state, action) => { }),
            builder.addCase(meetingCreateHandler.pending, (state, action) => {
                state.brandErr = false
                state.sponsorErr = false
            }),
            builder.addCase(meetingCreateHandler.fulfilled, (state, action) => {
                state.createdMeeting = action.payload?.data
                state.codeExistMessage = ""
                state.successful = true
            })
        builder.addCase(meetingCreateHandler.rejected, (state, action) => {
            state.successful = false
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const randomCodesError = get(error, 'payload.response.data.randomCodes');
            const networkError = get(error, 'payload.message');
            if (errorMessage) {
                state.codeExistMessage = errorMessage;
            } else if (networkError) {
                state.codeExistMessage = networkError;
            } else if (randomCodesError) {
                state.randomCodes = randomCodesError;
            }
        }),
            builder.addCase(meetingGetByCodeCheckingHandler.fulfilled, (state, action) => {
                if (action?.payload?.data?.status == 'inProgress') {
                    state.passwordProtectedJoin = action.payload?.data?.isPasswordProtected
                }
            })
        builder.addCase(meetingGetByCodeCheckingHandler.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const networkError = get(error, 'payload.message');
            if (errorMessage) {
                state.codeExistMessage = errorMessage;
            } else if (networkError) {
                state.codeExistMessage = networkError;
            }
        });
    },
})

export const { currentMeetingHandler, joinStatusHandler, joinMessageHandler, updateMeetingStateHandler, messageHandler, isAllowedHandler, waitingListHandler, interestedListHandler, activeQuizIdHandler, activeTabHandler, quizResultBtnHandler, activeQuizIdGuestHandler, activeQuizIdAttendeeHandler } = meetingSlice.actions;
export default meetingSlice.reducer;