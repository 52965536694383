import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../pages/store/store';
import { feedbackFormHandler, miniSidebarHandler } from '../pages/store/slices/settingsSlice';
import { currentMeetingHandler, meetingStatusUpdateHandler } from '../pages/store/slices/meetingSlice';
import { meetingQuestionSendViaEmailHandler, setMeetingIntrestedUserHandler } from '../pages/store/slices/joinMeetingSlice';
import socket from '../common/constants';
import DialogBox from '../pages/components/DeleteBox';
import { interestedListModalHandler, waitingListModalHandler } from '../pages/store/slices/modalSlice';
import { userExitWaitingList } from '../services/waitingList.service';
import GuestEmailModal from '../pages/components/Modals/InterestedGuestEmailModal';
import { setMeetingIntrestedGuestUserHandler } from '../pages/store/slices/guestUserSlice';
import FontSizeChanger from '../pages/components/FontSizeChanger';
import { QuizLocalStorage } from '../types/commonTypes';

const Header = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    let location = useLocation();
    let pathname = location.pathname
    const { t } = useTranslation('common');
    const profileRef = useRef<HTMLButtonElement>(null)
    const { user, isUser } = useSelector((state: RootState) => state.auth)
    const { guestUser, isGuest } = useSelector((state: RootState) => state.guestUser)
    const { isFeedBackForm } = useSelector((state: RootState) => state.settings)
    const { isMiniSidebar } = useSelector((state: RootState) => state.settings)
    const { currentMeeting, waitingList, interestedList } = useSelector((state: RootState) => state.meeting)
    const firstName = user?.user?.firstName
    const lastName = user?.user?.lastName
    const usersImageDetails = user?.user?.usersImageDetails && user?.user?.usersImageDetails?.length > 0 && user?.user?.usersImageDetails[0]?.imageUrl
    const userId = user?.user?.id
    const guestUserId = guestUser?.id;
    const currentMeetingId = currentMeeting?.id
    const currentMeetingTitle = currentMeeting?.title
    const currentMeetingHostId = currentMeeting?.hostId
    const currentMeetingGeoFenceProtected = currentMeeting?.isGeoFenceProtected
    const currentMeetingEmailProtected = currentMeeting?.emailProtected || currentMeeting?.isEmailProtected

    const isHost = !!userId && userId === currentMeetingHostId

    const [guestUserEmail, setGuestUserEmail] = useState<string>(guestUser.email);
    const [endMeeting, setEndMeeting] = useState(false);
    const [endMeetingID, setEndMeetingID] = useState(0);
    const [endMeetingStatus, setEndMeetingStatus] = useState('');
    const [leaveMeeting, setLeaveMeeting] = useState<boolean>(false);
    const [isUserIntrested, setIsUserIntrested] = useState<boolean>(false);
    const [isMeetingScreen, setIsMeetingScreen] = useState<boolean>(false)
    const [isWaitingScreen, setIsWaitingScreen] = useState<boolean>(false)
    const [guestEmailModal, setGuestEmailModal] = useState<boolean>(false)
    const [showHeader, setShowHeader] = useState<boolean>(false)
    const [guestLoginHeader, setGuestLoginHeader] = useState<boolean>(false)

    useEffect(() => {
        const paths = ['/waiting-area', '/feedback', '/guest/join']
        const isMatching = paths.some((path: string) => pathname.includes(path))
        if (isUser || (isGuest && isMatching)) {
            setShowHeader(true);
        } else {
            if (pathname.includes('/guest/login')) {
                setGuestLoginHeader(true)
            } else {
                setGuestLoginHeader(false)
            }
            setShowHeader(false);
        }
    }, [isUser, isGuest, pathname])


    useEffect(() => {
        setIsMeetingScreen(pathname.includes('/joinMeeting') || pathname.includes('/waiting-area') || pathname.includes('/guest/join'))
        setIsWaitingScreen(pathname.includes('/waiting-area'))
    }, [pathname])

    const handleChangeGuestEmail = (e: string) => {
        setGuestUserEmail(e)
    }

    const handleLeaveMeeting = async () => {
        if (isWaitingScreen) {
            await userExitWaitingList({
                meetingId: currentMeeting?.id,
                attendeeId: userId ? userId : null,
                guestUserId: guestUserId ? guestUserId : null
            })
            socket.emit('exit-waiting-list', { hostId: currentMeeting?.hostId, meetingId: currentMeeting?.id })

            dispatch(currentMeetingHandler(null))
            if (isGuest) {
                navigate("/")
            } else {
                navigate("/dashboard")
            }
        } else {
            dispatch(feedbackFormHandler(true))
            navigate('/feedback')
        }
        setGuestUserEmail("")
        setIsUserIntrested(false)
        window.localStorage.removeItem(QuizLocalStorage);
    }


    const handleEndMeeting = async (id: any, status: string) => {
        const data = { id, status }
        let meetingStatus = await dispatch(meetingStatusUpdateHandler(data))
        if (meetingStatus.payload.status === 200) {
            await dispatch(meetingQuestionSendViaEmailHandler({
                meetingId: id
            }));
            socket.emit('end-meeting-send', { meetingId: currentMeetingId })
            dispatch(currentMeetingHandler(null))
            navigate('/meeting')
        }
    }

    const hideGuestEmailModal = () => {
        setGuestEmailModal(false)
    }

    const intrestedUserHandler = async () => {
        dispatch(setMeetingIntrestedUserHandler(currentMeetingId))
            .then(() => {
                socket.emit("intrested-user-send", { meetingId: currentMeetingId })
                socket.on('connect', () => {
                    socket.emit("intrested-user-send", { meetingId: currentMeetingId })
                })
                setIsUserIntrested(!isUserIntrested)
            },
                (error: any) => {
                    const resMessage =
                        (error.response.payload &&
                            error.response.data.payload &&
                            error.response.payload.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                }
            )
    }

    const intrestedGuestUserHandler = async () => {
        let data = {
            meetingId: currentMeetingId,
            guestUserId: guestUserId,
            email: guestUserEmail
        }
        dispatch(setMeetingIntrestedGuestUserHandler(data))
            .then(
                (response: any) => {
                    socket.emit("intrested-user-send", { meetingId: currentMeetingId })
                    socket.on('connect', () => {
                        socket.emit("intrested-user-send", { meetingId: currentMeetingId })
                    })
                    setGuestEmailModal(false)
                    setIsUserIntrested(!isUserIntrested)
                },
                (error: any) => {
                    setGuestEmailModal(false)
                    const resMessage =
                        (error.response.payload &&
                            error.response.data.payload &&
                            error.response.payload.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                }
            )
    }

    const interestedGuestHandler = () => {
        if (isUserIntrested) {
            intrestedGuestUserHandler()
        }
        else {
            if (currentMeetingEmailProtected) {
                intrestedGuestUserHandler()
            } else {
                setGuestEmailModal(true)
            }
        }
    }

    const endMeetingHandler = (id: any, status: string) => {
        setEndMeeting(true)
        setEndMeetingID(id)
        setEndMeetingStatus(status)
    }

    const leaveMeetingHandler = () => {
        setLeaveMeeting(true)
    }

    useEffect(() => {
        if (interestedList?.findIndex(x => x.userId === userId) > -1) {
            setIsUserIntrested(true)
        } else {
            setIsUserIntrested(false)
        }
    }, [])

    return (
        <>
            {showHeader ?
                <>
                    <div className="sticky-top bg-white shadow" id="top-navbar">
                        <div className="d-flex">
                            <div className={`${isMiniSidebar ? 'mini-logo' : ''} logo hstack justify-content-center`}>
                                {isFeedBackForm && (isGuest || isUser) ?
                                    <span> <img src="/images/logo.webp" alt="logo" /></span>
                                    :
                                    <Link to='/dashboard'>
                                        <img src="/images/logo.webp" alt="logo" />
                                    </Link>
                                }
                            </div>


                            <div className="flex-fill d-flex justify-content-between align-items-center p-1 pe-3">
                                <div className='hstack gap-2 gap-sm-3 header-left-btns'>
                                    <FontSizeChanger />
                                    {(!isFeedBackForm && !isGuest) &&
                                        <button className="btn p-0" onClick={() => dispatch(miniSidebarHandler(!isMiniSidebar))} id="toggle-sidemenu">
                                            <i className="fa-solid fa-bars text-primary fa-2xl"></i>
                                        </button>
                                    }
                                </div>
                                <div className='hstack gap-2'>
                                    {isMeetingScreen &&
                                        <div className='header-action-btns'>
                                            {isHost ? (
                                                <div className='d-flex gap-2'>
                                                    {currentMeetingGeoFenceProtected &&
                                                        <button type='button' onClick={() => dispatch(waitingListModalHandler(true))} className='btn btn-primary position-relative' title={currentMeetingHostId != userId ? t('guest.guestJoin.lblInterestedToolTip') : ""}>
                                                            <img width={20} src="/images/waiting-list-users.svg" />
                                                            <span className='ms-1 d-none d-md-inline'>{t('guest.guestJoin.lblMeetingWaitingList')}</span>
                                                            <span className="position-absolute top-0 start-90 translate-middle badge rounded-pill badge-notification bg-danger ms-2">{waitingList?.length > 0 ? waitingList?.length : ""}</span>
                                                        </button>
                                                    }
                                                    <button type='button' onClick={() => dispatch(interestedListModalHandler(true))} className='btn btn-primary position-relative' title={currentMeetingHostId != userId ? t('guest.guestJoin.lblInterestedToolTip') : ""}>
                                                        <img width={15} src="/images/interested.svg" />
                                                        <span className='ms-1 d-none d-md-inline'>{t('guest.guestJoin.lblIntrested')}</span>
                                                        <span className="position-absolute top-0 start-90 translate-middle badge rounded-pill badge-notification bg-danger ms-2">{interestedList?.length > 0 ? interestedList?.length : ""}</span>
                                                    </button>

                                                    <button title={t('joinMeeting.endMeeting')} onClick={() => endMeetingHandler(currentMeetingId, "ended")} className="btn btn-danger text-light rounded-45">
                                                        <i className="fa-solid fa-sign-out"></i> <span className='d-none d-md-inline'>{t('joinMeeting.endMeeting')}</span>
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className='d-flex gap-2'>
                                                    {!isWaitingScreen &&

                                                        <button className={`btn ${isUserIntrested ? 'btn-primary text-light' : 'btn-primary-light text-primary'} rounded-45`} onClick={() => isGuest ? interestedGuestHandler() : intrestedUserHandler()} title={t('guest.guestJoin.lblInterestedToolTip')}>
                                                            <img width={15} src={`/images/${isUserIntrested ? 'interested.svg' : 'interest.svg'} `} /> <span className='ms-1 d-none d-md-inline'>{t('guest.guestJoin.lblIntrested')}</span> {isUserIntrested ? '' : '?'}
                                                        </button>
                                                    }
                                                    <button title={t('guest.guestJoin.btnLeaveMeeting')} onClick={leaveMeetingHandler} className="btn btn-danger text-light rounded-45">
                                                        <i className="fa-solid fa-sign-out"></i> <span className='d-none d-md-inline'>{t('guest.guestJoin.btnLeaveMeeting')}</span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    }

                                    {!!isGuest ?
                                        <div className='hstack gap-2'>
                                            <i className="fa-solid fa-user fa-lg text-muted"></i>
                                            <span className='fw-medium text-truncate' style={{ maxWidth: 200 }}>
                                                {
                                                    (guestUser.isAnonymous ? t('common.lblAnonymous') : guestUser.name)
                                                }
                                            </span>
                                        </div>
                                        :
                                        <div className="dropdown">
                                            <button ref={profileRef} className="btn" type="button"
                                                id="header-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className="hstack gap-2">
                                                    {!!usersImageDetails ?
                                                        <img src={usersImageDetails} alt="profile image" className="profile-img-header" />
                                                        :
                                                        <i className="fa-solid fa-user text-muted"></i>
                                                    }
                                                    <span className="d-md-block d-none fw-medium text-truncate" style={{ maxWidth: 200 }}>{firstName + " " + lastName}</span>
                                                </span>
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="profile">
                                                <li><a className="dropdown-item" href="/profile">{t('defaultHeaderLayout.lblProfile')}</a></li>
                                                <li><a className="dropdown-item" href="/logout">{t('defaultHeaderLayout.lblSignOut')}</a></li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <DialogBox show={leaveMeeting} clickOk={() => { handleLeaveMeeting(); setLeaveMeeting(false) }} clickCancel={() => { setLeaveMeeting(false) }} btncancel={t('deleteBox.btnNo')} btnyes={t('deleteBox.btnYes')} question={t('deleteBox.leaveMeeting')} />
                    <DialogBox show={endMeeting} clickOk={() => { handleEndMeeting(endMeetingID, endMeetingStatus); setEndMeeting(false) }} clickCancel={() => { setEndMeeting(false) }} btncancel={t('deleteBox.btnNo')} btnyes={t('deleteBox.btnYes')} question={t('deleteBox.endMeeting')} />
                    <GuestEmailModal guestEmailModal={guestEmailModal} hideGuestEmailModal={hideGuestEmailModal} intrestedGuestUserHandler={intrestedGuestUserHandler} handleChangeGuestEmail={handleChangeGuestEmail} guestUserEmail={guestUserEmail} />
                </>
                :
                <>
                    {
                        guestLoginHeader ?
                            <div className='guestlogin-topbar'>
                                <div className='container'>
                                    <div className='d-flex align-items-center gap-3'>
                                        <div>
                                            <Link className="navbar-brand" to="/">
                                                <img className='img-fluid' src="/images/logo.webp" alt="logo" width="50" height="50" />
                                            </Link>
                                        </div>
                                        <div className='font-24 fw-medium text-light'>{currentMeetingTitle}</div>
                                    </div>
                                </div>
                            </div>
                            :
                            <nav className="sticky-top navbar navbar-light bg-light shadow">
                                <div className="container-fluid">
                                    <div className="navbar-header">
                                        <Link to='/'>
                                            <img src="/images/logo.webp" width="50" height="50" />
                                        </Link>
                                    </div>
                                    <div className="show">
                                        <ul className="nav navbar-right">
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/signin">{t('header.btnLogin')}</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/signup">{t('header.btnSignup')}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                    }
                </>
            }
        </>
    );
};

export default Header;