import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import eventBus from '../../common/EventBus';
import { logoutHandler } from '../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import { currentMeetingHandler } from '../store/slices/meetingSlice';

const Logout = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate();

    const logOut = () => {
        dispatch(currentMeetingHandler(null))
        dispatch(logoutHandler())
        navigate("/");
    };

    useEffect(() => {
        logOut()
        eventBus.on("logout", logOut);
        return () => {
            eventBus.remove("logout", logOut);
        };
    }, []);

    return <></>
}

export default Logout;