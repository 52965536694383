import React, { useEffect, useRef, useState } from 'react'
import { meetingStatusUpdate } from '../../services/meeting.service'
import socket from '../../common/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import Draggable from 'react-draggable';
import { getallWaitingList, userExitWaitingList } from '../../services/waitingList.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { currentMeetingHandler } from '../store/slices/meetingSlice';
import { feedbackFormHandler } from '../store/slices/settingsSlice';
import { debounce } from 'lodash';
import { meetingQuestionSendViaEmailHandler } from '../store/slices/joinMeetingSlice';

const ReturnToMeeting = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    let pathname = location.pathname
    const { user } = useSelector((state: RootState) => state.auth)
    const userId = user?.user?.id
    const { currentMeeting } = useSelector((state: RootState) => state.meeting)
    const currentMeetingId = currentMeeting?.id
    const currentMeetingHostId = currentMeeting?.hostId
    const currentMeetingCode = currentMeeting?.code
    const currentMeetingTitle = currentMeeting?.title

    const [showPopup, setShowPopup] = useState(false)

    const handleEndMeeting = async () => {
        let meetingStatus = await meetingStatusUpdate(currentMeetingId, "ended")
        if (meetingStatus.status === 200) {
            await dispatch(meetingQuestionSendViaEmailHandler({
                meetingId: currentMeetingId
            }));
            socket.emit('end-meeting-send', { meetingId: Number(currentMeetingId) })
            dispatch(currentMeetingHandler(null))
            navigate('/meeting')
        }
    }

    const handleLeaveMeeting = () => {
        if (currentMeeting?.isGeoFenceProtected) {
            getallWaitingList(currentMeetingId)
                .then((res: any) => {
                    if (res.data.length > 0) {
                        res.data.map(async (ele: any) => {
                            if (ele.meetingId === currentMeetingId && ele.attendeeId === userId) {
                                if (ele.status === 'approved') {
                                    dispatch(feedbackFormHandler(true))
                                    navigate('/feedback')
                                } else if (ele.status === 'pending') {
                                    await userExitWaitingList({
                                        meetingId: currentMeetingId,
                                        attendeeId: userId,
                                        guestUserId: null
                                    })
                                    socket.emit('exit-waiting-list', { hostId: currentMeetingHostId, meetingId: currentMeetingId })
                                    dispatch(currentMeetingHandler(null))
                                    // navigate("/dashboard")
                                }
                            }
                        })
                    } else {
                        dispatch(feedbackFormHandler(true))
                        navigate('/feedback')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching waiting list:", error);
                });
        } else {
            dispatch(feedbackFormHandler(true))
            navigate('/feedback')
        }
    }

    const handleReturnTo = () => {
        if (currentMeeting?.hostId !== userId) {
            if (currentMeeting?.isGeoFenceProtected == true) {
                getallWaitingList(currentMeetingId)
                    .then((res: any) => {
                        if (res.data.length > 0) {
                            res.data.map((ele: any) => {
                                if (ele.meetingId === currentMeetingId && ele.attendeeId === userId) {
                                    if (ele.status === 'approved') {
                                        navigate(`/joinMeeting/${currentMeetingCode}`)
                                    }
                                    else if (ele.status === 'pending') {
                                        navigate(`/waiting-area/` + currentMeetingCode)
                                    }
                                }
                            })
                        } else {
                            navigate(`/joinMeeting/${currentMeetingCode}`)
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching waiting list:", error);
                    });
            } else {
                navigate(`/joinMeeting/${currentMeetingCode}`)
            }

        } else {
            navigate(`/joinMeeting/${currentMeetingCode}`)
        }
    }

    const dragRef = useRef<Draggable | null>(null)
    const dragWidth = 225
    const dragHeight = 115
    const offsetPosition = 50

    const dragPosition = {
        x: window.innerWidth - dragWidth - offsetPosition,
        y: window.innerHeight - dragHeight - offsetPosition
    }

    const [dragBounds, setDragBounds] = useState({
        left: 0,
        top: 0,
        right: window.innerWidth - dragWidth,
        bottom: window.innerHeight - dragHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            if (showPopup) {
                let dragPosition = {
                    x: window.innerWidth - dragWidth - offsetPosition,
                    y: window.innerHeight - dragHeight - offsetPosition
                }
                let dragBounds = {
                    left: 0,
                    top: 0,
                    right: window.innerWidth - dragWidth,
                    bottom: window.innerHeight - dragHeight,
                }
                setDragBounds(dragBounds)
                dragRef?.current?.setState(dragPosition);
            }
        };

        const debouncedHandleResize = debounce(handleResize, 100);
        window.addEventListener('resize', debouncedHandleResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);

    useEffect(() => {
        setShowPopup(!(pathname.includes('/joinMeeting') || pathname.includes('/waiting-area') || pathname.includes('/feedback')))
    }, [pathname])

    return (
        <>
            {!!currentMeetingCode && showPopup &&
                <Draggable
                    defaultPosition={dragPosition}
                    ref={dragRef}
                    handle=".drag-handle"
                    bounds={dragBounds}
                >
                    <div className='meet-actions-wrapper'>
                        <div className='meet-actions d-flex flex-column text-white bg-primary rounded-4' style={{ minWidth: `${dragWidth}px` }}>
                            <div className='flex-50 text-center p-3 border-bottom drag-handle border-white'>
                                <span className='d-block text-truncate' style={{ maxWidth: 250 }}>{currentMeetingTitle}</span>
                            </div>
                            <div className='flex-50 d-flex align-items-stretch'>
                                <button
                                    onClick={handleReturnTo}
                                    className="btn flex-50 d-flex align-items-center justify-content-center gap-2 p-3 rounded-0 text-white"
                                >
                                    <i className="fa-solid fa-right-to-bracket"></i> Return
                                </button>
                                <span className='border-end border-white'></span>
                                <button
                                    onClick={() => currentMeetingHostId === userId ? handleEndMeeting() : handleLeaveMeeting()}
                                    className="btn flex-50 d-flex align-items-center justify-content-center gap-2 p-3 rounded-0 text-white"
                                >
                                    <i className="fa-solid fa-phone-slash"></i>
                                    {currentMeetingHostId === userId ? ' End' : ' Leave'}
                                </button>
                            </div>
                        </div>
                    </div>
                </Draggable>
            }
        </>
    )
}

export default ReturnToMeeting