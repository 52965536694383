import React, { useEffect, useState, useRef } from 'react';
import { IMeetingSendQuestion } from '../../types/meeting.type';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteMeetingQuestion, getAllUpvoteMeetingQuestionListrByMeetingId, getMeetingIntrestedUsers, getMeetingQuestions, meetingGetByCode } from '../../services/meeting.service';
import socket from '../../common/constants';
import { useTranslation } from 'react-i18next';
import ShareMeetingDetails from '../components/ShareMeetingDetails';
import DialogBox from '../components/DeleteBox';
import { getAllPollsQuestionsAnswerByMeetingId, getPollQuestionsAnswerByGuest, getPollsForMeeting } from '../../services/poll.service';
import { toast } from 'react-toastify';
import { getallWaitingList, userExitWaitingList } from '../../services/waitingList.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../store/store';
import { activeQuizIdGuestHandler, activeTabHandler, currentMeetingHandler, isAllowedHandler } from '../store/slices/meetingSlice';
import { addPollsQuestionsAnswerByGuestHandler, guestAddMeetingQuestionHandler, guestEditMeetingQuestionHandler, saveUpvoteForMeetingQuestionForGuestHandler, saveUpvoteForMeetingQuestionReplyForGuestHandler } from '../store/slices/guestUserSlice';
import { feedbackFormHandler } from '../store/slices/settingsSlice';
import { Tab, Tabs } from 'react-bootstrap';
import { getSubmittedQuizzesByGuest, getQuizzesForMeeting } from '../../services/quiz.service';
import { Model, Serializer } from 'survey-core';
import { Survey } from 'survey-react-ui';
import * as SurveyTheme from "survey-core/themes";
import { quizAnswerByGuestHandler } from '../store/slices/quizSlice';
import ShowQuizResult from '../components/Results/ShowQuizResult';
import { QuizLocalStorage } from '../../types/commonTypes';
import { getJoinedMeetingUsersByMeetingIdHandler } from '../store/slices/joinMeetingSlice';

const GuestJoin = () => {
    const dispatch = useDispatch()
    const { currentMeeting, isAllowed, activeQuizIdGuest } = useSelector((state: RootState) => state.meeting)
    const { guestUser } = useSelector((state: RootState) => state.guestUser)
    const { isFeedBackForm } = useSelector((state: RootState) => state.settings)
    const guestUserId = guestUser?.id
    const currentMeetingId = currentMeeting?.id
    const currentMeetingHostId = currentMeeting?.hostId

    const questionsListRef = useRef<HTMLDivElement>(null)
    const { t } = useTranslation('common');
    let { code }: any = useParams();
    let navigate = useNavigate();
    const [meetingQuestions, setMeetingQuestions] = useState([])
    const [meetingCode, setMeetingCode] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [meetingQuestionDeleteId, setMeetingQuestionDeleteId] = useState(0);
    const [meetingPollsList, setMeetingPollsList] = useState<any[]>([]);
    const [allPollQuestions, setAllPollQuestions] = useState<any[]>([]);
    const [submitPoll, setSubmitPoll] = useState(false);
    const [submitPollId, setSubmitPollId] = useState();
    const [newSelectedAnswer, setNewSelectedAnswers] = useState<any>([]);
    const [GroupedQuestions, setGroupedQuestions] = useState<any[]>([]);
    const [GroupedAnswers, setGroupedAnswers] = useState<any[]>([]);
    const [allUpvoteMeetingQuestionList, setAllUpvoteMeetingQuestionList] = useState<any[]>([]);
    const all = { all: 'all' }
    const [selectedUser, setSelectedUser] = useState(all);
    const [shortBy, setShortBy] = useState("all");
    const [meetingIntrestedUserList, setMeetingIntrestedUserList] = useState<any[]>([]);
    const [allJoinedUsers, setAllJoinedUsers] = useState<any[]>([]);
    const [meetingAllPollsAnswerList, setMeetingAllPollsAnswerList] = useState<any[]>([]);
    const [isShowResult, setIsShowResult] = useState<boolean>(false);
    const [isShowQuizResult, setIsShowQuizResult] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [pollTab, setPollTab] = useState(false)
    const [questionTab, setQuestionTab] = useState(false)
    const [quizTab, setQuizTab] = useState(false)
    const [activePolls, setActivePolls] = useState(0)
    const [activeQuiz, setActiveQuiz] = useState(0)
    const questionBoxRef = useRef<HTMLTextAreaElement>()
    const [showAnswerQuestion, setShowAnswerQuestion] = useState<any>(false)
    const [showArchiveQuestion, setShowArchiveQuestion] = useState<any>(false)
    const [showUnanswerQuestion, setShowUnanswerQuestion] = useState<any>(false)
    const [meetingQuestion, setEditMeetingQuestions] = useState<any>("")
    const [editMeetingCondition, setEditMeetingCondition] = useState<boolean>(false)
    const [editMeetingQuestionId, setEditMeetingQuestionsId] = useState<any>("")
    const [activeQuizData, setActiveQuizData] = useState<any>([]);
    const [meetingQuizList, setMeetingQuizzesList] = useState<any[]>([]);
    const [allQuizAnswer, setAllQuizAnswer] = useState<any[]>([])

    const anonymous = {
        anonymous: 'anonymous'
    }

    const onClickApplyFilter = async () => {
        let shortByValue = shortBy;
        if (typeof (localStorage.getItem("shortBy")) === "string") {
            shortByValue = localStorage.getItem("shortBy") as string
        }
        let meetingQues = await getMeetingQuestions(currentMeetingId, selectedUser)
        if (meetingQues.status === 200) {
            shortingQustions(shortByValue, meetingQues.data.data)
        }
    }

    const onClickResetFilter = async () => {
        localStorage.setItem("filterUser", JSON.stringify(all))
        setSelectedUser(all)
        setShowAnswerQuestion(false)
        setShowArchiveQuestion(false)
        setShowUnanswerQuestion(false)
    }

    const onChangeUserFilter = async (e: any) => {
        localStorage.setItem("filterUser", e.target.value)
        let data = JSON.parse(e.target.value)
        setSelectedUser(data)
    }

    const [scrollBottom, setScrollBottom] = useState<any>(false)
    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const scrollBottomCheck = () => {
        if (!!questionsListRef.current) {
            let element = questionsListRef.current
            const isAtBottom = element.scrollTop + element.clientHeight === element.scrollHeight;
            if (!isAtBottom) {
                setScrollBottom(true)
            } else (
                setScrollBottom(false)
            )
        }
    }

    const getTotalPercentage = (pollId: number, questionId: number, questionOptionId: number) => {
        let totalPercentage = 0;
        let totalAnswerdUser = meetingAllPollsAnswerList.filter((x: any) => (x.pollId === pollId && x.questionId === questionId && x.questionOptionId === questionOptionId))
        let totalQuestionAnswerdUser = meetingAllPollsAnswerList.filter((x: any) => (x.pollId === pollId && x.questionId === questionId))
        totalPercentage = (totalAnswerdUser.length * 100) / totalQuestionAnswerdUser.length;
        let tempTotalpercentage = Number(totalPercentage.toFixed(2))
        return totalPercentage > 0 ? tempTotalpercentage : 0
    }

    const getTotalVote = (pollId: number, questionId: number) => {
        let totalQuestionAnswerdUser = meetingAllPollsAnswerList.filter((x: any) => (x.pollId === pollId && x.questionId === questionId))
        return totalQuestionAnswerdUser.length
    }

    const getMeetingDetailByMeetingCode = async (code: string) => {
        meetingGetByCode(code, 0, guestUserId).then(
            (response) => {
                if (response.status === 200) {
                    if (response.data.status === 'ended') {
                        toast.success(t('toastMessage.meetingEndedHost'), {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                        });
                        handleLeaveMeeting()
                    } else {
                        dispatch(currentMeetingHandler(response.data))
                        if (isAllowed) {
                            setTimeout(() => {
                                socket.emit('join-meeting-send', { meetingId: response.data.id, guestUser: guestUser })
                            }, 0);
                            socket.on('connect', () => {
                                socket.emit('join-meeting-send', { meetingId: response.data.id, guestUser: guestUser })
                            })
                        }
                    }
                }
            },
            (error) => {
                const resMessage =
                    (error.response.payload &&
                        error.response.payload.data &&
                        error.response.payload.data.message) ||
                    error.message ||
                    error.toString();
                toast.error(t('toastMessage.meetingNotFound'), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                handleLeaveMeetingHome()
            }
        )

    }

    const editQuestion = (meetingQuestion: any, meetingQuestionId: any) => {
        setEditMeetingQuestions(meetingQuestion);
        setEditMeetingQuestionsId(meetingQuestionId)
        setEditMeetingCondition(true);
        questionBoxRef.current?.focus()
    }

    const resetQuestionBox = () => {
        setEditMeetingQuestions('');
        setEditMeetingCondition(false);
    }

    const newmeetingQuestion = meetingQuestion.trim()
    const initialValuesSendQuestion: IMeetingSendQuestion = {
        question: newmeetingQuestion
    }

    const deleteConfirmHandler = (id: any) => {
        setShowDelete(true)
        setMeetingQuestionDeleteId(id)
    }

    const handleDeleteMeetingQuestion = (id: number) => {
        if (id > 0) {
            deleteMeetingQuestion(id).then(
                (response) => {
                    if (response.status === 200) {
                        socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                        socket.on('connect', () => {
                            socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                        })
                        resetQuestionBox()
                    }
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                }
            )
        } else {
            console.log("Meeting question not found...")
        }
    }

    const handleSendQuestion = (onSubmitProps: any) => {
        const queData = meetingQuestion.trim()
        if (queData.length !== 0) {
            if (editMeetingCondition == true) {
                const hasUpvotes = getUpvotedCount(editMeetingQuestionId);
                const hasReplies: any = meetingQuestions.find((item: any) => item.id === editMeetingQuestionId);
                const votesLength = hasUpvotes > 0
                const replyLength = hasReplies?.meetingQuestionsReplyDetails.length > 0
                if (replyLength || votesLength) {
                    toast.error(t('toastMessage.editQuestionValidation'), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                    });
                    resetQuestionBox()

                } else {
                    if (!!currentMeetingId && !!guestUser.id) {
                        const data = {
                            question: queData, meetingId: currentMeetingId, guestUserId: guestUserId, meetingQuestionId: editMeetingQuestionId, isEdited: true
                        }
                        dispatch(guestEditMeetingQuestionHandler(data))
                            .then(
                                (response: any) => {
                                    if (response?.payload?.status === 201) {
                                        const newData = response?.payload?.data || initialValuesSendQuestion;
                                        if (onSubmitProps.formik) {
                                            onSubmitProps.formik.resetForm({ values: newData });
                                        } socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                                        socket.on('connect', () => {
                                            socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                                        })
                                    }
                                    setEditMeetingQuestions('');
                                    setEditMeetingCondition(false);
                                },
                                (error: any) => {
                                    const resMessage =
                                        (error.response.payload &&
                                            error.response.payload?.data &&
                                            error.response.payload?.data.message) ||
                                        error.message ||
                                        error.toString();
                                    console.log("resMessage ==>", resMessage)
                                }
                            )
                    } else {
                        console.log("Meeting or guest User not found...")
                    }
                }
            } else {
                if (!!currentMeetingId && !!guestUserId) {
                    const data = { question: queData, meetingId: currentMeetingId, guestUserId: guestUserId }
                    dispatch(guestAddMeetingQuestionHandler(data))
                        .then(
                            (response: any) => {
                                if (response?.payload?.status === 201) {
                                    const newData = response?.payload?.data || initialValuesSendQuestion;
                                    if (onSubmitProps.formik) {
                                        onSubmitProps.formik.resetForm({ values: newData });
                                    }
                                    socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                                    socket.on('connect', () => {
                                        socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                                    })
                                }
                                setEditMeetingQuestions('');
                            },
                            (error: any) => {
                                const resMessage =
                                    (error.response.payload &&
                                        error.response.payload.data &&
                                        error.response.payload.data.message) ||
                                    error.message ||
                                    error.toString();
                                console.log("resMessage ==>", resMessage)
                            }
                        )
                } else {
                    console.log("Meeting or guest User not found...")
                }
            }
        }
        scrollBottomCheck()
        scrollToBottom()
    };

    const getMeetingQuestionsByMeetingId = async (meetingId: number) => {
        if (!!meetingId) {
            let meetingQues = await getMeetingQuestions(meetingId, selectedUser)

            let shortByValue = shortBy;
            if (typeof (localStorage.getItem("shortBy")) === "string") {
                shortByValue = localStorage.getItem("shortBy") as string
            }

            if (meetingQues.status === 200) {
                shortingQustions(shortByValue, meetingQues.data.data)
            }
        }
        scrollBottomCheck()
    }

    const handleLeaveMeeting = async () => {
        if (isAllowed) {
            navigate('/feedback')
        } else {
            await userExitWaitingList({
                meetingId: currentMeetingId,
                attendeeId: null,
                guestUserId: guestUserId
            })
            socket.emit('exit-waiting-list', { hostId: currentMeetingHostId, meetingId: currentMeetingId })
            dispatch(currentMeetingHandler(null))
            navigate("/")
        }
    }

    const handleLeaveMeetingHome = () => {
        navigate("/")
    }

    const getPollsByMeetingId = async (meetingId: number) => {
        if (!!meetingId) {
            await getPollsForMeeting(meetingId).then((response) => {
                if (response.status === 200) {
                    if (response.data.length > 0) {
                        setMeetingPollsList(response.data)
                        let allPollQuestions: any[] = []
                        response.data.map((poll: any, i: any) => {
                            return poll.pollDetails.pollsQuestionDetails.map((question: any, j: any) => {
                                return allPollQuestions.push(question)
                            })
                        })
                        setAllPollQuestions(allPollQuestions)

                        let groupedQuestions: any = []
                        response.data.forEach((item: any) => {
                            const pollId = item.pollId;
                            const questionDetails = item.pollDetails.pollsQuestionDetails;
                            const length = questionDetails.length;

                            if (!groupedQuestions[pollId]) {
                                groupedQuestions[pollId] = [];
                            }

                            groupedQuestions[pollId].push(length);
                        });
                        setGroupedQuestions(groupedQuestions)
                    } else {
                        setMeetingPollsList([])
                    }
                }
            })
        }
    }

    const handlePublishPoll = (pollId: any) => {
        const selectedPollAnswers = selectedOptions.filter((answer: any) => answer.pollId === pollId);
        const selectedPollQuestion = allPollQuestions.filter((question: any) => question.pollId === pollId);

        if (selectedPollQuestion.length === selectedPollAnswers.length) {
            onChangeGuestAnswer(selectedPollAnswers, pollId);
        }
        else {
            setSubmitPollId(pollId);
            setSubmitPoll(true);
        }
    };

    const handleSubmitPoll = (pollId: any) => {
        const selectedPollAnswers = selectedOptions.filter((answer: any) => answer.pollId === pollId);
        onChangeGuestAnswer(selectedPollAnswers, pollId);
    }

    const handleCheckboxChange = (e: any, selectedAnswer: any) => {
        const selectedArray = selectedOptions.length > 0 ? selectedOptions : newSelectedAnswer
        const existingIndex = selectedArray?.some((answer: any, i: any) => (answer.pollId === selectedAnswer.pollId && answer.questionId === selectedAnswer.questionId && answer.questionOptionId === selectedAnswer.questionOptionId));

        let updateSelectedAnswer;
        if (existingIndex) {
            e.target.checked = false
            updateSelectedAnswer = selectedArray.filter((item: any) =>
                !(item.pollId === selectedAnswer.pollId &&
                    item.questionId === selectedAnswer.questionId &&
                    item.questionOptionId === selectedAnswer.questionOptionId));

        } else {
            updateSelectedAnswer = [...selectedArray.filter((item: any) =>
                !(item.pollId === selectedAnswer.pollId && item.questionId === selectedAnswer.questionId)
            ), selectedAnswer];

        }

        setNewSelectedAnswers(updateSelectedAnswer);
        setSelectedOptions(updateSelectedAnswer)
    };

    const onChangeGuestAnswer = async (selectedPollAnswers: any, pollId: any) => {
        const data = {
            meetingId: currentMeetingId,
            newSelectedAnswer: selectedPollAnswers,
            guestUserId: guestUserId,
            pollId: pollId
        }
        dispatch(addPollsQuestionsAnswerByGuestHandler(data))
            .then(
                (response: any) => {
                    if (response?.payload?.status === 201) {
                        socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
                        socket.on('connect', () => {
                            socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
                        })
                        toast.success(t('toastMessage.pollSubmitted'), {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                        });
                    }
                },
                (error: any) => {
                    const resMessage =
                        (error.response.payload &&
                            error.response.payload.data &&
                            error.response.payload.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                }
            )
    }

    const getPollAnswerList = async (meetingId: number, guestUser: any) => {
        getPollQuestionsAnswerByGuest(meetingId, guestUser).then(
            (response) => {
                if (response.status === 200) {
                    let groupedAnswers: any = []
                    response.data.forEach((item: any) => {
                        const pollId = item.pollId;
                        if (!groupedAnswers[pollId]) {
                            groupedAnswers[pollId] = [];
                        }
                        groupedAnswers[pollId].push(pollId);
                    });
                    groupedAnswers = groupedAnswers.map((item: any) => [item.length]);
                    setGroupedAnswers(groupedAnswers)
                    setNewSelectedAnswers(response.data.map((item: any) => ({
                        pollId: item.pollId,
                        questionId: item.questionId,
                        questionOptionId: item.questionOptionId
                    })))
                }

            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }

    const checkOptionAnswerAvailable = (id: any) => {
        let checkIdInAnswerList = newSelectedAnswer?.findIndex((item: any) => (item.pollId === id.pollId && item.questionId === id.questionId && item.questionOptionId === id.questionOptionId))
        if (checkIdInAnswerList > -1) {
            return true
        } else {
            return false
        }
    }

    const handleUpvoteMeetingQuestion = async (meetingQuestionId: number) => {
        const data = {
            meetingId: currentMeetingId, meetingQuestionId: meetingQuestionId, guestUserId: guestUserId
        }
        dispatch(saveUpvoteForMeetingQuestionForGuestHandler(data))
            .then(
                (response: any) => {
                    socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                    socket.on('connect', () => {
                        socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                    })
                },
                (error: any) => {
                    const resMessage =
                        (error.response.payload &&
                            error.response.payload.data &&
                            error.response.payload.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                }
            )
    }

    const handleUpvoteMeetingQuestionReply = async (meetingQuestionId: number, meetingQuestionsReplyId: number) => {
        const data = {
            meetingQuestionId: meetingQuestionId,
            meetingQuestionsReplyId: meetingQuestionsReplyId,
            guestUserId: guestUserId
        }
        dispatch(saveUpvoteForMeetingQuestionReplyForGuestHandler(data))
            .then(
                (response: any) => {
                    socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                    socket.on('connect', () => {
                        socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                    })
                },
                (error: any) => {
                    const resMessage =
                        (error.response.payload &&
                            error.response.payload.data &&
                            error.response.payload.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                }
            )
    }

    const getAllUpvoteMeetingQuestionList = async (meetingId: number) => {
        getAllUpvoteMeetingQuestionListrByMeetingId(meetingId).then(
            (response) => {
                if (response.status === 200) {
                    setAllUpvoteMeetingQuestionList(response.data)
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }

    const getJoinedMeetingUsers = async (meetingId: number) => {
        dispatch(getJoinedMeetingUsersByMeetingIdHandler(meetingId))
            .then(
                (response: any) => {
                    if (response?.payload?.status === 200) {
                        let data: any = [];
                        response.payload?.data.data.forEach((usersData: any) => {
                            if (!!usersData.joinUser && !!usersData.userId) {
                                data.push(usersData)
                            }
                            if (!!usersData.joinGuestUser && !!usersData.guestUserId && usersData.joinGuestUser.isAnonymous === false) {
                                data.push(usersData)
                            }
                        });
                        setAllJoinedUsers(data)
                    }
                },
                (error: any) => {
                    const resMessage =
                        (error.response.payload &&
                            error.response.payload?.data &&
                            error.response.payload?.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                }
            )
    }


    const getUpvotedCount = (meetingQuestionId: number) => {
        let dataList = allUpvoteMeetingQuestionList.filter((x: any) => (x.meetingQuestionId === meetingQuestionId))
        return dataList.length
    }

    const getUpvotedAvaliable = (meetingQuestionId: number) => {
        let indexValue = allUpvoteMeetingQuestionList.findIndex((x: any) => (x.meetingQuestionId === meetingQuestionId && x.guestUserId === guestUserId))
        if (indexValue > -1) {
            return true
        } else {
            return false
        }
    }

    const getReplyUpvotedAvaliable = (meetingQuestionsReplyUpvoteDetails: any[]) => {
        let indexValue = meetingQuestionsReplyUpvoteDetails.findIndex((x: any) => (x.guestUserId === guestUserId))
        if (indexValue > -1) {
            return true
        } else {
            return false
        }
    }

    const shortingQustions = async (shortByValue: string, data: any) => {
        let dataSort;
        let filterOnchecked;
        switch (shortByValue) {
            case 'all':
                dataSort = data.sort((a: any, b: any) => (a['createdAt'] > b['createdAt'] ? 1 : -1))
                filterOnchecked = filterDatabyOnChecked(dataSort)
                setMeetingQuestions(filterOnchecked)
                break;
            case 'upvoted':
                // code block short by upVotedCounters
                dataSort = data.sort((a: any, b: any) => (a['meetingsQuestionUpvoteDetails'] > b['meetingsQuestionUpvoteDetails'] ? 1 : -1))
                filterOnchecked = filterDatabyOnChecked(dataSort)
                setMeetingQuestions(filterOnchecked)
                break;
            case 'time':
                dataSort = data.sort((a: any, b: any) => (a['createdAt'] < b['createdAt'] ? 1 : -1))
                filterOnchecked = filterDatabyOnChecked(dataSort)
                setMeetingQuestions(filterOnchecked)
                break;
            default:
                dataSort = data.sort((a: any, b: any) => (a['createdAt'] > b['createdAt'] ? 1 : -1))
                filterOnchecked = filterDatabyOnChecked(dataSort)
                setMeetingQuestions(filterOnchecked)
                break;
        }
    }

    const filterDatabyOnChecked = (data: any) => {
        let filter = data;
        if (showAnswerQuestion === true && showArchiveQuestion === false && showUnanswerQuestion === false) {
            // Show Only Answer Question
            return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length > 0))

        } else if (showAnswerQuestion === false && showArchiveQuestion === true && showUnanswerQuestion === false) {
            // Show Only Archive Question
            return filter = data.filter((x: any) => (x['isArchive'] === true))

        } else if (showAnswerQuestion === false && showArchiveQuestion === false && showUnanswerQuestion === true) {
            // Show Only Unanswer Question
            return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length === 0))

        } else if (showAnswerQuestion === true && showArchiveQuestion === true && showUnanswerQuestion === false) {
            // Show Only Answer Question and Show Only Archive Question
            return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length > 0 && x['isArchive'] === true))

        } else if (showAnswerQuestion === false && showArchiveQuestion === true && showUnanswerQuestion === true) {
            // Show Only Archive Question and Show Only Unanswer Question
            return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length === 0 && x['isArchive'] === true))

        } else if (showAnswerQuestion === true && showArchiveQuestion === false && showUnanswerQuestion === true) {
            // Show Only Answer Question and  Show Only Unanswer Question
            return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length > 0 && x['meetingQuestionsReplyDetails'].length === 0))

        } else if (showAnswerQuestion === true && showArchiveQuestion === true && showUnanswerQuestion === true) {
            // Show Only Answer Question and how Only Archive Question and Show Only Unanswer Question
            return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length > 0 && x['meetingQuestionsReplyDetails'].length === 0 && showUnanswerQuestion === true))

        } else {
            // none of above ( default)
            return filter
        }
    }

    const onChangeShort = async (shortByValue: string) => {
        const state = store.getState()
        const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
        localStorage.setItem("shortBy", shortByValue)
        setShortBy(shortByValue)
        getMeetingQuestionsByMeetingId(currentMeetingIdSocket)
    }

    const getAllPollAnswerList = async (meetingId: number, guestUserId: number) => {
        await getAllPollsQuestionsAnswerByMeetingId(meetingId).then(
            (response) => {
                if (response.status === 200) {
                    if (response.data.length > 0) {
                        setMeetingAllPollsAnswerList(response.data)
                    }
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }

    const getListOfMeetingIntrestedUsers = async (meetingId: number) => {
        getMeetingIntrestedUsers(meetingId).then(
            (response) => {
                if (response.status === 200) {
                    setMeetingIntrestedUserList(response.data)
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }

    useEffect(() => {
        if (!!code) {
            setMeetingCode(code)
        }
    }, [code])

    useEffect(() => {
        if (!!meetingCode) {
            getMeetingDetailByMeetingCode(meetingCode)
            getQuizzesByMeetingId(currentMeetingId, activeQuizIdGuest);
        }
    }, [meetingCode])

    useEffect(() => {
        socket.on('join-meeting-res', (data) => {
            const state = store.getState()
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
            const guestUserIdSocket = state.guestUser?.guestUser?.id
            if (!!currentMeetingIdSocket && guestUserIdSocket === data?.guestUser?.id) {
                getMeetingQuestionsByMeetingId(currentMeetingIdSocket)
                getPollsByMeetingId(currentMeetingIdSocket)
                getPollAnswerList(currentMeetingIdSocket, guestUserId)
                getAllPollAnswerList(currentMeetingIdSocket, guestUserId)
                getAllUpvoteMeetingQuestionList(currentMeetingIdSocket)
                getJoinedMeetingUsers(currentMeetingIdSocket)
                getListOfMeetingIntrestedUsers(currentMeetingIdSocket)
                getAllQuizByMeetingId(currentMeetingIdSocket)
            }
        })

        socket.on("meeting-question-res", async () => {
            const state = store.getState()
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
            if (!!currentMeetingIdSocket) {
                getMeetingQuestionsByMeetingId(currentMeetingIdSocket)
                getAllUpvoteMeetingQuestionList(currentMeetingIdSocket)
            }
        })

        socket.on("meeting-polls-res", async () => {
            const state = store.getState()
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
            if (!!currentMeetingIdSocket) {
                getPollsByMeetingId(currentMeetingIdSocket)
                getPollAnswerList(currentMeetingIdSocket, guestUserId)
                getAllPollAnswerList(currentMeetingIdSocket, guestUserId)
            }
        })

        socket.on("meeting-quiz-res", async (data: any) => {
            const state = store.getState()
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
            if (!!currentMeetingIdSocket) {
                if (!!data.quizId) {
                    window.localStorage.removeItem(QuizLocalStorage);
                    getQuizzesByMeetingId(currentMeetingIdSocket, data.quizId);
                    dispatch(activeQuizIdGuestHandler(data.quizId))
                    let allQuizAnswer: any[] = []
                    await getSubmittedQuizzesByGuest(currentMeetingIdSocket, guestUserId).then((res: any) => {
                        allQuizAnswer = res.data
                    })
                    const isNewQuiz = allQuizAnswer?.every((submittedQuiz) => {
                        return data.quizId !== submittedQuiz.quizId
                    });
                    if (isNewQuiz) {
                        setIsShowQuizResult(false)
                    }
                }
            }
        })

        socket.on("intrested-user-res", async () => {
            const state = store.getState()
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
            if (!!currentMeetingIdSocket) {
                getListOfMeetingIntrestedUsers(currentMeetingIdSocket)
            }
        })

        socket.on("meeting-question-archive-res", async () => {
            const state = store.getState()
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
            if (!!currentMeetingIdSocket) {
                resetQuestionBox()
            }
        })

        return (() => {
            socket.off('join-meeting-res')
            socket.off('meeting-question-res')
            socket.off('meeting-polls-res')
            socket.off('meeting-quiz-res')
            socket.off('intrested-user-res')
            socket.off('meeting-question-archive-res')
        })

    }, [currentMeeting])

    const getAllQuizByMeetingId = async (meetingId: number) => {
        let quizzesListData = await getQuizzesForMeeting(meetingId)
        if (quizzesListData.status === 200) {
            setMeetingQuizzesList(quizzesListData.data?.meetingQuizData)
        }
    }

    const getQuizzesByMeetingId = async (meetingId: number, quizId: number) => {
        if (!!meetingId) {
            let quizzesListData = await getQuizzesForMeeting(meetingId)
            if (quizzesListData.status === 200) {
                quizzesListData.data.meetingQuizData.map((item: any, index: number) => {
                    if (item.quizId === quizId) {
                        setActiveQuizData(quizzesListData.data?.meetingQuizzesData[index])
                    }
                })
                setMeetingQuizzesList(quizzesListData.data?.meetingQuizData)
            }
        }
    }

    const shareMeetingMobileShow = () => {
        if (document.body.classList.contains('share-meeting-show')) {
            document.body.classList.remove('share-meeting-show');
        } else {
            document.body.classList.add('share-meeting-show');
        }

    }

    useEffect(() => {
        // Calculate the count of active polls in the meetingPollsList
        const count = meetingPollsList.filter((ele) => ele.status === 'active').length;
        const quizcount = meetingQuizList.filter((ele) => ele.status === 'active').length;
        let storedCount: any;
        let storedQuizCount: any;
        if (localStorage.getItem('activePollsCount') != null) {
            storedCount = localStorage.getItem('activePollsCount');
        } else {
            storedQuizCount = 0;
        }
        if (localStorage.getItem('activeQuizCount') != null) {
            storedQuizCount = localStorage.getItem('activeQuizCount');
        } else {
            storedQuizCount = 0;
        }
        // Calculate the difference and update the activePollsCount in local storage
        const updatedCount = count - storedCount;
        localStorage.setItem('activePollsCount', count.toString());
        const updatedQuizCount = quizcount - storedQuizCount;
        localStorage.setItem('activeQuizCount', quizcount.toString());
        // Update the activePolls state with the calculated count difference
        setActivePolls((prevActivePolls) => prevActivePolls + updatedCount);
        setActiveQuiz((prevActiveQuiz) => prevActiveQuiz + updatedQuizCount);

        if (pollTab) {
            setActivePolls(0);
        }
        if (quizTab) {
            setActiveQuiz(0);
        }
    }, [meetingPollsList, pollTab, meetingQuizList, quizTab]);


    useEffect(() => {
        if (!!currentMeetingId) {
            getallWaitingList(currentMeetingId)
                .then((res: any) => {
                    res?.data?.map((ele: any) => {
                        if (ele.guestUserId == guestUserId && ele.status === 'approved') {
                            dispatch(isAllowedHandler(true))
                        } else {
                            dispatch(isAllowedHandler(false))
                        }
                    })
                })
                .catch((error) => {
                    dispatch(isAllowedHandler(false))
                    console.error("Error fetching waiting list:", error);
                });
        }

    }, [currentMeetingId]);

    useEffect(() => {
        if (typeof currentMeetingId === 'undefined' || isFeedBackForm) {
            dispatch(feedbackFormHandler(false))
            dispatch(currentMeetingHandler(null))
            navigate("/")
        }
    }, []);

    const getSubmittedQuizzes = () => {
        getSubmittedQuizzesByGuest(currentMeetingId, guestUserId).then((res: any) => {
            setAllQuizAnswer(res.data)
        })
    }

    useEffect(() => {
        if (!!currentMeetingId) {
            getSubmittedQuizzes()
        }
    }, [])

    const handleTabSelect = (key: any) => {
        dispatch(activeTabHandler(key))
        if (key == 0) {
            setQuestionTab(true)
            setPollTab(false)
            setQuizTab(false)
        }
        else if (key == 1) {
            setPollTab(true)
            setQuestionTab(false)
            setQuizTab(false)
        }
        else if (key == 2) {
            setQuizTab(true)
            setPollTab(false)
            setQuestionTab(false)
        }
    };

    const QuizHeader = () => {
        const isSubmittedQuiz = allQuizAnswer?.length > 0 &&
            !meetingQuizList?.some(quiz => quiz.status === 'active' &&
                !allQuizAnswer.some(submittedQuiz => submittedQuiz.quizId === quiz.quizId));
        return (
            <div className="hstack flex-wrap justify-content-between mb-3">
                <div className="font-20 fw-semibold">{t('common.Quiz')}</div>
                {isSubmittedQuiz &&
                    <button
                        className="btn btn-light text-nowrap rounded-45 mw-120"
                        onClick={() => setIsShowQuizResult(!isShowQuizResult)}>
                        {isShowQuizResult ? "Hide Result" : t('quizDetails.btnShowResult')}
                    </button>
                }
            </div>
        )
    }

    return (
        <>
            <div className="guestuser-joinwrap">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 h-100 join-meeting-share-block">
                            <div className="card border-0 sponsor-detail h-100">
                                <div className="card-body">
                                    <ShareMeetingDetails userId={guestUserId} currentMeeting={currentMeeting} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 h-100 position-relative pt-3">
                            <div className="custom-tab meeting-poll">
                                <div className="text-end mb-2">
                                    <div className='d-xl-none'>
                                        <button className='btn' onClick={shareMeetingMobileShow}><i className='fa-solid fa-share-nodes font-22'></i></button>
                                    </div>
                                </div>
                                <Tabs
                                    defaultActiveKey={0}
                                    onSelect={(key: any) => handleTabSelect(key)}
                                    variant="pills"
                                >
                                    <Tab eventKey={0} title={t('common.question')}>
                                        <div className="card question-tab">
                                            <div className="card-body gray-bg p-2 p-sm-3 vstack gap-2">
                                                <div className="hstack justify-content-between">
                                                    <div className="font-20 fw-semibold">Questions</div>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div className='dropdown'>
                                                            <button data-bs-toggle="dropdown" data-bs-auto-close="outside" className='btn btn-sm btn-primary'><i className='fa fa-sort'></i></button>
                                                            <div className='dropdown-menu px-2 border-0 shadow-lg rounded-0' style={{ width: 100 }}>
                                                                <div className="font-18 fw-medium mb-3 border-bottom pb-2">{t('joinMeeting.lblSortBy')}</div>
                                                                <div className='d-flex justify-content-between gap-3'>
                                                                    <button className={`btn ${shortBy === 'all' ? 'btn-primary' : 'btn-primary-light'} rounded-1 btn-sm`} onClick={(e) => onChangeShort('all')}>{t('guest.guestJoin.btnAll')}</button>
                                                                    <button className={`btn ${shortBy === 'upvoted' ? 'btn-primary text-light' : 'btn-primary-light text-primary'} rounded-1 btn-sm`} onClick={(e) => onChangeShort('upvoted')}>
                                                                        <i className="fa-regular fa-thumbs-up "></i>
                                                                    </button>
                                                                    <button className={`btn ${shortBy === 'time' ? 'btn-primary text-light' : 'btn-primary-light text-primary'} rounded-1 btn-sm`} onClick={(e) => onChangeShort('time')}>
                                                                        <i className="fa-regular fa-clock"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='dropdown'>
                                                            <button data-bs-toggle="dropdown" data-bs-auto-close="outside" className='btn btn-sm btn-primary'><i className='fa fa-filter'></i></button>
                                                            <div className='dropdown-menu px-2 border-0 shadow rounded-0' style={{ minWidth: 290 }}>
                                                                <div className="font-18 fw-medium mb-3 border-bottom pb-2">{t('joinMeeting.lblFilter')}</div>
                                                                <div className='d-flex align-items-center mb-3 gap-2'>
                                                                    <label className='text-nowrap'>{t('joinMeeting.lblSelectUser')}</label>
                                                                    <div className='flex-fill'>
                                                                        <select className="form-select rounded-0" aria-label="Default select example" onChange={(e) => onChangeUserFilter(e)} value={JSON.stringify(selectedUser)}>
                                                                            <option value={JSON.stringify(all)}>{t('joinMeeting.lblAllUser')}</option>
                                                                            <option value={JSON.stringify(anonymous)}>{t('joinMeeting.lblAllAnonymous')}</option>
                                                                            {!!allJoinedUsers && allJoinedUsers.length > 0 && allJoinedUsers.map((usersData: any) => {
                                                                                let userFullName = '';
                                                                                let value = {};
                                                                                if (!!usersData.joinUser && !!usersData.userId) {
                                                                                    userFullName = usersData.joinUser?.firstName + " " + usersData.joinUser?.lastName;
                                                                                    value = { "userId": usersData.userId };
                                                                                }
                                                                                if (!!usersData.joinGuestUser && !!usersData.guestUserId && usersData.joinGuestUser.isAnonymous === false) {
                                                                                    userFullName = usersData.joinGuestUser?.name;
                                                                                    value = { "guestUserId": usersData.guestUserId }
                                                                                }
                                                                                return (
                                                                                    <option key={'option_' + usersData.id} value={JSON.stringify(value)}>{userFullName}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='form-check mb-3'>
                                                                    <input type="checkbox" id='showAnswerQuestion' className='form-check-input' checked={showAnswerQuestion} onChange={(e) => setShowAnswerQuestion(!showAnswerQuestion)} />
                                                                    <label className='form-check-label text-nowrap' htmlFor='showAnswerQuestion'>{t('joinMeeting.lblOnlyAnswer')}</label>
                                                                </div>
                                                                <div className='form-check mb-3'>
                                                                    <input type="checkbox" id='showUnanswerQuestion' className='form-check-input' checked={showUnanswerQuestion} onChange={(e) => setShowUnanswerQuestion(!showUnanswerQuestion)} />
                                                                    <label className='form-check-label text-nowrap' htmlFor='showUnanswerQuestion'>{t('joinMeeting.lblOnlyUnanswer')}</label>
                                                                </div>
                                                                <div className='d-flex gap-3 align-items-center justify-content-between pt-3 border-top'>
                                                                    <button type="reset" className='btn btn-danger text-white rounded-1 btn-sm' onClick={(e) => onClickResetFilter()}>{t('joinMeeting.btnResetFilter')}</button>
                                                                    <button type="button" className='btn btn-primary rounded-1 btn-sm' onClick={(e) => onClickApplyFilter()}>{t('joinMeeting.btnApply')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div ref={questionsListRef} onScroll={scrollBottomCheck} className="conversation-list">
                                                    {!!meetingQuestions && meetingQuestions.length > 0 && meetingQuestions.map((meetingQuestion: any) => {

                                                        let userFullName = ""

                                                        if (!!meetingQuestion.joinUser && !!meetingQuestion.userId) {
                                                            userFullName = meetingQuestion.joinUser?.firstName + " " + meetingQuestion.joinUser?.lastName
                                                        }
                                                        if (!!meetingQuestion.joinGuestUser && !!meetingQuestion.guestUserId) {
                                                            if (meetingQuestion.joinGuestUser.isAnonymous === true) {
                                                                userFullName = t('common.lblAnonymous')
                                                            } else {
                                                                userFullName = meetingQuestion.joinGuestUser?.name
                                                            }
                                                        }
                                                        if (!meetingQuestion.isArchive) {

                                                            return (
                                                                <div key={meetingQuestion.id}>
                                                                    <div className={`meeting-questioncard ${currentMeetingHostId === meetingQuestion.userId ? 'meeting-host' : ''} ${meetingQuestion.isArchive ? 'unread' : 'read'}`}>
                                                                        {meetingQuestion.isEdited && <small className='edited-label'>edited</small>}
                                                                        <div className="hstack flex-wrap justify-content-between">
                                                                            <div className="meeting-user-badge">
                                                                                <i className="meeting-user-icon fa-solid fa-circle-user"></i>
                                                                                <div className="fw-medium">{guestUserId === meetingQuestion.guestUserId ? 'You' : userFullName}</div>
                                                                            </div>
                                                                            <div className="hstack gap-1 gap-md-2">
                                                                                {(meetingQuestion.meetingQuestionsReplyDetails.length <= 0) &&
                                                                                    (getUpvotedCount(meetingQuestion.id)) <= 0 &&
                                                                                    (meetingQuestion.guestUserId === guestUserId) &&

                                                                                    <button
                                                                                        className="btn btn-success btn-sm text-light"
                                                                                        title="edit"
                                                                                        onClick={
                                                                                            () => editQuestion(meetingQuestion.question, meetingQuestion.id)
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-pencil"></i>
                                                                                    </button>

                                                                                }
                                                                                <button className={`btn ${getUpvotedAvaliable(meetingQuestion.id) === true ? 'btn-primary' : 'btn-secondary'} btn-sm text-light`} onClick={(e) => { handleUpvoteMeetingQuestion(meetingQuestion.id) }}>
                                                                                    <i className="fa-regular fa-thumbs-up me-1"></i>
                                                                                    <span>{getUpvotedCount(meetingQuestion.id)}</span>
                                                                                </button>
                                                                                {(meetingQuestion.meetingQuestionsReplyDetails.length <= 0) &&
                                                                                    (getUpvotedCount(meetingQuestion.id)) <= 0 &&
                                                                                    (meetingQuestion.guestUserId === guestUserId) && (
                                                                                        <button onClick={(e) => { deleteConfirmHandler(meetingQuestion.id) }} className="btn btn-danger btn-sm text-light">
                                                                                            <i className="fa-solid fa-trash-can"></i>
                                                                                        </button>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='font-18 fw-medium'>{meetingQuestion.question}</div>
                                                                        <div className='vstack gap-2'>
                                                                            {!!meetingQuestion.meetingQuestionsReplyDetails &&
                                                                                meetingQuestion.meetingQuestionsReplyDetails.length > 0 &&
                                                                                meetingQuestion.meetingQuestionsReplyDetails.map((meetingQuestionsReplyValue: any, index: any) => {
                                                                                    let userFullNameReply = ""

                                                                                    if (!!meetingQuestionsReplyValue.userDetails && !!meetingQuestionsReplyValue.userId) {
                                                                                        userFullNameReply = meetingQuestionsReplyValue.userDetails?.firstName + " " + meetingQuestionsReplyValue.userDetails?.lastName
                                                                                    }
                                                                                    if (!!meetingQuestionsReplyValue.joinGuestUser && !!meetingQuestionsReplyValue.guestUserId) {
                                                                                        if (meetingQuestionsReplyValue.joinGuestUser.isAnonymous === true) {
                                                                                            userFullNameReply = t('common.lblAnonymous')
                                                                                        } else {
                                                                                            userFullNameReply = meetingQuestionsReplyValue.joinGuestUser?.name
                                                                                        }
                                                                                    }

                                                                                    return (
                                                                                        <div className={`question-reply ${currentMeetingHostId === meetingQuestionsReplyValue.userId ? 'meeting-host' : ''}`} key={'meetingQuestionsReply_' + meetingQuestionsReplyValue.id}>
                                                                                            <div className='hstack flex-wrap justify-content-between'>
                                                                                                <div className="meeting-user-badge">
                                                                                                    <i className="meeting-user-icon fa-solid fa-circle-user"></i>
                                                                                                    <div className="fw-medium">{userFullNameReply}</div>
                                                                                                </div>
                                                                                                <div className="hstack gap-1 gap-md-2">
                                                                                                    <button className={`btn ${getReplyUpvotedAvaliable(meetingQuestionsReplyValue.meetingQuestionsReplyUpvoteDetails) === true ? 'btn-primary' : 'btn-secondary'} btn-sm text-light`} title={t('joinMeeting.titleUpvote')} onClick={(e) => { handleUpvoteMeetingQuestionReply(meetingQuestion.id, meetingQuestionsReplyValue.id) }}>
                                                                                                        <i className="fa-regular fa-thumbs-up me-1"></i>
                                                                                                        <span>{meetingQuestionsReplyValue.meetingQuestionsReplyUpvoteDetails.length > 0 ? meetingQuestionsReplyValue.meetingQuestionsReplyUpvoteDetails.length : 0}</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="font-18 fw-medium">{meetingQuestionsReplyValue.questionReply}</div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                    <div ref={messagesEndRef} />
                                                </div>
                                                <div className="ask-question" id='ask-question'>
                                                    <Formik
                                                        initialValues={initialValuesSendQuestion}
                                                        onSubmit={handleSendQuestion}
                                                        enableReinitialize={false}
                                                    >
                                                        <Form>
                                                            <div className='hstack gap-1 gap-sm-4'>
                                                                <div className='flex-fill position-relative'>
                                                                    <Field innerRef={(input: any) => { questionBoxRef.current = input }} as="textarea" rows="2" name="question" className="form-control border-0" id="question" placeholder={t('guest.guestJoin.placeholderQuestion')}
                                                                        value={meetingQuestion}
                                                                        onChange={(e: any) => {
                                                                            setEditMeetingQuestions(e.target.value)
                                                                        }}
                                                                        onKeyDown={(e: any) => {
                                                                            if (e.ctrlKey && e.key === 'Enter') {
                                                                                e.currentTarget.form?.requestSubmit();
                                                                            }
                                                                        }}
                                                                    >
                                                                    </Field>
                                                                    <ErrorMessage name="question" component="div" className="text-danger validation-absolute" />
                                                                </div>
                                                                <div className='hstack gap-1 gap-sm-2'>
                                                                    {editMeetingCondition &&
                                                                        <button className="btn shadow btn-light rounded-1" onClick={resetQuestionBox}>
                                                                            <i className="fa fa-close"></i>
                                                                        </button>
                                                                    }
                                                                    <button type='submit' className="btn shadow btn-light rounded-1">
                                                                        <i className="fa-solid fa-paper-plane text-primary"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </Formik>
                                                </div>
                                                {
                                                    scrollBottom &&
                                                    <button onClick={scrollToBottom} className="scroll-bottom"><i className="fa-solid fa-angles-down"></i></button>
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey={1} title={
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span className="me-2">{t('common.Poll')}</span>
                                            <span className="position-relative translate-middle badge rounded-pill badge-notification bg-danger fs-9 ms-1">
                                                {activePolls > 0 ? activePolls : ""}
                                            </span>
                                        </div>
                                    }>
                                        <div className="card border-0 h-100 join-meet">
                                            <div className="card-body gray-bg p-2 p-sm-3">
                                                <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                                    <div className="font-20 fw-semibold">{t('common.Poll')}</div>
                                                    {meetingPollsList.length > 0 && meetingPollsList.some((poll: any) => poll?.status == 'inActive') &&
                                                        <div className="d-flex align-items-center gap-2">
                                                            <button className="btn btn-light text-nowrap rounded-45 mw-120" onClick={(e) => setIsShowResult(!isShowResult)}>{isShowResult ? "Hide Result" : t('pollDetails.btnShowResult')}</button>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="card border-0 rounded-2 bg-light">
                                                    <div className='card-body polls-card'>
                                                        {isShowResult ?
                                                            <>
                                                                {!!meetingPollsList &&
                                                                    meetingPollsList.length > 0 &&
                                                                    meetingPollsList.map((pollValue: any) => {
                                                                        if (pollValue.status !== 'active') {
                                                                            var pollId = pollValue.pollDetails.id
                                                                            return (
                                                                                <div className='border-bottom mb-3 border-primary border-opacity-50' key={'pollAnswer_' + pollId}>
                                                                                    <div className="fw-medium mb-3"  > {pollValue.pollDetails.title}</div>

                                                                                    {!!pollValue.pollDetails.pollsQuestionDetails &&
                                                                                        pollValue.pollDetails.pollsQuestionDetails.length > 0 &&
                                                                                        pollValue.pollDetails.pollsQuestionDetails.map((pollsQuestionValue: any) => {
                                                                                            var questionId = pollsQuestionValue.questionDetails.id
                                                                                            return (
                                                                                                <div className='ms-2 ms-md-4' key={'pollAnswer_question' + questionId}>
                                                                                                    <div className="d-flex justify-content-between flex-wrap mb-2">
                                                                                                        <div className="fw-medium flex-fill " >{pollsQuestionValue.questionDetails.title}
                                                                                                        </div>
                                                                                                        <span>{t('pastMeetingDetails.lblTotalVote')} {getTotalVote(pollId, questionId)}</span>
                                                                                                    </div>

                                                                                                    {!!pollsQuestionValue.questionDetails.questionOptions &&
                                                                                                        pollsQuestionValue.questionDetails.questionOptions.length > 0 &&
                                                                                                        pollsQuestionValue.questionDetails.questionOptions.map((questionOptionsValue: any) => {
                                                                                                            var questionOptionId = questionOptionsValue.id
                                                                                                            const isSelected = newSelectedAnswer.some((answer: any) =>
                                                                                                                answer.pollId === pollId &&
                                                                                                                answer.questionId === questionId &&
                                                                                                                answer.questionOptionId === questionOptionId
                                                                                                            );
                                                                                                            return (
                                                                                                                <div className="d-flex flex-wrap ps-3 align-items-center gap-2 mb-4" key={'pollAnswer_question_option' + questionOptionId}>
                                                                                                                    <div className="flex-fill">
                                                                                                                        <div>{questionOptionsValue.optionName}</div>
                                                                                                                        <div className="progress">
                                                                                                                            <div className={`progress-bar ${isSelected ? 'bg-danger' : ''}`} role="progressbar" aria-label="Basic example" aria-valuenow={getTotalPercentage(pollId, questionId, questionOptionId)} style={{ width: getTotalPercentage(pollId, questionId, questionOptionId) + "%" }}>
                                                                                                                                {getTotalPercentage(pollId, questionId, questionOptionId)} %
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    }

                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                                {
                                                                    (meetingPollsList?.length > 0 && !meetingPollsList.some((pollValue: any) => pollValue?.status !== 'active')) &&
                                                                    <div className="font-22 fw-medium" > {t('pollDetails.DataNotFound')}</div>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {meetingPollsList.length === 0 &&
                                                                    <div className="font-22 fw-medium" > {t('pollDetails.DataNotFound')}</div>
                                                                }


                                                                {!!meetingPollsList &&
                                                                    meetingPollsList?.length > 0 &&
                                                                    meetingPollsList?.map((pollValue: any) => {
                                                                        if (pollValue.status == 'active') {
                                                                            var pollId = pollValue.pollDetails.id
                                                                            return (
                                                                                <div className="row" key={'pollGuest_' + pollId}>
                                                                                    <div className="col-lg-12 border-bottom border-primary border-opacity-25 pb-3 mb-3">
                                                                                        {GroupedQuestions[pollId]?.[0] !== GroupedAnswers[pollId]?.[0] ?
                                                                                            <>
                                                                                                <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                                                                                    <div className="font-20 fw-semibold">{pollValue.pollDetails.title}</div>
                                                                                                    <button className="btn btn-dark text-nowrap rounded-45 btn-blue" title={t('pollDetails.titlePublishPoll')} onClick={() => handlePublishPoll(pollId)}>{t('pollDetails.btnPublishPoll')}</button>
                                                                                                </div>

                                                                                                {!!pollValue.pollDetails.pollsQuestionDetails &&
                                                                                                    pollValue.pollDetails.pollsQuestionDetails.length > 0 &&
                                                                                                    pollValue.pollDetails?.pollsQuestionDetails?.map((pollsQuestionValue: any, j: any) => {
                                                                                                        var questionId = pollsQuestionValue.questionDetails.id
                                                                                                        return (
                                                                                                            <div className='col-lg-6 ms-2 ms-md-4 mb-5' key={'pollGuest_question' + questionId}>
                                                                                                                <div className="fw-medium" > {pollsQuestionValue.questionDetails.title}</div>
                                                                                                                <div className='row'>
                                                                                                                    <div className='col-12'>
                                                                                                                        <div>
                                                                                                                            {!!pollsQuestionValue.questionDetails.questionOptions &&
                                                                                                                                pollsQuestionValue.questionDetails.questionOptions.length > 0 &&
                                                                                                                                pollsQuestionValue.questionDetails.questionOptions.map((questionOptionsValue: any, k: any) => {
                                                                                                                                    var questionOptionId = questionOptionsValue.id
                                                                                                                                    return (
                                                                                                                                        <div className="custom-radio" key={`${pollId}_${questionId}_${questionOptionId}`}>
                                                                                                                                            <input type="radio"
                                                                                                                                                name={`poll_que_${pollId}_${questionId}`}
                                                                                                                                                id={`poll_${pollId}_${questionId}_${questionOptionId}`}
                                                                                                                                                onClick={(e) => handleCheckboxChange(e, { pollId, questionId, questionOptionId })}
                                                                                                                                                checked={checkOptionAnswerAvailable({ pollId, questionId, questionOptionId })}
                                                                                                                                            />
                                                                                                                                            <label htmlFor={`poll_${pollId}_${questionId}_${questionOptionId}`}>
                                                                                                                                                <span className='label-text'>{questionOptionsValue.optionName}</span>
                                                                                                                                            </label>
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )

                                                                                                    })
                                                                                                }
                                                                                            </>

                                                                                            :
                                                                                            <div className='d-flex align-items-center justify-content-between gap-3'>
                                                                                                <div className="font-20 fw-semibold">{pollValue.pollDetails.title}</div>
                                                                                                <div>This poll has been submitted</div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                                {!!meetingPollsList &&
                                                                    meetingPollsList?.length > 0 &&
                                                                    !meetingPollsList?.find((pollValue: any) => pollValue?.status === 'active') &&
                                                                    <div className="font-22 fw-medium" > {t('pollDetails.DataNotFound')}</div>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey={2} title={
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span className="me-2">{t('common.Quiz')}</span>
                                            <span className="position-relative translate-middle badge rounded-pill badge-notification bg-danger fs-9 ms-1">
                                                {activeQuiz > 0 ? activeQuiz : ""}
                                            </span>
                                        </div>
                                    } >
                                        <div className="card border-0 h-100 join-meet">
                                            <div className="card-body gray-bg p-2 p-sm-3">

                                                <QuizHeader />
                                                <div className="card border-0 rounded-2 bg-light">
                                                    <div className='card-body vstack gap-4 quiz-card'>
                                                        {isShowQuizResult ?

                                                            allQuizAnswer?.map((quiz: any, index: number) => {
                                                                return <ShowQuizResult
                                                                    key={`showQuizResult2_${quiz.quizId}${index}`}
                                                                    index={index}
                                                                    quizId={quiz.quizId}
                                                                    questionsAnswers={quiz.questionsAnswers}
                                                                    quizJson={quiz.quizJson}
                                                                />
                                                            })


                                                            :
                                                            <>

                                                                {!!meetingQuizList &&
                                                                    meetingQuizList?.length > 0 &&
                                                                    meetingQuizList?.map((quizValue: any, index: number) => {
                                                                        if (quizValue.status == 'active') {
                                                                            const quizId = quizValue.quizId
                                                                            Serializer.addProperty("question", {
                                                                                name: "score:number"
                                                                            });
                                                                            const saveSurveyData = (survey: any) => {
                                                                                var data = undefined;
                                                                                if (survey.state == 'running') {
                                                                                    var data = survey.data;
                                                                                    data.pageNo = survey.currentPageNo;
                                                                                    data.timeSpent = survey.timeSpent;
                                                                                }
                                                                                window.localStorage.setItem(QuizLocalStorage, JSON.stringify(data));
                                                                            }
                                                                            const survey = new Model(activeQuizData);
                                                                            const prevData = window.localStorage.getItem(QuizLocalStorage) || null;
                                                                            if (prevData) {
                                                                                var data = JSON.parse(prevData);
                                                                                survey.start();
                                                                                survey.data = data;
                                                                                if (data.pageNo) {
                                                                                    survey.currentPageNo = data.pageNo;
                                                                                }
                                                                                survey.timeSpent = data.timeSpent;
                                                                            }
                                                                            survey.onTimer.add((sender, options) => {
                                                                                saveSurveyData(survey);
                                                                            });
                                                                            survey.applyTheme(SurveyTheme.BorderlessLight);
                                                                            const calculateMaxScore = (questions: any) => {
                                                                                var maxScore = 0;
                                                                                questions.forEach((question: any) => {
                                                                                    if (!!question.score) {
                                                                                        maxScore += question.score;
                                                                                    }
                                                                                });
                                                                                return maxScore;
                                                                            }
                                                                            const calculateTotalScore = (data: any) => {
                                                                                var totalScore = 0;
                                                                                Object.keys(data).forEach((qName) => {
                                                                                    const question = survey.getQuestionByValueName(qName);
                                                                                    if (!!question && question.isAnswerCorrect()) {
                                                                                        if (!!question.score) {
                                                                                            totalScore += question.score;
                                                                                        }
                                                                                    }
                                                                                });
                                                                                return totalScore;
                                                                            }
                                                                            survey.onComplete.add((sender: any, options: any) => {
                                                                                let questionsAnswers = sender.data
                                                                                const totalScore = calculateTotalScore(sender.data);
                                                                                const maxScore = calculateMaxScore(sender.getAllQuestions());
                                                                                let data = { meetingId: currentMeetingId, quizId, guestUserId, questionsAnswers, score: totalScore, quizJson: activeQuizData }
                                                                                dispatch(quizAnswerByGuestHandler(data))
                                                                                    .then(() => {
                                                                                        getSubmittedQuizzes()
                                                                                        dispatch(activeQuizIdGuestHandler(0))
                                                                                        window.localStorage.removeItem(QuizLocalStorage);
                                                                                    })
                                                                            });
                                                                            if (allQuizAnswer?.find((item: any) => item.quizId === quizId)) {
                                                                                return (
                                                                                    <div key={`quizSubmitted2_${quizId}${index}`} className='status-block status-block-submitted'>
                                                                                        <div>{quizValue.quiz.title}</div>
                                                                                        <div className='font-16'>This Quiz has been submitted</div>
                                                                                    </div>
                                                                                )
                                                                            } else {
                                                                                if (survey.isEmpty) {
                                                                                    return <div key={`quizStarted2_${quizId}${index}`} className='status-block status-block-started'>A quiz has already started</div>
                                                                                } else {
                                                                                    return <Survey key={`quizRunning2_${quizId}${index}`} model={survey} />
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                {meetingQuizList?.length >= 0 &&
                                                                    !meetingQuizList?.find((quizValue: any) => quizValue?.status === 'active') &&
                                                                    <div className="status-block status-block-nodata"> {t('quizDetails.DataNotFound')}</div>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <DialogBox show={showDelete} clickOk={() => { handleDeleteMeetingQuestion(meetingQuestionDeleteId); setShowDelete(false) }} clickCancel={() => { setShowDelete(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltquestion')} />
            <DialogBox show={submitPoll} clickOk={() => { handleSubmitPoll(submitPollId); setSubmitPoll(false) }} clickCancel={() => { setSubmitPoll(false) }} btncancel={t('deleteBox.btnNo')} btnyes={t('deleteBox.btnYes')} question={t('deleteBox.submitPollQuestion')} />
        </>
    );
};

export default GuestJoin;
