import React, { FC, useEffect, useState, MouseEvent } from 'react';
import moment from "moment";
import { useTranslation } from "react-i18next";
import QRCode from 'qrcode';
import { toast, } from 'react-toastify';
import { useLocation } from 'react-router-dom';

interface ShareMeetingDetailsProps {
    userId: number
    currentMeeting: any
}

const ShareMeetingDetails: FC<ShareMeetingDetailsProps> = ({ userId, currentMeeting }) => {
    const location = useLocation()
    const { t } = useTranslation('common');
    const [qrLink, setQrLink] = useState('');
    const [srcData, setSrc] = useState('');
    const copyLink = async (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(qrLink)
        toast.success(t('toastMessage.copyLink'), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
        });
    }

    const copyCode = async (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(currentMeeting?.code)
        toast.success(t('toastMessage.copyCode'), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
        });
    }

    const copyPassword = async (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(currentMeeting?.password)
        toast.success(t('toastMessage.copyPassword'), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
        });
    }

    useEffect(() => {
        const hostname = typeof window !== 'undefined' ? window.location.origin : process.env.RESTURL_SPEAKERS;
        const link = hostname + `/guest/login/${currentMeeting?.code}`;
        setQrLink(link)
        QRCode.toDataURL(link, { errorCorrectionLevel: 'H', maskPattern: 2 }).then((setSrc))

    }, [currentMeeting]);

    const shareMeetingMobileShow = (e: MouseEvent<HTMLButtonElement>) => {
        if (document.body.classList.contains('share-meeting-show')) {
            document.body.classList.remove('share-meeting-show');
        } else {
            document.body.classList.add('share-meeting-show');
        }
    }

    return (
        <>
            {!location.pathname.includes('/meeting') &&
                <button className='btn close-share-meeting-mobile' onClick={shareMeetingMobileShow}><i className='fa-solid fa-times font-22'></i></button>
            }
            {
                !!currentMeeting?.brandImageUrls && currentMeeting?.brandImageUrls?.length > 0 &&
                <div className="d-flex gap-3 gap-md-4 flex-wrap mb-4">
                    {
                        currentMeeting?.brandImageUrls?.map((item: any, index: number) => (
                            <div key={index}>
                                <img className='company-sponsor' src={item.brandImageUrl} />
                            </div>
                        ))
                    }
                </div>
            }

            <div className='font-24 fw-semibold mb-4'>{currentMeeting?.title?.toUpperCase()}</div>
            <div className='d-flex justify-content-between flex-wrap-reverse gap-1'>
                <div>
                    <div className="d-flex flex-column gap-4">
                        <div>
                            <div className="fw-medium text-nowrap">{t('guest.guestJoin.lblCode')}</div>
                            <div className="font-24 fw-semibold">{currentMeeting?.code}</div>
                        </div>

                        {(currentMeeting?.hostId === userId && currentMeeting?.password !== null) &&
                            <div>
                                <div className="fw-medium text-nowrap">{t('common.Password')}</div>
                                <div className="font-24 fw-semibold">{currentMeeting?.password}</div>
                            </div>
                        }

                        <div>
                            <div className="fw-medium text-nowrap">{t('guest.guestJoin.lblStartDate')}</div>
                            <div className="font-22 fw-semibold">{moment(new Date(currentMeeting?.startTime)).format('ll')}</div>
                        </div>
                        <div>
                            <div className="fw-medium text-nowrap">{t('guest.guestJoin.lblStartTime')}</div>
                            <div className="font-22 fw-semibold">{moment(new Date(currentMeeting?.startTime)).format('LT')}</div>
                        </div>

                        <div>
                            <div className="fw-medium text-nowrap">{t('guest.guestJoin.lblGeoFence')}</div>
                            <div className='font-22 fw-semibold'>  {currentMeeting?.isGeoFenceProtected ? t('common.lblYes') : t('common.lblNo')} </div>
                        </div>
                    </div>
                </div>

                <div className='flex-50'>

                    {!!srcData &&
                        <div className='d-flex justify-content-center mb-3'>
                            <img src={srcData} alt='' width={'220px'} height={'220px'} />
                        </div>
                    }

                    <div className="d-flex align-items-center justify-content-center gap-2">
                        <button onClick={copyLink} className="btn btn-primary-light btn-copy text-primary rounded-45 flex-fill">{t('shareMeeting.btnCopyLink')}</button>
                        {(currentMeeting?.hostId === userId && currentMeeting?.password) &&
                            <button onClick={copyPassword} className="btn btn-primary-light btn-copy text-primary flex-fill text-nowrap d-flex justify-content-center">{t('common.Password')}</button>
                        }
                        <button onClick={copyCode} className="btn btn-primary-light btn-copy text-primary flex-fill">{t('shareMeeting.btnCopyCode')}</button>
                    </div>
                </div>
            </div>
            {
                !!currentMeeting?.sponsorImageUrls && currentMeeting?.sponsorImageUrls.length > 0 &&
                <div className='d-flex align-items-center gap-3 flex-wrap mt-4'>
                    <div className="fw-medium">{t('guest.guestJoin.lblSponsors')}</div>
                    <div className="d-flex align-items-center gap-3 gap-md-4 flex-wrap">
                        {
                            currentMeeting?.sponsorImageUrls.map((item: any, index: number) => {
                                return <div key={index}><img className='company-sponsor' src={item.sponsorImageUrl} /></div>
                            })
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default ShareMeetingDetails