import React, { useEffect, useState, useRef } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { Wrapper } from "@googlemaps/react-wrapper";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IMeeting, IMeetingJoin } from "../types/meeting.type";
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import * as bootstrap from 'bootstrap';
import de from 'date-fns/locale/de';
import { meetingData, meetingGetByCode } from "../services/meeting.service";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import socket from '../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../pages/store/store';
import { attendeeWaitingListHandler, currentMeetingHandler, isAllowedHandler, joinMessageHandler, meetingCreateHandler, meetingGetByCodeCheckingHandler, meetingHandler, meetingStatusUpdateHandler, setMeetingSessionHandler, updateMeetingStateHandler } from '../pages/store/slices/meetingSlice';
import { messageHandler } from '../pages/store/slices/meetingSlice';
import { checkUserAccessHandler } from '../pages/store/slices/meetingSlice';
import { miniSidebarHandler, tourEnableHandler, stepIndexHandler } from '../pages/store/slices/settingsSlice';
import { debounce } from 'lodash';
import { hideBackButtonHandler, tourCompleteHandler } from '../pages/store/slices/authSlice';
import { meetingGetByCodeHandler } from '../pages/store/slices/joinMeetingSlice';
import IconCreateMeeting from "../images/icon-create-meeting.svg"
import IconDashboard from "../images/icon-dashboard.svg"
import IconFindMeeting from "../images/icon-find-meeting.svg"
import IconLogout from "../images/icon-logout.svg"
import IconMyMeetings from "../images/icon-my-meetings.svg"
import IconPoll from "../images/icon-poll.svg"
import IconQuestionCircle from "../images/icon-question-circle.png"
import IconQuiz from "../images/icon-quiz.png"
registerLocale('de', de)

const Sidebar = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { user, isUser, tourComplete, hideBackButton } = useSelector((state: RootState) => state.auth);
  const { nearMeetingList, createdMeeting, passwordProtectedJoin } = useSelector((state: RootState) => state.meeting);
  const userId = user?.user?.id;
  const { codeExistMessage } = useSelector((state: RootState) => state.meeting);
  const { tourEnable, stepIndex } = useSelector((state: RootState) => state.settings);

  const inputRef = useRef<HTMLInputElement>(null);

  // Initialize and add the map
  const [newLatitude, setNewLatitude] = useState<number>(0);
  const [newLongitude, setNewLongitude] = useState<number>(0);
  const [mapZoom, setMapZoom] = useState<any>(16);
  const [fileValidationError, setFileValidationError] = useState(false);
  const [sponserfileValidationError, setSponserFileValidationError] = useState(false);
  const [fileSizevalidation, setFileSizevalidation] = useState(false);
  const [sponserfileSizevalidation, setsponserFileSizevalidation] = useState(false);

  function initMap(): void {
    // The location of Uluru
    var currentLocation = { lat: newLatitude, lng: newLongitude };
    // The map, centered at Uluru
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: mapZoom,
        center: currentLocation
      }
    );
    // The marker, positioned at Uluru
    const marker = new google.maps.Marker({
      position: currentLocation,
      draggable: true,
      map: map,
    });

    google.maps.event.addListener(marker, 'dragend', function (marker: { latLng: any; }) {
      var latLng = marker.latLng;
      setNewLatitude(latLng.lat());
      setNewLongitude(latLng.lng());
      var zoom = map.getZoom();
      setMapZoom(zoom);
    });
  }

  useEffect(() => {
    if (tourComplete) {
      dispatch(hideBackButtonHandler(false));
      dispatch(tourEnableHandler(true))
    } else {
      dispatch(stepIndexHandler(0))
      dispatch(hideBackButtonHandler(true));
    }

    setTimeout(() => { initMap() }, 500);
  }, [newLatitude, newLongitude])

  useEffect(() => {
    const navigation = () => {
      if (!!isUser) {
        var width = window.innerWidth;
        if (width >= 992 && width <= 1496.98) {
          dispatch(miniSidebarHandler(true));
        } else {
          dispatch(miniSidebarHandler(false));
        }
      }
    }
    navigation();
    const debouncedNavigation = debounce(navigation, 100);
    window.addEventListener("resize", debouncedNavigation);
    return () => window.removeEventListener("resize", debouncedNavigation);
  }, [])

  const generateMeetingCode = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let code = '';
    for (let i = 0; i < 3; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }
    code += '-';
    for (let i = 0; i < 3; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }
    return code;
  };

  const { t } = useTranslation('common');
  const [successful, setSuccessful] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingCode, setMeetingCode] = useState(generateMeetingCode());
  const [meetingPassword, setMeetingPassword] = useState("");
  const [passwordProtected, setPasswordProtected] = useState<boolean>(false);
  const [emailProtected, setEmailProtected] = useState<boolean>(false);
  const [geoFenceProtected, setGEOFenceProtected] = useState<boolean>(false);
  const [meetingCodeHome, setMeetingCodeHome] = useState("");
  const [newLatitudeJoin, setNewLatitudeJoin] = useState<number>(0);
  const [newLongitudeJoin, setNewLongitudeJoin] = useState<number>(0);
  const [joinSuccessful, setJoinSuccessful] = useState<boolean>(false);
  const [joinMessage, setJoinMessage] = useState<string>("");
  const [LocationdisbleMessage, setLocationdisbleMessage] = useState<string>("");
  const [sponsorsFiles, setSponsorsFiles] = useState<any[]>([]);
  const [brandFiles, setBrandFiles] = useState<any[]>([]);
  const [newRandomCodes, setRandomCodes] = useState<any[]>([]);
  const [updateMeetingState, setupdateMeetingState] = useState<boolean>(false);
  const [pageSize, setpageSize] = useState(1); // Page number
  const [perPage, setperPage] = useState(9); // per page Limit
  const [filterValue, setFilterValue] = useState('');
  const [viewType, setviewType] = useState(1); // recent, past, all
  const [selectFilterData, setSelectFilterData] = useState("");
  const [sortColumn, setsortColumn] = useState<any>({ order: "desc", prop: "createdAt" }); // per page Limit
  const [meetingList, setMeetingData] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [startCreatedMeeting, setStartCreatedMeeting] = useState<boolean>(false);
  const [brandErr, setBrandErr] = useState(false)
  const [sponsorErr, setSponsorError] = useState(false)
  const inputReference = useRef<HTMLInputElement>(null);
  const inputReferenced = useRef<HTMLInputElement>(null);
  const geoRef = useRef<HTMLInputElement>(null);

  const handleModalClose = (isJoin: any) => {
    setStartCreatedMeeting(false);
    setShowModal(false)
    if (isJoin === true) {
      setShowModal(false)
    } else {
      window.location.reload()
    }
  };

  const handleModalShow = () => setShowModal(true);
  const startMeetingHandler = async (id: any, status: string) => {
    const meetingStatusValues = { id, status }
    await dispatch(meetingStatusUpdateHandler(meetingStatusValues))
    dispatch(updateMeetingStateHandler(true))
  }

  const getMeetingData = async (userId: any, pageLimit: number, perPage: number, filterData: any, searchVal: string, sortColumn: any) => {
    const meetingDataList = await meetingData(userId, pageLimit, perPage, filterData, searchVal, sortColumn.prop, sortColumn.order);
    setMeetingData(meetingDataList.data);
  };

  useEffect(() => {
    isUser && getMeetingData(userId, pageSize, perPage, selectFilterData, filterValue, sortColumn);
  }, [perPage, pageSize, selectFilterData, filterValue, viewType, updateMeetingState]);

  const joinMeetingHandler = async (id: any, status: string) => {
    const meetingStatusValues = { id, status };
    await dispatch(meetingStatusUpdateHandler(meetingStatusValues)).then((res: any) => {
      if (res?.type == 'meetingStatusUpdate/fulfilled') {
        if (res?.payload?.status === 200) {
          dispatch(updateMeetingStateHandler(true));
          dispatch(meetingGetByCodeHandler({ code: res?.payload?.data.code, userId: 0 })).then((meetDetails: any) => {
            if (meetDetails?.payload.status === 200) {
              dispatch(currentMeetingHandler(meetDetails?.payload?.data));
              navigate('/joinMeeting/' + res?.payload?.data.code);
            }
          })
        }
      }
    })
  }

  const newmeetingTitle = meetingTitle.trim();
  const newMeetingCode = meetingCode.trim();
  const initialValues: IMeeting = {
    title: newmeetingTitle,
    code: newMeetingCode,
    password: meetingPassword,
    passwordProtected: passwordProtected,
    startTime: new Date(startDate),
    endTime: new Date(endDate)
  };

  const handleRandomCodeClick = (code: any) => {
    setMeetingCode(code);
    dispatch(messageHandler(''));
  };

  const createMeetingModal = () => {
    setStartDate((new Date(new Date().setTime(new Date().getTime() + (5 * 60 * 1000)))));
    setEndDate(((new Date(new Date().setTime(new Date().getTime() + (30 * 60 * 1000))))));
    new bootstrap.Modal(document.getElementById('host-meeting-modal') as HTMLElement).show();
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(t('validation.requiredField'))
      .transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .strict(true)
      .test(
        "len",
        t('validation.minlengthTitle'),
        (val: any) =>
          val &&
          val.toString().length >= 3
      )
      .test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val &&
          val.trim() !== ''
      ),
    code: Yup.string()
      .required(t('validation.requiredField'))
      .transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .strict(true)
      .test(
        "len",
        t('validation.minlengthCode'),
        (val: any) =>
          val &&
          val.toString().length >= 3
      )
      .test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val &&
          val.trim() !== ''
      ),
    passwordProtected: Yup.boolean(),
    password: Yup.string().when("passwordProtected", {
      is: true,
      then: Yup.string()
        .required(t('validation.requiredField'))
        .trim(t('trimMessage.trimPassword'))
        .matches(/^[A-Za-z0-9#@!%^&*()_+\-=[\]{}|;:'",.<>?/\\`~]+$/, (t('validation.specialcharacterMeetingPassword')))
        .strict(true)
        .test(
          "len",
          t('validation.minlengthPassword'),
          (val: any) =>
            val &&
            val.toString().length >= 3
        ),
    }),

    startTime: Yup.date()
      .nullable()
      .required(t('validation.requiredField'))
      .min(new Date(), t('validation.startTime')),
    endTime: Yup.date()
      .nullable()
      .required(t('validation.requiredField'))
      .when('startTime', (startTime, schema) => {
        const expiryDate = moment(startTime);
        const enteredDate = moment(endDate);
        if (!enteredDate.isAfter(expiryDate)) {
          const currentDay = new Date(startTime.getTime());
          return schema
            .min(currentDay, t('validation.endTime'))
        } else {
          return schema;
        }
      })

  });
  const brandUploadCount = process.env.REACT_APP_NUMBER_OF_BRAND_UPLOAD_IMAGES ?? 'defaultBrandCount';
  const updatedBrandFilesValidation = t('deafultLeftLayout.hostMeeting.lblBrandFilesValidation', { brandUploadCount })
    .replace('{BrandUploadCount}', brandUploadCount)
    .replace('{BrandUploadCount}', brandUploadCount)

  const sponsorUploadCount = process.env.REACT_APP_NUMBER_OF_SPONSORS_UPLOAD_IMAGES ?? 'defaultBrandCount';
  const updatedSponsorFilesValidation = t('deafultLeftLayout.hostMeeting.lblSponsorFilesValidation', { brandUploadCount })
    .replace('{SponsorUploadCount}', sponsorUploadCount)
    .replace('{SponsorUploadCount}', sponsorUploadCount)

  const filterPassedEndTime = (time: any) => {
    const meetingStartDate = new Date(startDate);
    const selectedDate = new Date(time);

    return meetingStartDate.getTime() < selectedDate.getTime();
  };

  const filterPassedStartTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const initialValuesJoinMeeting: IMeetingJoin = {
    passwordProtected: passwordProtectedJoin,
    joinPassword: ""
  }

  const validationSchemaJoinMeeting = Yup.object().shape({

    joinPassword: Yup.string()
      .required(t('validation.requiredField')),
  });

  const functionMeetingNearMe = async (latitude: number, longitude: number) => {
    try {
      const formValue = {
        latitude, longitude
      }
      await dispatch(meetingHandler(formValue)).unwrap();

    } catch (err) {
      console.log(err);
    }
  }

  const joinMeetingHost = (meetingCode: any) => {
    const form = initialValuesJoinMeeting
    const { joinPassword } = form;
    dispatch(meetingGetByCodeCheckingHandler({ code: meetingCode, loggedInUser: userId })).then((res: any) => {
      if (res?.type == 'checkingCode/fulfilled') {
        if (res?.payload?.status === 200) {
          if (res?.payload?.data?.status !== 'inProgress') {
            if (res?.payload?.data?.status === "created") {
              toast.error(t('toastMessage.NotStartedMeeting'), {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }
            else if (res?.payload?.data?.status === "started") {
              toast.success(t('toastMessage.NotStartedMeetingByHost'), {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }
            else if (res?.payload?.data?.status === "ended") {
              toast.success(t('toastMessage.meetingEnded'), {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }

          }
          else {
            if (res?.payload?.data?.isPasswordProtected === false) {
              checkAccess(meetingCode, joinPassword, newLongitudeJoin, newLatitudeJoin)
            }
            else if (res?.payload?.data?.isPasswordProtected === true) {
              var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
              myModal.hide();
              new bootstrap.Modal(document.getElementById('password-join-meeting') as HTMLElement).show();
              var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
              passwordJoinMeetingModal.hide();
            }
          }
        }
      } else {
        toast.error(t('toastMessage.meetingNotFound'), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    })

  }

  const nearMeetingJoin = (nearMeetingCode: any) => {
    setMeetingCodeHome(nearMeetingCode);
    joinMeetingHost(nearMeetingCode);
  }
  const btnJoinMeetingByCode = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setNewLatitudeJoin(position.coords.latitude);
      setNewLongitudeJoin(position.coords.longitude);
    },
      function (error) {
        if (error.code == error.PERMISSION_DENIED) {
          toast.warning(t('toastMessage.enableLocationJoinMeeting'), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      });
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.value = '';
      inputElement.focus();
    }
    setMeetingCodeHome(meetingCodeHome);
    joinMeetingHost(meetingCodeHome);
  }
  var joinMeetingModal: any = document.getElementById('join-meeting-modal');
  var joinMeetingForm: any = document.getElementById('joinMeetingForm');
  if (joinMeetingModal) {
    joinMeetingModal.addEventListener('show.bs.modal', function () {
      navigator.geolocation.getCurrentPosition(function (position) {
        setNewLatitudeJoin(position.coords.latitude);
        setNewLongitudeJoin(position.coords.longitude);
        functionMeetingNearMe(position.coords.longitude, position.coords.latitude);
      },
        function (error) {
          if (error.code == error.PERMISSION_DENIED) {
            setLocationdisbleMessage(t('toastMessage.enableLocationFindMeeting'));
          }
        });
      joinMeetingForm.reset()
    });
  }
  const refreshNearMeeting = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setNewLatitudeJoin(position.coords.latitude);
      setNewLongitudeJoin(position.coords.longitude);
      functionMeetingNearMe(position.coords.longitude, position.coords.latitude);
    },
      function (error) {
        if (error.code == error.PERMISSION_DENIED) {
          setLocationdisbleMessage(t('toastMessage.enableLocationFindMeeting'));
        }
      });
  }
  const handleMeetingCreate = (formValue: IMeeting) => {
    const { title, code, password, startTime, endTime } = formValue;
    const newTitle = title.trim();
    const newCode = code.trim();
    // Defining the maximum file counts based on environment variables
    const rawSponsorMaxFileCount = process.env.REACT_APP_NUMBER_OF_SPONSORS_UPLOAD_IMAGES;
    const sponsorMaxFileCount = rawSponsorMaxFileCount ? parseInt(rawSponsorMaxFileCount, 10) : 0;
    const meetingPassword = passwordProtected ? password : '';
    const MeetingValues = { newTitle, newCode, meetingPassword, startTime, endTime, emailProtected, passwordProtected, geoFenceProtected, newLongitude, newLatitude, brandFiles, sponsorsFiles }
    if (brandFiles.length <= 3 && sponsorsFiles.length <= sponsorMaxFileCount && fileValidationError == false && sponserfileValidationError == false && fileSizevalidation == false && sponserfileSizevalidation == false) {
      dispatch(meetingCreateHandler(MeetingValues)).then((res: any) => {
        if (res?.type == 'createMeeting/fulfilled') {
          toast.success(t('toastMessage.meetingCreate'), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setPasswordProtected(false);
          setEmailProtected(false);
          setGEOFenceProtected(false);
          setNewLatitude(0);
          setNewLongitude(0);
          setMeetingPassword("");
          setMeetingTitle('');
          setStartDate(new Date());
          setEndDate(new Date());
          setBrandFiles([]);
          setSponsorsFiles([]);
          setMeetingCode(generateMeetingCode());
          dispatch(messageHandler(''));
          const inputElement = inputReference.current;
          const inputElement1 = inputReferenced.current;
          if (geoRef.current) {
            geoRef.current.checked = false;
          }
          if (inputElement) {
            inputElement.value = '';
            inputElement.focus();
          }
          if (inputElement1) {
            inputElement1.value = '';
            inputElement1.focus();
          }
          var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('host-meeting-modal') as HTMLElement);
          myModal.hide();
          handleModalShow();
        }
      })
    } else {
      if (brandFiles.length > 3) {
        setBrandErr(true);
      }
      if (sponsorsFiles.length > sponsorMaxFileCount) {
        setSponsorError(true);
      }
    }
  };

  const checkAccess = (meetingCodeHome: any, joinPassword: any, newLongitudeJoin: any, newLatitudeJoin: any) => {
    const formValue = { meetingCodeHome, joinPassword, newLongitudeJoin, newLatitudeJoin, loggedInUser: userId }
    meetingGetByCode(meetingCodeHome, 0, 0).then((resMeet: any) => {
      dispatch(checkUserAccessHandler(formValue)).then(async (res: any) => {
        if (res?.type == 'checkUserAccess/fulfilled') {
          const state = store.getState();
          meetingGetByCode(meetingCodeHome, 0, 0).then(async (meetingData: any) => {
            if (res.payload.data.joinStatus == true) {
              dispatch(isAllowedHandler(true))
              const guestUserId = 0
              let sessionFormValue = { meetingCodeHome, userId, guestUserId }
              let sessionManage = await dispatch(setMeetingSessionHandler(sessionFormValue))
              if (sessionManage?.payload?.status === 200) {
                var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                myModal.hide();
                var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                passwordJoinMeetingModal.hide();
                setTimeout(() => {
                  dispatch(currentMeetingHandler(resMeet.data))
                  navigate('joinMeeting/' + meetingCodeHome)
                }, 1000);
              } else {
                dispatch(joinMessageHandler(t('toastMessage.meetingNotLongerAvailable')))
              }
            } else {
              if (resMeet?.data?.isPasswordProtected == true) {
                if (res.payload.data.passwordAuthenticated == true) {
                  if (meetingData.data.isGeoFenceProtected == true) {
                    /**
                     * @todo: Refactor this functionality
                     */
                    // NOTE: Changed by Gaurang/Junaid. Let's verifye this functionality again.
                    if (res.payload.data.joinStatus == true) {
                      dispatch(isAllowedHandler(true))
                      const guestUserId = 0
                      let sessionFormValue = { meetingCodeHome, userId, guestUserId }
                      let sessionManage = await dispatch(setMeetingSessionHandler(sessionFormValue))
                      if (sessionManage?.payload?.status === 200) {
                        var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                        myModal.hide();
                        var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                        passwordJoinMeetingModal.hide();
                        setTimeout(() => {
                          dispatch(currentMeetingHandler(resMeet.data))
                          navigate('joinMeeting/' + meetingCodeHome)
                        }, 1000);
                      } else {
                        dispatch(joinMessageHandler(t('toastMessage.meetingNotLongerAvailable')))
                      }
                    } else {
                      const guestUserId = 0
                      const attendeeWaitingFormValue = { meetingId: resMeet.data.id, hostId: resMeet.data.hostId, userId, guestUserId }
                      if (resMeet.data.hostId !== userId) {
                        dispatch(attendeeWaitingListHandler(attendeeWaitingFormValue)).then((res: any) => {
                          if (res?.payload?.status === 201) {
                            var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                            myModal.hide();
                            var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                            passwordJoinMeetingModal.hide();
                            socket.emit("add-waiting-user", { meetingId: resMeet.data.id, user: state?.auth?.user ? state?.auth?.user : state?.guestUser?.guestUser })
                            setTimeout(() => {
                              dispatch(currentMeetingHandler(resMeet.data))
                              navigate(`/waiting-area/` + resMeet.data.code)
                            }, 1000);
                          } else {
                            if (res?.payload?.data.statusCode === 403) {
                              var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                              myModal.hide();
                              var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                              passwordJoinMeetingModal.hide();
                              setTimeout(() => {
                                dispatch(currentMeetingHandler(resMeet.data))
                                navigate(`/waiting-area/` + resMeet.data.code)
                              }, 1000);
                            } else if (res?.payload?.data?.statusCode == 307) {
                              var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                              myModal.hide();
                              var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                              passwordJoinMeetingModal.hide();
                              setTimeout(() => {
                                dispatch(currentMeetingHandler(resMeet.data))
                                navigate(`joinMeeting/` + resMeet.data.code)
                              }, 1000);
                            } else if (res?.payload?.data?.statusCode == 409) {
                              dispatch(currentMeetingHandler(null))
                              var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                              myModal.hide();
                              var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                              passwordJoinMeetingModal.hide();
                              toast.warning(res?.payload?.data?.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                              });
                              navigate('/dashboard');
                            }
                          }
                        })
                      }
                    }
                  } else {

                    const guestUserId = 0
                    let sessionFormValue = { meetingCodeHome, userId, guestUserId }
                    let sessionManage = await dispatch(setMeetingSessionHandler(sessionFormValue))
                    if (sessionManage?.payload?.status === 200) {
                      var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                      myModal.hide();
                      var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                      passwordJoinMeetingModal.hide();
                      setTimeout(() => {
                        dispatch(currentMeetingHandler(resMeet.data))
                        navigate('joinMeeting/' + meetingCodeHome)
                      }, 1000);
                    } else {
                      dispatch(joinMessageHandler(t('toastMessage.meetingNotLongerAvailable')))
                    }
                  }
                } else {
                  return toast.error(res?.payload?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                  });
                }
              } else {
                if (resMeet.data?.isGeoFenceProtected == true) {
                  if (res.payload.data.joinStatus == true) {
                    dispatch(isAllowedHandler(true))
                    const guestUserId = 0
                    let sessionFormValue = { meetingCodeHome, userId, guestUserId }
                    let sessionManage = await dispatch(setMeetingSessionHandler(sessionFormValue))
                    if (sessionManage?.payload?.status === 200) {
                      var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                      myModal.hide();
                      var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                      passwordJoinMeetingModal.hide();

                      setTimeout(() => {
                        dispatch(currentMeetingHandler(resMeet.data))
                        navigate('joinMeeting/' + meetingCodeHome)
                      }, 1000);
                    } else {
                      dispatch(joinMessageHandler(t('toastMessage.meetingNotLongerAvailable')))
                    }
                  } else {
                    const guestUserId = 0
                    const attendeeWaitingFormValue = { meetingId: resMeet.data.id, hostId: resMeet.data.hostId, userId, guestUserId }
                    if (resMeet.data.hostId !== userId) {
                      dispatch(attendeeWaitingListHandler(attendeeWaitingFormValue)).then((res: any) => {
                        if (res?.payload?.status === 201) {
                          var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                          myModal.hide();
                          var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                          passwordJoinMeetingModal.hide();
                          socket.emit("add-waiting-user", { meetingId: resMeet.data.id, user: state?.auth?.user ? state?.auth?.user : state?.guestUser?.guestUser })

                          setTimeout(() => {
                            dispatch(currentMeetingHandler(resMeet.data))
                            navigate(`/waiting-area/` + resMeet.data.code)
                          }, 1000);
                        } else {
                          if (res?.payload?.data.statusCode === 403) {
                            var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                            myModal.hide();
                            var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                            passwordJoinMeetingModal.hide();

                            setTimeout(() => {
                              dispatch(currentMeetingHandler(resMeet.data))
                              navigate(`/waiting-area/` + resMeet.data.code)
                            }, 1000);
                          } else if (res?.payload?.data?.statusCode == 307) {
                            var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                            myModal.hide();
                            var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                            passwordJoinMeetingModal.hide();

                            setTimeout(() => {
                              dispatch(currentMeetingHandler(resMeet.data))
                              navigate(`joinMeeting/` + resMeet.data.code)
                            }, 1000);
                          } else if (res?.payload?.data?.statusCode == 409) {
                            dispatch(currentMeetingHandler(null))
                            setTimeout(() => {
                              var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                              myModal.hide();
                              var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                              passwordJoinMeetingModal.hide();

                            }, 1000);
                            toast.warning(res?.payload?.data?.message, {
                              position: toast.POSITION.TOP_RIGHT,
                              autoClose: 3000,
                            });
                            navigate('/dashboard')
                          }
                        }
                      })
                    }
                  }
                } else {
                  const guestUserId = 0
                  let sessionFormValue = { meetingCodeHome, userId, guestUserId }
                  let sessionManage = await dispatch(setMeetingSessionHandler(sessionFormValue))
                  if (sessionManage?.payload?.status === 200) {
                    var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                    myModal.hide();
                    var passwordJoinMeetingModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('password-join-meeting') as HTMLElement);
                    passwordJoinMeetingModal.hide();
                    setTimeout(() => {
                      dispatch(currentMeetingHandler(resMeet.data))
                      navigate('joinMeeting/' + meetingCodeHome)
                    }, 1000);
                  } else {
                    dispatch(joinMessageHandler(t('toastMessage.meetingNotLongerAvailable')))
                  }
                }
              }
            }
          })
        }
      })
    })
  }

  const handleJoinMeeting = async (formValue: IMeetingJoin) => {
    const { joinPassword } = formValue;
    checkAccess(meetingCodeHome, joinPassword, newLongitudeJoin, newLatitudeJoin);
  }
  const steps: any = [
    {
      target: "body",
      content: (
        <>
          <img src="/images/logo.webp" alt="" width="100px" height="100px" style={{ borderRadius: "10%" }} />

          <h1 className='font-34 mt-5 mb-4' >{t("common.welcome_tour")}</h1>{" "}
        </>
      ),
      disableBeacon: true,
      disableOverlayClose: false,
      hideCloseButton: false,
      placement: "center",
      spotlightClicks: true,
      disableScrolling: false,
      continue: true,
      styles: {
        options: {
          primaryColor: '#3C5EAB', // Change the primary button color
          zIndex: 10000,
          width: "700px",
        },
      },
    },
    {
      target: "#tour-join-meeting",
      content: (
        <div>
          <img src="/images/tour/FindaMeeting.svg" className="img-fluid" />
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: false,
      hideCloseButton: false,
      hideBackButton: hideBackButton,
      placement: "right",
      spotlightClicks: true,
      disableScrolling: false,
      styles: {
        options: {
          zIndex: 10000,
          width: "600px",
          primaryColor: '#3C5EAB', // Change the primary button color
        },
      },
      continue: true,
    },
    {
      target: "#btn-create-meeting",
      content: (
        <div>
          <img src="/images/tour/CreateMeeting.svg" className="img-fluid" />
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: false,
      hideCloseButton: false,
      hideFooter: false,
      placement: "right",
      spotlightClicks: true,
      disableScrolling: false,
      styles: {
        options: {
          zIndex: 10000,
          width: "500px",
          primaryColor: '#3C5EAB', // Change the primary button color
        },
      },
      continue: true,
    },
    {
      target: "#tour-all-meeting",
      content: (
        <div>
          <img src="/images/tour/MyMeetings.svg" className="img-fluid" />
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: false,
      hideCloseButton: false,
      hideFooter: false,
      spotlightClicks: true,
      disableScrolling: false,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
          width: "1000px",
          primaryColor: '#3C5EAB',
        },
      },
      continue: true,
    },
    {
      target: "#poll-tour",
      content: (
        <div>
          <img src="/images/tour/PollList.svg" className="img-fluid" />
        </div>
      ),
      continue: true,
      hideCloseButton: false,
      placement: "right",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
          width: "1000px",
          primaryColor: '#3C5EAB',
        },
      },
    },
    {
      target: "#header-dropdown",
      content: (
        <div>
          <img src="/images/tour/ProfileImage.svg" className="img-fluid" />
        </div>
      ),
      continue: true,
      hideCloseButton: false,
      placement: "right",
      spotlightClicks: true,
      locale: {
        last: 'Done'
      },
      styles: {
        options: {
          zIndex: 10000,
          width: "600px",
          primaryColor: '#3C5EAB',
        },
      },
    },
  ];

  const handleCallback = (data: any) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      dispatch(stepIndexHandler(index + (action === ACTIONS.PREV ? -1 : 1)))
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      dispatch(tourEnableHandler(false))
      dispatch(hideBackButtonHandler(true));
      dispatch(stepIndexHandler(1))
      dispatch(tourCompleteHandler(false));

    }
    if (action === "close" || type === "finished") {
      dispatch(tourEnableHandler(false))
      dispatch(hideBackButtonHandler(true));
      dispatch(stepIndexHandler(1))
      dispatch(tourCompleteHandler(false));
    }
  };


  const handleTour = () => {
    dispatch(tourEnableHandler(true))
    dispatch(stepIndexHandler(1))
    navigate("/dashboard");
  }

  const handleMessageClear = () => {
    setPasswordProtected(false);
    setEmailProtected(false);
    setGEOFenceProtected(false);
    setNewLatitude(0);
    setNewLongitude(0);
    setMeetingPassword("");
    setMeetingTitle('');
    setStartDate(new Date());
    setEndDate(new Date());
    setBrandFiles([]);
    setSponsorsFiles([]);
    setMeetingCode(generateMeetingCode());
    dispatch(messageHandler(''));
    setBrandErr(false)
    setSponsorError(false)
    setFileValidationError(false)
    setSponserFileValidationError(false)
    setFileSizevalidation(false)
    setsponserFileSizevalidation(false)
    const inputElement = inputReference.current;
    const inputElement1 = inputReferenced.current;
    if (geoRef.current) {
      geoRef.current.checked = false;
    }
    if (inputElement) {
      inputElement.value = '';
      inputElement.focus();
    }
    if (inputElement1) {
      inputElement1.value = '';
      inputElement1.focus();
    }
    if (inputElement) {
      inputElement.value = '';
      inputElement.focus();
    }
    if (inputElement1) {
      inputElement1.value = '';
      inputElement1.focus();
    }
    // this function is called when the modal is closed
  };



  return (
    <>
      <Joyride
        callback={handleCallback}
        steps={steps}
        run={tourEnable}
        continuous={true}
        stepIndex={stepIndex}
        showSkipButton={true}
        scrollToFirstStep
      />
      <nav className="align-items-start bg-primary" id="side-menu">
        <div className="flex-column h-100" id="mainNavigation">
          <div className="d-flex flex-column align-items-center justify-content-between h-100">
            <ul className="navbar-nav flex-column gap-4 mt-4 h-100">
              <li className="nav-item">
                <Link className="nav-link fw-medium" to="/dashboard">
                  <span className="d-flex align-items-center gap-4">
                    <span className="size32">
                      <img className="img-fluid" src={IconDashboard} alt="join-meet" />
                    </span>
                    <span className="nav-link-label flex-fill">{t('deafultLeftLayout.navigation.dashboard')}</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fw-medium" data-bs-toggle="modal" to="#join-meeting-modal"  >
                  <span className="d-flex align-items-center gap-4" id='tour-join-meeting'>
                    <span className="size32">
                      <img className="img-fluid" src={IconFindMeeting} alt="join-meet" />
                    </span>
                    <span className="nav-link-label flex-fill">{t('common.findMeeting')}</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="#" className="nav-link fw-medium" onClick={createMeetingModal}>
                  <span className="d-flex align-items-center gap-4" id="btn-create-meeting">
                    <span className="size32">
                      <img className="img-fluid" src={IconCreateMeeting} alt="join-meet" />
                    </span>
                    <span className="nav-link-label flex-fill">{t('common.createMeeting')}</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fw-medium" to="/meeting"  >
                  <span className="d-flex align-items-center gap-4" id='tour-all-meeting'>
                    <span className="size32">
                      <img className="img-fluid" src={IconMyMeetings} alt="join-meet" />
                    </span>
                    <span className="nav-link-label flex-fill">{t('deafultLeftLayout.navigation.myMeeting')}</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fw-medium" to="/poll" >
                  <span className="d-flex align-items-center gap-4" id='poll-tour'>
                    <span className="size32">
                      <img className="img-fluid" src={IconPoll} alt="join-meet" />
                    </span>
                    <span className="nav-link-label flex-fill">{t('common.Poll')}</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fw-medium" to="/quiz" >
                  <span className="d-flex align-items-center gap-4">
                    <span className="size32">
                      <img className="img-fluid" src={IconQuiz} alt="join-meet" />
                    </span>
                    <span className="nav-link-label flex-fill">{t('common.Quiz')}</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item d-flex flex-column flex-fill justify-content-end">
                <Link className="nav-link fw-medium" to="/logout">
                  <span className="d-flex align-items-center gap-4">
                    <span className="size32">
                      <img className="img-fluid" src={IconLogout} alt="icon" />
                    </span>
                    <span className="nav-link-label flex-fill">{t('deafultLeftLayout.navigation.logout')}</span>
                  </span>
                </Link>
              </li>
              <li >

                <button className="btn nav-link fw-medium" id='question_logo' onClick={handleTour} >
                  <span className="d-flex align-items-center gap-4" >
                    <span className="size32" title='Help' >
                      <img
                        className="img-fluid"
                        src={IconQuestionCircle}
                        width={"30px"}
                        alt="icon"
                      />
                    </span>

                    <span className="nav-link-label flex-fill">
                      {t("deafultLeftLayout.navigation.help")}
                    </span>
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="modal fade" id="password-join-meeting" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="password-join-meetingLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-2 px-md-4">
              <h2 className="fw-bold mb-0">Join Meeting</h2>
              <button type="button" className="btn-close text-muted" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body px-2 px-md-4 pb-5">
              <Formik
                initialValues={initialValuesJoinMeeting}
                validationSchema={validationSchemaJoinMeeting}
                onSubmit={handleJoinMeeting}
                enableReinitialize={false}
              >
                <Form id='joinMeetingForm' name="joinForm">
                  {joinMessage && (
                    <div className="form-group">
                      <div
                        className={
                          joinSuccessful ? "alert alert-success" : "alert alert-danger p-2 rounded-0 border-0"
                        }
                        role="alert"
                      >
                        {joinMessage}
                      </div>
                    </div>
                  )}

                  <div className="mb-4">
                    <label htmlFor="meeting-password" className="form-label">{t('common.Password')}</label>
                    <Field type="text" name='joinPassword' className="form-control password-text" id="meeting-password" placeholder={t('deafultLeftLayout.joinMeetingModal.placeholderMeetingPassword')} />
                    <ErrorMessage name="joinPassword" component="div" className="text-danger txt-error" />

                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <button type='submit' className="btn btn-primary mw-120">{t('deafultLeftLayout.joinMeetingModal.btnjoin')}</button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="join-meeting-modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="join-meeting-modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-2 px-md-4">
              <h2 className="fw-bold mb-0">{t('common.findMeeting')}</h2>
              <button type="button" className="btn-close text-muted" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body px-2 px-md-4 pb-5">
              <div className='mb-3'>
                <div className="input-group mb-3">
                  <input ref={inputRef} type="text" onChange={(e: any) => { setMeetingCodeHome(e.target.value.trim()) }} className="form-control rounded-45" placeholder={t('common.placeholderCode')} />
                  <button disabled={meetingCodeHome ? false : true} className="btn btn-primary rounded-end" onClick={btnJoinMeetingByCode} type="button" id="button-addon2">{t('common.btnGo')}</button>
                </div>

              </div>
              <div className='mb-3 text-black text-center'>
                {t('common.lblOr')}
              </div>
              <div className="font-22 fw-medium mb-4">{t('deafultLeftLayout.joinMeetingModal.lblNearBy')}</div>
              <ul className='near-meeting-listing'>
                {!!nearMeetingList &&
                  nearMeetingList.length > 0 ?
                  nearMeetingList.map((nearMeeting: any) => (
                    <li key={nearMeeting.uuid} className="border-bottom pb-3 mb-3">
                      <div className='d-flex justify-content-between flex-fill me-3 align-items-center'>
                        <div>{nearMeeting.title}</div>
                        <div>{nearMeeting.code}</div>
                      </div>
                      <div className='col-action'>
                        <button className='btn btn-sm btn-primary' onClick={() => nearMeetingJoin(nearMeeting.code)} type='button'>{t('common.btnJoinMeeting')}</button>
                      </div>
                    </li>
                  )) : (
                    <>
                      <div className='d-flex flex-wrap align-items-center'>
                        {LocationdisbleMessage}
                        <button type='button' onClick={refreshNearMeeting} className='btn'>{t('deafultLeftLayout.joinMeetingModal.btnClickHere')}</button>
                      </div>
                    </>
                  )
                }
              </ul>

            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => handleModalClose(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Do You Want To Start The Meeting?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!createdMeeting &&
            Object.keys(createdMeeting).length > 0 &&
            <div className="card h-100 shadow">
              <div className="card-body">
                <div>{createdMeeting.title}</div>
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                  <div>
                    <img
                      className="img-fluid"
                      src="/images/meeting.svg"
                      alt="meeting"
                    />
                  </div>
                  <div>
                    {(createdMeeting.status === "created" && startCreatedMeeting === false) && (
                      <div>
                        <button
                          onClick={() => {
                            startMeetingHandler(
                              createdMeeting.id,
                              "inProgress"
                            );
                            setStartCreatedMeeting(true);
                          }}
                          className="btn btn-primary rounded-45 mw-120"
                        >
                          {t('myMeeting.btnStartMeeting')}
                        </button>
                      </div>
                    )}

                    {(createdMeeting.status === "started" || createdMeeting.status === "inProgress" || startCreatedMeeting === true) && (
                      <div>
                        <button onClick={() => {
                          joinMeetingHandler(
                            createdMeeting.id,
                            "inProgress"
                          );
                          handleModalClose(true);
                        }}
                          className="btn btn-primary rounded-45 mw-120"
                        >
                          {t('myMeeting.btnJoinMeeting')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleModalClose(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="modal fade" id="host-meeting-modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="host-meeting-modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-2 px-md-4">
              <h2 className="fw-bold mb-0">{t('common.createMeeting')}</h2>
              <button type="button" className="btn-close text-muted" data-bs-dismiss="modal" aria-label="Close" onClick={handleMessageClear}></button>
            </div>
            <div className="modal-body px-2 px-md-4 pb-5">
              <div className="container-fluid">
                <div className="font-22 fw-medium mb-4">{t('deafultLeftLayout.hostMeeting.tagLine')}</div>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleMeetingCreate}
                >
                  <Form id='hostMeetingForm'>
                    <div className="mb-4">
                      <label htmlFor="meeting-title" className="form-label">{t('deafultLeftLayout.hostMeeting.lblTitle')}</label>
                      <Field onChange={(e: any) => { setMeetingTitle(e.target.value) }} type="text" name='title' className="form-control" id="meeting-title" placeholder={t('deafultLeftLayout.hostMeeting.placeholderMeetingTitle')} value={meetingTitle} />
                      <ErrorMessage name="title" component="div" className="text-danger txt-error" />

                    </div>

                    <div className="mb-4">
                      <label htmlFor="meeting-host-code" className="form-label">{t('deafultLeftLayout.hostMeeting.lblMeetingCode')}</label>
                      <Field type="text" onChange={(e: any) => { setMeetingCode(e.target.value) }} name='code' className="form-control" id="meeting-host-code" placeholder={t('deafultLeftLayout.hostMeeting.placeholderMeetingCode')} value={meetingCode} />
                      <ErrorMessage name="code" component="div" className="text-danger txt-error" />
                      {!!codeExistMessage && codeExistMessage.length > 0 && (
                        <div className="form-group">
                          <div
                            className={
                              successful ? "alert alert-success" : "alert alert-danger p-2 rounded-0 border-0"
                            }
                            role="alert"
                          >
                            {codeExistMessage}
                          </div>
                        </div>
                      )}
                      {newRandomCodes && newRandomCodes.length > 0 &&
                        <div className="d-flex align-items-center flex-wrap gap-3 mt-2">
                          <div>
                            <span title='New available codes generation, Click to use code from here.'>Suggestions:</span>
                          </div>
                          <div className="d-flex flex-wrap gap-2">
                            {newRandomCodes.map((item, index) => (
                              <span
                                key={index}
                                onClick={() => handleRandomCodeClick(item)}
                                className="meeting-code-tag me-2"
                              >
                                {item}
                              </span>
                            ))}
                          </div>
                        </div>
                      }
                    </div>
                    <div>
                      <label className='form-label mb-3'>{t('deafultLeftLayout.hostMeeting.lblEmailProtected')}
                        <input className='ms-1' checked={emailProtected} onChange={(e: any) => setEmailProtected(e.target.checked)} type="checkbox" />
                      </label>
                    </div>
                    <div className='d-flex mb-2 justify-content-between flex-wrap'>
                      <label className='form-label mb-3'>{t('common.lblPasswordProtected')} <input className='ms-1' checked={passwordProtected} onChange={(e: any) => setPasswordProtected(e.target.checked)} type="checkbox" /></label>
                      <label className='form-label mb-3'>{t('common.lblGEOProtected')}
                        <input
                          type="checkbox"
                          className='ms-1'
                          ref={geoRef}
                          onChange={(e: any) => {
                            setGEOFenceProtected(e.target.checked);
                            if (geoFenceProtected !== true) {
                              if ("geolocation" in navigator) {
                                navigator.geolocation.getCurrentPosition(function (position) {
                                  setNewLatitude(position.coords.latitude)
                                  setNewLongitude(position.coords.longitude)
                                  setTimeout(() => { initMap() }, 100);
                                  function initMap(): void {
                                    var currentLocation = { lat: newLatitude, lng: newLongitude };
                                    // The map, centered at Uluru
                                    const map = new google.maps.Map(
                                      document.getElementById("map") as HTMLElement,
                                      {
                                        zoom: mapZoom,
                                        center: currentLocation
                                      }
                                    );
                                    // The marker, positioned at Uluru
                                    const marker = new google.maps.Marker({
                                      position: currentLocation,
                                      draggable: true,
                                      map: map,
                                    });

                                    google.maps.event.addListener(marker, 'dragend', function (marker: { latLng: any; }) {
                                      var latLng = marker.latLng;
                                      setNewLatitude(latLng.lat())
                                      setNewLongitude(latLng.lng())
                                      var zoom = map.getZoom();
                                      setMapZoom(zoom);
                                    });

                                  }

                                },
                                  function (error) {
                                    if (error.code == error.PERMISSION_DENIED)
                                      toast.warning(t('toastMessage.enableLocationCreateMeeting'), {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,

                                      });
                                  });
                              }
                            } 
                          }}
                        />
                      </label>
                    </div>
                    {passwordProtected && (
                      <div className="mb-4">
                        <label htmlFor="meeting-host-password" className="form-label">{t('common.Password')}</label>
                        <><Field type="text" name='password' onChange={(e: any) => { setMeetingPassword(e.target.value) }} className="form-control" id="meeting-host-password" placeholder={t('deafultLeftLayout.hostMeeting.placeholderMeetingPassword')} />
                          <ErrorMessage name="password" component="div" className="text-danger txt-error" /></>

                      </div>
                    )} <div id='mymap' style={{ height: '250px', width: '100%' }}>
                      <Wrapper apiKey="AIzaSyD-ge9d_DPPLLsEkjlGCNCZhfX69Lr4wjk">
                        <div style={{ height: '250px', width: '100%' }} id="map" />
                      </Wrapper>
                    </div>

                    <div className='border-top py-3 mt-3'>
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button font-22 fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#meetingTimeBlock" aria-expanded="true" aria-controls="meetingTimeBlock">
                          <span>{t('deafultLeftLayout.hostMeeting.lblMeetingTime')} </span>
                          <span className='ms-2'>
                            <i className='fa fa-edit'></i>
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div className="row collapse" id='meetingTimeBlock'>
                      <div className="col-md-6 mb-4">
                        <label htmlFor="meeting-start-time" className="form-label">{t('deafultLeftLayout.hostMeeting.lblMeetingStartTime')}</label>
                        <DatePicker locale={window.localStorage.language} className='form-control' name='startTime'
                          selected={startDate}
                          onChange={(date: Date) => {
                            setStartDate(date)
                          }
                          }
                          minDate={new Date()}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          filterTime={filterPassedStartTime}
                          dateFormat="MMM d, yyyy hh:mm aa"
                          showTimeSelect
                        />

                        <ErrorMessage name="startTime" component="div" className="text-danger txt-error" />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label htmlFor="meeting-end-time" className="form-label">{t('deafultLeftLayout.hostMeeting.lblMeetingEndTime')}</label>
                        <DatePicker locale={window.localStorage.language} className='form-control' name='endTime'
                          selected={endDate}
                          onChange={(date: Date) => {
                            setEndDate(date)
                          }}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          filterTime={filterPassedEndTime}
                          dateFormat="MMM d, yyyy hh:mm aa"
                          showTimeSelect
                        />
                        <ErrorMessage name="endTime" component="div" className="text-danger txt-error" />
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className='d-flex align-items-center justify-content-between'>
                        <label htmlFor="meeting-host-formFile-brand" className="form-label">{t('deafultLeftLayout.hostMeeting.lblMeetingUploadBrand')}</label>
                        <span><small>{t('deafultLeftLayout.hostMeeting.lblUploadOptional')}</small></span>
                      </div>
                      <input ref={inputReference} className="form-control" type="file" accept=".jpg, .jpeg, .png" id="meeting-host-formFile-brand" multiple={true} onChange={(event: any) => {
                        const bfiles = event.target.files;
                        let mybFiles: any = Array.from(bfiles);
                        const brandimge = process.env.REACT_APP_NUMBER_OF_BRAND_UPLOAD_IMAGES;
                        if (mybFiles.length == 0) {
                          setFileValidationError(false)
                          setFileSizevalidation(false)
                        }
                        if (mybFiles.length > Number(brandimge)) {
                          setBrandErr(true);
                        } else {
                          for (let i = 0; i < mybFiles.length; i++) {
                            const fileType = mybFiles[i].type;
                            const fileSize = mybFiles[i].size;
                            if (fileSize > 2 * 1024 * 1024) {
                              setFileSizevalidation(true)
                              break;
                            } else {
                              setFileSizevalidation(false)
                            }

                            if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg') {
                              setFileValidationError(true)
                            } else {
                              setFileValidationError(false)
                            }
                          }
                          setBrandErr(false);
                        }
                        setBrandFiles(mybFiles)
                      }} />
                      <span style={{ color: "red" }}>
                        {fileValidationError ? t("validation.FormatNotAllowed")
                          : brandErr ? updatedBrandFilesValidation : fileSizevalidation ? t("validation.FileSizeValidation") : ""}
                      </span>
                    </div>
                    <div className="mb-4">
                      <div className='d-flex align-items-center justify-content-between'>
                        <label htmlFor="meeting-host-formFile-sponsors" className="form-label">{t('deafultLeftLayout.hostMeeting.lblMeetingUploadSponsors')}</label>
                        <span><small>{t('deafultLeftLayout.hostMeeting.lblUploadOptional')}</small></span>
                      </div>
                      <input ref={inputReferenced} className="form-control" type="file" accept=".jpg, .jpeg, .png" id="meeting-host-formFile-sponsors" multiple={true} onChange={(event: any) => {
                        const sfiles = event.target.files;
                        let mysFiles: any = Array.from(sfiles);
                        const rawSponsorMaxFileCount = process.env.REACT_APP_NUMBER_OF_SPONSORS_UPLOAD_IMAGES;
                        const sponsorMaxFileCount = rawSponsorMaxFileCount ? parseInt(rawSponsorMaxFileCount, 10) : 0;
                        if (mysFiles.length == 0) {
                          setSponserFileValidationError(false)
                          setsponserFileSizevalidation(false)
                        }
                        if (mysFiles.length > sponsorMaxFileCount) {
                          setSponsorError(true);
                        } else {
                          for (let i = 0; i < mysFiles.length; i++) {
                            const fileType = mysFiles[i].type;
                            const fileSize = mysFiles[i].size; 
                            if (fileSize > 2 * 1024 * 1024) {
                              setsponserFileSizevalidation(true);
                              break;
                            } else {
                              setSponsorsFiles(mysFiles);
                              setsponserFileSizevalidation(false);
                            }
                            if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg') {
                              setSponserFileValidationError(true);
                            } else {
                              setSponsorsFiles(mysFiles);
                              setSponserFileValidationError(false);
                            }
                          }
                          setSponsorError(false);
                        }
                      }} />
                      <span style={{ color: "red" }}>
                        {sponserfileValidationError
                          ? t("validation.FormatNotAllowed")
                          : sponsorErr
                            ? updatedSponsorFilesValidation : sponserfileSizevalidation ? t("validation.FileSizeValidation")
                              : ""}
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <button type='submit' className="btn btn-primary mw-120">{t('deafultLeftLayout.hostMeeting.btnHostMeeting')}</button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
