import React, { useEffect, useState } from "react";
import { DatatableWrapper, Filter, TableBody, TableHeader } from "react-bs-datatable";
import DialogBox from "../components/DeleteBox";
import { useTranslation } from "react-i18next";
import { quizDataHandler, deleteQuizHandler } from "../store/slices/quizSlice";
import { Link, useNavigate } from 'react-router-dom';
import PaginationBox from '../components/Pagination';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { selectedQuestionHandler } from "../store/slices/pollSlice";

const QuizList = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.auth)
    const { quizList, totalQuizPage, totalQuizRecord } = useSelector((state: RootState) => state.quiz)
    const userId = user?.user?.id

    const { t } = useTranslation('common');
    const [showDelete, setShowDelete] = useState(false);
    const [pageSize, setpageSize] = useState(1); // Page number
    const [perPage, setperPage] = useState(10); // per page Limit
    const [filterValue, setFilterValue] = useState("");
    const [endItem, setendItem] = useState(0);
    const [startItem, setStartItem] = useState(1);
    const pageLimitOptions = [5, 10, 15, 20]
    const [QuizDeleteId, setQuizDeleteID] = useState();
    const [sortColumn, setsortColumn] = useState<any>({ order: "desc", prop: "createdAt" }); // per page Limit 

    const getQuizData = async (
        hostID: any,
        pageLimit: number,
        perPage: number,
        searchVal: string,
        sortColumn: any
    ) => {
        const sortColumnProp = sortColumn.prop;
        const sortColumnOrder = sortColumn.order
        const data = {
            hostID, pageLimit, perPage, searchVal, sortColumnProp, sortColumnOrder
        };

        const quizDataList = await dispatch(quizDataHandler(data));
        if (quizDataList?.payload?.currentPage !== undefined) {
            setpageSize(quizDataList?.payload?.currentPage)
        } else {
            setpageSize(prev => prev)
        }
    };


    useEffect(() => {
        getQuizData(userId, pageSize, perPage, filterValue, sortColumn);
    }, [perPage, pageSize, sortColumn, filterValue]);

    const handlePrevPage = (e: number) => {
        setpageSize(e);
        setStartItem(startItem - perPage);
        totalrecordHandle();
    };

    const handleNextPage = (e: number) => {
        setpageSize(e);
        setStartItem(endItem + 1);
        totalrecordHandle();
    };

    const handlePageClick = (e: any) => {
        setpageSize(e);
        setStartItem(e * perPage + 1 - perPage);
        totalrecordHandle();
    };

    const totalrecordHandle = () => {
        var totalRecordTemp = startItem - 1 + perPage;
        if (totalRecordTemp > totalQuizRecord) {
            totalRecordTemp = totalQuizRecord;
        }
        setendItem(totalRecordTemp);
    };

    useEffect(() => {
        totalrecordHandle()
    }, [startItem, endItem, totalQuizRecord, quizList]);

    const DeletePollHandlers = async (id: any) => {
        await dispatch(deleteQuizHandler(id)).then((response: any) => {
            if (response?.payload?.status === 200) {
                getQuizData(userId, pageSize, perPage, filterValue, sortColumn);
                toast.success(t("toastMessage.quizDelete"), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
            }
        })
    };
    const deleteConfirmHandler = (id: any) => {
        setShowDelete(true)
        setQuizDeleteID(id)
    }

    const header = [
        {
            title: t("quiz.lblTitle"),
            prop: "title",
            isSortable: true,
            isFilterable: true,
        },
        {
            title: t("quiz.lblTag"),
            prop: "tag",
            cell: (row: any) => {
                return (
                    row.quizMeetingDetails?.length > 0 &&
                    row.quizMeetingDetails.map((tags: any, index: any) => {
                        return (
                            <div
                                key={`quizlistrow_${index}`}
                                className="badge rounded-pill badge-pill py-2 fw-medium me-2 mb-2">
                                <div>{tags.meetingDetails.title}</div>
                                <div></div>
                            </div>
                        );
                    })
                );
            },
        },
        {
            title: "",
            prop: "",
            cellProps: {
                className: "action-td",
            },
            cell: (row: any) => {
                return (
                    <>
                        <div className="d-flex align-items-center gap-2 action-col">
                            <button
                                onClick={() => navigate("/quiz/" + row.id)}
                                className="bg-success btn bg-opacity-25 text-decoration-none rounded-1 size-38">
                                <i className="fa-solid fa-pen text-success"></i>
                            </button>
                            {row.quizMeetingDetails?.length === 0 &&
                                <button
                                    onClick={() => deleteConfirmHandler(row.id)}
                                    className="btn mx-2 btn-primary-light rounded-1 size-38">
                                    <i className="fa-solid fa-trash-can text-danger"></i>
                                </button>
                            }
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <div className="container-fluid">
            <div className="font-26 fw-semibold mb-4">{t("common.Quiz")}</div>
            <div className="quiz">
                <div className="row">
                    <div className="col-12">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <div className="font-20 fw-semibold">{t("quiz.title")}</div>
                                    <div>
                                        <button
                                            onClick={() => { navigate('/quiz/0'); dispatch(selectedQuestionHandler([])) }}
                                            className="btn btn-primary rounded-45 mw-120">
                                            {t("quiz.btnCreateQuiz")}
                                        </button>
                                    </div>
                                </div>

                                <div className="quiz-table">
                                    <DatatableWrapper body={quizList} headers={header}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                            </div>
                                            <div>
                                                <Filter
                                                    placeholder={t("searchplaceholder.placeholder")}
                                                    controlledProps={{
                                                        filter: filterValue,
                                                        onFilterChange: setFilterValue,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <TableHeader
                                                    controlledProps={{
                                                        onSortChange: setsortColumn,
                                                        sortState: sortColumn,
                                                    }}
                                                />
                                                <TableBody />
                                            </table>
                                        </div>

                                        <div className="d-flex justify-content-sm-between gap-3 align-items-center flex-wrap justify-content-center">

                                            <PaginationBox
                                                filteredDataLength={perPage}
                                                startItem={startItem}
                                                totalPage={totalQuizPage}
                                                endItem={endItem}
                                                currentPage={pageSize}
                                                handlePrevPage={handlePrevPage}
                                                handleNextPage={handleNextPage}
                                                handlePageClick={handlePageClick}
                                                rowsPerPageOptions={pageLimitOptions}
                                                rowsPerPage={perPage}
                                                totalRecord={totalQuizRecord}
                                            />
                                        </div>
                                    </DatatableWrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DialogBox
                show={showDelete}
                clickOk={() => {
                    DeletePollHandlers(QuizDeleteId);
                    setShowDelete(false);
                }}
                clickCancel={() => {
                    setShowDelete(false);
                }}
                btncancel={t("common.cancel")}
                btnyes={t("common.delete")}
                question={t("deleteBox.dltQuiz")}
            />
        </div>
    );
};

export default QuizList;
