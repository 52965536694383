import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { getMeetingNearbyMe } from '../../services/meeting.service';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

const Home = () => {
    const { t } = useTranslation('common');
    let navigate = useNavigate();
    const [nearMeetingList, setnearMeetingList] = useState<any[]>([]);
    const [meetingCodeHome, setMeetingCodeHome] = useState("");
    const [nearbyMeetingListModal, setNearbyMeetingListModal] = useState<boolean>(false)

    const functionMeetingNearMe = (latitude: number, longitude: number) => {
        getMeetingNearbyMe(latitude, longitude).then(
            (response) => {
                if (response.length === 0) {
                    toast.warning(t('toastMessage.allowLocationPermission'), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
                else {
                    if (response.length > 1) {
                        setnearMeetingList(response)
                        setNearbyMeetingListModal(true)
                    }
                    else {
                        navigate(`/guest/login/${response[0].code}`)
                    }
                }

            }
        )
    }

    const nearMeetingJoin = (nearMeetingCode: string) => {
        setNearbyMeetingListModal(false)
        navigate(`/guest/login/${nearMeetingCode}`)
    }

    const btnJoinMeeting = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            functionMeetingNearMe(position.coords.longitude, position.coords.latitude)
        },
            function (error) {
                if (error.code == error.PERMISSION_DENIED)
                    toast.warning(t('toastMessage.enableLocationFindMeeting'), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
            });
    }

    const btnJoinMeetingByCode = () => {
        navigate(`/guest/login/${meetingCodeHome}`)
    }

    return (
        <div className="wrapper">
            <div className="intro d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <div className="mb-2 text-center">
                        <h1 className="title d-flex flex-column align-items-center">
                            <span>{t('homePage.lblIntroducing')}</span>
                            <span>{t('homePage.lblMeeting')}</span>
                            <span>{t('homePage.lblBuddy')}</span>
                        </h1>
                        <p className="subtitle">{t('homePage.lblTitle')}</p>
                    </div>
                    <div className='mb-3'>
                        <label className='text-white font-18'>{t('homePage.lblFindMeeting')}</label>
                    </div>
                    <div className='d-flex gap-3 flex-wrap justify-content-center mb-1'>
                        <button onClick={btnJoinMeeting} className="btn px-5 py-2 rounded-1 text-white bg-primary text-uppercase">{t('homePage.btnLocation')}</button>
                    </div>
                    <div className='my-3 text-white'>
                        {t('common.lblOr')}
                    </div>
                    <div>
                        <div className="input-group mb-3" style={{ maxWidth: 200 }}>
                            <input type="text" onChange={(e: any) => { setMeetingCodeHome(e.target.value.trim()) }} className="form-control rounded-45" placeholder={t('common.placeholderCode')} />
                            <button disabled={meetingCodeHome ? false : true} className="btn btn-primary rounded-end" onClick={btnJoinMeetingByCode} type="button" id="button-addon2">{t('common.btnGo')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-info">
                <div className="p-4">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-4">
                            <h2 className="mb-4">{t('homePage.lblSimplicity')}</h2>
                            <p className="mb-4">
                                {t('homePage.descSimplicity')}
                            </p>
                            <p className="mb-4">
                                {t('homePage.descGoal')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="silder">
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active"></li>
                        <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></li>
                        <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="/images/slider-img-01.jpg" className="sliderImage" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src="/images/slider-img-02.jpg" className="sliderImage" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src="/images/slider-img-03.jpg" className="sliderImage" alt="..." />
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-info">
                <div className="p-4">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-4">
                            <h2 className="mb-4">{t('homePage.lblMeetingBuddy')}</h2>
                            <p className="mb-4">{t('homePage.titleMeetingBuddy')}</p>
                            <p>{t('homePage.descMeetingBuddy')}
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="row g-0">
                <div className="col-lg-6">
                    <img src="/images/img-networking.webp" className="img-fluid w-100" />
                </div>
                <div className="col-lg-6 bg-black text-white">
                    <div className="d-flex flex-column h-100 align-items-center justify-content-center p-3">
                        <div className="row justify-content-center">
                            <div className="col-lg-11 col-xl-8 col-12">
                                <h3 className="mb-3 mb-lg-2 mb-xl-4">{t('homePage.lblNetworking')}</h3>
                                <p className="mb-3 mb-lg-2 mb-xl-4">{t('homePage.titleNetworking')}</p>
                                <p>{t('homePage.descNetworking')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-lg-6 order-0 order-lg-1">
                    <img src="/images/img-connect.webp" className="img-fluid w-100" />
                </div>
                <div className="col-lg-6 order-1 order-lg-0 bg-black text-white">
                    <div className="d-flex flex-column h-100 align-items-center justify-content-center p-3">
                        <div className="row justify-content-center">
                            <div className="col-lg-11 col-xl-8 col-12">
                                <h3 className="mb-3 mb-lg-2 mb-xl-4">{t('homePage.lblConnect')}</h3>
                                <p className="mb-3 mb-lg-2 mb-xl-4">{t('homePage.titleConnect')}</p>
                                <p>{t('homePage.descConnect')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer">
                <Link to="/#" className="text-decoration-none text-white">
                    {t('footer.title')}</Link>
            </div>

            <Modal show={nearbyMeetingListModal} centered onHide={() => setNearbyMeetingListModal(false)}>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='fw-semibold'>
                        {t('homePage.locationModel.lblSelectMeeting')}
                        {t('deafultLeftLayout.joinMeetingModal.lblNearBy')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className='list-unstyled'>
                        {!!nearMeetingList &&
                            nearMeetingList.length > 0 &&
                            nearMeetingList.map((nearMeeting, index) => (
                                <li key={index} className="hstack justify-content-between border-bottom pb-3 mb-3">
                                    <div className='text-truncate'>{nearMeeting.title}</div>
                                    <button className='btn btn-sm btn-primary' onClick={() => { nearMeetingJoin(nearMeeting.code) }} type='button'>{t('common.btnJoinMeeting')}</button>
                                </li>
                            ))
                        }
                    </ul>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Home;