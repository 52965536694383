import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, useParams } from "react-router-dom";
import { IPoll } from "../../types/poll.type";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { pollCreateHandler, pollGetByIdHandler, pollUpdateByIdHandler, selectedQuestionHandler } from "../store/slices/pollSlice";
import * as ACTION from "../store/actions/index";
import { DropArea } from "../components/DropArea";
import SelectedQuestionList from "../components/ManageQuestions/SelectedQuestionList";
import QuestionContainer from "../components/ManageQuestions/QuestionContainer";

const PollDetails = () => {
  const dispatch = useDispatch()
  let navigate = useNavigate();
  const { pollMessage, selectedQuestion, pollSuccessful: successful } = useSelector((state: RootState) => state.poll)
  const { questionmessage } = useSelector((state: RootState) => state.question)

  const { t } = useTranslation("common");
  let { pollId } = useParams();
  const createMode = !!pollId && pollId === "0" ? true : false
  const initialValues: IPoll = {
    title: "",
  };
  const [currentPoll, setPollData] = useState(initialValues);
  const [pollMeetings, setPollMeetings] = useState<any[]>([]);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(t("validation.requiredField"))
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          return originalValue.trim();
        }
        return value;
      })
      .matches(/^[a-zA-Z0-9]/, t("validation.specialcharacterPollTitle"))
      .strict(true)
      .test(
        "len",
        t('validation.minlengthTitle'),
        (val: any) =>
          val &&
          val.toString().length >= 3
      )
      .test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val &&
          val.trim() !== ''
      ),
  });

  const handlePollCreate = async (formValue: IPoll) => {
    let { title } = formValue;
    title = title.trim();
    if (!createMode) {
      const data = {
        ID: pollId, title, selectedQuestion
      }
      await dispatch(pollUpdateByIdHandler(data)).then((res: any) => {
        if (res?.type == `${ACTION.POLLUPDATE}/${ACTION.FULFILLED}`) {
          toast.success(t("toastMessage.pollUpdate"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          navigate("/poll");
        }
        else if (res.type == `${ACTION.POLLUPDATE}/${ACTION.REJECTED}`) {
          toast.error(t("toastMessage.pollAlredyExist"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

        }
      })
    } else {
      const formValue = {
        title, selectedQuestion
      }
      await dispatch(pollCreateHandler(formValue)).then((res: any) => {
        if (res?.type == `${ACTION.CREATEPOLL}/${ACTION.FULFILLED}`) {
          toast.success(t("toastMessage.pollCreate"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          navigate("/poll");
        } else {
          toast.error(pollMessage)
        }
      })
    }
  };

  const getPollDetailByPollCode = async (pollId: string) => {
    let pollDetails = await dispatch(pollGetByIdHandler(pollId))
    if (pollDetails?.payload?.status === 200) {
      setPollData(pollDetails?.payload?.data);
      let questionData: any = [];
      pollDetails.payload?.data.pollsQuestionDetails.forEach((questionValue: any) => {
        questionData.push(questionValue.questionDetails);
      });
      dispatch(selectedQuestionHandler(questionData))
    }
  };

  useEffect(() => {
    if (!!pollId && !createMode) {
      getPollDetailByPollCode(pollId);
    }
  }, [pollId]);

  useEffect(() => {
    return (() => {
      if (!!pollId && createMode) {
        dispatch(selectedQuestionHandler([]))
      }
    })
  }, []);

  return (
    <div className="container-fluid">
      <h2 className="font-26 fw-semibold text-decoration-none text-dark mb-4">{createMode ? t("poll.btnCreatePoll") : t("pollDetails.title")}</h2>
      <div className="poll">
        <div className="row">
          <div className="col-xl-7 mb-4">
            <div className="card border-0 h-100">
              <Formik
                initialValues={currentPoll}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handlePollCreate}>
                <Form>
                  <div className="card-body">
                    <div className="d-flex flex-wrap justify-content-between">
                      <div className="font-20 fw-semibold mb-2">
                        {t("pollDetails.title")}
                      </div>
                      <div className="d-flex align-items-center gap-2 mb-2">
                        <Link
                          to="/poll"
                          className="btn btn-outline-danger rounded-45 mw-120">
                          {t("common.cancel")}
                        </Link>
                        <button
                          className="btn btn-primary rounded-45 mw-120"
                          type="submit">
                          {createMode
                            ? t("pollDetails.btnSave")
                            : t("common.update")}
                        </button>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="poll-title" className="form-label">
                        {t("pollDetails.pollTitle")}
                      </label>
                      <Field
                        type="text"
                        name="title"
                        className="form-control"
                        id="poll-title"
                        placeholder={t(
                          "pollDetails.placeholderPollTitle"
                        )}></Field>
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-danger"
                      />
                      {questionmessage && (
                        <div className="form-group">
                          <div
                            className={
                              successful
                                ? "alert alert-success"
                                : "alert alert-danger p-2 rounded-0 border-0"
                            }
                            role="alert">
                            {questionmessage}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label fw-medium font-20">
                        {t("pollDetails.attachedTitle")}
                      </label>
                      <div className="d-flex flex-wrap align-items-center gap-2">
                        {pollMeetings.length === 0 && (
                          <div className="d-flex flex-wrap align-items-center gap-2">
                            {t("pollDetails.NoDataAvailable")}
                          </div>
                        )}
                        {!!pollMeetings &&
                          pollMeetings.length > 0 &&
                          pollMeetings.map((meetingData: any) => {
                            return (
                              <div
                                className="badge rounded-pill badge-pill py-2 fw-medium mw-120"
                                key={"meeting_" + meetingData.meetingId}>
                                {meetingData.meetingDetails.title}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>

              <DropArea />
              <SelectedQuestionList meetingsLength={pollMeetings.length} />

            </div>
          </div>
          <div className="col-xl-5 mb-4">
            <QuestionContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PollDetails;
