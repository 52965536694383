import React, { FC } from 'react';
interface DeleteProps {
    show: any;
    question: string;
    btncancel: string;
    btnyes: string;
    clickOk: any;
    clickCancel: any;
}

const DialogBox: FC<DeleteProps> = ({ show, question, btncancel, btnyes, clickOk, clickCancel }) => {

    return show &&
        <div className='delete-confirm'>
            <div className='delete-confirm-inner'>
                <p className='delete-confirm-title mb-4'>{question}</p>
                <div className='hstack justify-content-center gap-3'>
                    <button className='btn pt-2 px-3 btn-secondary text-white mw-120' type='button' onClick={() => { clickCancel() }}>{btncancel}</button>
                    <button className='btn pt-2 px-3 btn-danger text-white mw-120' type='button' onClick={() => { clickOk() }}>{btnyes}</button>
                </div>
            </div>
        </div>
}

export default DialogBox;