import React, { FC } from 'react'
interface QuestionOptionsProps {
    questionOptions: any
}

const QuestionOptions: FC<QuestionOptionsProps> = ({ questionOptions }) => {
    return (
        <ul className="list-unstyled question-option">
            {questionOptions?.length > 0 &&
                questionOptions?.map((optionItem: any) => (
                    <li
                        key={optionItem.id}
                        className={optionItem.isAnswer ? 'correct-option' : ''}
                    >
                        {optionItem.optionName}
                    </li>
                ))
            }
        </ul>
    )
}

export default QuestionOptions