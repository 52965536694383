import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL

export const questionCreate = (title: string, options: any, score: any) => {
    return axios.post(API_URL + "questions/create", {
        title,
        options,
        score
    }, { headers: authHeader() });

};

export const questionData = async (id: any, pageSize: any, perPage: any, searchVal: any) => {
    return axios.get(API_URL + "questions" + "/user/" + id + "/?page=" + pageSize + "&limit=" + perPage + "&searchVal=" + searchVal)
        .then((response) => {
            return response.data;
        });
};
export const questionGetByID = async (id: any) => {
    return axios.get(API_URL + "questions/" + id)
        .then((response) => {
            return response.data;
        });
    ;
};

export const questionupdateByID = async (id: any, title: string, options: any, score: any) => {
    return axios.put(API_URL + "questions/" + id, {
        title,
        options,
        score
    }, { headers: authHeader() });
};

export const deleteQuestion = async (id: any) => {
    return axios.delete(API_URL + "questions/" + id,
        { headers: authHeader() })
        .then((response) => {
            return response;
        });
};

