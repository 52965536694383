import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { logoutHandler } from '../pages/store/slices/authSlice';
import { useSelector } from "react-redux";
import { RootState, store } from '../pages/store/store';
import { currentMeetingHandler } from '../pages/store/slices/meetingSlice';
import { persistStore } from 'redux-persist';
import { Persistor } from 'redux-persist/es/types';

const AuthVerify = () => {
    const { user, isUser }: any = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    let navigate = useNavigate();
    const logOut = (message: string = '') => {
        let toastMsg = (message !== '') ? message : t('toastMessage.authVarification');
        toast.error(toastMsg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
        });
         // Clear the persisted state
      const persistor: Persistor = persistStore(store);
      persistor.purge();
        setTimeout(() => {
            dispatch(currentMeetingHandler(null))
            dispatch(logoutHandler());
            navigate("/signin");
        }, 1500);
    };

    //For GET requests
    axios.interceptors.request.use(
        (req) => {
            // Add configurations here
            return req;
        },
        (err) => {
            if (err.response.status === 401) {
                logOut();
            } else {
                return Promise.reject(err);
            }
        }
    );

    // For POST requests
    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        (err) => {
            if (err.response.status === 401) {
                let message = '';
                if(err.response?.data?.message) {
                    message = err.response.data.message;
                }
                logOut(message)
            } else {
                return Promise.reject(err);
            }
        }
    );

    const parseJwt = (token: any) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    useEffect(() => {
        if (isUser) {
            const decodedJwt = parseJwt(user?.token);
            if (decodedJwt.exp * 1000 < Date.now()) {
                logOut();
            }
        }
    }, []);
    return (<></>)
}
export default AuthVerify;