import React, { FC, useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IQuestion } from "../../../types/question.type";
import DialogBox from "../DeleteBox";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { QuestionCreateHandler, QuestionDataHandler, QuestionGetByIdHandler, QuestionUpdateById, DeleteQuestionHandler } from "../../store/slices/questionSlice";
import * as ACTION from "../../store/actions/index";
import { Accordion, Modal } from "react-bootstrap";
import { selectedQuestionHandler } from "../../store/slices/pollSlice";
import { useDrag } from "react-dnd";
import QuestionOptions from "./QuestionOptions";
import PaginationBox from "../Pagination";
import { DragItemTypes } from "../../../types/dragItemTypes";

const QuestionContainer = () => {
    const dispatch = useDispatch()
    const { user } = useSelector((state: RootState) => state.auth)
    const { questionList, totalQuestionsPage, totalQuestions } = useSelector((state: RootState) => state.question)
    const { selectedQuestion } = useSelector((state: RootState) => state.poll)
    const userId = user?.user?.id

    const { t } = useTranslation("common");

    const defaultScore: number = 5;
    const [successful, setSuccessful] = useState<boolean>(false);
    const [message1, setMessage1] = useState<string>("");
    const newOption = { optionName: "", isAnswer: false };
    const defaultOptions = [{ optionName: "", isAnswer: true }, { optionName: "", isAnswer: false }];
    const [questionOptions, setQuestionOptions] = useState(defaultOptions);
    const [nextId, setNextId] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [questionDeleteID, setquestionDeleteID] = useState();
    const newQuestionData = { questionTitle: "", options: [], score: defaultScore };
    const [questionData, setQuestionData] = useState(newQuestionData);
    const [questionScore, setQuestionScore] = useState(defaultScore);
    const [questionEditId, setQuestionEditId] = useState(0);
    const [addQuestionModal, setAddQuestionModal] = useState<boolean>(false)
    const [pageSize, setpageSize] = useState(1); // Page number
    const [perPage, setperPage] = useState(10); // per page Limit
    const [searchVal, setSearchVal] = useState("");
    const [endItem, setendItem] = useState(0);
    const [startItem, setStartItem] = useState(1);
    const pageSizeOptions = [5, 10, 15, 20]

    const questioninitialValues: IQuestion = {
        questionTitle: questionData.questionTitle,
        options: questionOptions,
        score: questionScore
    };

    const getQuestionList = async () => {
        const data = { userId, pageSize, perPage, searchVal }
        return await dispatch(QuestionDataHandler(data))
    }

    useEffect(() => {
        getQuestionList()
    }, [pageSize, perPage, searchVal, questionEditId]);

    const onFilterChange = (e: any) => {
        setSearchVal(e.target.value);
    };

    const deleteConfirmHandler = (id: any) => {
        setShowDelete(true);
        setquestionDeleteID(id);
    };

    const deleteQuestionHandler = async (id: any) => {
        await dispatch(DeleteQuestionHandler(id)).then(async (response: any) => {
            if (response?.payload?.status === 200) {
                getQuestionList()
                let updateSelectedQuestions = selectedQuestion.filter(item => item.id !== id);
                dispatch(selectedQuestionHandler(updateSelectedQuestions))
                toast.success(t("toastMessage.questionDelete"), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
            }
        })
    };

    const questionCreateModal = (e: any) => {
        getQuestionList()
        setQuestionData(newQuestionData);
        setQuestionOptions(defaultOptions);
        setQuestionEditId(0);
        setAddQuestionModal(true)
    };

    const questionEdit = async (id: any) => {
        setQuestionEditId(id);
        await dispatch(QuestionGetByIdHandler(id)).then((response: any) => {
            setQuestionData({
                ...questionData,
                questionTitle: response?.payload?.title,
                score: response?.payload?.score,
            });
            setQuestionOptions(response?.payload?.questionOptions);
            setAddQuestionModal(true)
        })
    };

    const onChangeOptionData = (e: any) => {
        setQuestionData({ ...questionData, [e.target.name]: e.target.value });
    };
    const handleFormChange = (index: any, event: any) => {
        let data = [...questionOptions];
        data[index]["optionName"] = event.target.value;
        setQuestionOptions(data);
    };

    function handleOptionRemove(option: any) {
        const remaningItems = [...questionOptions].filter(
            (_option) => _option !== option
        );
        setQuestionOptions(remaningItems);
    }
    function handleOptionAdd(e: any) {
        setQuestionOptions([...questionOptions, newOption]);

        setNextId(nextId + 1);
        e.preventDefault();

    }

    const handleQuestionCreate = (PageValue: IQuestion) => {
        const { questionTitle, options, score } = PageValue;
        const newQuestionTitle = questionTitle.trim();
        const fiterQuestionOptions = questionOptions.map((item) => {
            return {
                ...item,
                optionName: item.optionName.trim(),
            };
        });


        if (fiterQuestionOptions.length > 1) {
            if (questionEditId === 0) {
                const data = { newQuestionTitle, fiterQuestionOptions, score }
                dispatch(QuestionCreateHandler(data)).then((response: any) => {
                    if (response.type == `${ACTION.CREATEQUESTION}/${ACTION.FULFILLED}`) {
                        toast.success(t("toastMessage.questionCreate"), {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                        });
                        setAddQuestionModal(false)
                        getQuestionList()
                        setQuestionData(newQuestionData);
                        setQuestionOptions([...fiterQuestionOptions, newOption]);
                        setQuestionEditId(0);
                    }
                })
            } else {
                const data = {
                    questionEditId,
                    newQuestionTitle,
                    fiterQuestionOptions,
                    score
                }
                dispatch(QuestionUpdateById(data)).then((response: any) => {
                    if (response.type == `${ACTION.QUETIONUPDATEBYID}/${ACTION.FULFILLED}`) {
                        let data = response.payload.data
                        toast.success(t("toastMessage.questionUpdate"), {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                        });
                        const updateSelectedQuestions = selectedQuestion.map(item => (item.id === data.id ? data : item));
                        dispatch(selectedQuestionHandler(updateSelectedQuestions))
                        setAddQuestionModal(false);
                        getQuestionList()
                        setQuestionData(newQuestionData);
                        setQuestionOptions(defaultOptions);
                        setQuestionEditId(0);
                    }
                    else if (response.type == `${ACTION.QUETIONUPDATEBYID}/${ACTION.REJECTED}`) {
                        const resMessage =
                            (response.payload &&
                                response.payload.data &&
                                response.payload.data.message) ||
                            response.message ||
                            response.toString();
                        setMessage1(resMessage);
                    }
                });
            }
        } else {
            setMessage1(t("question.mustTwoOption"));
            setTimeout(() => {
                setMessage1("");
            }, 2000);
        }
    };

    const validationSchemaQuestion = Yup.object().shape({
        questionTitle: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .test(
                "len",
                t('validation.minlengthTitle'),
                (val: any) =>
                    val &&
                    val.toString().length >= 3
            )
            .test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ),

        options: Yup.array().of(
            Yup.object().shape({
                optionName: Yup.string()
                    .required(t('validation.requiredField'))
                    .transform((value, originalValue) => {
                        if (typeof originalValue === 'string') {
                            return originalValue.trim();
                        }
                        return value;
                    })
                    .strict(true)
                    .test(
                        "len",
                        t('validation.minlengthTitlequestionOption'),
                        (val: any) =>
                            val &&
                            val.toString().length >= 1
                    )
                    .test(
                        "no-spaces",
                        t('trimMessage.optionName'),
                        (val: any) =>
                            val &&
                            val.trim() !== ''
                    ),
            })),
        score: Yup.number()
            .required(t("validation.requiredField"))
            .test(
                "is-positive-number",
                "score must be a valid number",
                (value: any) => !isNaN(value) && value >= 0,
            )
            .test(
                "is-positive-number",
                "Score must be less than 50",
                (value: any) => !isNaN(value) && value <= 50,
            )
    });



    interface DropResult {
        name: string
    }
    interface QuestionListProps {
        questionItem: any
        index: number
    }
    const QuestionList: FC<QuestionListProps> = ({ questionItem, index }) => {
        const [{ isDragging }, drag] = useDrag(() => ({
            type: DragItemTypes.QUESTION,
            end: (item: any, monitor) => {
                const dropResult = monitor.getDropResult<DropResult>()
                if (item && dropResult) {
                    let findIndex = selectedQuestion?.findIndex(
                        (x) => x.id === questionList[index]["id"]
                    );
                    if (findIndex === -1) {
                        const cloneQuestionIndex = [
                            ...selectedQuestion,
                            questionList[index],
                        ];
                        dispatch(selectedQuestionHandler(cloneQuestionIndex.sort((a, b) =>
                            a.createdAt < b.createdAt ? 1 : -1
                        )))

                    }
                }
            },
            collect: (monitor: any) => ({
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId(),
            }),
        }))



        const opacity = isDragging ? 0.4 : 1
        return (
            <Accordion.Item
                eventKey={`${index}`}
                ref={drag}
                style={{ opacity }}
                data-testid={DragItemTypes.QUESTION}
                className="question-item"
                key={questionItem.id}
            >
                <Accordion.Header>
                    {questionItem.title}
                </Accordion.Header>
                <Accordion.Body>
                    <QuestionOptions questionOptions={questionItem.questionOptions} />
                    <div className="d-flex justify-content-end gap-3">
                        <button
                            className="btn bg-info text-white size-38"
                            onClick={() => questionEdit(questionItem.id)}>
                            <i className="fa-solid fa-edit"></i>
                        </button>
                        <button
                            className="btn bg-danger text-white size-38"
                            onClick={() =>
                                deleteConfirmHandler(questionItem.id)
                            }>
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        )
    }

    const updateIsAnswer = (index: number) => {
        setQuestionOptions(
            questionOptions.map((option, i) => {
                if (i === index) {
                    return { ...option, isAnswer: true };
                } else {
                    return { ...option, isAnswer: false };
                }
            })
        );
    };

    const handlePrevPage = (e: number) => {
        setpageSize(e);
        setStartItem(startItem - perPage);
        totalrecordHandle();
    };

    const handleNextPage = (e: number) => {
        setpageSize(e);
        setStartItem(endItem + 1);
        totalrecordHandle();
    };

    const handlePageClick = (e: any) => {
        setpageSize(e);
        setStartItem(e * perPage + 1 - perPage);
        totalrecordHandle();
    };

    const totalrecordHandle = () => {
        var totalRecordTemp = startItem - 1 + perPage;
        if (totalRecordTemp > totalQuestions) {
            totalRecordTemp = totalQuestions;
        }
        setendItem(totalRecordTemp);
    };

    useEffect(() => {
        totalrecordHandle()
    }, [startItem, endItem, totalQuestions, questionList]);

    return (
        <>
            <div className="card question-block border-0 h-100">
                <div className="card-body">
                    <div className="d-flex flex-wrap justify-content-between ">
                        <div className="font-20 fw-semibold mb-4">
                            {t("question.title")}
                        </div>
                        <div className="mb-4">
                            <button
                                type="button"
                                className="btn btn-primary rounded-45 mw-120"
                                onClick={questionCreateModal}>
                                {t("question.btnAddQuestion")}
                            </button>
                        </div>
                    </div>
                    <div className="search-filter w-100">
                        <input
                            type="text"
                            value={searchVal}
                            onChange={(e) => onFilterChange(e)}
                            className="form-control border-0 ps-5"
                            id="question-list-filter"
                            placeholder={t("searchplaceholder.search")}
                        />
                        <i className="fa-solid fa-magnifying-glass icon"></i>
                    </div>

                    <Accordion defaultActiveKey={['0']} alwaysOpen className="question-list py-3">
                        {!!questionList &&
                            questionList.length > 0 &&
                            questionList.map((questionItem, index) =>
                                <QuestionList questionItem={questionItem} index={index} key={`question_list${questionItem.id}`} />
                            )}
                    </Accordion>
                    {totalQuestions > perPage &&
                        <PaginationBox
                            filteredDataLength={perPage}
                            startItem={startItem}
                            totalPage={totalQuestionsPage}
                            endItem={endItem}
                            currentPage={pageSize}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                            handlePageClick={handlePageClick}
                            rowsPerPageOptions={pageSizeOptions}
                            rowsPerPage={perPage}
                            totalRecord={totalQuestions}
                        />
                    }
                </div>
            </div>

            <Modal show={addQuestionModal} centered onHide={() => { setAddQuestionModal(false) }}>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='fw-semibold'>
                        {questionEditId === 0
                            ? t("question.btnCreate")
                            : t("common.update")}{" "}
                        {t("question.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={questioninitialValues}
                        validationSchema={validationSchemaQuestion}
                        enableReinitialize={true}
                        onSubmit={handleQuestionCreate}>

                        {({ validateField }) => (
                            <Form>
                                {message1 && (
                                    <div className="form-group">
                                        <div
                                            className={
                                                successful
                                                    ? "alert alert-success"
                                                    : "alert alert-danger p-2 rounded-0 border-0"
                                            }
                                            role="alert">
                                            {message1}
                                        </div>
                                    </div>
                                )}
                                <div className="mb-3">
                                    <label htmlFor="question-title" className="form-label">
                                        {t("question.lblTitle")}
                                    </label>
                                    <Field
                                        type="text"
                                        onChange={onChangeOptionData}
                                        name="questionTitle"
                                        className="form-control"
                                        id="question-title"
                                        placeholder={t("question.placeholderTitle")}></Field>
                                    <ErrorMessage
                                        name="questionTitle"
                                        component="div"
                                        className="text-danger"
                                    />
                                </div>
                                {!!questionOptions &&
                                    questionOptions?.length > 0 &&
                                    questionOptions?.map((option, index) => (
                                        <div key={`questionOptions${index}`} className="mb-3">
                                            <div
                                                className="d-flex align-items-center gap-3 "
                                            >
                                                <div className="flex-fill hstack gap-3">
                                                    <Field
                                                        type="radio"
                                                        name="selectedOption"
                                                        className="selectedOptions-radio"
                                                        value={option.isAnswer}
                                                        checked={option.isAnswer}
                                                        onChange={() => updateIsAnswer(index)}
                                                        required
                                                    />
                                                    <Field
                                                        type="text" name="option" placeholder={`option ${index + 1}`} onChange={(e: any) => { handleFormChange(index, e); validateField(`options[${index}].optionName`) }} className="form-control"
                                                        value={option.optionName}
                                                    >
                                                    </Field>
                                                </div>

                                                {questionOptions.length > 2 &&
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() => handleOptionRemove(option)}
                                                    >
                                                        <i className="fa-solid fa-minus text-danger"></i>
                                                    </button>
                                                }
                                            </div>
                                            <ErrorMessage
                                                name={`options[${index}].optionName`} component="div"
                                                className="text-danger ms-4 ps-3"
                                            />
                                        </div>
                                    ))}

                                <div className="d-flex align-items-center gap-3 mb-3">
                                    <div className="flex-fill"></div>
                                    <div className="align-self-end">
                                        <button
                                            type="button"
                                            onClick={handleOptionAdd}
                                            className="btn btn-sm btn-primary-light">
                                            <i className="fa-solid fa-plus text-primary"></i>
                                        </button>
                                    </div>
                                </div>

                                {/* score field is hidden currently, we will reconsider it if necessary  */}
                                <div className="mb-3 d-none">
                                    <label htmlFor="question-score" className="form-label">
                                        Enter score
                                    </label>
                                    <Field
                                        type="number"
                                        name="score"
                                        className="form-control"
                                        id="question-score"
                                        onChange={(e: any) => setQuestionScore(Math.max(0, e.target.value))}
                                        min={1}
                                        max={5}
                                    ></Field>
                                    <ErrorMessage
                                        name="score"
                                        component="div"
                                        className="text-danger"
                                    />
                                </div>
                                <div className="modal-footer d-flex align-items-center justify-content-center border-top-0">
                                    <button
                                        type="submit"
                                        className="btn btn-primary rounded-45 mw-120">
                                        {questionEditId === 0
                                            ? t("question.btnCreate")
                                            : t("common.update")}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal >

            <DialogBox
                show={showDelete}
                clickOk={() => {
                    deleteQuestionHandler(questionDeleteID);
                    setShowDelete(false);
                }}
                clickCancel={() => {
                    setShowDelete(false);
                }}
                btncancel={t("common.cancel")}
                btnyes={t("common.delete")}
                question={t("deleteBox.dltquestion")}
            />
        </>
    )
}


export default QuestionContainer
