import React, { useEffect, useState, MouseEvent, useRef, FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IMeetingSendQuestion, IMeetingSendQuestionReply } from '../../types/meeting.type';
import { Model, Serializer } from "survey-core";
import { Survey } from 'survey-react-ui';
import socket from '../../common/constants';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { addPollForMeeting, addPollsQuestionsAnswerByLogInUser, deleteMeetingPoll, getAllPollsQuestionsAnswerByMeetingId, getPollsForMeeting, getPollsQuestionsAnswerByMeetingId, pollData, updateMeetingPollStatus } from '../../services/poll.service';
import PaginationBox from '../components/Pagination';
import { useTranslation } from "react-i18next";
import ShareMeetingDetails from '../components/ShareMeetingDetails';
import DialogBox from '../components/DeleteBox';
import { toast } from 'react-toastify';
import { getWaitingList, updateWaitingUserStatus } from '../../services/waitingList.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../store/store';
import { activeQuizIdAttendeeHandler, activeQuizIdHandler, activeTabHandler, currentMeetingHandler, interestedListHandler, waitingListHandler } from '../store/slices/meetingSlice';
import { addMeetingQuestionHandler, addMeetingQuestionReplyHandler, deleteMeetingQuestionHandler, deleteMeetingQuestionReplyHandler, editMeetingQuestionHandler, getAllUpvoteMeetingQuestionListrByMeetingIdHandler, getJoinedMeetingUsersByMeetingIdHandler, getMeetingIntrestedUsersHandler, getMeetingQuestionsHandler, meetingGetByCodeHandler, saveUpvoteForMeetingQuestionHandler, saveUpvoteForMeetingQuestionReplyHandler, updateMeetingQuestionHandler } from '../store/slices/joinMeetingSlice';
import { feedbackFormHandler } from '../store/slices/settingsSlice';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { addQuizForMeeting, deleteMeetingQuiz, getAllSubmittedQuizzes, getQuizzesForMeeting, getSubmittedQuizzesByAttendee, quizData, updateMeetingQuizStatus } from '../../services/quiz.service';
import WaitingListModal from '../components/Modals/WaitingListModal';
import { waitingListModalHandler } from '../store/slices/modalSlice';
import InterestedListModal from '../components/Modals/InterestedListModal';
import * as SurveyTheme from "survey-core/themes";
import { quizAnswerByAttendeeHandler } from '../store/slices/quizSlice';
import ShowQuizResult from '../components/Results/ShowQuizResult';
import { ChildrenProps, QuizLocalStorage } from '../../types/commonTypes';
import { selectedQuestionHandler } from '../store/slices/pollSlice';

const JoinMeeting = () => {
   //single hooks starts here
   const dispatch = useDispatch()
   const { t } = useTranslation('common');
   let { code } = useParams();
   let navigate = useNavigate();
   //single hooks ends here

   //custom variables starts here
   const all = { all: 'all' }
   const anonymous = { anonymous: 'anonymous' }
   //custom variables ends here


   //useSelectors starts here
   const { user } = useSelector((state: RootState) => state.auth)
   const { currentMeeting, waitingList, activeQuizId, activeQuizIdAttendee } = useSelector((state: RootState) => state.meeting)
   const { isFeedBackForm } = useSelector((state: RootState) => state.settings)
   const userId = user?.user?.id
   const currentMeetingId = currentMeeting?.id
   const currentMeetingHostId = currentMeeting?.hostId
   const currentMeetingCode = currentMeeting?.code;
   const isHost = !!userId && userId === currentMeetingHostId
   //useSelectors ends here


   //useRefs starts here
   const messagesEndRef = useRef<null | HTMLDivElement>(null);
   const questionsListRef = useRef<HTMLDivElement>(null)
   //useRefs ends here


   //useStates starts here
   const [showDelete, setShowDelete] = useState(false);
   const [showReplyDelete, setShowReplyDelete] = useState(false);
   const [showDeletePoll, setShowDeletePoll] = useState(false);
   const [showDeleteQuiz, setShowDeleteQuiz] = useState(false);
   const [meetingQuestionDeleteId, setMeetingQuestionDeleteId] = useState(0);
   const [meetingQuestionReplyDeleteId, setMeetingQuestionReplyDeleteId] = useState(0);
   const [meetingPollDeleteId, setMeetingPollDeleteId] = useState(0);
   const [meetingQuizDeleteId, setMeetingQuizDeleteId] = useState(0);
   const [meetingAllPollsAnswerList, setMeetingAllPollsAnswerList] = useState<any[]>([]);
   const [allUpvoteMeetingQuestionList, setAllUpvoteMeetingQuestionList] = useState<any[]>([]);
   const [allJoinedUsers, setAllJoinedUsers] = useState<any[]>([]);
   const [pollTab, setPollTab] = useState(false)
   const [questionTab, setQuestionTab] = useState(false)
   const [quizTab, setQuizTab] = useState(false)
   const [meetingQuestion, setEditMeetingQuestions] = useState<any>("")
   const [editMeetingCondition, setEditMeetingCondition] = useState<boolean>(false)
   const [editMeetingQuestionId, setEditMeetingQuestionsId] = useState<any>("")
   const [GroupedQuestions, setGroupedQuestions] = useState<any[]>([]);
   const [GroupedAnswers, setGroupedAnswers] = useState<any[]>([]);
   const [isFullscreen, setIsFullscreen] = useState(false);
   const [selectedOptions, setSelectedOptions] = useState<any>([]);
   const questionBoxRef = useRef<HTMLTextAreaElement>()
   const [addPollModal, setAddPollModal] = useState<boolean>(false);
   const [addQuizModal, setAddQuizModal] = useState<boolean>(false);
   const [replyModal, setReplyModal] = useState<boolean>(false);
   const [allQuizAnswer, setAllQuizAnswer] = useState<any[]>([])
   const [allSubmittedQuizAnswer, setAllSubmittedQuizAnswer] = useState<any[]>([])
   const [selectedUser, setSelectedUser] = useState(all);
   const [shortBy, setShortBy] = useState("all");
   const [pollList, setPollList] = useState<any[]>([]);
   const [quizList, setQuizList] = useState<any[]>([]);
   const [meetingPollsList, setMeetingPollsList] = useState<any[]>([]);
   const [meetingQuizList, setMeetingQuizzesList] = useState<any[]>([]);
   const [allPollQuestions, setAllPollQuestions] = useState<any[]>([]);
   const [submitPoll, setSubmitPoll] = useState(false);
   const [submitPollId, setSubmitPollId] = useState();
   const [newSelectedAnswer, setNewSelectedAnswers] = useState<any>([]);
   const [activeQuizData, setActiveQuizData] = useState<any>([]);
   const [isShowResult, setIsShowResult] = useState(false);
   const [isShowQuizResult, setIsShowQuizResult] = useState(false);
   const [totalPage, setTotalPage] = useState();
   const [pageSize, setpageSize] = useState(1);
   const [perPage, setperPage] = useState(5);
   const pageLimitOptions = [5, 10, 15, 20]
   const [totalRecord, setTotalRecord] = useState<any>();
   const [endItem, setendItem] = useState(0);
   const [startItem, setStartItem] = useState(1);
   const [filterValue, setFilterValue] = useState('');
   const [meetingQuestions, setMeetingQuestions] = useState<any[]>([])
   const [sortColumn, setsortColumn] = useState<any>({ order: "desc", prop: "createdAt" });
   const [addPollList, setAddPollList] = useState<any[]>([])
   const [addQuizList, setAddQuizList] = useState<any[]>([])
   const [meetingQuestionId, setMeetingQuestionId] = useState(0);
   const [showAnswerQuestion, setShowAnswerQuestion] = useState<any>(false)
   const [showArchiveQuestion, setShowArchiveQuestion] = useState<any>(false)
   const [showUnanswerQuestion, setShowUnanswerQuestion] = useState<any>(false)
   const [activePolls, setActivePolls] = useState(0)
   const [activeQuiz, setActiveQuiz] = useState(0)
   const [scrollBottom, setScrollBottom] = useState<any>(false)
   //useStates ends here


   //formik starts here
   const initialValuesSendQuestionReply: IMeetingSendQuestionReply = {
      questionReply: ""
   }

   const initialValuesSendQuestion: IMeetingSendQuestion = {
      question: meetingQuestion.trim()
   }

   const validationSchemaSendQuestionReply = Yup.object().shape({
      questionReply: Yup.string()
         .required(t('validation.requiredField'))
         .transform((value, originalValue) => {
            if (typeof originalValue === 'string') {
               return originalValue.trim();
            }
            return value;
         })
   });
   //formik ends here


   //functions starts here
   const toggleFullscreen = () => {
      if (isFullscreen) {
         exitFullscreen();
      } else {
         enterFullscreen();
      }
   };

   const enterFullscreen = () => {
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
         elem.requestFullscreen();
      } else if (elem.requestFullscreen) {
         elem.requestFullscreen();
      }
      setIsFullscreen(true);
   };

   const exitFullscreen = () => {
      const fullscreenElement = document.fullscreenElement ||
         (document as any).webkitFullscreenElement ||
         (document as any).mozFullScreenElement ||
         (document as any).msFullscreenElement;

      if (fullscreenElement) {
         if (document.exitFullscreen) {
            document.exitFullscreen();
         } else if ((document as any).webkitExitFullscreen) {
            (document as any).webkitExitFullscreen();
         } else if ((document as any).mozCancelFullScreen) {
            (document as any).mozCancelFullScreen();
         } else if ((document as any).msExitFullscreen) {
            (document as any).msExitFullscreen();
         }
         setIsFullscreen(false);
      }
   };

   const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
   };

   const scrollBottomCheck = () => {
      if (!!questionsListRef.current) {
         let element = questionsListRef.current
         const isAtBottom = element.scrollTop + element.clientHeight === element.scrollHeight;
         if (!isAtBottom) {
            setScrollBottom(true)
         } else (
            setScrollBottom(false)
         )
      }
   }

   const handlePublishPoll = (pollId: any) => {
      const selectedPollAnswers = selectedOptions.filter((answer: any) => answer.pollId === pollId);
      const selectedPollQuestion = allPollQuestions.filter((question: any) => question.pollId === pollId);

      if (selectedPollQuestion.length === selectedPollAnswers.length) {
         onChangeGuestAnswer(selectedPollAnswers, pollId);
      }
      else {
         setSubmitPollId(pollId);
         setSubmitPoll(true);
      }
   };

   const handleSubmitPoll = (pollId: any) => {
      const selectedPollAnswers = selectedOptions.filter((answer: any) => answer.pollId === pollId);
      addPollsQuestionsAnswerByLogInUser(currentMeetingId, selectedPollAnswers, pollId).then(
         (response) => {
            if (response.status === 201) {
               socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
               socket.on('connect', () => {
                  socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
               })
            }
            toast.success(t('toastMessage.pollSubmitted'), {
               position: toast.POSITION.TOP_RIGHT,
               autoClose: 1000,
            });
         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
         }
      )
   }

   const handleCheckboxChange = (e: any, selectedAnswer: any) => {
      const selectedArray = selectedOptions.length > 0 ? selectedOptions : newSelectedAnswer
      const existingIndex = selectedArray?.some((answer: any, i: any) => (answer.pollId === selectedAnswer.pollId && answer.questionId === selectedAnswer.questionId && answer.questionOptionId === selectedAnswer.questionOptionId));

      let updateSelectedAnswer;
      if (existingIndex) {
         e.target.checked = false
         updateSelectedAnswer = selectedArray.filter((item: any) =>
            !(item.pollId === selectedAnswer.pollId &&
               item.questionId === selectedAnswer.questionId &&
               item.questionOptionId === selectedAnswer.questionOptionId));

      } else {
         updateSelectedAnswer = [...selectedArray.filter((item: any) =>
            !(item.pollId === selectedAnswer.pollId && item.questionId === selectedAnswer.questionId)
         ), selectedAnswer];

      }

      setNewSelectedAnswers(updateSelectedAnswer);
      setSelectedOptions(updateSelectedAnswer)
   };

   const onChangeGuestAnswer = async (newSelectedAnswer: any, pollId: any) => {
      addPollsQuestionsAnswerByLogInUser(currentMeetingId, newSelectedAnswer, pollId).then(
         (response) => {
            if (response.status === 201) {
               socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
               socket.on('connect', () => {
                  socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
               })
               toast.success(t('toastMessage.pollSubmitted'), {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
               });
            }
         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
         }
      )
   }

   const getPollData = async (userId: any, pageLimit: number, perPage: number, searchVal: string, sortColumn: any) => {
      const pollDataList = await pollData(userId, pageLimit, perPage, searchVal, sortColumn.prop, sortColumn.order)
      setPollList(pollDataList.data);
      setTotalPage(pollDataList.totalPages)
      setpageSize(pollDataList.currentPage)
      setTotalRecord(pollDataList.totalItems)
   }

   const getQuizData = async (userId: any, pageLimit: number, perPage: number, searchVal: string, sortColumn: any) => {
      const quizDataList = await quizData(userId, pageLimit, perPage, searchVal, sortColumn.prop, sortColumn.order)
      setQuizList(quizDataList.data);
      setTotalPage(quizDataList.totalPages)
      setpageSize(quizDataList.currentPage)
      setTotalRecord(quizDataList.totalItems)
   }

   const getPollList = () => {
      setFilterValue("")
      getPollData(userId, pageSize, perPage, filterValue, sortColumn);
      setAddPollModal(true)
   }

   const getQuizList = () => {
      setFilterValue("")
      getQuizData(userId, pageSize, perPage, filterValue, sortColumn);
      setAddQuizModal(true)
   }

   const onFilterChange = (e: any) => {
      setFilterValue(e.target.value)
   }

   const refreshWaitingList = () => {
      if (!!currentMeetingId) {
         getWaitingList(currentMeetingId)
            .then((waitingList: any) => {
               dispatch(waitingListHandler(waitingList.data))
            })
            .catch((error) => {
               console.error("Error fetching waiting list:", error);
            });
      }
   }

   const getMeetingDetailByMeetingCode = async (code: string) => {
      const data = {
         code, userId
      }
      dispatch(meetingGetByCodeHandler(data))
         .then(
            (response: any) => {
               if (response?.payload.status === 200) {
                  dispatch(currentMeetingHandler(response?.payload?.data))
                  refreshWaitingList()
                  setTimeout(() => {
                     socket.emit('join-meeting-send', { meetingId: response.payload.data.id, user: user.user, hostId: response.payload.data.hostId })
                  }, 0);
                  socket.on('connect', () => {
                     socket.emit('join-meeting-send', { meetingId: response.payload.data.id, user: user.user, hostId: response.payload.data.hostId })
                  })
               } else {
                  if (response.payload.status === 404) {
                     toast.error(response.payload.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                     });
                  }
               }
            },
            (error: any) => {
               const resMessage =
                  (error.response.payload &&
                     error.response.payload.data &&
                     error.response.payload.data.message) ||
                  error.message ||
                  error.toString();
               toast.error(resMessage, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
               });
               navigate('/meeting')
            }
         )
   }

   const editQuestion = (meetingQuestion: any, meetingQuestionId: any) => {
      setEditMeetingQuestions(meetingQuestion);
      setEditMeetingQuestionsId(meetingQuestionId)
      setEditMeetingCondition(true);
      questionBoxRef.current?.focus()
   }

   const resetQuestionBox = () => {
      setEditMeetingQuestions('');
      setEditMeetingCondition(false);
   }

   const handleSendQuestion = (formValue: IMeetingSendQuestion, onSubmitProps: any) => {
      const { question } = formValue;
      const queData = question === '' ? meetingQuestion.trim() : question
      if (queData.length !== 0) {

         if (editMeetingCondition == true) {
            const hasUpvotes = getUpvotedCount(editMeetingQuestionId);
            const hasReplies: any = meetingQuestions.find((item: any) => item.id === editMeetingQuestionId);
            const votesLength = hasUpvotes > 0
            const replyLength = hasReplies?.meetingQuestionsReplyDetails.length > 0
            if (replyLength || votesLength) {
               toast.error(t('toastMessage.editQuestionValidation'), {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
               });
               resetQuestionBox()

            } else {
               const data = {
                  queData, editMeetingQuestionId
               }
               dispatch(editMeetingQuestionHandler(data))
                  .then(
                     (response: any) => {
                        if (response?.payload.status === 201) {
                           onSubmitProps.resetForm(initialValuesSendQuestion);
                           socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                           socket.on('connect', () => {
                              socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                           })
                        }
                        setEditMeetingQuestions('');
                        setEditMeetingCondition(false);
                     },
                     (error: any) => {
                        const resMessage =
                           (error.response.payload &&
                              error.response.payload.data &&
                              error.response.payload.data.message) ||
                           error.message ||
                           error.toString();
                        console.log("resMessage ==>", resMessage)
                     }
                  )
            }
         } else {
            const data = {
               queData, currentMeetingId
            }
            dispatch(addMeetingQuestionHandler(data)).then(
               (response: any) => {
                  if (response?.payload?.status === 201) {
                     onSubmitProps.resetForm(initialValuesSendQuestion);
                     socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                     socket.on('connect', () => {
                        socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                     })
                  }
                  setEditMeetingQuestions("");
               },
               (error: any) => {
                  const resMessage =
                     (error.response?.payload &&
                        error.response.payload.data &&
                        error.response.payload.data.message) ||
                     error.message ||
                     error.toString();
                  console.log("resMessage ==>", resMessage)
               }
            )
         }
      }
      scrollBottomCheck()
      scrollToBottom()
   };

   const handleSendQuestionReply = (formValue: IMeetingSendQuestionReply, onSubmitProps: any) => {
      const { questionReply } = formValue;
      const newquestionReply = questionReply.trim()
      const data = {
         newquestionReply, meetingQuestionId
      }
      dispatch(addMeetingQuestionReplyHandler(data)).then(
         (response: any) => {
            if (response?.payload?.status === 201) {
               setReplyModal(false)
               onSubmitProps.resetForm(initialValuesSendQuestionReply);
               socket.emit("meeting-question-send", { meetingId: currentMeetingId })
               socket.on('connect', () => {
                  socket.emit("meeting-question-send", { meetingId: currentMeetingId })
               })
            }

         },
         (error: any) => {
            const resMessage =
               (error.response.payload &&
                  error.response.payload?.data &&
                  error.response.payload?.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
         }
      )

   };

   const handleDeleteMeetingQuestion = (id: number) => {
      if (id > 0) {
         dispatch(deleteMeetingQuestionHandler(id)).then(
            (response: any) => {
               if (response.payload.status === 200) {
                  socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                  socket.on('connect', () => {
                     socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                  })
                  resetQuestionBox()
               }
            },
            (error: any) => {
               const resMessage =
                  (error.response.payload &&
                     error.response.payload.data &&
                     error.response.payload.data.message) ||
                  error.message ||
                  error.toString();
               console.log("resMessage ==>", resMessage)
            }
         )
      } else {
         console.log("meeting question not found...")
      }
   }

   const handleDeleteMeetingQuestionReply = (id: number) => {
      if (id > 0) {
         dispatch(deleteMeetingQuestionReplyHandler(id))
            .then(
               (response: any) => {
                  if (response?.payload?.status === 200) {
                     socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                     socket.on('connect', () => {
                        socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                     })
                  }
               },
               (error: any) => {
                  const resMessage =
                     (error.response?.payload &&
                        error.response?.payload?.data &&
                        error.response?.payload?.data.message) ||
                     error.message ||
                     error.toString();
                  console.log("resMessage ==>", resMessage)
               }
            )
      } else {
         console.log("meeting question not found...")
      }
   }

   const deleteConfirmHandler = (id: any) => {
      setShowDelete(true)
      setMeetingQuestionDeleteId(id)
   }

   const deleteConfirmHandlerReply = (id: any) => {
      setShowReplyDelete(true)
      setMeetingQuestionReplyDeleteId(id)
   }

   const deleteConfirmHandlerForPoll = (id: any) => {
      setShowDeletePoll(true)
      setMeetingPollDeleteId(id)
   }

   const deleteConfirmHandlerForQuiz = (id: any) => {
      setShowDeleteQuiz(true)
      setMeetingQuizDeleteId(id)
   }

   const handleDeleteMeetingPoll = (id: any) => {
      deleteMeetingPoll(id).then(
         (response) => {
            if (response.status === 200) {
               socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
               socket.on('connect', () => {
                  socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
               })
            }
         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
         }
      )
   }

   const handleDeleteMeetingQuiz = (id: any) => {
      deleteMeetingQuiz(id).then(
         (response) => {
            if (response.status === 200) {
               socket.emit("meeting-quiz-send", { meetingId: currentMeetingId })
            }
         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
         }
      )
   }

   const updatePollStatus = async (id: any, status: any) => {
      updateMeetingPollStatus(id, status).then(
         (response) => {
            if (response.status === 200) {
               // TO BE USED IN FUTURE
               // let state = store.getState()
               // if(status=='active'){
               //    if(state?.poll?.activePollsCount>0){
               //       dispatch(activePollCountHandler(state?.poll?.activePollsCount-1))
               //    }else{
               //       dispatch(activePollCountHandler(0))
               //    }
               // }else{
               //    dispatch(activePollCountHandler(state?.poll?.activePollsCount+1))
               // }
               socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
               socket.on('connect', () => {
                  socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
               })
            }
         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
         }
      )
   }

   const updateQuizStatus = async (id: any, status: any, quizId: any) => {
      if (activeQuizId === 0 || activeQuizId === quizId) {
         updateMeetingQuizStatus(id, status).then(
            (response) => {
               if (response.status === 200) {
                  if (status === 'inActive') {
                     dispatch(activeQuizIdHandler(quizId))
                  } else {
                     dispatch(activeQuizIdHandler(0))
                  }

                  socket.emit("meeting-quiz-send", { meetingId: currentMeetingId, quizId: quizId })
                  socket.on('connect', () => {
                     socket.emit("meeting-quiz-send", { meetingId: currentMeetingId, quizId: quizId })
                  })
               }
            },
            (error) => {
               const resMessage =
                  (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                  error.message ||
                  error.toString();
               console.log("resMessage ==>", resMessage)
            }
         )
      }
   }

   const handleArchiveMeetingQuestion = (id: number, isArchive: boolean) => {
      const data = {
         id, isArchive
      }
      dispatch(updateMeetingQuestionHandler(data))
         .then(
            (response: any) => {
               if (response.payload.status === 200) {
                  socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                  socket.on('connect', () => {
                     socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                  })
                  socket.emit("meeting-question-archive", { meetingId: currentMeetingId })
                  socket.on('connect', () => {
                     socket.emit("meeting-question-archive", { meetingId: currentMeetingId })
                  })
               }
            },
            (error: any) => {
               const resMessage =
                  (error.response.payload &&
                     error.response.payload.data &&
                     error.response.payload.data.message) ||
                  error.message ||
                  error.toString();
               console.log("resMessage ==>", resMessage)
            }
         )
   }

   const handleOnClickSelectedPoll = (pollId: number) => {
      let checkPollAvaliable = addPollList.findIndex((x: any) => x === pollId)
      if (checkPollAvaliable === -1) {
         if (addPollList.length === 0) {
            setAddPollList([pollId])
         } else {
            let data = addPollList.concat([pollId])
            setAddPollList(data)
         }
      } else {
         let filter = addPollList.filter((x: any) => x !== pollId)
         setAddPollList(filter)
      }
   }

   const handleOnClickSelectedQuiz = (quizId: number) => {
      let checkQuizAvaliable = addQuizList.findIndex((x: any) => x === quizId)
      if (checkQuizAvaliable === -1) {
         if (addQuizList.length === 0) {
            setAddQuizList([quizId])
         } else {
            let data = addQuizList.concat([quizId])
            setAddQuizList(data)
         }
      } else {
         let filter = addQuizList.filter((x: any) => x !== quizId)
         setAddQuizList(filter)
      }
   }

   const checkPollIdIsSelected = (pollId: number) => {
      let checkPollAvaliable = addPollList.findIndex((x: any) => x === pollId)
      if (checkPollAvaliable === -1) {
         return false
      } else {
         return true
      }
   }

   const checkQuizIdIsSelected = (quizId: number) => {
      let checkQuizAvaliable = addQuizList.findIndex((x: any) => x === quizId)
      if (checkQuizAvaliable === -1) {
         return false
      } else {
         return true
      }
   }

   const checkPollIsAvailableInList = (pollId: number) => {
      let checkPollAvaliable = meetingPollsList.findIndex((x: any) => x.pollId === pollId)
      if (checkPollAvaliable === -1) {
         return false
      } else {
         return true
      }
   }

   const checkQuizIsAvailableInList = (quizId: number) => {
      let checkQuizAvaliable = meetingQuizList.findIndex((x: any) => x.quizId === quizId)
      if (checkQuizAvaliable === -1) {
         return false
      } else {
         return true
      }
   }

   const handleAddPoll = () => {
      if (addPollList.length > 0) {
         addPollForMeeting(addPollList, currentMeetingId).then(
            (response) => {
               if (response.status === 201) {
                  setAddPollList([])
                  setAddPollModal(false)
                  socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
                  socket.on('connect', () => {
                     socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
                  })
               }
            },
            (error) => {
               const resMessage =
                  (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                  error.message ||
                  error.toString();
               console.log("resMessage ==>", resMessage)
            }
         )
      } else {
         console.log("Selected poll length zero")
      }
   }

   const handleAddQuiz = () => {
      if (addQuizList.length > 0) {
         addQuizForMeeting(addQuizList, currentMeetingId).then(
            (response) => {
               if (response.status === 201) {
                  setAddQuizList([])
                  setAddQuizModal(false)
                  socket.emit("meeting-quiz-send", { meetingId: currentMeetingId })
                  socket.on('connect', () => {
                     socket.emit("meeting-quiz-send", { meetingId: currentMeetingId })
                  })
               }
            },
            (error) => {
               const resMessage =
                  (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                  error.message ||
                  error.toString();
               console.log("resMessage ==>", resMessage)
            }
         )
      } else {
         console.log("Selected Quiz length zero")
      }
   }

   const getMeetingQuestionsByMeetingId = async (meetingId: number) => {
      let filterData = {};
      let shortByValue = shortBy;
      if (typeof (localStorage.getItem("filterUser")) === "string") {
         filterData = JSON.parse(localStorage.getItem("filterUser") as string)
      } else {
         filterData = selectedUser
      }

      if (typeof (localStorage.getItem("shortBy")) === "string") {
         shortByValue = localStorage.getItem("shortBy") as string
      }

      if (!!meetingId) {
         const data = {
            currentMeetingId: meetingId, selectedUser: filterData
         }

         let meetingQues = await dispatch(getMeetingQuestionsHandler(data))
         if (meetingQues?.payload?.status === 200) {
            if (meetingQuestions.length === 0) {
               setMeetingQuestions([])

            } else if (meetingQuestions.length > 0) {
               if (meetingQuestions[0]?.meetingId === currentMeetingId) {
                  setMeetingQuestions(meetingQues.payload?.data.data)

               } else {
                  setMeetingQuestions([])
               }
            }
            shortingQustions(shortByValue, meetingQues.payload?.data.data)
         }
      }
      scrollBottomCheck()
   }

   const getPollsByMeetingId = async (meetingId: number) => {
      if (!!meetingId) {
         let pollsListData = await getPollsForMeeting(meetingId)
         if (pollsListData.status === 200) {
            if (pollsListData.data.length > 0) {
               setMeetingPollsList(pollsListData.data)
               let allPollQuestions: any[] = []
               pollsListData.data.map((poll: any, i: any) => {
                  return poll.pollDetails.pollsQuestionDetails.map((question: any, j: any) => {
                     return allPollQuestions.push(question)
                  })
               })
               setAllPollQuestions(allPollQuestions)

               let groupedQuestions: any = []
               pollsListData.data.forEach((item: any) => {
                  const pollId = item.pollId;
                  const questionDetails = item.pollDetails.pollsQuestionDetails;
                  const length = questionDetails.length;

                  if (!groupedQuestions[pollId]) {
                     groupedQuestions[pollId] = [];
                  }

                  groupedQuestions[pollId].push(length);
               });
               setGroupedQuestions(groupedQuestions)

            } else {
               setMeetingPollsList([])
            }
         }
      }
   }

   const getAllQuizByMeetingId = async (meetingId: number) => {
      let quizzesListData = await getQuizzesForMeeting(meetingId)
      if (quizzesListData.status === 200) {
         setMeetingQuizzesList(quizzesListData.data?.meetingQuizData)
      }
   }

   const getQuizzesByMeetingId = async (meetingId: number, quizId: number) => {
      if (!!meetingId) {
         let quizzesListData = await getQuizzesForMeeting(meetingId)
         if (quizzesListData.status === 200) {
            quizzesListData.data.meetingQuizData.map((item: any, index: number) => {
               if (item.quizId === quizId) {
                  setActiveQuizData(quizzesListData.data?.meetingQuizzesData[index])
               }
            })
            setMeetingQuizzesList(quizzesListData.data?.meetingQuizData)
         }
      }
   }

   const getPollAnswerList = async (meetingId: number) => {
      getPollsQuestionsAnswerByMeetingId(meetingId).then(
         (response) => {
            if (response.status === 200) {
               let groupedAnswers: any = []
               response.data.forEach((item: any) => {
                  const pollId = item.pollId;
                  if (!groupedAnswers[pollId]) {
                     groupedAnswers[pollId] = [];
                  }
                  groupedAnswers[pollId].push(pollId);
               });
               groupedAnswers = groupedAnswers.map((item: any) => [item.length]);
               setGroupedAnswers(groupedAnswers)
               setNewSelectedAnswers(response.data.map((item: any) => ({
                  pollId: item.pollId,
                  questionId: item.questionId,
                  questionOptionId: item.questionOptionId
               })))
            }

         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
         }
      )
   }

   const getAllPollAnswerList = async (meetingId: number) => {
      getAllPollsQuestionsAnswerByMeetingId(meetingId).then(
         (response) => {
            if (response.status === 200) {
               setMeetingAllPollsAnswerList(response.data)
            }
         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
         }
      )
   }

   const checkOptionAnswerAvailable = (id: any) => {
      let checkIdInAnswerList = newSelectedAnswer?.findIndex((item: any) => (item.pollId === id.pollId && item.questionId === id.questionId && item.questionOptionId === id.questionOptionId))
      if (checkIdInAnswerList > -1) {
         return true
      } else {
         return false
      }
   }

   const handleUpvoteMeetingQuestion = async (meetingQuestionId: number) => {
      const data = {
         currentMeetingId, meetingQuestionId
      }
      dispatch(saveUpvoteForMeetingQuestionHandler(data))
         .then(() => {
            socket.emit("meeting-question-send", { meetingId: currentMeetingId })
            socket.on('connect', () => {
               socket.emit("meeting-question-send", { meetingId: currentMeetingId })
            })
         },
            (error: any) => {
               const resMessage =
                  (error.response.payload &&
                     error.response.payload.data &&
                     error.response.payload.data.message) ||
                  error.message ||
                  error.toString();
               console.log("resMessage ==>", resMessage)
            }
         )
   }

   const handleUpvoteMeetingQuestionReply = async (meetingQuestionId: number, meetingQuestionsReplyId: number) => {
      const data = {
         meetingQuestionId, meetingQuestionsReplyId
      }
      dispatch(saveUpvoteForMeetingQuestionReplyHandler(data))
         .then(() => {
            socket.emit("meeting-question-send", { meetingId: currentMeetingId })
            socket.on('connect', () => {
               socket.emit("meeting-question-send", { meetingId: currentMeetingId })
            })
         },
            (error: any) => {
               const resMessage =
                  (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                  error.message ||
                  error.toString();
               console.log("resMessage ==>", resMessage)
            }
         )
   }

   const getListOfMeetingIntrestedUsers = async (meetingId: number) => {
      dispatch(getMeetingIntrestedUsersHandler(meetingId))
         .then(
            (response: any) => {
               if (response?.payload?.status === 200) {
                  dispatch(interestedListHandler(response?.payload?.data))
               }
            },
            (error: any) => {
               const resMessage =
                  (error.response?.payload &&
                     error.response.data &&
                     error.response?.payload?.data.message) ||
                  error.message ||
                  error.toString();
               console.log("resMessage ==>", resMessage)
            }
         )
   }

   const getAllUpvoteMeetingQuestionList = async (meetingId: number) => {
      dispatch(getAllUpvoteMeetingQuestionListrByMeetingIdHandler(meetingId))
         .then(
            (response: any) => {
               if (response.payload.status === 200) {
                  setAllUpvoteMeetingQuestionList(response.payload.data)
               }
            },
            (error: any) => {
               const resMessage =
                  (error.response.payload &&
                     error.response.payload.data &&
                     error.response.payload.data.message) ||
                  error.message ||
                  error.toString();
               console.log("resMessage ==>", resMessage)
            }
         )
   }

   const getJoinedMeetingUsers = async (meetingId: number) => {
      dispatch(getJoinedMeetingUsersByMeetingIdHandler(meetingId))
         .then(
            (response: any) => {
               if (response?.payload?.status === 200) {
                  let data: any = [];
                  response.payload?.data.data.forEach((usersData: any) => {
                     if (!!usersData.joinUser && !!usersData.userId) {
                        data.push(usersData)
                     }
                     if (!!usersData.joinGuestUser && !!usersData.guestUserId && usersData.joinGuestUser.isAnonymous === false) {
                        data.push(usersData)
                     }
                  });
                  setAllJoinedUsers(data)
               }
            },
            (error: any) => {
               const resMessage =
                  (error.response.payload &&
                     error.response.payload?.data &&
                     error.response.payload?.data.message) ||
                  error.message ||
                  error.toString();
               console.log("resMessage ==>", resMessage)
            }
         )
   }

   const handlePrevPage = (e: number) => {
      setpageSize((e));
      setStartItem((startItem - perPage))
      totalrecordHandle()
   };

   const handleNextPage = (e: number) => {
      setpageSize((e));
      setStartItem(endItem + 1)
      totalrecordHandle()
   };

   const handlePageClick = (e: any) => {
      setpageSize(e);
      setStartItem(((e * perPage) + 1) - perPage)
      totalrecordHandle()
   }

   const totalrecordHandle = () => {
      var totalRecordTemp = ((startItem - 1) + perPage);
      if (totalRecordTemp > totalRecord) {
         totalRecordTemp = totalRecord;
      }

      setendItem(totalRecordTemp)
   }

   const getTotalPercentage = (pollId: number, questionId: number, questionOptionId: number) => {
      let totalPercentage = 0;
      let totalAnswerdUser = meetingAllPollsAnswerList.filter((x: any) => (x.pollId === pollId && x.questionId === questionId && x.questionOptionId === questionOptionId))
      let totalQuestionAnswerdUser = meetingAllPollsAnswerList.filter((x: any) => (x.pollId === pollId && x.questionId === questionId))
      totalPercentage = (totalAnswerdUser.length * 100) / totalQuestionAnswerdUser.length;
      let tempTotalpercentage = Number(totalPercentage.toFixed(2))

      return totalPercentage > 0 ? tempTotalpercentage : 0
   }

   const getTotalVote = (pollId: number, questionId: number) => {
      let totalQuestionAnswerdUser = meetingAllPollsAnswerList.filter((x: any) => (x.pollId === pollId && x.questionId === questionId))
      return totalQuestionAnswerdUser.length
   }

   const getUpvotedCount = (meetingQuestionId: number) => {
      let dataList = allUpvoteMeetingQuestionList.filter((x: any) => (x.meetingQuestionId === meetingQuestionId))
      return dataList.length
   }

   const getReplyUpvotedAvaliable = (meetingQuestionsReplyUpvoteDetails: any[]) => {
      let indexValue = meetingQuestionsReplyUpvoteDetails.findIndex((x: any) => (x.userId === userId))
      if (indexValue > -1) {
         return true
      } else {
         return false
      }
   }

   const getUpvotedAvaliable = (meetingQuestionId: number) => {
      let indexValue = allUpvoteMeetingQuestionList.findIndex((x: any) => (x.meetingQuestionId === meetingQuestionId && x.userId === userId))
      if (indexValue > -1) {
         return true
      } else {
         return false
      }
   }

   const shortingQustions = async (shortByValue: string, data: any) => {
      let dataSort;
      let filterOnchecked;
      switch (shortByValue) {
         case 'all':
            dataSort = data.sort((a: any, b: any) => (a['createdAt'] > b['createdAt'] ? 1 : -1))
            filterOnchecked = filterDatabyOnChecked(dataSort)
            setMeetingQuestions(filterOnchecked)
            break;
         case 'upvoted':
            // code block short by upVotedCounters
            dataSort = data.sort((a: any, b: any) => (a['meetingsQuestionUpvoteDetails'] > b['meetingsQuestionUpvoteDetails'] ? 1 : -1))
            filterOnchecked = filterDatabyOnChecked(dataSort)
            setMeetingQuestions(filterOnchecked)
            break;
         case 'time':
            dataSort = data.sort((a: any, b: any) => (a['createdAt'] < b['createdAt'] ? 1 : -1))
            filterOnchecked = filterDatabyOnChecked(dataSort)
            setMeetingQuestions(filterOnchecked)
            break;
         default:
            dataSort = data.sort((a: any, b: any) => (a['createdAt'] > b['createdAt'] ? 1 : -1))
            filterOnchecked = filterDatabyOnChecked(dataSort)
            setMeetingQuestions(filterOnchecked)
            break;
      }
   }

   const filterDatabyOnChecked = (data: any) => {
      let filter = data;
      if (showAnswerQuestion === true && showArchiveQuestion === false && showUnanswerQuestion === false) {
         // Show Only Answer Question
         return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length > 0))

      } else if (showAnswerQuestion === false && showArchiveQuestion === true && showUnanswerQuestion === false) {
         // Show Only Archive Question
         return filter = data.filter((x: any) => (x['isArchive'] === true))

      } else if (showAnswerQuestion === false && showArchiveQuestion === false && showUnanswerQuestion === true) {
         // Show Only Unanswer Question
         return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length === 0))

      } else if (showAnswerQuestion === true && showArchiveQuestion === true && showUnanswerQuestion === false) {
         // Show Only Answer Question and Show Only Archive Question
         return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length > 0 && x['isArchive'] === true))

      } else if (showAnswerQuestion === false && showArchiveQuestion === true && showUnanswerQuestion === true) {
         // Show Only Archive Question and Show Only Unanswer Question
         return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length === 0 && x['isArchive'] === true))

      } else if (showAnswerQuestion === true && showArchiveQuestion === false && showUnanswerQuestion === true) {
         // Show Only Answer Question and  Show Only Unanswer Question
         return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length > 0 && x['meetingQuestionsReplyDetails'].length === 0))

      } else if (showAnswerQuestion === true && showArchiveQuestion === true && showUnanswerQuestion === true) {
         // Show Only Answer Question and how Only Archive Question and Show Only Unanswer Question
         return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length > 0 && x['meetingQuestionsReplyDetails'].length === 0 && showUnanswerQuestion === true))

      } else {
         // none of above ( default)
         return filter
      }
   }

   const onChangeShort = async (shortByValue: string) => {
      const state = store.getState()
      const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
      localStorage.setItem("shortBy", shortByValue)
      setShortBy(shortByValue)
      getMeetingQuestionsByMeetingId(currentMeetingIdSocket)
   }

   const onChangeUserFilter = async (e: any) => {
      localStorage.setItem("filterUser", e.target.value)
      let data = JSON.parse(e.target.value)
      setSelectedUser(data)
      // TO BE USED IN FUTURE
      // let shortByValue = shortBy;
      // if (typeof (localStorage.getItem("shortBy")) === "string") {
      //    shortByValue = localStorage.getItem("shortBy") as string
      // }
      // let meetingQues = await getMeetingQuestions(currentMeetingId, data)
      // if (meetingQues.status === 200) {
      //    shortingQustions(shortByValue, meetingQues.data.data)
      // }
   }

   const shareMeetingMobileShow = (e: MouseEvent<HTMLButtonElement>) => {
      if (document.body.classList.contains('share-meeting-show')) {
         document.body.classList.remove('share-meeting-show');
      } else {
         document.body.classList.add('share-meeting-show');
      }
   }

   const handleOnClickReplyButton = async (meetingQuestionIdValue: number) => {
      setMeetingQuestionId(meetingQuestionIdValue);
      setReplyModal(true)
   }

   const onClickApplyFilter = async () => {
      let shortByValue = shortBy;
      if (typeof (localStorage.getItem("shortBy")) === "string") {
         shortByValue = localStorage.getItem("shortBy") as string;
      }
      const data = {
         currentMeetingId, selectedUser
      }
      let meetingQues = await dispatch(getMeetingQuestionsHandler(data));
      if (meetingQues.payload.status === 200) {
         shortingQustions(shortByValue, meetingQues?.payload?.data?.data);
      }
   }

   const onClickResetFilter = async () => {
      localStorage.setItem("filterUser", JSON.stringify(all));
      setSelectedUser(all);
      setShowAnswerQuestion(false);
      setShowArchiveQuestion(false);
      setShowUnanswerQuestion(false);
   }

   const acceptUserInMeeting = (status: boolean, userId: any, guestUserId: any) => {
      const details = {
         status: status == true ? t('joinMeeting.approved') : t('joinMeeting.rejected'),
         meetingId: currentMeetingId,
         attendeeId: userId,
         guestUserId: guestUserId
      }

      updateWaitingUserStatus(details).then((res: any) => {
         if (res.data[0] == 1) {
            refreshWaitingList()
            socket.emit("waiting-user-accept-reject", { status, meetingId: currentMeetingId, attendeeId: userId ? userId : guestUserId });
         }
      }).catch((err: any) => {
         console.log(err.message);
      })
   }

   const getSubmittedQuizzes = () => {
      getSubmittedQuizzesByAttendee(currentMeetingId).then((res: any) => {
         setAllQuizAnswer(res.data)
      })
   }

   const getAllSubmittedQuizzesAnswers = () => {
      getAllSubmittedQuizzes(currentMeetingId).then((res: any) => {
         setAllSubmittedQuizAnswer(res?.data)
      })
   }

   const handleTabSelect = (key: any) => {
      dispatch(activeTabHandler(key))
      if (key == 0) {
         setQuestionTab(true)
         setPollTab(false)
         setQuizTab(false)
      }
      else if (key == 1) {
         setPollTab(true)
         setQuestionTab(false)
         setQuizTab(false)
      }
      else if (key == 2) {
         setQuizTab(true)
         setPollTab(false)
         setQuestionTab(false)
      }

   };
   //functions ends here


   //useEffects starts here
   useEffect(() => {
      if (typeof currentMeetingId === 'undefined' || isFeedBackForm) {
         dispatch(feedbackFormHandler(false))
         dispatch(currentMeetingHandler(null))
         navigate("/dashboard")
      }
   }, []);

   useEffect(() => {
      getPollData(userId, pageSize, perPage, filterValue, sortColumn);
      getQuizData(userId, pageSize, perPage, filterValue, sortColumn);
   }, [filterValue, pageSize]);

   useEffect(() => {
      if (!!code) {
         localStorage.setItem("filterUser", JSON.stringify(all))
      }
   }, [code])

   useEffect(() => {
      if (!!currentMeetingCode) {
         getMeetingDetailByMeetingCode(currentMeetingCode)
         getQuizzesByMeetingId(currentMeetingId, activeQuizIdAttendee);
      }
   }, [currentMeetingCode])

   useEffect(() => {
      totalrecordHandle()
   }, [startItem, endItem, totalRecord]);

   useEffect(() => {
      // for reset filter
      if (showAnswerQuestion === false && showArchiveQuestion === false && showUnanswerQuestion === false && selectedUser.all === 'all') {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         getMeetingQuestionsByMeetingId(currentMeetingIdSocket);
      }
   }, [showAnswerQuestion, showArchiveQuestion, showUnanswerQuestion, selectedUser]);

   useEffect(() => {
      // Calculate the count of active polls in the meetingPollsList
      const count = meetingPollsList.filter((ele) => ele.status === 'active').length;
      const quizcount = meetingQuizList.filter((ele) => ele.status === 'active').length;
      let storedCount: any;
      let storedQuizCount: any;
      if (localStorage.getItem('activePollsCount') != null) {
         storedCount = localStorage.getItem('activePollsCount');
      } else {
         storedQuizCount = 0;
      }
      if (localStorage.getItem('activeQuizCount') != null) {
         storedQuizCount = localStorage.getItem('activeQuizCount');
      } else {
         storedQuizCount = 0;
      }
      // Calculate the difference and update the activePollsCount in local storage
      const updatedCount = count - storedCount;
      localStorage.setItem('activePollsCount', count.toString());
      const updatedQuizCount = quizcount - storedQuizCount;
      localStorage.setItem('activeQuizCount', quizcount.toString());
      // Update the activePolls state with the calculated count difference
      setActivePolls((prevActivePolls) => prevActivePolls + updatedCount);
      setActiveQuiz((prevActiveQuiz) => prevActiveQuiz + updatedQuizCount);

      if (pollTab) {
         setActivePolls(0);
      }
      if (quizTab) {
         setActiveQuiz(0);
      }
   }, [meetingPollsList, pollTab, meetingQuizList, quizTab]);

   useEffect(() => {
      if (!isShowResult && isFullscreen) {
         exitFullscreen();
      }
   }, [isShowResult, isFullscreen]);

   useEffect(() => {
      if (isHost && waitingList?.length === 0) {
         dispatch(waitingListModalHandler(false))
      }
   }, [waitingList]);

   useEffect(() => {
      if (!!currentMeetingId) {
         getSubmittedQuizzes()
         getAllSubmittedQuizzesAnswers()
      }
   }, [isShowQuizResult])

   useEffect(() => {

      socket.on('join-meeting-res', (data) => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         const userIdSocket = state.auth?.user?.user?.id
         if (!!currentMeetingIdSocket && userIdSocket === data?.user?.id) {
            getMeetingQuestionsByMeetingId(currentMeetingIdSocket)
            getPollsByMeetingId(currentMeetingIdSocket)
            getPollAnswerList(currentMeetingIdSocket)
            getAllPollAnswerList(currentMeetingIdSocket)
            getAllUpvoteMeetingQuestionList(currentMeetingIdSocket)
            getJoinedMeetingUsers(currentMeetingIdSocket)
            getListOfMeetingIntrestedUsers(currentMeetingIdSocket)
            getAllQuizByMeetingId(currentMeetingIdSocket)
         }
      })

      socket.on("meeting-question-res", async () => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         if (!!currentMeetingIdSocket) {

            getMeetingQuestionsByMeetingId(currentMeetingIdSocket)
            getAllUpvoteMeetingQuestionList(currentMeetingIdSocket)
         }
      })

      socket.on("meeting-polls-res", async () => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         if (!!currentMeetingIdSocket) {
            getPollsByMeetingId(currentMeetingIdSocket)
            getPollAnswerList(currentMeetingIdSocket)
            getAllPollAnswerList(currentMeetingIdSocket)
         }
      })

      socket.on("meeting-quiz-res", async (data: any) => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         if (!!currentMeetingIdSocket) {
            if (isHost) {
               getQuizzesByMeetingId(currentMeetingIdSocket, data.quizId);
            } else {
               if (!!data.quizId) {
                  window.localStorage.removeItem(QuizLocalStorage);
                  getQuizzesByMeetingId(currentMeetingIdSocket, data.quizId);
                  dispatch(activeQuizIdAttendeeHandler(data.quizId))
                  let allQuizAnswer: any[] = []
                  await getSubmittedQuizzesByAttendee(currentMeetingIdSocket).then((res: any) => {
                     allQuizAnswer = res.data
                  })
                  const isNewQuiz = allQuizAnswer?.every((submittedQuiz) => {
                     return data.quizId !== submittedQuiz.quizId
                  });
                  if (isNewQuiz) {
                     setIsShowQuizResult(false)
                  }
               }
            }
         }
      })

      socket.on("intrested-user-res", async () => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         if (!!currentMeetingIdSocket) {
            getListOfMeetingIntrestedUsers(currentMeetingIdSocket)
         }
      })

      socket.on("meeting-question-archive-res", async () => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         if (!!currentMeetingIdSocket) {
            resetQuestionBox()
         }
      })

      socket.on('exit-waiting-list-res', async (data: any) => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         const userIdSocket = state.auth?.user?.user?.id
         if (userIdSocket === data.hostId && currentMeetingIdSocket === data.meetingId) {
            refreshWaitingList()
         }
      });

      return (() => {
         socket.off('join-meeting-res')
         socket.off('meeting-question-res')
         socket.off('meeting-polls-res')
         socket.off('meeting-quiz-res')
         socket.off('intrested-user-res')
         socket.off('meeting-question-archive-res')
         socket.off('exit-waiting-list-res')
      })

   }, [currentMeeting])
   //useEffects ends here


   //components starts here
   const PollHeader = () => {
      return (
         <div className="hstack flex-wrap justify-content-between mb-3">
            <div className="font-20 fw-semibold">{t('common.Poll')}</div>
            <div className="hstack gap-2">
               {meetingPollsList.length > 0 &&
                  meetingPollsList.some((poll: any) => poll?.status === 'inActive') &&
                  <button
                     className="btn btn-light text-nowrap rounded-45 mw-120"
                     onClick={() => {
                        setIsShowResult(!isShowResult);
                        { isHost && toggleFullscreen(); }
                     }}
                  >
                     {isShowResult ? t('pollDetails.btnHideResult') : t('pollDetails.btnShowResult')}
                  </button>
               }
               {isHost && !isFullscreen && (
                  <button title={t('joinMeeting.addPoll')} onClick={() => getPollList()}
                     className="btn btn-primary rounded-45">
                     <i className="fa-solid fa-plus"></i> <span className='d-none d-md-inline'>{t('joinMeeting.addPoll')}</span>
                  </button>
               )}
            </div>
         </div>
      )
   }

   const PollBody: FC<ChildrenProps> = useCallback(({ children }) => {
      return (
         <div className="row justify-content-center">
            <div className="col-12 col-lg-12">
               <div className="card polls-card">
                  <div className="card-body section-question-container">
                     {meetingPollsList.length === 0 && (
                        <div className="status-block status-block-nodata"> {t('pollDetails.DataNotFound')}</div>
                     )}
                     {children}
                  </div>
               </div>
            </div>
         </div>
      )
   }, [meetingPollsList, allPollQuestions])

   const PollHost = () => {
      return (
         <>
            {!!meetingPollsList &&
               meetingPollsList.length > 0 &&
               meetingPollsList.map((pollValue: any, i: any) => {
                  var pollId = pollValue.pollDetails.id
                  return (
                     <div className='section-question-block' key={`pollAnswer_${pollId}`}>
                        <div className='section-question-header'>
                           <div className="fw-medium" >{pollValue.pollDetails.title}</div>
                           {!isShowResult &&
                              <div className='hstack gap-2'>
                                 <button className="btn btn-primary rounded-45" onClick={(e) => updatePollStatus(pollValue.id, pollValue.status)}>{pollValue.status === 'inActive' ? 'Active' : 'End'} Poll</button>
                                 <button className="btn btn-danger btn-sm text-light" title={t('common.delete')} onClick={(e) => deleteConfirmHandlerForPoll(pollValue.id)}>
                                    <i className="fa-solid fa-trash-can"></i>
                                 </button>
                              </div>
                           }
                        </div>
                        {!!pollValue.pollDetails.pollsQuestionDetails &&
                           pollValue.pollDetails.pollsQuestionDetails.length > 0 &&
                           pollValue.pollDetails.pollsQuestionDetails.map((pollsQuestionValue: any, j: any) => {
                              var questionId = pollsQuestionValue.questionDetails.id
                              return (
                                 <div className='section-question' key={'pollAnswer_question' + questionId}>
                                    <div className="section-question-header">
                                       <div className="fw-medium">{pollsQuestionValue.questionDetails.title}</div>
                                       {isShowResult &&
                                          <span>{t('pastMeetingDetails.lblTotalVote')} {getTotalVote(pollId, questionId)}</span>
                                       }
                                    </div>
                                    <ul className={`section-option-block ${isShowResult ? 'list-unstyled' : ''}`}>
                                       {!!pollsQuestionValue.questionDetails.questionOptions &&
                                          pollsQuestionValue.questionDetails.questionOptions.length > 0 &&
                                          pollsQuestionValue.questionDetails.questionOptions.map((questionOptionsValue: any, k: any) => {
                                             var questionOptionId = questionOptionsValue.id
                                             return (
                                                <li className="section-option" key={'pollAnswer_question_option' + questionOptionId}>
                                                   <div>{questionOptionsValue.optionName}</div>
                                                   {isShowResult &&
                                                      <div className="progress poll-progress">
                                                         <div className="progress-bar" role="progressbar" aria-label="Basic example" aria-valuenow={getTotalPercentage(pollId, questionId, questionOptionId)} style={{ width: getTotalPercentage(pollId, questionId, questionOptionId) + "%" }}>
                                                            {getTotalPercentage(pollId, questionId, questionOptionId)} %
                                                         </div>
                                                      </div>
                                                   }
                                                </li>
                                             )
                                          })
                                       }
                                    </ul>

                                 </div>
                              )
                           })
                        }
                     </div>
                  )
               })
            }

         </>
      )
   }

   const QuizHeader = () => {
      return (
         <div className="hstack flex-wrap justify-content-between mb-3">
            <div className="font-20 fw-semibold">
               {isFullscreen ? "Quiz Results" : t('common.Quiz')}
            </div>
            {isHost ?
               <div className="hstack gap-2">
                  {meetingQuizList?.length > 0 &&
                     meetingQuizList.some((quiz: any) => quiz?.status === 'inActive') &&
                     <button
                        className="btn btn-light text-nowrap rounded-45 mw-120"
                        onClick={() => {
                           setIsShowQuizResult(!isShowQuizResult);
                           toggleFullscreen()
                        }}
                     >
                        {isShowQuizResult
                           ? t('quizDetails.btnHideResult')
                           : t('quizDetails.btnShowResult')}
                     </button>

                  }
                  {!isFullscreen &&
                     <button
                        title={t('joinMeeting.addQuiz')}
                        onClick={getQuizList}
                        className="btn btn-primary rounded-45"
                     >
                        <i className="fa-solid fa-plus"></i>{' '}
                        <span className="d-none d-md-inline">
                           {t('joinMeeting.addQuiz')}
                        </span>
                     </button>
                  }
               </div>

               :
               <>
                  {
                     allQuizAnswer?.length > 0 &&
                     !meetingQuizList?.some(quiz => quiz.status === 'active' &&
                        !allQuizAnswer.some(submittedQuiz => submittedQuiz.quizId === quiz.quizId)) &&
                     <button
                        className="btn btn-light text-nowrap rounded-45 mw-120"
                        onClick={() => setIsShowQuizResult(!isShowQuizResult)}>
                        {isShowQuizResult ? "Hide Result" : t('quizDetails.btnShowResult')}
                     </button>
                  }
               </>
            }
         </div>
      );
   };

   const QuizBody: FC<ChildrenProps> = ({ children }) => {
      return (
         <div className="row justify-content-center">
            <div className="col-12 col-lg-12">
               <div className="card quiz-card">
                  <div className="card-body section-question-container">
                     {children}
                  </div>
               </div>
            </div>
         </div>
      )
   }

   const QuizHost = () => {
      return (
         <>
            {!!meetingQuizList &&
               meetingQuizList.length > 0 &&
               meetingQuizList.map((quizValue: any, index: number) => {
                  return (
                     <div className='section-question-block' key={'quizhost_' + quizValue.quiz.id + index}>
                        <div className='section-question-header'>
                           <div className="fw-medium">{quizValue.quiz.title}</div>
                           {!isShowQuizResult &&
                              <div className='hstack gap-2'>
                                 <button className="btn btn-primary rounded-45" disabled={activeQuizId !== 0 && activeQuizId !== quizValue.quizId} onClick={() => updateQuizStatus(quizValue.id, quizValue.status, quizValue.quizId)}>{quizValue.status === 'inActive' ? 'Active' : 'End'} Quiz</button>
                                 {quizValue.status === 'inActive' &&
                                    <button className="btn btn-danger btn-sm text-light" title={t('common.delete')} onClick={(e) => deleteConfirmHandlerForQuiz(quizValue.id)}>
                                       <i className="fa-solid fa-trash-can"></i>
                                    </button>
                                 }
                              </div>
                           }
                        </div>
                        {!!quizValue.quiz.questions &&
                           quizValue.quiz.questions.length > 0 &&
                           quizValue.quiz.questions.map((quizQuestionValue: any, j: any) => {
                              return (
                                 <div className='section-question' key={'quizhost_question' + quizQuestionValue.question.id}>
                                    <div className="fw-medium"> {quizQuestionValue.question.title}</div>
                                    <ul className={`section-option-block ${isShowQuizResult ? 'list-unstyled' : ''}`}>
                                       {!!quizQuestionValue.question.questionOptions &&
                                          quizQuestionValue.question.questionOptions.length > 0 &&
                                          quizQuestionValue.question.questionOptions.map((questionOptionsValue: any, k: any) => {
                                             return (
                                                <li key={'quizhost_question_option' + questionOptionsValue.id} className='section-option'>
                                                   {questionOptionsValue.optionName}
                                                </li>
                                             )
                                          })
                                       }
                                    </ul>
                                 </div>
                              )
                           })
                        }
                     </div>
                  )
               })
            }
            {meetingQuizList?.length === 0 &&
               <div className="status-block status-block-nodata"> {t('quizDetails.DataNotFound')}</div>
            }
         </>
      )
   }

   const QuizHostResult = () => {
      const quizResultsByQuizId: any = {};
      const highestScoresByQuizId: any = {};

      allSubmittedQuizAnswer.forEach((quiz) => {
         const quizId = quiz.quizId;
         if (!quizResultsByQuizId[quizId]) {
            quizResultsByQuizId[quizId] = [];
         }
         quizResultsByQuizId[quizId].push(quiz);
         const currentScore = quiz.score;

         if (!highestScoresByQuizId[quizId] || currentScore > highestScoresByQuizId[quizId]) {
            highestScoresByQuizId[quizId] = currentScore;
         }
      });

      return (
         <>
            {allSubmittedQuizAnswer.length > 0 ?

               Object.keys(quizResultsByQuizId).map((quizId: any, index: number) => {

                  // Sort quiz results by score in descending order
                  quizResultsByQuizId[quizId].sort((a: any, b: any) => b.score - a.score);
                  const quizTitleIndex = quizResultsByQuizId[quizId].findIndex(
                     (quiz: any) => quiz.quizJson && quiz.quizJson.title
                  );

                  // Use the quizJson title if found, otherwise use "Untitled Quiz"
                  const quizTitle =
                     quizTitleIndex !== -1
                        ? quizResultsByQuizId[quizId][quizTitleIndex].quizJson.title
                        : "Untitled Quiz";

                  return (
                     <div className='section-question-block' key={`QuizHostResult_${quizId + index}`}>
                        <div className='section-question-header'>
                           <div className="font-22 fw-medium">{quizTitle}</div>
                           <div>
                              {
                                 quizResultsByQuizId[quizId].length === 1 ?
                                    '1 person has submitted this quiz'
                                    :
                                    `${quizResultsByQuizId[quizId].length} people have submitted this quiz`
                              }
                           </div>
                        </div>
                        {/* show the total score of a quiz here  */}
                        {/* <h2>Highest Score: {highestScoresByQuizId[quizId]}</h2> */}
                        <div className="section-question table-responsive">
                           <table className="table">
                              <thead>
                                 <tr>
                                    <th>Name</th>
                                    <th>Score</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {quizResultsByQuizId[quizId].map((quiz: any) => (
                                    <tr key={quiz.id}>
                                       <td>
                                          {quiz.questionAnswerHostUser
                                             ? `${quiz.questionAnswerHostUser.firstName} ${quiz.questionAnswerHostUser.lastName}`
                                             : quiz.questionAnswerGuestUser &&
                                                quiz.questionAnswerGuestUser.isAnonymous
                                                ? 'Anonymous'
                                                : quiz.questionAnswerGuestUser.name}
                                       </td>
                                       <td>{quiz.score}</td>
                                    </tr>
                                 ))}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  );
               })
               :
               <div className="status-block status-block-nodata"> {t('quizDetails.DataNotFound')}</div>

            }
         </>
      );
   };
   //components ends here


   return (
      <>
         {isFullscreen && isHost ?
            <div className="card section-tab">
               <div className="card-body gray-bg p-2 p-sm-3 vstack gap-2">
                  {isShowResult &&
                     <>
                        <PollHeader />
                        <PollBody>
                           <PollHost />
                        </PollBody>
                     </>
                  }
                  {isShowQuizResult &&
                     <>
                        <QuizHeader />
                        <QuizBody>
                           <QuizHostResult />
                        </QuizBody>
                     </>
                  }
               </div>
            </div>
            :
            <div className="container-fluid">
               <div className="row position-relative">
                  <div className="col-xl-4 join-meeting-share-block">
                     <div className="card h-100 border-0 sponsor-detail">
                        <div className="card-body">
                           <ShareMeetingDetails userId={userId} currentMeeting={currentMeeting} />
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-8 ">
                     <div className="custom-tab meeting-poll">
                        <div className="text-end mb-2">
                           <div className='d-xl-none'>
                              <button className='btn' onClick={shareMeetingMobileShow}><i className='fa-solid fa-share-nodes font-22'></i></button>
                           </div>
                        </div>
                        <Tabs
                           onSelect={(key: any) => handleTabSelect(key)}
                           defaultActiveKey={0}
                           variant="pills"
                        >
                           <Tab eventKey={0} title={t('common.question')}>
                              <div className="card section-tab">
                                 <div className="card-body gray-bg p-2 p-sm-3 vstack gap-2">
                                    <div className="hstack justify-content-between">
                                       <div className="font-20 fw-semibold">{t('common.question')}</div>
                                       <div className="hstack gap-2">
                                          <div className='dropdown'>
                                             <button data-bs-toggle="dropdown" data-bs-auto-close="outside" className='btn btn-sm btn-primary'><i className='fa fa-sort'></i></button>
                                             <div className='dropdown-menu px-2 border-0 shadow-lg rounded-0' style={{ width: 100 }}>
                                                <div className="font-18 fw-medium mb-3 border-bottom pb-2">{t('joinMeeting.lblSortBy')}</div>
                                                <div className='d-flex justify-content-between gap-3'>
                                                   <button className={`btn ${shortBy === 'all' ? 'btn-primary' : 'btn-primary-light'} rounded-1 btn-sm`} onClick={(e) => onChangeShort('all')}>{t('joinMeeting.btnAll')}</button>
                                                   <button className={`btn ${shortBy === 'upvoted' ? 'btn-primary text-light' : 'btn-primary-light text-primary'} rounded-1 btn-sm`} onClick={(e) => onChangeShort('upvoted')}>
                                                      <i className="fa-regular fa-thumbs-up "></i>
                                                   </button>
                                                   <button className={`btn ${shortBy === 'time' ? 'btn-primary text-light' : 'btn-primary-light text-primary'} rounded-1 btn-sm`} onClick={(e) => onChangeShort('time')}>
                                                      <i className="fa-regular fa-clock "></i>
                                                   </button>
                                                </div>
                                             </div>
                                          </div>
                                          <div className='dropdown'>
                                             <button data-bs-toggle="dropdown" data-bs-auto-close="outside" className='btn btn-sm btn-primary'><i className='fa fa-filter'></i></button>
                                             <div className='dropdown-menu px-2 border-0 shadow rounded-0' style={{ minWidth: 290 }}>
                                                <div className="font-18 fw-medium mb-3 border-bottom pb-2">{t('joinMeeting.lblFilter')}</div>
                                                <div className='hstack mb-3 gap-2'>
                                                   <label className='text-nowrap'>{t('joinMeeting.lblSelectUser')}</label>
                                                   <div className='flex-fill'>
                                                      <select className="form-select rounded-0" aria-label="Default select example" onChange={(e) => onChangeUserFilter(e)} value={JSON.stringify(selectedUser)}>
                                                         <option value={JSON.stringify(all)}>{t('joinMeeting.lblAllUser')}</option>
                                                         <option value={JSON.stringify(anonymous)}>{t('joinMeeting.lblAllAnonymous')}</option>
                                                         {!!allJoinedUsers && allJoinedUsers.length > 0 && allJoinedUsers.map((usersData: any) => {
                                                            let userFullName = '';
                                                            let value = {};
                                                            if (!!usersData.joinUser && !!usersData.userId) {
                                                               userFullName = usersData.joinUser?.firstName + " " + usersData.joinUser?.lastName;
                                                               value = { "userId": usersData.userId };
                                                            }
                                                            if (!!usersData.joinGuestUser && !!usersData.guestUserId && usersData.joinGuestUser.isAnonymous === false) {
                                                               userFullName = usersData.joinGuestUser?.name;
                                                               value = { "guestUserId": usersData.guestUserId }
                                                            }
                                                            return (
                                                               <option key={'option_' + usersData.id} value={JSON.stringify(value)}>{userFullName}</option>
                                                            )
                                                         })}
                                                      </select>
                                                   </div>
                                                </div>
                                                <div className='form-check mb-3'>
                                                   <input type="checkbox" id='showAnswerQuestion' className='form-check-input' checked={showAnswerQuestion} onChange={(e) => setShowAnswerQuestion(!showAnswerQuestion)} />
                                                   <label className='form-check-label text-nowrap' htmlFor='showAnswerQuestion'>{t('joinMeeting.lblOnlyAnswer')}</label>
                                                </div>
                                                {isHost &&
                                                   <div className='form-check mb-3'>
                                                      <input type="checkbox" id='showArchiveQuestion' className='form-check-input' checked={showArchiveQuestion} onChange={(e) => setShowArchiveQuestion(!showArchiveQuestion)} />
                                                      <label className='form-check-label text-nowrap' htmlFor='showArchiveQuestion'>{t('joinMeeting.lblOnlyArchive')}</label>
                                                   </div>
                                                }
                                                <div className='form-check mb-3'>
                                                   <input type="checkbox" id='showUnanswerQuestion' className='form-check-input' checked={showUnanswerQuestion} onChange={(e) => setShowUnanswerQuestion(!showUnanswerQuestion)} />
                                                   <label className='form-check-label text-nowrap' htmlFor='showUnanswerQuestion'>{t('joinMeeting.lblOnlyUnanswer')}</label>
                                                </div>
                                                <div className='d-flex gap-3 align-items-center justify-content-between pt-3 border-top'>
                                                   <button type="reset" className='btn btn-danger text-white rounded-1 btn-sm' onClick={(e) => onClickResetFilter()}>{t('joinMeeting.btnResetFilter')}</button>
                                                   <button type="button" className='btn btn-primary rounded-1 btn-sm' onClick={onClickApplyFilter}>{t('joinMeeting.btnApply')}</button>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div ref={questionsListRef} onScroll={scrollBottomCheck} className="conversation-list">
                                       {!!meetingQuestions && meetingQuestions.length > 0 && meetingQuestions.map((meetingQuestion: any) => {
                                          let userFullName = "";
                                          if (!!meetingQuestion.joinUser && !!meetingQuestion.userId) {
                                             userFullName = meetingQuestion.joinUser?.firstName + " " + meetingQuestion.joinUser?.lastName;
                                          }
                                          if (!!meetingQuestion.joinGuestUser && !!meetingQuestion.guestUserId) {
                                             if (meetingQuestion.joinGuestUser.isAnonymous === true) {
                                                userFullName = t('common.lblAnonymous');
                                             } else {
                                                userFullName = meetingQuestion.joinGuestUser?.name;
                                             }
                                          }

                                          if (!meetingQuestion.isArchive || (meetingQuestion.isArchive && isHost)) {
                                             return (
                                                <div key={meetingQuestion.id}>
                                                   <div className={`meeting-questioncard ${currentMeetingHostId === meetingQuestion.userId ? 'meeting-host' : ''} ${meetingQuestion.isArchive ? 'unread' : 'read'}`}>
                                                      {meetingQuestion.isEdited && <small className='edited-label'>edited</small>}
                                                      <div className="hstack flex-wrap justify-content-between">
                                                         <div className="meeting-user-badge">
                                                            <i className="meeting-user-icon fa-solid fa-circle-user"></i>
                                                            <div className="fw-medium">{userId === meetingQuestion.userId ? 'You' : userFullName}</div>
                                                         </div>
                                                         <div className="hstack gap-1 gap-md-2">
                                                            {meetingQuestion.meetingQuestionsReplyDetails.length <= 0 &&
                                                               getUpvotedCount(meetingQuestion.id) <= 0 &&
                                                               userId === meetingQuestion.userId &&

                                                               <button
                                                                  className="btn btn-success btn-sm text-light"
                                                                  title="edit"
                                                                  onClick={() =>
                                                                     editQuestion(meetingQuestion.question, meetingQuestion.id)
                                                                  }
                                                               >
                                                                  <i className="fa fa-pencil"></i>
                                                               </button>

                                                            }
                                                            <button className={`btn ${getUpvotedAvaliable(meetingQuestion.id) === true ? 'btn-primary' : 'btn-secondary'} btn-sm text-light`} title={t('joinMeeting.titleUpvote')} onClick={() => { handleUpvoteMeetingQuestion(meetingQuestion.id) }}>
                                                               <i className="fa-regular fa-thumbs-up me-1"></i>
                                                               <span>{getUpvotedCount(meetingQuestion.id)}</span>

                                                            </button>
                                                            {(isHost) && (
                                                               <>
                                                                  <button className="btn btn-secondary btn-sm text-light" title={t('joinMeeting.titleArchive')} onClick={() => { handleArchiveMeetingQuestion(meetingQuestion.id, !meetingQuestion.isArchive) }}>
                                                                     <i className={meetingQuestion.isArchive ? 'fa-solid fa-check-double' : 'fa-solid fa-check'}></i>
                                                                  </button>
                                                                  <button type="button" className="btn btn-success btn-sm text-light" onClick={() => handleOnClickReplyButton(meetingQuestion.id)} title={t('joinMeeting.titleReply')}>
                                                                     <i className="fas fa-reply"></i>
                                                                  </button>
                                                               </>
                                                            )
                                                            }
                                                            {(meetingQuestion.meetingQuestionsReplyDetails.length <= 0) &&
                                                               (getUpvotedCount(meetingQuestion.id) <= 0) &&
                                                               (userId === meetingQuestion.userId) && (
                                                                  <button className="btn btn-danger btn-sm text-light" title={t('common.delete')} onClick={() => { deleteConfirmHandler(meetingQuestion.id) }}>
                                                                     <i className="fa-solid fa-trash-can"></i>
                                                                  </button>
                                                               )
                                                            }
                                                         </div>
                                                      </div>
                                                      <div className="font-18 fw-medium">{meetingQuestion.question}</div>
                                                      <div className='vstack gap-2'>
                                                         {!!meetingQuestion.meetingQuestionsReplyDetails &&
                                                            meetingQuestion.meetingQuestionsReplyDetails.length > 0 &&
                                                            meetingQuestion.meetingQuestionsReplyDetails.map((meetingQuestionsReplyValue: any, index: any) => {
                                                               let userFullNameReply = ""

                                                               if (!!meetingQuestionsReplyValue.userDetails && !!meetingQuestionsReplyValue.userId) {
                                                                  userFullNameReply = meetingQuestionsReplyValue.userDetails?.firstName + " " + meetingQuestionsReplyValue.userDetails?.lastName
                                                               }
                                                               if (!!meetingQuestionsReplyValue.joinGuestUser && !!meetingQuestionsReplyValue.guestUserId) {
                                                                  if (meetingQuestionsReplyValue.joinGuestUser.isAnonymous === true) {
                                                                     userFullNameReply = t('common.lblAnonymous')
                                                                  } else {
                                                                     userFullNameReply = meetingQuestionsReplyValue.joinGuestUser?.name
                                                                  }
                                                               }

                                                               return (
                                                                  <div className={`question-reply ${currentMeetingHostId === meetingQuestionsReplyValue.userId ? 'meeting-host' : ''}`} key={'meetingQuestionsReply_' + meetingQuestionsReplyValue.id}>
                                                                     <div className='hstack flex-wrap justify-content-between'>
                                                                        <div className="meeting-user-badge">
                                                                           <i className="meeting-user-icon fa-solid fa-circle-user"></i>
                                                                           <div className="fw-medium">{isHost ? 'You' : userFullNameReply}</div>
                                                                        </div>
                                                                        <div className="hstack gap-1 gap-md-2">
                                                                           <button className={`btn ${getReplyUpvotedAvaliable(meetingQuestionsReplyValue.meetingQuestionsReplyUpvoteDetails) === true ? 'btn-primary' : 'btn-secondary'} btn-sm text-light`} title={t('joinMeeting.titleUpvote')} onClick={(e) => { handleUpvoteMeetingQuestionReply(meetingQuestion.id, meetingQuestionsReplyValue.id) }}>
                                                                              <i className="fa-regular fa-thumbs-up me-1"></i>
                                                                              <span>{meetingQuestionsReplyValue.meetingQuestionsReplyUpvoteDetails.length > 0 ? meetingQuestionsReplyValue.meetingQuestionsReplyUpvoteDetails.length : 0}</span>
                                                                           </button>
                                                                           {isHost &&
                                                                              <button className="btn btn-danger btn-sm text-light" title={t('common.delete')} onClick={(e) => { deleteConfirmHandlerReply(meetingQuestionsReplyValue.id) }}>
                                                                                 <i className="fa-solid fa-trash-can"></i>
                                                                              </button>
                                                                           }
                                                                        </div>
                                                                     </div>
                                                                     <div className="font-18 fw-medium">{meetingQuestionsReplyValue.questionReply}</div>
                                                                  </div>
                                                               )
                                                            })
                                                         }
                                                      </div>
                                                   </div>
                                                </div>
                                             )
                                          }
                                       })}
                                       <div ref={messagesEndRef} />
                                    </div>
                                    <div className="ask-question">
                                       <Formik
                                          initialValues={initialValuesSendQuestion}
                                          onSubmit={handleSendQuestion}
                                          enableReinitialize={false}
                                       >
                                          <Form>
                                             <div className='hstack gap-1 gap-sm-4'>
                                                <div className='flex-fill position-relative'>
                                                   <Field innerRef={(input: any) => { questionBoxRef.current = input }} as="textarea" name="question" className="form-control border-0" id="question" placeholder={t('searchplaceholder.question')} value={meetingQuestion}
                                                      onChange={(e: any) => {
                                                         setEditMeetingQuestions(e.target.value)
                                                      }}
                                                      onKeyDown={(e: any) => {
                                                         if (e.ctrlKey && e.key === 'Enter') {
                                                            e.currentTarget.form?.requestSubmit();
                                                         }
                                                      }}>
                                                   </Field>
                                                   <ErrorMessage name="question" component="div" className="text-danger validation-absolute" />
                                                </div>
                                                <div className='hstack gap-1 gap-sm-2'>
                                                   {editMeetingCondition &&
                                                      <button className="btn shadow btn-light rounded-1" onClick={resetQuestionBox}>
                                                         <i className="fa fa-close"></i>
                                                      </button>
                                                   }
                                                   <button type='submit' className="btn shadow btn-light rounded-1">
                                                      <i className="fa-solid fa-paper-plane text-primary"></i>
                                                   </button>
                                                </div>
                                             </div>
                                          </Form>
                                       </Formik>
                                    </div>
                                    {
                                       scrollBottom &&
                                       <button onClick={scrollToBottom} className="scroll-bottom"><i className="fa-solid fa-angles-down"></i></button>
                                    }
                                 </div>
                              </div>
                           </Tab>
                           <Tab eventKey={1} title={
                              <div className="d-flex align-items-center justify-content-center">
                                 <span className="me-2">{t('common.Poll')}</span>
                                 {!isHost && (
                                    <span className="position-relative translate-middle badge rounded-pill badge-notification bg-danger fs-9 ms-1">
                                       {activePolls > 0 ? activePolls : ""}
                                    </span>
                                 )}
                              </div>
                           }>
                              <div className="card section-tab">
                                 <div className="card-body gray-bg p-2 p-sm-3 vstack gap-2">
                                    <PollHeader />
                                    <PollBody>
                                       {isHost ?
                                          <PollHost />

                                          :

                                          isShowResult ?
                                             <>
                                                {!!meetingPollsList &&
                                                   meetingPollsList.length > 0 &&
                                                   meetingPollsList.map((pollValue: any, i: any) => {
                                                      if (pollValue.status !== 'active') {
                                                         var pollId = pollValue.pollDetails.id
                                                         return (
                                                            <div className='card-body'>
                                                               <div className='border-bottom mb-3 border-primary border-opacity-50' key={'pollAnswer_' + pollId}>
                                                                  <div className="fw-medium mb-3"  > {pollValue.pollDetails.title}</div>

                                                                  {!!pollValue.pollDetails.pollsQuestionDetails &&
                                                                     pollValue.pollDetails.pollsQuestionDetails.length > 0 &&
                                                                     pollValue.pollDetails.pollsQuestionDetails.map((pollsQuestionValue: any, j: any) => {
                                                                        var questionId = pollsQuestionValue.questionDetails.id
                                                                        return (
                                                                           <div className='section-question-block' key={'pollAnswer_question' + questionId}>
                                                                              <div className="d-flex justify-content-between flex-wrap mb-2">
                                                                                 <div className="fw-medium flex-fill " >{pollsQuestionValue.questionDetails.title}
                                                                                 </div>
                                                                                 <span>{t('pastMeetingDetails.lblTotalVote')} {getTotalVote(pollId, questionId)}</span>
                                                                              </div>

                                                                              {!!pollsQuestionValue.questionDetails.questionOptions &&
                                                                                 pollsQuestionValue.questionDetails.questionOptions.length > 0 &&
                                                                                 pollsQuestionValue.questionDetails.questionOptions.map((questionOptionsValue: any, k: any) => {
                                                                                    var questionOptionId = questionOptionsValue.id
                                                                                    const isSelected = newSelectedAnswer.some((answer: any) =>
                                                                                       answer.pollId === pollId &&
                                                                                       answer.questionId === questionId &&
                                                                                       answer.questionOptionId === questionOptionId
                                                                                    );
                                                                                    return (
                                                                                       <div className="d-flex flex-wrap ps-3 align-items-center gap-2 mb-4" key={'pollAnswer_question_option' + questionOptionId}>
                                                                                          <div className="flex-fill">
                                                                                             <div>{questionOptionsValue.optionName}</div>
                                                                                             <div className="progress">
                                                                                                <div className={`progress-bar ${isSelected ? 'bg-danger' : ''}`} role="progressbar" aria-label="Basic example" aria-valuenow={getTotalPercentage(pollId, questionId, questionOptionId)} style={{ width: getTotalPercentage(pollId, questionId, questionOptionId) + "%" }}>
                                                                                                   {getTotalPercentage(pollId, questionId, questionOptionId)} %
                                                                                                </div>
                                                                                             </div>
                                                                                          </div>
                                                                                       </div>
                                                                                    )
                                                                                 })
                                                                              }
                                                                           </div>
                                                                        )
                                                                     })
                                                                  }
                                                               </div>
                                                            </div>
                                                         )
                                                      }
                                                   })
                                                }
                                                {
                                                   (meetingPollsList?.length > 0 && !meetingPollsList.some((pollValue: any) => pollValue?.status !== 'active')) &&
                                                   <div className="status-block status-block-nodata"> {t('pollDetails.DataNotFound')}</div>
                                                }
                                             </>
                                             :
                                             <>
                                                {!!meetingPollsList &&
                                                   meetingPollsList?.length > 0 &&
                                                   meetingPollsList?.map((pollValue: any, i: any) => {
                                                      if (pollValue.status == 'active') {
                                                         var pollId = pollValue.pollDetails.id
                                                         return (

                                                            <div className="row " key={'pollGuest_' + pollValue.pollDetails.id}>
                                                               <div className="col-lg-12 border-bottom border-primary border-opacity-25 pb-3 mb-3">
                                                                  {GroupedQuestions[pollId]?.[0] !== GroupedAnswers[pollId]?.[0] ?
                                                                     <>
                                                                        <div className="hstack flex-wrap justify-content-between mb-3">
                                                                           <div className="font-20 fw-semibold">{pollValue.pollDetails.title}</div>
                                                                           <button className="btn btn-dark text-nowrap rounded-45 btn-blue" title={t('pollDetails.titlePublishPoll')} onClick={() => handlePublishPoll(pollId)}>{t('pollDetails.btnPublishPoll')}</button>
                                                                        </div>

                                                                        {!!pollValue.pollDetails.pollsQuestionDetails &&
                                                                           pollValue.pollDetails.pollsQuestionDetails.length > 0 &&
                                                                           pollValue.pollDetails?.pollsQuestionDetails?.map((pollsQuestionValue: any, j: any) => {
                                                                              var questionId = pollsQuestionValue.questionDetails.id
                                                                              return (
                                                                                 <div className='col-lg-6 section-question-block mb-5' key={'pollGuest_question' + pollsQuestionValue.questionDetails.id}>
                                                                                    <div className="fw-medium mb-3" >{pollsQuestionValue.questionDetails.title}</div>
                                                                                    <div className='row'>
                                                                                       <div className='col-12'>
                                                                                          <div>
                                                                                             {!!pollsQuestionValue.questionDetails.questionOptions &&
                                                                                                pollsQuestionValue.questionDetails.questionOptions.length > 0 &&
                                                                                                pollsQuestionValue.questionDetails.questionOptions.map((questionOptionsValue: any, k: any) => {
                                                                                                   var questionOptionId = questionOptionsValue.id
                                                                                                   return (
                                                                                                      <div className="custom-radio" key={`${pollId}_${questionId}_${questionOptionId}`}>
                                                                                                         <input type="radio"
                                                                                                            name={`poll_que_${pollId}_${questionId}`}
                                                                                                            id={`poll_${pollId}_${questionId}_${questionOptionId}`}
                                                                                                            onClick={(e) => handleCheckboxChange(e, { pollId, questionId, questionOptionId })}
                                                                                                            checked={checkOptionAnswerAvailable({ pollId, questionId, questionOptionId })}
                                                                                                         />
                                                                                                         <label htmlFor={`poll_${pollId}_${questionId}_${questionOptionId}`}>
                                                                                                            <span className='label-text'>{questionOptionsValue.optionName}</span>
                                                                                                         </label>
                                                                                                      </div>
                                                                                                   )
                                                                                                })
                                                                                             }
                                                                                          </div>
                                                                                       </div>
                                                                                    </div>
                                                                                 </div>
                                                                              )
                                                                           })
                                                                        }
                                                                     </>


                                                                     :
                                                                     <div className='hstack justify-content-between gap-3'>
                                                                        <div className="font-20 fw-semibold">{pollValue.pollDetails.title}</div>
                                                                        <div>This poll has been submitted</div>
                                                                     </div>
                                                                  }
                                                               </div>
                                                            </div>
                                                         )
                                                      }

                                                   })
                                                }{!!meetingPollsList &&
                                                   meetingPollsList.length > 0 &&
                                                   !meetingPollsList.find((pollValue: any) => pollValue?.status === 'active') &&
                                                   <div className="status-block status-block-nodata"> {t('pollDetails.DataNotFound')}</div>
                                                }
                                             </>
                                       }
                                    </PollBody>
                                 </div>
                              </div>
                           </Tab>
                           <Tab eventKey={2} title={
                              <div className="d-flex align-items-center justify-content-center">
                                 <span className="me-2">{t('common.Quiz')}</span>
                                 {!isHost && (
                                    <span className="position-relative translate-middle badge rounded-pill badge-notification bg-danger fs-9 ms-1">
                                       {activeQuiz > 0 ? activeQuiz : ""}
                                    </span>
                                 )}
                              </div>
                           } >
                              <div className="card section-tab">
                                 <div className="card-body gray-bg p-2 p-sm-3 vstack gap-2">
                                    <QuizHeader />
                                    <QuizBody>
                                       {isHost ?
                                          <QuizHost />

                                          :
                                          isShowQuizResult ?
                                             allQuizAnswer?.map((quiz: any, index: number) => {
                                                return <ShowQuizResult
                                                   key={`showQuizResult1_${quiz.quizId}${index}`}
                                                   index={index}
                                                   quizId={quiz.quizId}
                                                   questionsAnswers={quiz.questionsAnswers}
                                                   quizJson={quiz.quizJson}
                                                />
                                             })

                                             :
                                             <>
                                                {!!meetingQuizList &&
                                                   meetingQuizList?.length > 0 &&
                                                   meetingQuizList?.map((quizValue: any, index: number) => {
                                                      if (quizValue.status == 'active') {
                                                         const quizId = quizValue.quizId
                                                         Serializer.addProperty("question", {
                                                            name: "score:number"
                                                         });
                                                         const saveSurveyData = (survey: any) => {
                                                            var data = undefined;
                                                            if (survey.state == 'running') {
                                                               var data = survey.data;
                                                               data.pageNo = survey.currentPageNo;
                                                               data.timeSpent = survey.timeSpent;
                                                            }
                                                            window.localStorage.setItem(QuizLocalStorage, JSON.stringify(data));
                                                         }
                                                         const survey = new Model(activeQuizData);
                                                         const prevData = window.localStorage.getItem(QuizLocalStorage) || null;
                                                         if (prevData) {
                                                            var data = JSON.parse(prevData);
                                                            survey.start();
                                                            survey.data = data;
                                                            if (data.pageNo) {
                                                               survey.currentPageNo = data.pageNo;
                                                            }
                                                            survey.timeSpent = data.timeSpent;
                                                         }
                                                         survey.onTimer.add((sender, options) => {
                                                            saveSurveyData(survey);
                                                         });
                                                         survey.applyTheme(SurveyTheme.BorderlessLight);
                                                         const calculateMaxScore = (questions: any) => {
                                                            var maxScore = 0;
                                                            questions.forEach((question: any) => {
                                                               if (!!question.score) {
                                                                  maxScore += question.score;
                                                               }
                                                            });
                                                            return maxScore;
                                                         }
                                                         const calculateTotalScore = (data: any) => {
                                                            var totalScore = 0;
                                                            Object.keys(data).forEach((qName) => {
                                                               const question = survey.getQuestionByValueName(qName);
                                                               if (!!question && question.isAnswerCorrect()) {
                                                                  if (!!question.score) {
                                                                     totalScore += question.score;
                                                                  }
                                                               }
                                                            });
                                                            return totalScore;
                                                         }
                                                         survey.onCompleting.add((sender) => {
                                                            let questionsAnswers = sender.data
                                                            const totalScore = calculateTotalScore(sender.data);
                                                            const maxScore = calculateMaxScore(sender.getAllQuestions());
                                                            let data = { meetingId: currentMeetingId, quizId, questionsAnswers, score: totalScore, quizJson: activeQuizData }
                                                            dispatch(quizAnswerByAttendeeHandler(data))
                                                               .then(() => {
                                                                  getSubmittedQuizzes()
                                                                  dispatch(activeQuizIdAttendeeHandler(0))
                                                                  window.localStorage.removeItem(QuizLocalStorage);
                                                               })
                                                         });
                                                         if (allQuizAnswer?.find((item: any) => item.quizId === quizId)) {
                                                            return (
                                                               <div key={`quizSubmitted1_${quizId}${index}`} className='status-block status-block-submitted'>
                                                                  <div>{quizValue.quiz.title}</div>
                                                                  <div className='font-16'>This Quiz has been submitted</div>
                                                               </div>
                                                            )
                                                         } else {
                                                            if (survey.isEmpty) {
                                                               return <div key={`quizStarted1_${quizId}${index}`} className='status-block status-block-started'>A quiz has already started</div>
                                                            } else {
                                                               return <Survey key={`quizRunning1_${quizId}${index}`} model={survey} />
                                                            }
                                                         }

                                                      }
                                                   })
                                                }
                                                {meetingQuizList?.length >= 0 &&
                                                   !meetingQuizList?.find((quizValue: any) => quizValue?.status === 'active') &&
                                                   <div className="status-block status-block-nodata"> {t('quizDetails.DataNotFound')}</div>
                                                }
                                             </>
                                       }
                                    </QuizBody>
                                 </div>
                              </div>
                           </Tab>
                        </Tabs>
                     </div>
                  </div>
               </div>
            </div>
         }

         {/* modals starts here */}
         <InterestedListModal />
         <WaitingListModal acceptUserInMeeting={acceptUserInMeeting} />

         <Modal show={addPollModal} centered onHide={() => setAddPollModal(false)}>
            <Modal.Header closeButton className='border-0'>
               <Modal.Title className='position-relative fw-semibold'>
                  {t('pollModel.header')}
               </Modal.Title>
            </Modal.Header>
            {!!pollList && pollList.length > 0 ?
               <>
                  <Modal.Body>
                     {pollList.length > 3 &&
                        <div className="mb-3">
                           <label htmlFor="search-box" className="form-label">{t('pollModel.title')}</label>
                           <div className="search-filter w-100">
                              <input type="text" value={filterValue} onChange={(e) => onFilterChange(e)} className="form-control border-0 ps-5" id="search-box" placeholder={t('pollModel.placeholderSearch')} />
                              <i className="fa-solid fa-magnifying-glass icon"></i>
                           </div>
                        </div>
                     }

                     {pollList.map((list, index) => (

                        <div className="hstack justify-content-between mb-3" key={index} title={checkPollIsAvailableInList(list.id) ? `${list.title} alrady added in poll` : ''}>
                           <div>{list.title}</div>
                           <button className="btn btn-primary-light size-38" onClick={() => handleOnClickSelectedPoll(list.id)} disabled={checkPollIsAvailableInList(list.id)} >
                              {checkPollIsAvailableInList(list.id) ?
                                 <i className={`fa-solid fa-minus text-primary`}></i>
                                 :
                                 <i className={`fa-solid ${checkPollIdIsSelected(list.id) ? 'fa-minus' : 'fa-plus'} text-primary`}></i>
                              }
                           </button>
                        </div>

                     ))}
                     {pollList.length > perPage &&
                        <div className="d-flex justify-content-sm-between gap-3 align-items-center flex-wrap justify-content-center">
                           <PaginationBox
                              filteredDataLength={perPage}
                              startItem={startItem}
                              totalPage={totalPage}
                              endItem={endItem}
                              currentPage={pageSize}
                              handlePrevPage={handlePrevPage}
                              handleNextPage={handleNextPage}
                              handlePageClick={handlePageClick}
                              rowsPerPageOptions={pageLimitOptions}
                              rowsPerPage={perPage}
                              totalRecord={totalRecord}
                           />
                        </div>
                     }

                  </Modal.Body>
                  <Modal.Footer className='border-0'>
                     <button type="button" className="btn btn-primary rounded-45 mw-120" onClick={handleAddPoll}>{t('quizModel.btnAdd')}</button>
                  </Modal.Footer>
               </>
               :
               <>
                  <Modal.Body>
                     <div className='font-22 text-center'>Looks like there are no polls yet!</div>
                  </Modal.Body>
                  <Modal.Footer className='border-0 justify-content-center'>
                     <button type="button" className="btn btn-primary rounded-45 mw-120" onClick={() => { navigate('/poll/0'); dispatch(selectedQuestionHandler([])) }}>{t("poll.btnCreatePoll")}</button>
                  </Modal.Footer>
               </>
            }
         </Modal>

         <Modal show={addQuizModal} centered onHide={() => setAddQuizModal(false)}>
            <Modal.Header closeButton className='border-0'>
               <Modal.Title className='position-relative fw-semibold'>
                  {t('quizModel.header')}
               </Modal.Title>
            </Modal.Header>
            {!!quizList && quizList.length > 0 ?
               <>
                  <Modal.Body>
                     {quizList.length > 3 &&
                        <div className="mb-3">
                           <label htmlFor="search-box" className="form-label">{t('quizModel.title')}</label>
                           <div className="search-filter w-100">
                              <input type="text" value={filterValue} onChange={(e) => onFilterChange(e)} className="form-control border-0 ps-5" id="search-box" placeholder={t('quizModel.placeholderSearch')} />
                              <i className="fa-solid fa-magnifying-glass icon"></i>
                           </div>
                        </div>
                     }
                     {quizList.map((list, index) => (

                        <div className="hstack justify-content-between mb-3" key={index} title={checkQuizIsAvailableInList(list.id) ? `${list.title} alrady added in poll` : ''}>
                           <div>{list.title}</div>
                           <button className="btn btn-primary-light size-38" onClick={(e) => handleOnClickSelectedQuiz(list.id)} disabled={checkQuizIsAvailableInList(list.id)} >
                              {checkQuizIsAvailableInList(list.id) ?
                                 <i className={`fa-solid fa-minus text-primary`}></i>
                                 :
                                 <i className={`fa-solid ${checkQuizIdIsSelected(list.id) ? 'fa-minus' : 'fa-plus'} text-primary`}></i>
                              }
                           </button>
                        </div>

                     ))}
                     {quizList.length > perPage &&
                        <div className="d-flex justify-content-sm-between gap-3 align-items-center flex-wrap justify-content-center">
                           <PaginationBox
                              filteredDataLength={perPage}
                              startItem={startItem}
                              totalPage={totalPage}
                              endItem={endItem}
                              currentPage={pageSize}
                              handlePrevPage={handlePrevPage}
                              handleNextPage={handleNextPage}
                              handlePageClick={handlePageClick}
                              rowsPerPageOptions={pageLimitOptions}
                              rowsPerPage={perPage}
                              totalRecord={totalRecord}
                           />
                        </div>
                     }

                  </Modal.Body>
                  <Modal.Footer className='border-0'>
                     <button type="button" className="btn btn-primary rounded-45 mw-120" onClick={handleAddQuiz}>{t('quizModel.btnAdd')}</button>
                  </Modal.Footer>
               </>
               :
               <>
                  <Modal.Body>
                     <div className='font-22 text-center'>Looks like there are no quizzes yet!</div>
                  </Modal.Body>
                  <Modal.Footer className='border-0 justify-content-center'>
                     <button type="button" className="btn btn-primary rounded-45 mw-120" onClick={() => { navigate('/quiz/0'); dispatch(selectedQuestionHandler([])) }}>{t("quiz.btnCreateQuiz")}</button>
                  </Modal.Footer>
               </>
            }
         </Modal>

         <Modal show={replyModal} centered onHide={() => setReplyModal(false)}>
            <Modal.Header closeButton className='border-0'>
               <Modal.Title className='position-relative fw-semibold'>
                  {t('questionReply.title')}
               </Modal.Title>
            </Modal.Header>
            <Formik
               initialValues={initialValuesSendQuestionReply}
               validationSchema={validationSchemaSendQuestionReply}
               onSubmit={handleSendQuestionReply}
               enableReinitialize={false}
            >
               <Form>
                  <Modal.Body>

                     <Field as="textarea" name="questionReply" className="form-control" id="reply" placeholder={t('questionReply.ansPlaceHolder')}
                        onKeyDown={(e: any) => {
                           if (e.ctrlKey && e.key === 'Enter') {
                              e.currentTarget.form?.requestSubmit();
                           }
                        }}>
                     </Field>
                     <ErrorMessage name="questionReply" component="div" className="text-danger" />


                  </Modal.Body>
                  <Modal.Footer className='border-0'>
                     <button type="reset" className="btn btn-outline-danger rounded-45 mw-120">{t('common.cancel')}</button>
                     <button type='submit' className="btn btn-primary rounded-45 mw-120">{t('questionReply.addAnswer')}</button>
                  </Modal.Footer>
               </Form>
            </Formik>
         </Modal>
         {/* modals ends here */}

         {/* alerts actions starts heres */}
         <DialogBox show={showDelete} clickOk={() => { handleDeleteMeetingQuestion(meetingQuestionDeleteId); setShowDelete(false) }} clickCancel={() => { setShowDelete(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltquestion')} />
         <DialogBox show={showReplyDelete} clickOk={() => { handleDeleteMeetingQuestionReply(meetingQuestionReplyDeleteId); setShowReplyDelete(false) }} clickCancel={() => { setShowReplyDelete(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltReply')} />
         <DialogBox show={showDeletePoll} clickOk={() => { handleDeleteMeetingPoll(meetingPollDeleteId); setShowDeletePoll(false) }} clickCancel={() => { setShowDeletePoll(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltPoll')} />
         <DialogBox show={showDeleteQuiz} clickOk={() => { handleDeleteMeetingQuiz(meetingQuizDeleteId); setShowDeleteQuiz(false) }} clickCancel={() => { setShowDeleteQuiz(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltQuiz')} />
         <DialogBox show={submitPoll} clickOk={() => { handleSubmitPoll(submitPollId); setSubmitPoll(false) }} clickCancel={() => { setSubmitPoll(false) }} btncancel={t('deleteBox.btnNo')} btnyes={t('deleteBox.btnYes')} question={t('deleteBox.submitPollQuestion')} />
         {/* alerts actions ends heres */}
      </>
   )
}

export default JoinMeeting;