import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as actions from "../actions/index";
import {
  questionCreate,
  questionData,
  questionGetByID,
  questionupdateByID,
  deleteQuestion
} from "../../../services/question.service";
import { get } from "lodash";

/* eslint-disable @typescript-eslint/no-unused-expressions */

export interface QuestionState {
  questionmessage: string;
  questionmessage1: string;
  questionSuccessful: boolean;
  questionList: any[]
  sortBy: string
  totalQuestionsPage: number
  totalQuestions: number,
}

const initialState: QuestionState = {
  questionmessage: "",
  questionmessage1: "",
  questionSuccessful: false,
  questionList: [],
  sortBy: "all",
  totalQuestionsPage: 0,
  totalQuestions: 0,

};

export const QuestionCreateHandler: any = createAsyncThunk(
  actions.CREATEQUESTION,
  (data: any, { rejectWithValue }) =>
    questionCreate(data?.newQuestionTitle, data?.fiterQuestionOptions, data?.score).catch(
      (error) => error && rejectWithValue(error)
    )
);
export const QuestionDataHandler: any = createAsyncThunk(
  actions.QUESTIONDATA,
  (data: any, { rejectWithValue }) =>
    questionData(data?.userId, data?.pageSize, data?.perPage, data?.searchVal).catch(
      (error) => error && rejectWithValue(error)
    )
);
export const QuestionGetByIdHandler: any = createAsyncThunk(
  actions.QUESTIONGETBYID,
  (data: any, { rejectWithValue }) =>
    questionGetByID(data).catch(
      (error) => error && rejectWithValue(error)
    )
);

export const QuestionUpdateById: any = createAsyncThunk(
  actions.QUETIONUPDATEBYID,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await questionupdateByID(data?.questionEditId, data?.newQuestionTitle, data?.fiterQuestionOptions, data?.score)
      return response
    }
    catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const DeleteQuestionHandler: any = createAsyncThunk(
  actions.DELETEQUESTION,
  (data: any, { rejectWithValue }) =>
    deleteQuestion(data).catch(
      (error) => error && rejectWithValue(error)
    )
);


export const questionSlice = createSlice({
  name: "questionSlice",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(QuestionCreateHandler.pending, (state, action) => { }),
      builder.addCase(QuestionCreateHandler.fulfilled, (state, action) => {
        state.questionmessage = action.payload?.data?.message;
        state.questionSuccessful = true;
      }),
      builder.addCase(QuestionCreateHandler.rejected, (state, action) => {
        const error = action;
        const errorMessage = get(error, "payload.response.data.message");
        const networkError = get(error, "payload.message");
        if (errorMessage) {
          state.questionmessage = errorMessage;

          state.questionSuccessful = false;
        } else if (networkError) {
          state.questionmessage = networkError;
          state.questionSuccessful = false;
        }
      }),
      builder.addCase(QuestionDataHandler.pending, (state, action) => { }),
      builder.addCase(QuestionDataHandler.fulfilled, (state, action) => {

        const strDescending = action?.payload.data.sort((a: any, b: any) => {
          a.id > b.id ? -1 : 1
        });
        state.questionList = strDescending;
        state.totalQuestionsPage = action?.payload.totalPages;
        state.totalQuestions = action?.payload.totalItems;

      }),
      builder.addCase(QuestionDataHandler.rejected, (state, action) => { }),
      builder.addCase(QuestionGetByIdHandler.pending, (state, action) => { }),
      builder.addCase(QuestionGetByIdHandler.fulfilled, (state, action) => {
        state.questionmessage = action.payload?.data?.message;
        state.questionSuccessful = true;
      }),
      builder.addCase(QuestionGetByIdHandler.rejected, (state, action) => {
        const error = action;
        const errorMessage = get(error, "payload.response.data.message");
        const networkError = get(error, "payload.message");
        if (errorMessage) {
          state.questionSuccessful = false;
          state.questionmessage = errorMessage;
        } else if (networkError) {
          state.questionSuccessful = false;
          state.questionmessage = networkError;
        }
      });
    builder.addCase(QuestionUpdateById.pending, (state, action) => { }),
      builder.addCase(QuestionUpdateById.fulfilled, (state, action) => {
        state.questionmessage = action?.payload?.data?.message
        state.questionSuccessful = true;

      }),
      builder.addCase(QuestionUpdateById.rejected, (state, action) => {
        state.questionSuccessful = false;
      }),
      builder.addCase(DeleteQuestionHandler.pending, (state, action) => { }),
      builder.addCase(DeleteQuestionHandler.fulfilled, (state, action) => {

      }),
      builder.addCase(DeleteQuestionHandler.rejected, (state, action) => { })
  },
});

export const { } = questionSlice.actions;
export default questionSlice.reducer;