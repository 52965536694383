import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ISettings {
    language: string
    defaultFontSize: number
    fontSize: number
    minFontSize: number
    maxFontSize: number
    isMiniSidebar: boolean
    profileButtonWidth: number | undefined
    isFeedBackForm: boolean
    tourEnable: boolean
    stepIndex: number
}

const initialState: ISettings = {
    language: "en",
    defaultFontSize: 16,
    fontSize: 16,
    minFontSize: 12,
    maxFontSize: 20,
    isMiniSidebar: false,
    profileButtonWidth: undefined,
    isFeedBackForm: false,
    tourEnable: false,
    stepIndex: 0,
}

export const setColor = (property: string, value: string) => {
    document.documentElement.style.setProperty(property, value, 'important');
}

export const settingsSlice = createSlice({
    name: 'settingsSlice',
    initialState,
    reducers: {
        languageHandler: (state, { payload }: PayloadAction<string>) => {
            state.language = payload;
        },
        fontSizeHandler: (state, { payload }: PayloadAction<number>) => {
            state.fontSize = payload;
            setColor('--font-size', `${payload}px`);
        },
        miniSidebarHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isMiniSidebar = payload;
        },
        profileButtonHandler: (state, { payload }: PayloadAction<number | undefined>) => {
            state.profileButtonWidth = payload;
        },
        feedbackFormHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isFeedBackForm = payload;
        },
        tourEnableHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.tourEnable = payload;
        },
        stepIndexHandler: (state, { payload }: PayloadAction<number>) => {
            state.stepIndex = payload;
        },
    }
})

export const { languageHandler, fontSizeHandler, miniSidebarHandler, profileButtonHandler, feedbackFormHandler, tourEnableHandler, stepIndexHandler } = settingsSlice.actions;
export default settingsSlice.reducer;