import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IUser } from "../../types/user.type";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { messageHandler, signUpHandler } from '../store/slices/authSlice';
import * as ACTION from "../store/actions/index";
import { currentMeetingHandler } from '../store/slices/meetingSlice';

const Signup = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const { message, signUpIsLoading } = useSelector((state: RootState) => state.auth)

    const { t } = useTranslation('common');
    const [successful, setSuccessful] = useState<boolean>(false);
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const initialValues: IUser = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
    };
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(t('validation.requiredField')).transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            )
            .matches(/^[a-zA-Z\s]+$/, (t('validation.specialcharacterFName')))
            .test(
                "len",
                t('validation.firstnameLimit'),
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= 3 && trimmedValue.length <= 20;
                }
            ),
        lastName: Yup.string()
            .required(t('validation.requiredField')).transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .matches(/^[a-zA-Z\s]+$/, (t('validation.specialcharacterLName')))
            .strict(true).test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            )
            .test(
                "len",
                t('validation.lastnameLimit'),
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= 3 && trimmedValue.length <= 20;
                }
            ),
        email: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .email(t('validation.notValidEmail'))
            .test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ),
        password: Yup.string()
            .required(t('validation.requiredField')).transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .matches(/^[A-Za-z0-9#@$\s]+$/, (t('validation.specialcharacterPassword')))
            .test(
                "len",
                t('validation.PasswordLimit'),
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 40;
                }
            ).test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ),
    });

    const handleRegister = async (formValue: IUser) => {
        await dispatch(signUpHandler(formValue)).then((res: any) => {
            if (res?.type == `${ACTION.SIGNUP}/${ACTION.FULFILLED}`) {
                dispatch(currentMeetingHandler(null))
                navigate("/dashboard");
            }
        })
    };
    useEffect(() => {
        dispatch(messageHandler())
    }, [])

    return (
        <div className="signup-wrap d-flex flex-column">
            <div className="d-flex align-items-center justify-content-center flex-fill signin-form">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 col-xxl-4 col-xl-6 my-1">
                            <div className="card border-0 rounded-4">
                                <div className="card-body d-flex flex-column flex-fill justify-content-center px-3 px-sm-5">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleRegister}
                                    >
                                        <Form>
                                            <h1 className="form-type text-center mb-3">{t('signUp.title')}</h1>
                                            <div className="mb-4">
                                                <Field type="text" name="firstName" className="form-control"
                                                    placeholder={t('signUp.placeholderFirstname')} />
                                                <ErrorMessage name="firstName" component="div" className="text-danger txt-error" />
                                            </div>
                                            <div className="mb-4">
                                                <Field name="lastName" className="form-control" type="text"
                                                    placeholder={t('signUp.placeholderLastname')} />
                                                <ErrorMessage name="lastName" component="div" className="text-danger txt-error" />
                                            </div>
                                            <div className="mb-4">
                                                <Field name="email" placeholder={t('signUp.placeholderEmail')}
                                                    className="form-control" />
                                                <ErrorMessage name="email" component="div" className="text-danger txt-error" />
                                                {message && (
                                                    <div className="form-group">
                                                        <div
                                                            className={
                                                                successful ? "alert alert-success" : "alert alert-danger p-2 rounded-0 border-0"
                                                            }
                                                            role="alert"
                                                        >
                                                            {message}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="password-toggle mb-4">
                                                <Field type={passwordType} name="password" className="form-control password"
                                                    placeholder={t('common.Password')} />
                                                <button type='button' onClick={togglePassword} className="password-toggle-btn btn btn-link p-0 text-black">
                                                    <i className={`fa-solid ${passwordType === 'password' ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                                </button>
                                                <ErrorMessage name="password" component="div" className="text-danger txt-error" />
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <Link to="/signin" className="text-decoration-none">{t('signUp.linkLogin')}</Link>
                                                </div>
                                                <button className="btn btn-primary rounded-3" type="submit" disabled={signUpIsLoading}>
                                                    {signUpIsLoading && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                    {t('signUp.btnSignUp')}
                                                </button>
                                            </div>

                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;