import React, { useEffect, useState } from 'react';
import { IGuestUserLogin } from '../../types/guestUser.type';
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { meetingGetByCodeForCheckMeetingPassword, meetingGetByID } from '../../services/meeting.service';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { attendeeWaitingList } from '../../services/waitingList.service';
import * as bootstrap from 'bootstrap';
import socket from '../../common/constants';
import { currentMeetingHandler, isAllowedHandler } from '../store/slices/meetingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { guestUserHandler, checkUserAccessHandler, guestUserLoginHandler, rememberMeHandler, rememberHandler } from '../store/slices/guestUserSlice';
import { RootState, store } from '../store/store';
import { miniSidebarHandler } from '../store/slices/settingsSlice';

const GuestLogin = () => {
    const dispatch = useDispatch()
    const { currentMeeting } = useSelector((state: RootState) => state.meeting)
    const currentMeetingId = currentMeeting?.id
    const currentMeetingCode = currentMeeting?.code
    const currentMeetingTitle = currentMeeting?.title
    const { t } = useTranslation('common');
    const { rememberMe, rememberName } = useSelector((state: RootState) => state.guestUser)
    let { code } = useParams();
    let navigate = useNavigate();
    const [isAnonymousChecked, setIsAnonymousChecked] = useState(false);
    const [passwordProtected, setPasswordProtected] = useState<boolean>(false);
    const [emailProtected, setEmailProtected] = useState<boolean>(false);
    const [geoFenceProtected, setGEOFenceProtected] = useState<boolean>(false);
    const [newLatitude, setNewLatitude] = useState<number>(0);
    const [newLongitude, setNewLongitude] = useState<number>(0);
    const [successful, setSuccessful] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [joinSuccessful, setJoinSuccessful] = useState<boolean>(false);
    const [joinMessage, setJoinMessage] = useState<string>("");


    const getMeetingDetailByMeetingCode = async (code: string) => {
        await meetingGetByCodeForCheckMeetingPassword(code, '').then(
            async (response) => {
                if (response.status === 200) {
                    setEmailProtected(response.data.isEmailProtected)
                    setPasswordProtected(response.data.isPasswordProtected)
                    setGEOFenceProtected(response.data.isGeoFenceProtected)
                    if (response.data.isGeoFenceProtected === true) {
                        navigator.geolocation.getCurrentPosition(function (position) {
                            setNewLatitude(position.coords.latitude)
                            setNewLongitude(position.coords.longitude)
                        },
                            function (error) {
                                if (error.code == error.PERMISSION_DENIED)
                                    toast.warning(t('toastMessage.enableLocationJoinMeeting'), {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                    });
                            });
                    }
                    if (response.data.status !== 'inProgress') {
                        if (response.data.status === "created") {
                            toast.error(t('toastMessage.NotStartedMeeting'), {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                            });
                            handleLeaveMeeting()
                        } else if (response.data.status === "started") {
                            toast.success(t('toastMessage.NotStartedMeetingByHost'), {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                            });
                            handleLeaveMeeting()
                        } else if (response.data.status === "ended") {
                            toast.success(t('toastMessage.meetingEnded'), {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                            });
                            handleLeaveMeeting()
                        }
                    } else {
                        await meetingGetByID(response.data.id).then((meeting) => {
                            dispatch(currentMeetingHandler(meeting));
                        })
                    }
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                toast.error(t('toastMessage.meetingNotFound'), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                handleLeaveMeeting();
            }
        )
    }
    const handleLeaveMeeting = () => {
        navigate("/")
    }
    const initialValuesGuestUserLogin: IGuestUserLogin = {
        name: rememberName,
        passwordProtected: passwordProtected,
        password: "",
        emailProtected: emailProtected,
        email: ""
    }

    useEffect(() => {
        if (rememberMe === false) {
            dispatch(rememberHandler(''));
        }
        dispatch(miniSidebarHandler(true))

    }, [])

    const validationSchemaGuestUserLogin = Yup.object().shape({
        name: Yup.string()
            .nullable()
            .required(t('validation.requiredField'))
            .test(
                "len",
                t('validation.miniLengthName'),
                (val: any) =>
                    val &&
                    val.trim().toString().length >= 3
            ),
        passwordProtected: Yup.boolean(),
        password: Yup.string().when("passwordProtected", {
            is: true,
            then: Yup.string()
                .required(t('validation.requiredField'))
                .test(
                    "len",
                    t('validation.minlengthPassword'),
                    (val: any) =>
                        val &&
                        val.trim().toString().length >= 3
                )
        }),
        emailProtected: Yup.boolean(),
        email: Yup.string().when("emailProtected", {
            is: true,
            then: Yup.string()
                .required(t('validation.requiredField'))
                .email(t('validation.notValidEmail')),
        }),
    });
    const [waitingErrMsg, setWaitingErrMessage] = useState("You have been added to the meeting waiting list we will let you know if someone lets you in.")
    const [showErrModal, setShowErrModal] = useState<boolean>(false);
    const handleErrModalShow = () => setShowErrModal(true);
    const handleGuestUserLogin = async (formValue: IGuestUserLogin, onSubmitProps: any) => {
        const { name, password, email } = formValue;
        let guestId: any;
        const data = {
            code: currentMeetingCode,
            password: password,
            longitude: newLongitude,
            latitude: newLatitude,
            loggedInUser: ''
        }
        await dispatch(checkUserAccessHandler(data))
            .then(
                async (res: any) => {
                    const state = store.getState()
                    if (res.payload.data.joinStatus == true) {
                        dispatch(isAllowedHandler(true))
                        let userName = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
                        dispatch(rememberHandler(name.trim()))
                        const data = {
                            name: name.trim(),
                            userName: userName,
                            isAnonymous: isAnonymousChecked,
                            meetingId: currentMeetingId,
                            email: email
                        }
                        await dispatch(guestUserLoginHandler(data)).then(
                            async (response: any) => {
                                setMessage(response?.payload?.data.message);
                                setSuccessful(true);
                                dispatch(guestUserHandler(response?.payload?.data))
                                guestId = response?.payload?.data.id
                                navigate('/guest/join/' + currentMeetingCode)
                            },

                            (error: any) => {
                                const resMessage =
                                    (error.response.payload &&
                                        error.response.payload?.data &&
                                        error.response.payload?.data.message) ||
                                    error.message ||
                                    error.toString();
                                setMessage(resMessage);
                                setSuccessful(false);
                            }
                        )
                    } else {
                        if (state.meeting.currentMeeting?.isPasswordProtected == true) {
                            if (res?.payload?.data?.passwordAuthenticated == true) {
                                if (state.meeting.currentMeeting?.isGeoFenceProtected == true) {
                                    if (res.payload.data.joinStatus == true) {
                                        dispatch(isAllowedHandler(true))
                                        let userName = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
                                        dispatch(rememberHandler(name.trim()))

                                        const data = {
                                            name: name.trim(),
                                            userName: userName,
                                            isAnonymous: isAnonymousChecked,
                                            meetingId: currentMeetingId,
                                            email: email
                                        }
                                        await dispatch(guestUserLoginHandler(data)).then(
                                            async (response: any) => {
                                                setMessage(response?.payload?.data.message);
                                                setSuccessful(true);
                                                dispatch(guestUserHandler(response?.payload?.data))
                                                guestId = response?.payload?.data.id
                                                navigate('/guest/join/' + currentMeetingCode)
                                            },

                                            (error: any) => {
                                                const resMessage =
                                                    (error.response.payload &&
                                                        error.response.payload?.data &&
                                                        error.response.payload?.data.message) ||
                                                    error.message ||
                                                    error.toString();
                                                setMessage(resMessage);
                                                setSuccessful(false);
                                            }
                                        )
                                    } else {
                                        let userName = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
                                        dispatch(rememberHandler(name.trim()))

                                        const data = {
                                            name: name.trim(),
                                            userName: userName,
                                            isAnonymous: isAnonymousChecked,
                                            meetingId: currentMeetingId,
                                            email: email
                                        }
                                        await dispatch(guestUserLoginHandler(data)).then(
                                            async (response: any) => {
                                                dispatch(guestUserHandler(response?.payload?.data))
                                                guestId = response?.payload?.data.id

                                            },

                                            (error: any) => {
                                                const resMessage =
                                                    (error.response.payload &&
                                                        error.response.payload?.data &&
                                                        error.response.payload?.data.message) ||
                                                    error.message ||
                                                    error.toString();
                                                setMessage(resMessage);
                                                setSuccessful(false);
                                            }
                                        )
                                        meetingGetByID(currentMeetingId).then((res: any) => {
                                            dispatch(currentMeetingHandler(res))
                                            attendeeWaitingList(currentMeetingId, res.hostId, 0, guestId).then((response) => {
                                                if (response.status === 201) {
                                                    setTimeout(() => {
                                                        var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                                                        myModal.hide();
                                                        setTimeout(() => {
                                                            handleErrModalShow();
                                                        }, 500);
                                                    }, 1000);
                                                    socket.emit("add-waiting-user", { meetingId: currentMeetingId, user: name.trim() })
                                                    navigate('/waiting-area/' + currentMeetingCode)
                                                } else {
                                                    if (response.data.statusCode === 403) {
                                                        setTimeout(() => {
                                                            var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                                                            myModal.hide();
                                                            setTimeout(() => {
                                                                handleErrModalShow();
                                                            }, 500);
                                                        }, 1000);
                                                        navigate('/waiting-area/' + res.code)
                                                        setShowErrModal(false)
                                                    } else if (response.data.statusCode == 307) {
                                                        setTimeout(() => {
                                                            var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                                                            myModal.hide();
                                                            setTimeout(() => {
                                                                handleErrModalShow();
                                                            }, 500);
                                                        }, 1000);
                                                        navigate('/guest/join/' + res.code)
                                                        setShowErrModal(false)
                                                    } else if (response.data.statusCode == 409) {
                                                        setTimeout(() => {
                                                            var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                                                            myModal.hide();
                                                            setTimeout(() => {
                                                                handleErrModalShow();
                                                            }, 500);
                                                        }, 1000);
                                                        toast.warning(response.data.message, {
                                                            position: toast.POSITION.TOP_RIGHT,
                                                            autoClose: 3000,
                                                        });
                                                        navigate('/guest/login/')
                                                        setShowErrModal(false)
                                                    }
                                                }
                                            }).catch((err: any) => {
                                                setTimeout(() => {
                                                    var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                                                    myModal.hide();
                                                    setTimeout(() => {
                                                        handleErrModalShow();
                                                        setWaitingErrMessage(err.response.data.message)
                                                    }, 500);
                                                }, 1000);
                                            })
                                        })
                                    }
                                } else {
                                    let userName = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
                                    dispatch(rememberHandler(name.trim()))

                                    const data = {
                                        name: name.trim(),
                                        userName: userName,
                                        isAnonymous: isAnonymousChecked,
                                        meetingId: currentMeetingId,
                                        email: email
                                    }
                                    await dispatch(guestUserLoginHandler(data)).then(
                                        async (response: any) => {
                                            setMessage(response?.payload?.data.message);
                                            setSuccessful(true);
                                            dispatch(guestUserHandler(response?.payload?.data))
                                            guestId = response?.payload?.data.id
                                            navigate('/guest/join/' + currentMeetingCode)
                                        },

                                        (error: any) => {
                                            const resMessage =
                                                (error.response.payload &&
                                                    error.response.payload?.data &&
                                                    error.response.payload?.data.message) ||
                                                error.message ||
                                                error.toString();
                                            setMessage(resMessage);
                                            setSuccessful(false);
                                        }
                                    )
                                }
                            } else {
                                setMessage(res.payload.data.message)
                            }
                        } else {
                            if (res.payload.data.joinStatus == true) {
                                dispatch(isAllowedHandler(true))
                                let userName = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
                                dispatch(rememberHandler(name.trim()))

                                const data = {
                                    name: name.trim(),
                                    userName: userName,
                                    isAnonymous: isAnonymousChecked,
                                    meetingId: currentMeetingId,
                                    email: email
                                }
                                await dispatch(guestUserLoginHandler(data)).then(
                                    async (response: any) => {
                                        setMessage(response?.payload?.data.message);
                                        setSuccessful(true);
                                        dispatch(guestUserHandler(response?.payload?.data))
                                        guestId = response?.payload?.data.id
                                        navigate('/guest/join/' + currentMeetingCode)
                                    },

                                    (error: any) => {
                                        const resMessage =
                                            (error.response.payload &&
                                                error.response.payload?.data &&
                                                error.response.payload?.data.message) ||
                                            error.message ||
                                            error.toString();
                                        setMessage(resMessage);
                                        setSuccessful(false);
                                    }
                                )
                            } else {
                                let userName = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
                                dispatch(rememberHandler(name.trim()))

                                const data = {
                                    name: name.trim(),
                                    userName: userName,
                                    isAnonymous: isAnonymousChecked,
                                    meetingId: currentMeetingId,
                                    email: email
                                }
                                await dispatch(guestUserLoginHandler(data)).then(
                                    async (response: any) => {
                                        dispatch(guestUserHandler(response?.payload?.data))
                                        guestId = response?.payload?.data.id

                                    },

                                    (error: any) => {
                                        const resMessage =
                                            (error.response.payload &&
                                                error.response.payload?.data &&
                                                error.response.payload?.data.message) ||
                                            error.message ||
                                            error.toString();
                                        setMessage(resMessage);
                                        setSuccessful(false);
                                    }
                                )
                                meetingGetByID(currentMeetingId).then((res: any) => {
                                    dispatch(currentMeetingHandler(res))
                                    attendeeWaitingList(currentMeetingId, res.hostId, 0, guestId).then((response: any) => {
                                        if (response.status === 201) {
                                            setTimeout(() => {
                                                var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                                                myModal.hide();
                                                setTimeout(() => {
                                                    handleErrModalShow();
                                                }, 500);
                                            }, 1000);
                                            socket.emit("add-waiting-user", { meetingId: currentMeetingId, user: name.trim() })
                                            navigate('/waiting-area/' + currentMeetingCode)
                                        } else {
                                            if (response.data.statusCode === 403) {
                                                setTimeout(() => {
                                                    var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                                                    myModal.hide();
                                                    setTimeout(() => {
                                                        handleErrModalShow();
                                                    }, 500);
                                                }, 1000);
                                                navigate('/waiting-area/' + res.code)
                                                setShowErrModal(false)
                                            } else if (response.data.statusCode == 307) {
                                                setTimeout(() => {
                                                    var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                                                    myModal.hide();
                                                    setTimeout(() => {
                                                        handleErrModalShow();
                                                    }, 500);
                                                }, 1000);
                                                navigate('/guest/join/' + res.code)
                                                setShowErrModal(false)
                                            } else if (response.data.statusCode == 409) {
                                                setTimeout(() => {
                                                    var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                                                    myModal.hide();
                                                    setTimeout(() => {
                                                        handleErrModalShow();
                                                    }, 500);
                                                }, 1000);
                                                toast.warning(response.data.message, {
                                                    position: toast.POSITION.TOP_RIGHT,
                                                    autoClose: 3000,
                                                });
                                                navigate('/guest/login/')
                                                setShowErrModal(false)
                                            }
                                        }
                                    }).catch((err: any) => {
                                        setTimeout(() => {
                                            var myModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('join-meeting-modal') as HTMLElement);
                                            myModal.hide();
                                            setTimeout(() => {
                                                handleErrModalShow();
                                                setWaitingErrMessage(err.response.data.message)
                                            }, 500);
                                        }, 1000);
                                    })
                                })
                            }

                        }

                    }

                }
            )
    }

    useEffect(() => {
        if (!!code) {
            getMeetingDetailByMeetingCode(code)
        }
    }, [code])

    return (
        <div className='guestlogin-wrapper d-flex flex-column'>     
            <div className='guest-join-meeting flex-fill'>
                <div className='container'>
                    <Formik
                        initialValues={initialValuesGuestUserLogin}
                        validationSchema={validationSchemaGuestUserLogin}
                        onSubmit={handleGuestUserLogin}
                        enableReinitialize={true}
                    >
                        <Form autoComplete="off">
                            <h2 className='fw-bold mb-4'>{t('guest.guestLogin.lblJoin')} <u>{currentMeetingTitle}</u> {t('guest.guestLogin.lblMeeting')}</h2>
                            <div className="row">
                                <div className="col-lg-6 mb-4">
                                    {joinMessage && (
                                        <div className="form-group">
                                            <div
                                                className={
                                                    joinSuccessful ? "alert alert-success" : "alert alert-danger p-2 rounded-0 border-0"
                                                }
                                                role="alert"
                                            >
                                                {joinMessage}
                                            </div>
                                        </div>
                                    )}
                                    <label htmlFor="guestuser-name" className="form-label">{t('guest.guestLogin.name')}</label>
                                    <Field type="text" name="name" className="form-control" id="guestuser-name" placeholder={t('guest.guestLogin.name')}
                                    />
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                    {message && (
                                        <div className="form-group">
                                            <div
                                                className={
                                                    successful ? "alert alert-success" : "alert alert-danger p-2 rounded-0 border-0"
                                                }
                                                role="alert"
                                            >
                                                {message}
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                            {emailProtected && (
                                <>
                                    <div className="row">
                                        <div className="col-lg-6 mb-4">
                                            <label htmlFor="guestuser-email" className="form-label">{t('guest.guestLogin.email')}</label>
                                            <Field type="text" name="email" className="form-control" id="guestuser-email" placeholder={t('guest.guestLogin.placeholderEmail')} />
                                            <ErrorMessage name="email" component="div" className="text-danger" />
                                        </div>
                                    </div>

                                </>
                            )}
                            {passwordProtected && (
                                <>
                                    <div className="row">
                                        <div className="col-lg-6 mb-4">
                                            <label htmlFor="guestuser-password" className="form-label">{t('guest.guestLogin.password')}</label>
                                            <Field type="text" autoComplete="off" name="password" className="form-control password-text" id="guestuser-password" placeholder={t('guest.guestLogin.password')} />
                                            <ErrorMessage name="password" component="div" className="text-danger" />
                                        </div>
                                    </div>

                                </>
                            )}
                            <div className='d-flex flex-wrap align-items-center gap-2 mb-4'>
                                <button className="btn btn-light rounded-45 mw-120" type="submit">{t('guest.guestLogin.btnJoin')}
                                </button>
                                <button type='reset' className="btn btn-outline-light rounded-45 mw-120" onClick={(e) => { }}>{t('guest.guestLogin.btnReset')}</button>
                            </div>
                            <div className="form-check mb-3">
                                <Field className="form-check-input" name="isAnonymous" type="checkbox" value={isAnonymousChecked} id="guestuser-privacy" checked={isAnonymousChecked} onChange={(e: any) => setIsAnonymousChecked(e.target.checked)} />
                                <label className="form-check-label" htmlFor="guestuser-privacy">
                                    {t('guest.guestLogin.checkboxName')}
                                </label>
                            </div>
                            <div className="form-check mb-3">
                                <Field className="form-check-input" name="isRememberMe" type="checkbox" id="guestuser-remember" checked={rememberMe} onChange={(e: any) => dispatch(rememberMeHandler(e.target.checked))} />
                                <label className="form-check-label" htmlFor="guestuser-remember">
                                    {t('guest.guestLogin.rememberMe')}

                                </label>
                            </div>

                        </Form>

                    </Formik>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <img src="/images/dashboard-img.svg" className='img-fluid guest-login-img' />
            </div>

        </div>
    );
};

export default GuestLogin;