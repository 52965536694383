import React, { FC } from "react";
import { Model } from "survey-core";
import * as SurveyTheme from "survey-core/themes";
import { Survey } from "survey-react-ui";
interface ShowQuizResultProps {
    quizId: number
    questionsAnswers: []
    quizJson: []
    index: number
}
const ShowQuizResult: FC<ShowQuizResultProps> = ({ quizId, questionsAnswers, quizJson, index }) => {

    const survey = new Model(quizJson);
    survey.applyTheme(SurveyTheme.BorderlessLight);

    survey.data = questionsAnswers

    survey.mode = "display";
    survey.questionsOnPageMode = "singlePage";
    survey.showNavigationButtons = "none";
    survey.showProgressBar = "off";
    survey.showTimerPanel = "none";
    survey.maxTimeToFinishPage = 0;
    survey.maxTimeToFinish = 0;
    const correctStr = "Correct";
    const inCorrectStr = "Incorrect";
    function getTextHtml(text: string, str: string, isCorrect: boolean) {
        if (text.indexOf(str) < 0) return undefined;
        return text.substring(0, text.indexOf(str)) + "<span class='" + (isCorrect ? "correctAnswer" : "incorrectAnswer") + "'>" + str + "</span>";
    }

    function renderCorrectAnswer(q: any) {
        if (!q) return;
        const isCorrect = q.isAnswerCorrect();
        if (!q.prevTitle) {
            q.prevTitle = q.title;
        }
        if (isCorrect === undefined) {
            q.title = q.prevTitle;
        }
        q.title = q.prevTitle + ' ' + (isCorrect ? correctStr : inCorrectStr);
    }

    survey.onValueChanged.add((sender, options) => {
        renderCorrectAnswer(options.question);
    });
    survey.onTextMarkdown.add((sender, options) => {
        var text = options.text;
        var html = getTextHtml(text, correctStr, true);
        if (!html) {
            html = getTextHtml(text, inCorrectStr, false);
        }
        if (!!html) {
            options.html = html;
        }
    });
    survey.getAllQuestions().forEach(q => { renderCorrectAnswer(q) });
    return (<Survey key={`ShowQuizResult_${Math.random}${index}`} model={survey} />);
}

export default ShowQuizResult