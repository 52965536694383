import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
interface Props {
  currentPage: number;
  startItem: any,
  totalPage: any;
  rowsPerPageOptions: any;
  endItem: any;
  rowsPerPage: any;
  totalRecord: any;
  filteredDataLength: number;
  handleNextPage: (page: number) => void;
  handlePrevPage: (page: number) => void;
  handlePageClick: (page: number) => void;
}

const PaginationBox: React.FC<Props> = ({

  currentPage,
  totalPage,
  rowsPerPage,
  startItem,
  endItem,
  totalRecord,
  handlePrevPage,
  handleNextPage,
  handlePageClick,

}) => {
  const { t, i18n } = useTranslation('common');
  const pages: any = [];
  for (let i = 1; i <= totalPage; i++) {
    pages.push(i);
  }

  return (
    <>

      <div className="d-flex gap-2">
        {pages.length > 0 && (
          <>
            {/* {t('pagination.totalRecored')} {totalRecord } */}
            <span>{t('pagination.lblStartItem')}  </span>
            <span>{startItem}</span>
            <span>{t('pagination.lblBetween')}</span>
            <span>{endItem}</span>
            <span>{t('pagination.lblEndItem')}</span>
            <span>{totalRecord}</span>
            <span>{t('pagination.lblTotalRecoreds')}</span>
          </>
        )}
      </div>

      <div className="d-flex align-items-center gap-0-5 custom-pagination">
        <button
          className="btn btn-primary"
          onClick={() => handlePrevPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {t('pagination.btnPrevPage')}
        </button>
        <ul>
          {
            !!pages &&
            pages.length > 0 &&
            pages.map((page: any, index: any) => (
              <li className={currentPage === (page + 1) ? 'active-sibling' : `${currentPage === (page) ? 'active' : ""}`} key={index}>
                <button className={currentPage === page ? 'active btn btn-primary disabled' : "btn btn-primary"} key={index} id={page} onClick={() => handlePageClick(page)}>
                  {page}
                </button>
              </li>
            ))
          }
        </ul>

        <button
          className="btn btn-primary"
          onClick={() => handleNextPage(currentPage + 1)}
          disabled={currentPage === totalPage || totalPage === 0}
        >
          {t('pagination.btnNextPage')}
        </button>
      </div>
    </>
  );
};

PaginationBox.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPage: PropTypes.number,
  totalRecord: PropTypes.number,
  startItem: PropTypes.number,
  endItem: PropTypes.number,
  handlePrevPage: PropTypes.func.isRequired,
  handleNextPage: PropTypes.func.isRequired,
  handlePageClick: PropTypes.func.isRequired,
};
export default PaginationBox