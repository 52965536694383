import React, { useEffect, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import ShareMeetingDetails from '../components/ShareMeetingDetails';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { currentMeetingHandler } from '../store/slices/meetingSlice';
import { feedbackFormHandler } from '../store/slices/settingsSlice';
import clockImg from "../../images/clock.svg"
import { useTranslation } from 'react-i18next';

const WaitingArea = () => {
   const dispatch = useDispatch()
   let navigate = useNavigate();
   const { t } = useTranslation('common');
   const { user } = useSelector((state: RootState) => state.auth)
   const { guestUser } = useSelector((state: RootState) => state.guestUser)
   const { isFeedBackForm } = useSelector((state: RootState) => state.settings)
   const { currentMeeting } = useSelector((state: RootState) => state.meeting)
   const userId = user?.user?.id;
   const guestUserId = guestUser?.id;
   const currentMeetingId = currentMeeting?.id

   const shareMeetingMobileShow = (e: MouseEvent<HTMLButtonElement>) => {
      if (document.body.classList.contains('share-meeting-show')) {
         document.body.classList.remove('share-meeting-show');
      } else {
         document.body.classList.add('share-meeting-show');
      }
   }

   useEffect(() => {
      if (typeof currentMeetingId === 'undefined' || isFeedBackForm) {
         dispatch(feedbackFormHandler(false))
         dispatch(currentMeetingHandler(null))
         if (!!guestUserId) {
            navigate("/")
         } else {
            navigate("/dashboard")
         }
      }
   }, []);


   return (
      <div className="container-fluid">
         <div className="row position-relative">
            <div className="col-12 mb-2 d-xl-none text-end">
               <button className='btn' onClick={shareMeetingMobileShow}><i className='fa-solid fa-share-nodes font-22'></i></button>
            </div>
            <div className="col-xl-4 join-meeting-share-block">
               <div className="card h-100 border-0 sponsor-detail">
                  <div className="card-body">
                     <ShareMeetingDetails userId={userId} currentMeeting={currentMeeting} />
                  </div>
               </div>
            </div>
            <div className="col-xl-8 waiting-list">
               <div className='card h-100'>
                  <div className='card-body d-flex align-items-center justify-content-center  p-4'>
                     <div className='d-flex align-items-center justify-content-center gap-4 flex-column py-2'>
                        <div>
                           <img src={clockImg} alt="clock image" className='img-fluid' style={{ width: "100px" }} />
                        </div>
                        <p className='font-22 mb-0 fw-medium text-center'>{t('toastMessage.waitingArea')} </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default WaitingArea;