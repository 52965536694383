import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL


export const profileupdate = async (id: any, firstName: string, lastName: string, files: any[]) => {
    let formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('photo', files[0]);
    let newHeader = {
        "Content-Type": "multipart/form-data",
    }

    return axios({
        url: API_URL + "users/profile-update/" + id,
        method: "post",
        headers: newHeader,
        data: formData
    })
}