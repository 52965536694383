import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as actions from "../actions/index";
import { deleteQuiz, getQuizAnswerOfAttendee, getQuizAnswerOfGuest, quizAnswersByAttendee, quizAnswersByGuest, quizCreate, quizData, quizGetById, quizUpdateByID } from "../../../services/quiz.service";
import { get } from "lodash";
/* eslint-disable @typescript-eslint/no-unused-expressions */

export interface IQuizSlice {
    quizList: any[]
    quizMessage: string
    quizSuccessful: boolean
    quizMeetings: any[]
    totalQuizPage: number
    totalQuizRecord: number,
    submittedQuizId: number[],
    submittedQuizzes: any[],
    userAnswers: any[]
}

const initialState: IQuizSlice = {
    quizList: [],
    quizMessage: '',
    quizSuccessful: false,
    quizMeetings: [],
    totalQuizPage: 0,
    totalQuizRecord: 0,
    submittedQuizId: [],
    submittedQuizzes: [],
    userAnswers: []
}

interface IQuizCreate {
    title: string
    maxTimeToFinish: number
    selectedQuestion: any[]
}

interface IQuizUpdate {
    ID: number
    title: string
    maxTimeToFinish: number
    selectedQuestion: any[]
}

interface IQuizData {
    hostID: any,
    pageLimit: any,
    perPage: any,
    searchVal: any,
    sortColumnProp: any,
    sortColumnOrder: any,
}

export const quizCreateHandler: any = createAsyncThunk(
    actions.CREATEQUIZ,
    (data: IQuizCreate, { rejectWithValue }) =>
        quizCreate(data?.title, data?.maxTimeToFinish, data?.selectedQuestion,).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const quizGetByIdHandler: any = createAsyncThunk(
    actions.QUIZGETBYID,
    (data: any, { rejectWithValue }) =>
        quizGetById(data).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const quizUpdateByIdHandler: any = createAsyncThunk(
    actions.QUIZUPDATE,
    (data: IQuizUpdate, { rejectWithValue }) =>
        quizUpdateByID(data?.ID, data?.title, data?.maxTimeToFinish, data?.selectedQuestion).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const deleteQuizHandler: any = createAsyncThunk(
    actions.DELETEQUIZ,
    (data: any, { rejectWithValue }) =>
        deleteQuiz(data).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const quizDataHandler: any = createAsyncThunk(
    actions.QUIZDATA,
    async (data: IQuizData, { rejectWithValue }) => {
        try {
            const response = quizData(data?.hostID, data?.pageLimit, data?.perPage, data?.searchVal, data?.sortColumnProp, data?.sortColumnOrder)
            return response
        }
        catch (error) {
            return rejectWithValue(error)
        }
    }
);
export const quizAnswerByAttendeeHandler: any = createAsyncThunk(
    actions.QUIZANSWERBYATTENDEE,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = quizAnswersByAttendee(data?.meetingId, data?.quizId, data?.questionsAnswers, data?.score, data?.quizJson)
            return response
        }
        catch (error) {
            return rejectWithValue(error)
        }
    }
);
export const quizAnswerByGuestHandler: any = createAsyncThunk(
    actions.QUIZANSWERBYGUEST,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = quizAnswersByGuest(data?.meetingId, data?.quizId, data?.guestUserId, data?.questionsAnswers, data?.score, data?.quizJson)
            return response
        }
        catch (error) {
            return rejectWithValue(error)
        }
    }
);
export const getQuizAnswerOfGuestHandler: any = createAsyncThunk(
    actions.GETQUIZANSWEROFGUEST,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = getQuizAnswerOfGuest(data?.meetingId, data?.guestUserId, data?.quizId)
            return response
        }
        catch (error) {
            return rejectWithValue(error)
        }
    }
);
export const getQuizAnswerOfAttendeeHandler: any = createAsyncThunk(
    actions.GETQUIZANSWEROFATTENDIEE,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = getQuizAnswerOfAttendee(data?.meetingId, data?.quizId)
            return response
        }
        catch (error) {
            return rejectWithValue(error)
        }
    }
);



export const quizSlice = createSlice({
    name: 'quizSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(quizCreateHandler.pending, (state, action) => { }),
            builder.addCase(quizCreateHandler.fulfilled, (state, action) => {
                state.quizMessage = action?.payload?.data?.message;
                state.quizSuccessful = true;
            }),
            builder.addCase(quizCreateHandler.rejected, (state, action) => {
                const error = action;
                const errorMessage = get(error, "payload.response.data.message");
                const networkError = get(error, "payload.message");
                if (errorMessage) {
                    state.quizSuccessful = false;
                    state.quizMessage = errorMessage;
                } else if (networkError) {
                    state.quizSuccessful = false;
                    state.quizMessage = networkError;
                }
            }),
            builder.addCase(quizGetByIdHandler.pending, (state, action) => { }),
            builder.addCase(quizGetByIdHandler.fulfilled, (state, action) => { }),
            builder.addCase(quizGetByIdHandler.rejected, (state, action) => {
                const error = action;
                const errorMessage = get(error, "payload.response.data.message");
                const networkError = get(error, "payload.message");
                if (errorMessage) {
                    state.quizSuccessful = false;
                    state.quizMessage = errorMessage;
                } else if (networkError) {
                    state.quizSuccessful = false;
                    state.quizMessage = networkError;
                }
            }),
            builder.addCase(quizUpdateByIdHandler.pending, (state, action) => { }),
            builder.addCase(quizUpdateByIdHandler.fulfilled, (state, action) => {
                state.quizMessage = action.payload?.data?.message;
                state.quizSuccessful = true;
            }),
            builder.addCase(quizUpdateByIdHandler.rejected, (state, action) => {
                const error = action;
                const errorMessage = get(error, "payload.response.data.message");
                const networkError = get(error, "payload.message");
                if (errorMessage) {
                    state.quizSuccessful = false;
                    state.quizMessage = errorMessage;
                } else if (networkError) {
                    state.quizSuccessful = false;
                    state.quizMessage = networkError;
                }
            });
        builder.addCase(quizDataHandler.pending, (state, action) => { }),
            builder.addCase(quizDataHandler.fulfilled, (state, action) => {
                state.quizList = action.payload.data;
                state.totalQuizPage = action.payload.totalPages;
                state.totalQuizRecord = action.payload.totalItems;
            }),
            builder.addCase(quizDataHandler.rejected, (state, action) => { }),
            builder.addCase(deleteQuizHandler.pending, (state, action) => { }),
            builder.addCase(deleteQuizHandler.fulfilled, (state, action) => { }),
            builder.addCase(deleteQuizHandler.rejected, (state, action) => { });
        builder.addCase(quizAnswerByGuestHandler.pending, (state, action) => { }),
            builder.addCase(quizAnswerByGuestHandler.fulfilled, (state, action) => { }),
            builder.addCase(quizAnswerByGuestHandler.rejected, (state, action) => { });
        builder.addCase(quizAnswerByAttendeeHandler.pending, (state, action) => { }),
            builder.addCase(quizAnswerByAttendeeHandler.fulfilled, (state, action) => { }),
            builder.addCase(quizAnswerByAttendeeHandler.rejected, (state, action) => { });
        builder.addCase(getQuizAnswerOfGuestHandler.pending, (state, action) => { }),
            builder.addCase(getQuizAnswerOfGuestHandler.fulfilled, (state, action) => { }),
            builder.addCase(getQuizAnswerOfGuestHandler.rejected, (state, action) => { })
    },
})

export const { } = quizSlice.actions;
export default quizSlice.reducer;