import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { meetingData } from "../../services/meeting.service";
import DialogBox from "../components/DeleteBox";
import PaginationBox from "../components/Pagination";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

interface MeetingCardProps {
  title: string;
  meetingEdit: any;
  shareMeeting: any;
  selectFilterData: any;
  getMeetingDetails: any;
  viewType: any;
  updateMeetingState: any;
  startMeetingHandler: any;
  deleteMeetingHandler: any;
  joinMeetingHandler: any;
  btnMeetingQuestionSendViaEmail: any

}

const MeetingCard: FC<MeetingCardProps> = ({ title, meetingEdit, shareMeeting, getMeetingDetails, selectFilterData, viewType, updateMeetingState, startMeetingHandler, deleteMeetingHandler, joinMeetingHandler, btnMeetingQuestionSendViaEmail }) => {
  const { t } = useTranslation('common');
  const { user } = useSelector((state: RootState) => state.auth)
  const userId = user?.user?.id
  const [showDelete, setShowDelete] = useState(false);
  const [PollDeleteID, setPollDeleteID] = useState();
  const [meetingList, setMeetingData] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState();
  const [pageSize, setpageSize] = useState(1);
  const [perPage, setperPage] = useState(9);
  const [totalRecord, setTotalRecord] = useState<any>();
  const pageLimitOptions = [5, 10, 15, 20]
  const [filterValue, setFilterValue] = useState('');
  const [endItem, setendItem] = useState(0);
  const [startItem, setStartItem] = useState(1);
  const [filterData, setfilterData] = useState("recent");
  const [sortColumn, setsortColumn] = useState<any>({ order: "desc", prop: "createdAt" });

  const getMeetingData = async (userId: any, pageLimit: number, perPage: number, filterData: any, searchVal: string, sortColumn: any) => {
    const meetingDataList = await meetingData(userId, pageLimit, perPage, filterData, searchVal, sortColumn.prop, sortColumn.order)
    setMeetingData(meetingDataList.data);
    setTotalPage(meetingDataList.totalPages)
    setpageSize(meetingDataList.currentPage)
    setTotalRecord(meetingDataList.totalItems)
  };

  useEffect(() => {
    getMeetingData(userId, pageSize, perPage, selectFilterData, filterValue, sortColumn,);
  }, [perPage, pageSize, selectFilterData, filterValue, viewType, updateMeetingState]);

  const deleteConfirmHandler = (id: any) => {
    setShowDelete(true)
    setPollDeleteID(id)
  }

  const onFilterChange = (e: any) => {
    setFilterValue(e.target.value)
  }
  const onClearSearch = (e: any) => {
    setFilterValue('')
  }
  const handlePrevPage = (e: number) => {
    setpageSize((e));
    setStartItem((startItem - perPage))
    totalrecordHandle()
  };

  const handleNextPage = (e: number) => {
    setpageSize((e));
    setStartItem(endItem + 1)
    totalrecordHandle()
  };
  const handlePageClick = (e: any) => {
    setpageSize(e);
    setStartItem(((e * perPage) + 1) - perPage)
    totalrecordHandle()
  }
  const totalrecordHandle = () => {
    var totalRecordTemp = ((startItem - 1) + perPage);
    if (totalRecordTemp > totalRecord) {
      totalRecordTemp = totalRecord;
    }
    setendItem(totalRecordTemp)
  }

  useEffect(() => {
    totalrecordHandle()
  }, [startItem, endItem, totalRecord]);

  return (
    <div className="bg-white p-3 rounded-bottom">
      <div className="d-flex justify-content-between pt-2 pb-3">
        <div></div>
        <div>
          <div className="input-group">
            <input type="text" id="searchtxt" placeholder={t('searchplaceholder.placeholder')} value={filterValue} onChange={(e) => onFilterChange(e)} className="form-control" />
            <button type="button" onClick={onClearSearch} aria-label="Clear filter" className="btn btn-primary">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" className="svg-inline--fa fa-xmark fa-solid fa-times fa-fw" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path></svg>
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        {meetingList.length === 0 && (
          <>
            <div className="font-22 fw-medium mb-3" >{t('myMeeting.dataNotFound')}</div>
          </>
        )}

        {!!meetingList &&
          meetingList.length > 0 &&
          meetingList.map((meeting, index) => (
            <div className="col-12 col-lg-6 col-xxl-4 mb-4" key={index}>
              <div className="card h-100 shadow">
                <div className="card-body">
                  <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                      <img
                        className="img-fluid"
                        src="/images/meeting.svg"
                        alt="meeting"
                      />
                    </div>
                    <div>
                      {(meeting.status === "created") && (
                        <div>
                          <button
                            onClick={() =>
                              startMeetingHandler(
                                meeting.id,
                                "inProgress"
                              )
                            }
                            className="btn btn-primary rounded-45 mw-120"
                          >
                            {t('myMeeting.btnStartMeeting')}
                          </button>
                        </div>
                      )}

                      {(meeting.status === "started" || meeting.status === "inProgress") && (
                        <div>
                          <button onClick={() =>
                            joinMeetingHandler(
                              meeting.id,
                              "inProgress"
                            )
                          } className="btn btn-primary rounded-45 mw-120">
                            {t('myMeeting.btnJoinMeeting')}
                          </button>
                        </div>
                      )}

                      {(meeting.status === "ended") && (
                        <div className='d-flex justify-content-end gap-2'>
                          <button
                            className="btn btn-outline-primary d-flex align-items-center gap-2"
                            onClick={() => btnMeetingQuestionSendViaEmail(meeting.id)}
                            title={t('pastMeetingDetails.btnSendEmail')}
                          >
                            <i className="fas fa-envelope"></i>
                            <span className="d-none d-sm-inline text-nowrap">{t('pastMeetingDetails.btnSendEmail')}</span>
                          </button>
                          <button
                            className="btn btn-primary rounded-45 d-flex align-items-center gap-2"
                            onClick={() => getMeetingDetails(meeting.id)}
                          >
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                            <span className="d-none d-sm-inline text-nowrap">{t('myMeeting.btnDetailMeeting')}</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="d-flex flex-wrap align-items-center font-20 mb-3">
                      <label className="form-label mb-0 mw-200">{t('myMeeting.lbltitle')}</label>
                      <div className="fw-bold flex-fill">{meeting.title}</div>
                    </div>
                    {(meeting.status !== "ended") && (
                      <div className="d-flex flex-wrap align-items-center font-20 mb-3">
                        <label className="form-label mb-0 mw-200">{t('myMeeting.lblCreateAt')}</label>
                        <div className="fw-bold flex-fill">
                          {moment(new Date(meeting.createdAt)).format(
                            "lll"
                          )}
                        </div>
                      </div>
                    )}
                    {(meeting.status !== "ended") && (
                      <div className="d-flex flex-wrap align-items-center font-20 mb-3">
                        <label className="form-label mb-0 mw-200">{t('myMeeting.lblStartAt')}</label>
                        <div className="fw-bold flex-fill">
                          {moment(new Date(meeting.startTime)).format(
                            "lll"
                          )}
                        </div>
                      </div>
                    )}
                    {(meeting.status === "ended") && (
                      <div className="d-flex flex-wrap align-items-center font-20 mb-3">
                        <label className="form-label mb-0 mw-200">Started At</label>
                        <div className="fw-bold flex-fill">
                          {moment(new Date(meeting.startTime)).format(
                            "lll"
                          )}
                        </div>
                      </div>
                    )}
                    {(meeting.status === "ended") && (
                      <div className="d-flex flex-wrap align-items-center font-20 mb-3">
                        <label className="form-label mb-0 mw-200">Ended At</label>
                        <div className="fw-bold flex-fill">
                          {moment(new Date(meeting.endedAt)).format(
                            "lll"
                          )}
                        </div>
                      </div>
                    )}


                    <div className="d-flex flex-wrap align-items-center font-20 mb-3">
                      <label className="form-label mb-0 mw-200">{t('common.Password')}</label>
                      <div className="fw-bold flex-fill">
                        {meeting.password ? (
                          <>{meeting.password}</>
                        ) : (
                          <>-</>
                        )}</div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center font-20 mb-3">
                      <label className="form-label mb-0 mw-200">{t('common.lblGeoFence')}</label>
                      <div className="fw-bold flex-fill">
                        {meeting.isGeoFenceProtected ? (
                          <div className='fw-bold flex-fill'>{t('common.lblYes')}</div>
                        ) : (
                          <div className='fw-bold flex-fill'>{t('common.lblNo')}</div>
                        )
                        }
                      </div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center font-20 mb-3">
                      <label className="form-label mb-0 mw-200">{t('myMeeting.lblCode')}</label>
                      <div className="fw-bold flex-fill">{meeting.code}</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mb-4">
                    <div className="text-muted">Created {moment(new Date(meeting.createdAt)).fromNow()}</div>
                  </div>
                  <div className="d-flex flex-wrap align-items-center justify-content-between">

                    <div className="d-flex align-items-center gap-2">
                      {(meeting.status === "created") && (
                        <>
                          <button
                            onClick={(e) => { meetingEdit(e, meeting.id) }}
                            className="btn btn-primary-light rounded-1 size-38"
                          >
                            <i className="fa-solid fa-pen text-primary"></i>
                          </button>
                          <button
                            onClick={() =>
                              deleteConfirmHandler(meeting.id)
                            }
                            className="btn btn-primary-light rounded-1 size-38"
                          >
                            <i className="fa-solid fa-trash text-primary"></i>
                          </button>
                        </>
                      )}
                    </div>

                    <div>
                      {(meeting.status !== "ended") && (
                        <button
                          data-id={meeting.id}
                          className="btn btn-link text-decoration-none size-38"
                          onClick={(e) => { shareMeeting(e, meeting.id) }}
                        >
                          <i className="fa-solid fa-share-nodes fa-2xl"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="d-flex justify-content-sm-between gap-3 align-items-center flex-wrap justify-content-center">
        <PaginationBox
          filteredDataLength={perPage}
          startItem={startItem}
          totalPage={totalPage}
          endItem={endItem}
          currentPage={pageSize}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          handlePageClick={handlePageClick}
          rowsPerPageOptions={pageLimitOptions}
          rowsPerPage={perPage}
          totalRecord={totalRecord}
        />
      </div>


      <DialogBox show={showDelete} clickOk={() => { deleteMeetingHandler(PollDeleteID); setShowDelete(false) }} clickCancel={() => { setShowDelete(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltMeetingquestion')} />
    </div>
  )
};
export default MeetingCard;