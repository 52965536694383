import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, useParams } from "react-router-dom";
import { IQuizForm } from "../../types/quiz.type";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { quizCreateHandler, quizGetByIdHandler, quizUpdateByIdHandler } from '../store/slices/quizSlice'
import { selectedQuestionHandler } from "../store/slices/pollSlice";
import * as ACTION from "../store/actions/index";
import QuestionContainer from "../components/ManageQuestions/QuestionContainer";
import { DropArea } from "../components/DropArea";
import SelectedQuestionList from "../components/ManageQuestions/SelectedQuestionList";

const QuizDetails = () => {
  const dispatch = useDispatch()
  let navigate = useNavigate();
  const { quizMessage, quizSuccessful } = useSelector((state: RootState) => state.quiz)
  const { selectedQuestion } = useSelector((state: RootState) => state.poll)
  const { questionmessage } = useSelector((state: RootState) => state.question)

  const { t } = useTranslation("common");
  let { quizId } = useParams();

  const createMode = !!quizId && quizId === "0" ? true : false
  const initialValues: IQuizForm = {
    title: "",
    maxTimeToFinish: 1
  };

  const [currentQuiz, setQuizData] = useState(initialValues);
  const [quizMeetings, setQuizMeetings] = useState<any[]>([]);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(t("validation.requiredField"))
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          return originalValue.trim();
        }
        return value;
      })
      .matches(/^[a-zA-Z0-9]/, t("validation.specialcharacterQuizTitle"))
      .strict(true)
      .test(
        "len",
        t('validation.minlengthTitle'),
        (val: any) =>
          val &&
          val.toString().length >= 3
      )
      .test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val &&
          val.trim() !== ''
      ),
    maxTimeToFinish: Yup.string()
      .required('Please enter a number')
      .test('is-positive-number', 'Time must be between 1 and 60 minutes', (value: any) => {
        const numValue = parseFloat(value);
        return !isNaN(numValue) && numValue >= 1 && numValue <= 60;
      })
  });

  const handleQuizCreate = async (formValue: IQuizForm) => {
    let { title, maxTimeToFinish } = formValue;
    title = title.trim();
    if (!createMode) {
      const data = {
        ID: quizId, title, maxTimeToFinish, selectedQuestion
      }
      await dispatch(quizUpdateByIdHandler(data)).then((res: any) => {
        if (res?.type == `${ACTION.QUIZUPDATE}/${ACTION.FULFILLED}`) {
          toast.success(t("toastMessage.quizUpdate"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          navigate("/quiz");
        }
        else if (res.type == `${ACTION.QUIZUPDATE}/${ACTION.REJECTED}`) {
          if (res?.payload?.response?.data?.statusCode == 403) {
            toast.error(t("toastMessage.quizAlredyExist"), {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          } else {
            toast.error(t("toastMessage.quizQuestionError"), {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          }
        }
      })
    } else {
      const formValue = { title, selectedQuestion, maxTimeToFinish }
      await dispatch(quizCreateHandler(formValue)).then((res: any) => {
        if (res?.type == `${ACTION.CREATEQUIZ}/${ACTION.FULFILLED}`) {
          toast.success(t("toastMessage.quizCreate"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          navigate("/quiz");
        } else {
          toast.error(quizMessage)
        }
      })
    }
  };

  const getQuizDetailByQuizCode = async (quizId: string) => {
    let quizDetails = await dispatch(quizGetByIdHandler(quizId))
    if (quizDetails?.payload?.status === 200) {

      setQuizData(quizDetails?.payload?.data);
      let questionData: any = [];
      quizDetails.payload?.data.questions.forEach((questionValue: any) => {
        questionData.push(questionValue.question);
      });
      dispatch(selectedQuestionHandler(questionData))
    }
  };

  useEffect(() => {
    if (!!quizId && !createMode) {
      getQuizDetailByQuizCode(quizId);
    }
  }, [quizId]);


  useEffect(() => {
    return (() => {
      if (!!quizId && createMode) {
        dispatch(selectedQuestionHandler([]))
      }
    })
  }, []);

  return (
    <>
      <div className="container-fluid">
        <h2 className="font-26 fw-semibold text-decoration-none text-dark mb-4">{createMode ? t("quiz.btnCreateQuiz") : t("quizDetails.title")}</h2>
        <div className="quiz">
          <div className="row">
            <div className="col-xl-7 mb-4">
              <div className="card border-0 h-100">
                <Formik
                  initialValues={currentQuiz}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  onSubmit={handleQuizCreate}>
                  <Form>
                    <div className="card-body">
                      <div className="d-flex flex-wrap justify-content-between gap-2 mb-4">
                        <div className="font-20 fw-semibold">
                          {t("quizDetails.title")}
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <Link
                            to="/quiz"
                            className="btn btn-outline-danger rounded-45 mw-120">
                            {t("common.cancel")}
                          </Link>
                          <button
                            className="btn btn-primary rounded-45 mw-120"
                            type="submit">
                            {createMode
                              ? t("quizDetails.btnSave")
                              : t("common.update")}
                          </button>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="quiz-title" className="form-label">
                          {t("quizDetails.quizTitle")}
                        </label>
                        <Field
                          type="text"
                          name="title"
                          className="form-control"
                          id="quiz-title"
                          placeholder={t(
                            "quizDetails.placeholderQuizTitle"
                          )}></Field>
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger"
                        />
                        {questionmessage && (
                          <div className="form-group">
                            <div
                              className={
                                quizSuccessful
                                  ? "alert alert-success"
                                  : "alert alert-danger p-2 rounded-0 border-0"
                              }
                              role="alert">
                              {questionmessage}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label fw-medium font-20">
                          {t("quizDetails.attachedTitle")}
                        </label>
                        <div className="d-flex flex-wrap align-items-center gap-2">
                          {quizMeetings.length === 0 && (
                            <div className="d-flex flex-wrap align-items-center gap-2">
                              {t("quizDetails.NoDataAvailable")}
                            </div>
                          )}
                          {!!quizMeetings &&
                            quizMeetings.length > 0 &&
                            quizMeetings.map((meetingData: any) => {
                              return (
                                <div
                                  className="badge rounded-pill badge-pill py-2 fw-medium mw-120"
                                  key={"meeting_" + meetingData.meetingId}>
                                  {meetingData.meetingDetails.title}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          {t("quizDetails.maxTimeToFinish")}
                        </label>
                        <div className="position-relative">

                          <Field
                            type="number"
                            name="maxTimeToFinish"
                            className="form-control"
                            min={0}
                            max={60}
                          >
                          </Field>
                          <div className="position-absolute translate-middle-y top-50 end-0 text-muted me-4 pe-2">minutes</div>
                        </div>
                        <ErrorMessage
                          name="maxTimeToFinish"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </Form>
                </Formik>

                <DropArea />
                <SelectedQuestionList meetingsLength={quizMeetings.length} />

              </div>
            </div>
            <div className="col-xl-5 mb-4">
              <QuestionContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizDetails;
