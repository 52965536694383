import axios from "axios";
import languageHeader from "./language-header";


const API_URL = process.env.REACT_APP_API_URL

export const register = async (firstName: string, lastName: string, email: string, password: string) => {
    return axios.post(API_URL + "auth/signup", {
        firstName,
        lastName,
        email,
        password,
    }, { headers: languageHeader() }).then((response) => {
        return response.data;
    });;
};

export const login = async (username: string, password: string) => {
    return axios
        .post(API_URL + "auth/login", {
            username,
            password,
        }, { headers: languageHeader() })
        .then((response) => {
            return response.data;
        });
};

export const loginWithGoogle = async (credentialResponse: any) => {
    return axios
        .post(API_URL + "auth/googlelogin", {
            token: credentialResponse.credential
        }, { headers: languageHeader() })
        .then((response) => {
            return response.data;
        });
};

export const loginWithMicrosoft = async (data: any) => {
    return axios
        .post(API_URL + "auth/microsoftLogin", {
            data: data
        })
        .then((response) => {
            return response.data;
        });
};

export const logout = () => {
    sessionStorage.clear()
};