import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface IModal {
    waitingListModal: boolean
    interestedListModal: boolean
}

const initialState: IModal = {
    waitingListModal: false,
    interestedListModal: false
}

export const modalSlice = createSlice({
    name: 'modalSlice',
    initialState,
    reducers: {
        waitingListModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.waitingListModal = payload;
        },
        interestedListModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.interestedListModal = payload;
        }
    }
})

export const { waitingListModalHandler, interestedListModalHandler } = modalSlice.actions;
export default modalSlice.reducer;