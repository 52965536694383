
import React, { useEffect, useState } from 'react';
import { feedbackForm } from '../../services/feedback.service';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { currentMeetingHandler } from '../store/slices/meetingSlice';
import { feedbackFormHandler, miniSidebarHandler } from '../store/slices/settingsSlice';
import { logoutGuestUserHandler } from '../store/slices/guestUserSlice';


const FeedbackForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { currentMeeting } = useSelector((state: RootState) => state.meeting)
    const { user, isUser } = useSelector((state: RootState) => state.auth)
    const { guestUser, isGuest, rememberMe } = useSelector((state: RootState) => state.guestUser)
    const userId = user?.user?.id
    const currentMeetingId = currentMeeting?.id

    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [userComment, setuserComment] = useState('');
    const [successful, setSuccessful] = useState<boolean>(false);
    const [ratingValidation, setRatingValidation] = useState<string>("");
    const [feedbacksuccessful, setFeedbackSuccessful] = useState<boolean>(false);
    const [feedbackMessage, setFeedbackMessage] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [guestUserID, setGuestUserID] = useState<number>(0);
    const { t } = useTranslation('common');

    useEffect(() => {
        dispatch(feedbackFormHandler(true))
        dispatch(miniSidebarHandler(true))
        if (isUser) {
            setUserName(user.user.firstName + " " + user.user.lastName)
        }
        else if (isGuest) {
            setGuestUserID(guestUser?.id)
            if (guestUser?.isAnonymous === true) {
                setUserName(t('common.lblAnonymous'))
            }
            else {
                setUserName(guestUser?.name)
            }
        }
    }, [])

    const submitFeedback = () => {
        if (typeof currentMeetingId !== 'undefined') {
            if (rating > 0) {
                setRatingValidation('')
                feedbackForm(currentMeetingId, userId, guestUserID, rating, userComment).then(
                    (response) => {
                        setFeedbackMessage(response.data.message);
                        toast.success(t('toastMessage.feedbackSubmit'), {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        isGuest && !rememberMe && dispatch(logoutGuestUserHandler())
                        dispatch(currentMeetingHandler(null))
                        dispatch(feedbackFormHandler(false))
                        if (isUser) {
                            navigate("/dashboard")
                        } else {
                            navigate("/")
                        }
                    },
                    (error) => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        setFeedbackMessage(resMessage);
                        setFeedbackSuccessful(false);
                        dispatch(feedbackFormHandler(false))
                    }
                )
            }
            else {
                setRatingValidation(t('validation.requiredField'))
                setSuccessful(false);
            }
        } else {
            if (isUser) {
                navigate("/dashboard")
            } else {
                navigate("/")
            }
        }
    }

    const handleSkipClick = () => {
        isGuest && !rememberMe && dispatch(logoutGuestUserHandler())
        dispatch(currentMeetingHandler(null))
        dispatch(feedbackFormHandler(false))
    };

    return (
        <div className='container-fluid pt-4'>
            <div className='row justify-content-center'>
                <div className='col-xl-4 col-lg-5 col-md-6 col-sm-10'>
                    <div className='card shadow'>
                        <div className='card-body'>
                            <div className='text-center mb-3'>
                                <div className='font-24 mb-2 fw-medium'>{t('feedBack.thankyouTitle')}</div>
                            </div>
                            <div className='text-center mb-5'>
                                <div className='font-24 mb-3 fw-medium'>{t('feedBack.title')}</div>
                                <p>{t('feedBack.descFeedBack')}</p>
                            </div>
                            <div className='mb-3'>
                                <label>{t('feedBack.lblName')}</label>
                                <input type="text" className='form-control' readOnly disabled defaultValue={userName} />
                            </div>
                            <div className='mb-3'>
                                <label className='mb-2 fw-medium'>{t('feedBack.lblRating')}<small>({t('feedBack.lblOutOf')})</small></label>
                                <div className="star-rating">
                                    {[...Array(10)].map((star, index) => {
                                        index += 1;
                                        return (
                                            <button
                                                type="button"
                                                key={index}
                                                className={index <= (hover || rating) ? "on" : "off"}
                                                onClick={() => setRating(index)}
                                                onMouseEnter={() => setHover(index)}
                                                onDoubleClick={() => { setRating(0); setHover(0); }}
                                                onMouseLeave={() => setHover(rating)}
                                            >
                                                <i className='fa fa-star'></i>
                                            </button>
                                        );
                                    })}
                                </div>
                                {ratingValidation && (
                                    <div className="form-group">
                                        <div
                                            className={
                                                successful ? "alert alert-success" : "alert alert-danger p-2 rounded-0 border-0"
                                            }
                                            role="alert"
                                        >
                                            {ratingValidation}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='mb-3'>
                                <label className='fw-medium mb-2'>{t('feedBack.lblComment')} <small>({t('feedBack.lblOptional')})</small></label>
                                <textarea onChange={(e: any) => { setuserComment(e.target.value) }} className='form-control' rows={2} placeholder={t('feedBack.placeholderComment')}></textarea>
                            </div>
                            {feedbackMessage && (
                                <div className="form-group">
                                    <div
                                        className={
                                            feedbacksuccessful ? "alert alert-success" : "alert alert-danger p-2 rounded-0 border-0"
                                        }
                                        role="alert"
                                    >
                                        {feedbackMessage}
                                    </div>
                                </div>
                            )}
                            <div className='text-end'>
                                <Link to='/' className='btn btn-outline-danger me-2 btn-sm' type='submit' onClick={handleSkipClick}>{t('feedBack.btnSkip')}</Link>
                                <button onClick={submitFeedback} className='btn btn-primary btn-sm' type='submit'>{t('feedBack.btnSubmit')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedbackForm;