import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Link } from 'react-router-dom';
import { dashboardHandler } from '../store/slices/dashboardSlice';
import { feedbackFormHandler } from '../store/slices/settingsSlice';
import IconQuiz from "../../images/icon-quiz.png"
import IconJoinMeeting from "../../images/join-meet.svg"
import IconHostMeeting from "../../images/host-meet.svg"
import IconPoll from "../../images/poll.svg"
import IconQuestions from "../../images/que-list.svg"


const Dashboard = () => {
  const { t } = useTranslation('common');
  const { user } = useSelector((state: RootState) => state.auth)
  const userId = user?.user?.id


  const dashboardState: any = useSelector((state: RootState) => state.dashboard)
  const dispatch = useDispatch()

  const getDashboardCount = async (userId: any) => {
    if (userId != undefined) {
      try {
        const id: any = userId
        await dispatch(dashboardHandler(id))
      } catch (err) {
        console.log(err);
      }
    }
  };
  const createMeetingModal = () => {
    const createZMeetinTigger: any = document.getElementById("btn-create-meeting")
    createZMeetinTigger.click()
  }

  useEffect(() => {
    getDashboardCount(userId);
  }, [userId]);

  useEffect(() => {
    dispatch(feedbackFormHandler(false))
  }, []);

  return (
    <div className="container-fluid h-100">
      <div className="dashboard-layout h-100 d-flex flex-column">
        <div className="mb-4 d-flex justify-content-between">
          <div className='font-26 fw-semibold'>
            {t('dashbord.headerDashbord')}
          </div>
          <div>
            <Link to="#" className="btn btn-primary" id="tour_createMeeting" title='Create a Meeting' onClick={createMeetingModal}>
              <span className="d-flex align-items-center gap-4"  >
                <span className="nav-link-label flex-fill"><i className='fa fa-plus'></i> <span className='d-none d-sm-inline'>{t('common.createMeeting')}</span></span>
              </span>
            </Link>
          </div>
        </div>
        <div className="row flex-fill">
          <div className="col-12">

            <div className="d-flex flex-wrap align-items-center mx-n2 meeting-card">
              <div className="card border-0 text-light bg-secondary mx-2 mb-3 join-meet">
                <div className="card-body p-4 p-md-4">
                  <div className="mb-2">
                    <img className="img-fluid" src={IconJoinMeeting} alt="join-meet" />
                  </div>
                  <div className="fw-semibold count">{dashboardState.dashboardDataCount[0].findJoinedMeetingData}</div>
                  <div className="fw-semibold font-20">{t('dashbord.lblMeetingsJoin')}</div>
                </div>
              </div>
              <div className="card border-0 text-light mx-2 mb-3 bg-success host-meet">
                <div className="card-body p-4 p-md-4">
                  <div className="mb-2">
                    <img className="img-fluid" src={IconHostMeeting} alt="host-meet" />
                  </div>
                  <div className="fw-semibold count">{dashboardState.dashboardDataCount[1].findHostedMeetingData}</div>
                  <div className="fw-semibold font-20">{t('dashbord.lblMeetingHost')}</div>
                </div>
                <Link to='/meeting' className='stretched-link'></Link>
              </div>
              <div className="card border-0 text-light mx-2 mb-3 bg-warning poll">
                <div className="card-body p-4 p-md-4">
                  <div className="mb-2">
                    <img className="img-fluid" src={IconPoll} alt="poll" />
                  </div>
                  <div className="fw-semibold count">{dashboardState.dashboardDataCount[2].findPollData}</div>
                  <div className="fw-semibold font-20">{t('common.Poll')}</div>
                </div>
                <Link to='/poll' className='stretched-link'></Link>
              </div>
              <div className="card border-0 text-light mx-2 mb-3 bg-primary que-list">
                <div className="card-body p-4 p-md-4">
                  <div className="mb-2">
                    <img className="img-fluid" src={IconQuestions} alt="que-list" />
                  </div>
                  <div className="fw-semibold count">{dashboardState.dashboardDataCount[3].findQuestionData}</div>
                  <div className="fw-semibold font-20">{t('common.question')}</div>
                </div>
                <Link to='/poll/0' className='stretched-link'></Link>
              </div>
              <div className="card border-0 text-light mx-2 mb-3 bg-danger que-list">
                <div className="card-body p-4 p-md-4">
                  <div className="mb-2">
                    <img className="img-fluid" src={IconQuiz} alt="que-list" />
                  </div>
                  <div className="fw-semibold count">{dashboardState.dashboardDataCount[4].findQuizData}</div>
                  <div className="fw-semibold font-20">{t('common.Quiz')}</div>
                </div>
                <Link to='/quiz' className='stretched-link'></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;