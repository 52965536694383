import React, { useState, useEffect } from 'react';
import { IUserLogin } from "../../types/user.type";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import * as ACTION from "../store/actions/index";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { signInHandler, LoginWithGoogle, MicrosoftLogins, signInMessageHandler, userHandler } from '../store/slices/authSlice';
import { RootState } from '../store/store';
import { Link, useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import MicrosoftLogin from "react-microsoft-login";
import { logoutGuestUserHandler } from '../store/slices/guestUserSlice';

const Signin = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    const [successful, setSuccessful] = useState<boolean>(false);
    const [passwordType, setPasswordType] = useState("password");
    const signInState = useSelector((state: RootState) => state.auth)
    const { signInMessage: message, signInIsLoading: loading } = signInState

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    let navigate = useNavigate();
    const initialValues: IUserLogin = {
        username: "",
        password: "",
    };

    useEffect(() => {
        dispatch(signInMessageHandler())
    }, [])

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .email(t('validation.notValidEmail')),
        password: Yup.string()
            .required(t('validation.requiredField')).transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            }),
    });



    const handleLogin = async (formValue: { username: string; password: string }) => {

        await dispatch(signInHandler(formValue)).then((res: any) => {
            if (res?.type === `${ACTION.LOGIN}/${ACTION.FULFILLED}`) {
                toast.success(t('toastMessage.loginSuccessFul'))
                dispatch(logoutGuestUserHandler())
                navigate("/dashboard");
            }
            else {
                if (res?.type === `${ACTION.LOGIN}/${ACTION.REJECTED}`) {
                    dispatch(signInMessageHandler(res?.payload?.response?.data?.message))
                    toast.error(res?.payload?.response?.data?.message)
                } else {
                    toast.error(t('toastMessage.loginFail'))
                }
            }
        })

    };

    const googleLogin = async (credentialResponse: any) => {

        await dispatch(LoginWithGoogle(credentialResponse)).then((res: any) => {
            if (res?.type === `${ACTION.GOOGLELOGIN}/${ACTION.FULFILLED}`) {
                dispatch(logoutGuestUserHandler())
                navigate("/dashboard");
            }
            else {
                toast.error(t('toastMessage.loginFail'))
            }
        })
    }

    const microsoftLogin = async (err: any, data: any) => {
        if (err) {
            toast.error(t('toastMessage.loginFail'))
        } else {

            await dispatch(MicrosoftLogins(data)).then((res: any) => {
                if (res?.type === `${ACTION.MICROSOFTLOGIN}/${ACTION.FULFILLED}`) {
                    dispatch(logoutGuestUserHandler())
                    navigate("/dashboard");
                }
                else {
                    toast.error(t('toastMessage.loginFail'))
                }
            })
        };
    }

    return (
        <div className="signup-wrap d-flex flex-column">
            <div className="d-flex align-items-center justify-content-center flex-fill signin-form">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 col-xxl-4 col-xl-6 my-1">
                            <div className="card border-0 rounded-4">
                                <div className="card-body d-flex flex-column flex-fill justify-content-center px-3 px-sm-5">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleLogin}>
                                        <Form>
                                            <h1 className="form-type text-center">{t('header.btnLogin')}</h1>
                                            <div className="signin-option text-center my-4">
                                                <div className='mb-3'>
                                                    <GoogleOAuthProvider clientId="903733391227-ppshltpmnlakp6jno0k5ugd2h4qor4g0.apps.googleusercontent.com">
                                                        <GoogleLogin
                                                            onSuccess={async (credentialResponse: any) => {
                                                                googleLogin(credentialResponse)
                                                            }}
                                                            onError={() => {
                                                                console.log('Login Failed');
                                                            }}
                                                        />
                                                    </GoogleOAuthProvider>
                                                </div>
                                                <MicrosoftLogin clientId={process.env.REACT_APP_MICROSOFT_APP_ID || ''} authCallback={microsoftLogin}
                                                />
                                            </div>
                                            <div className="mb-4 text-center"><small>{t('signIn.title')}</small></div>
                                            {message && (
                                                <div className="form-group">
                                                    <div
                                                        className={
                                                            successful ? "alert alert-success" : "alert alert-danger p-2 border-0"
                                                        }
                                                        role="alert"
                                                    >
                                                        {message}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="mb-3">
                                                <Field name="username" type="text" className="form-control"
                                                    placeholder={t('signIn.placeholderUsername')} />
                                                <ErrorMessage name="username" component="div" className="text-danger txt-error" />
                                            </div>
                                            <div className="password-toggle mb-4">
                                                <Field name="password" type={passwordType} className="form-control password"
                                                    placeholder={t('common.Password')} />
                                                <button type='button' onClick={togglePassword} className="password-toggle-btn btn btn-link p-0 text-black">
                                                    <i className={`fa-solid ${passwordType === 'password' ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                                </button>
                                                <ErrorMessage name="password" component="div" className="text-danger txt-error" />
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="rememberme" />
                                                    <label className="form-check-label" htmlFor="rememberme">
                                                        {t('signIn.lblRememberMe')}
                                                    </label>
                                                </div>
                                                <div>

                                                    <button className="btn btn-sm btn-primary" type="submit" disabled={loading}>
                                                        {loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                        {t('signIn.btnSignIn')}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <Link to="/signup" className="text-decoration-none">{t('signIn.linkSignUp')}</Link>
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signin;