import React, { useEffect, useState } from "react";
import {
  DatatableWrapper,
  Filter,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import DialogBox from "../components/DeleteBox";
import { useTranslation } from "react-i18next";
import { DeletePollHandler, PollDataHandler, selectedQuestionHandler } from "../store/slices/pollSlice";
import { Link, useNavigate } from 'react-router-dom';
import PaginationBox from '../components/Pagination';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";

const PollList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth)
  const { pollList, totalPage, totalRecord } = useSelector((state: RootState) => state.poll);
  const userId = user?.user?.id

  const { t } = useTranslation('common');
  const [showDelete, setShowDelete] = useState(false);
  const [PollDeleteID, setPollDeleteID] = useState();
  const [pageSize, setpageSize] = useState(1); // Page number
  const [perPage, setperPage] = useState(10); // per page Limit
  const [filterValue, setFilterValue] = useState("");
  const [endItem, setendItem] = useState(0);
  const [startItem, setStartItem] = useState(1);
  const pageLimitOptions = [5, 10, 15, 20]
  const [sortColumn, setsortColumn] = useState<any>({ order: "desc", prop: "createdAt" }); // per page Limit 

  const getPollData = async (
    hostID: any,
    pageLimit: number,
    perPage: number,
    searchVal: string,
    sortColumn: any
  ) => {
    const sortColumnProp = sortColumn.prop;
    const sortColumnOrder = sortColumn.order
    const data = {
      hostID, pageLimit, perPage, searchVal, sortColumnProp, sortColumnOrder
    };
    const pollDataList = await dispatch(PollDataHandler(data));
    setpageSize(pollDataList?.payload?.currentPage)
  };

  useEffect(() => {
    getPollData(userId, pageSize, perPage, filterValue, sortColumn);
  }, [perPage, pageSize, sortColumn, filterValue]);

  const handlePrevPage = (e: number) => {
    setpageSize(e);
    setStartItem(startItem - perPage);
    totalrecordHandle();
  };

  const handleNextPage = (e: number) => {
    setpageSize(e);
    setStartItem(endItem + 1);
    totalrecordHandle();
  };

  const handlePageClick = (e: any) => {
    setpageSize(e);
    setStartItem(e * perPage + 1 - perPage);
    totalrecordHandle();
  };

  const totalrecordHandle = () => {
    var totalRecordTemp = startItem - 1 + perPage;
    if (totalRecordTemp > totalRecord) {
      totalRecordTemp = totalRecord;
    }
    setendItem(totalRecordTemp);
  };

  useEffect(() => {
    totalrecordHandle()
  }, [startItem, endItem, totalRecord, pollList]);

  const deleteConfirmHandler = (id: any) => {
    setShowDelete(true)
    setPollDeleteID(id)
  }

  const DeletePollHandlers = async (id: any) => {
    await dispatch(DeletePollHandler(id)).then((response: any) => {
      if (response?.payload?.status === 200) {
        getPollData(userId, pageSize, perPage, filterValue, sortColumn);
        toast.success(t("toastMessage.pollDelete"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    })
  };

  const EditPollHandler = async (id: any) => {
    navigate("/poll/" + id);
  };

  const header = [
    {
      title: t("poll.lblTitle"),
      prop: "title",
      isSortable: true,
      isFilterable: true,
    },
    {
      title: t("poll.lblTag"),
      prop: "tag",
      cell: (row: any) => {
        return (
          row.pollsMeetingDetails.length > 0 &&
          row.pollsMeetingDetails.map((tags: any, index: any) => {
            return (
              <div
                key={index}
                className="badge rounded-pill badge-pill py-2 fw-medium me-2 mb-2">
                <div>{tags.meetingDetails.title}</div>
              </div>
            );
          })
        );
      },
    },
    // {
    //     title: t('poll.lblStatus'), prop: 'status',
    //     cell: (row :any) => {
    //         return (
    //             <>
    //                 {row.pollsMeetingDetails.length === 0 ? (
    //                     <>

    //                     </>
    //                 ) :
    //                     (
    //                         row.pollsMeetingDetails.map((tags: any, index: any) => {
    //                             return (
    //                                 <div key={index} className="badge rounded-pill badge-pill bg-danger text-light py-2 fw-medium mw-120">
    //                                     <div>{tags.status}</div>
    //                                 </div>
    //                             )
    //                         })
    //                     )

    //                 }

    //             </>

    //         )
    //     }
    // },
    // {
    //     title: t('poll.lblVotes'), prop: 'votes',
    //     cell: (row :any) => {
    //         return (
    //             <>
    //                 <div className="fw-medium">35</div>
    //             </>
    //         )
    //     }
    // },
    {
      title: "",
      prop: "",
      cellProps: {
        className: "action-td",
      },
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex align-items-center gap-2 action-col">
              {/* <a href="/pollDetail" */}
              <button
                onClick={() => EditPollHandler(row.id)}
                className="bg-success btn bg-opacity-25 text-decoration-none rounded-1 size-38">
                <i className="fa-solid fa-pen text-success"></i>
              </button>
              {row.pollsMeetingDetails.length === 0 &&
                <button
                  onClick={() => deleteConfirmHandler(row.id)}
                  className="btn mx-2 btn-primary-light rounded-1 size-38">
                  <i className="fa-solid fa-trash-can text-danger"></i>
                </button>
              }
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className="container-fluid">
      <div className="font-26 fw-semibold mb-4">{t("common.Poll")}</div>
      <div className="poll">
        <div className="row">
          <div className="col-12">
            <div className="card border-0">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div className="font-20 fw-semibold">{t("poll.title")}</div>
                  <div>
                    <button
                      onClick={() => { navigate('/poll/0'); dispatch(selectedQuestionHandler([])) }}
                      className="btn btn-primary rounded-45 mw-120">
                      {t("poll.btnCreatePoll")}
                    </button>
                  </div>
                </div>

                <div className="poll-table">
                  <DatatableWrapper body={pollList} headers={header}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        {/* <PaginationOptions
                                                            controlledProps={{
                                                                filteredDataLength: perPage,
                                                                onRowsPerPageChange: setperPage,
                                                                rowsPerPageOptions: pageLimitOptions,
                                                                rowsPerPage: perPage
                                                            }}
                                                        /> */}
                      </div>
                      <div>
                        <Filter
                          placeholder={t("searchplaceholder.placeholder")}
                          controlledProps={{
                            filter: filterValue,
                            onFilterChange: setFilterValue,
                          }}
                        />
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <TableHeader
                          controlledProps={{
                            onSortChange: setsortColumn,
                            sortState: sortColumn,
                          }}
                        />
                        <TableBody />
                      </table>
                    </div>

                    <div className="d-flex justify-content-sm-between gap-3 align-items-center flex-wrap justify-content-center">
                      {/* <div></div>
                                                <div>
                                                    <Pagination controlledProps={{
                                                        maxPage: totalPage,
                                                        onPaginationChange: setpageSize,
                                                        currentPage: pageSize,
                                                    }} labels={{
                                                        firstPage: t('pagination.firstPage'),
                                                        lastPage: t('pagination.lastPage'),
                                                        prevPage: t('pagination.prevPage'),
                                                        nextPage: t('pagination.nextPage'),
                                                    }} />
                                                </div>
                                             */}
                      <PaginationBox
                        filteredDataLength={perPage}
                        startItem={startItem}
                        totalPage={totalPage}
                        endItem={endItem}
                        currentPage={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        handlePageClick={handlePageClick}
                        rowsPerPageOptions={pageLimitOptions}
                        rowsPerPage={perPage}
                        totalRecord={totalRecord}
                      />
                    </div>
                    {/* <Pagination controlledProps={{
                                                    maxPage: totalPage,
                                                    onPaginationChange: setpageSize,
                                                    currentPage: pageSize
                                                }} /> */}
                  </DatatableWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogBox
        show={showDelete}
        clickOk={() => {
          DeletePollHandlers(PollDeleteID);
          setShowDelete(false);
        }}
        clickCancel={() => {
          setShowDelete(false);
        }}
        btncancel={t("common.cancel")}
        btnyes={t("common.delete")}
        question={t("deleteBox.dltPoll")}
      />
    </div>
  );
};

export default PollList;
