import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import {
    DatatableWrapper,
    Filter,
    TableBody,
    TableColumnType,
    TableHeader
} from 'react-bs-datatable';

import { meetingData } from "../../services/meeting.service";
import DialogBox from '../components/DeleteBox';
import { useTranslation } from 'react-i18next';
import PaginationBox from '../components/Pagination';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

interface MeetingListProps {
    title: any;
    meetingEdit: any;
    shareMeeting: any;
    selectFilterData: any;
    viewType: any;
    updateMeetingState: any;
    startMeetingHandler: any;
    deleteMeetingHandler: any;
    joinMeetingHandler: any;
    getMeetingDetails: any;
    btnMeetingQuestionSendViaEmail: any
}

const MeetingList: FC<MeetingListProps> = ({ title, meetingEdit, shareMeeting, selectFilterData, viewType, updateMeetingState, startMeetingHandler, deleteMeetingHandler, joinMeetingHandler, getMeetingDetails, btnMeetingQuestionSendViaEmail }) => {

    const { t } = useTranslation('common');
    const { user } = useSelector((state: RootState) => state.auth)
    const userId = user?.user?.id
    const [showDelete, setShowDelete] = useState(false);
    const [meetingDeleteID, setMeetingDeleteID] = useState();
    const [meetingList, setMeetingData] = useState<any[]>([]);
    const [totalPage, setTotalPage] = useState();
    const [pageSize, setpageSize] = useState(1);
    const [perPage, setperPage] = useState(10);
    const [totalRecord, setTotalRecord] = useState<any>();
    const [endItem, setendItem] = useState(0);
    const [startItem, setStartItem] = useState(1);
    const [filterValue, setFilterValue] = useState('');
    const pageLimitOptions = [5, 10, 15, 20]
    const [sortColumn, setsortColumn] = useState<any>({ order: "desc", prop: "createdAt" });
    const { currentMeeting } = useSelector((state: RootState) => state.meeting)
    const currentMeetingId = currentMeeting?.id

    const getMeetingData = async (userId: any, pageLimit: number, perPage: number, filterData: any, searchVal: string, sortColumn: any) => {
        const meetingDataList = await meetingData(userId, pageLimit, perPage, filterData, searchVal, sortColumn.prop, sortColumn.order)
        setMeetingData(meetingDataList.data);
        setTotalPage(meetingDataList.totalPages)
        setpageSize(meetingDataList.currentPage)
        setTotalRecord(meetingDataList.totalItems)
    };

    const handlePrevPage = (e: number) => {
        setpageSize((e));
        setStartItem((startItem - perPage))
        totalrecordHandle()
    };

    const handleNextPage = (e: number) => {
        setpageSize((e));
        setStartItem(endItem + 1)
        totalrecordHandle()
    };
    const handlePageClick = (e: any) => {
        setpageSize(e);
        setStartItem(((e * perPage) + 1) - perPage)
        totalrecordHandle()
    }
    useEffect(() => {
        getMeetingData(userId, pageSize, perPage, selectFilterData, filterValue, sortColumn);

    }, [currentMeetingId, perPage, pageSize, selectFilterData, filterValue, sortColumn, viewType, updateMeetingState]);

    const totalrecordHandle = () => {
        var totalRecordTemp = ((startItem - 1) + perPage);
        if (totalRecordTemp > totalRecord) {

            totalRecordTemp = totalRecord;
        }
        setendItem(totalRecordTemp)
    }
    useEffect(() => {
        totalrecordHandle()

    }, [startItem, endItem, totalRecord]);

    const deleteConfirmHandler = (id: any) => {
        setShowDelete(true)
        setMeetingDeleteID(id)
    }

    type ArrayElementType = typeof body[number] & {
        button: any;
    };
    const header: TableColumnType<ArrayElementType>[] = [
        { title: t('myMeeting.lbltitle'), prop: 'title', isSortable: true, isFilterable: true },
        {
            title: t('myMeeting.lblCode'), prop: 'code',
            isFilterable: true,
            cell: (row) => {
                return (
                    <>
                        <div>
                            <span className='me-2'>
                                {(row.code)}
                            </span>

                        </div>

                    </>
                )
            }
        },
        {
            title: t('common.Password'), prop: 'password',
            cell: (row) => {
                return (
                    <div className='text-nowrap'>
                        {row.password ? (
                            <>{row.password}</>
                        ) : (
                            <>-</>
                        )}

                    </div>
                )
            }

        },
        {
            title: t('common.lblGeoFence'), prop: 'password',
            cell: (row) => {
                return (
                    <div className='text-nowrap'>
                        {row.isGeoFenceProtected ? (
                            <div className=''>{t('common.lblYes')}</div>
                        ) : (
                            <div className=''>{t('common.lblNo')}</div>
                        )
                        }
                    </div>
                )
            }
        },


        {
            title: t('myMeeting.lblCreateAt'), prop: 'createdAt',
            isSortable: true,
            cell: (row) => {
                return (
                    <div className='text-nowrap'>
                        {moment(new Date(row.createdAt)).format('lll')}
                    </div>
                )
            }
        },
        {
            title: t('myMeeting.lblStartAt'), prop: 'startTime',
            isSortable: true,
            cell: (row) => {
                return (
                    <div className='text-nowrap'>
                        {moment(new Date(row.startTime)).format('lll')}
                    </div>
                )
            }
        },
        {
            title: "Ended At", prop: 'endTime',
            isSortable: true,
            cell: (row) => {
                return (
                    <div className='text-nowrap'>
                        {row.endedAt ? (
                            <>{moment(new Date(row.endedAt)).format('lll')}</>
                        ) : (
                            <>-</>
                        )
                        }
                    </div>
                )
            }
        },
        {
            title: '', prop: '',
            cell: (row) => {
                if (row.status === "created") {
                    return (
                        <>
                            <div className='d-flex justify-content-end'>
                                <button onClick={(e) => { meetingEdit(e, row.id) }}
                                    className="btn btn-primary-light rounded-1 size-38">
                                    <i className="fa-solid fa-pen text-primary"></i>
                                </button>
                                <button onClick={() => deleteConfirmHandler(row.id)}
                                    className="btn mx-2 btn-primary-light rounded-1 size-38">
                                    <i className="fa-solid fa-trash text-primary"></i>
                                </button>
                                <button data-id={row.id} className="btn btn-primary-light rounded-1 size-38 mx-2" onClick={(e) => { shareMeeting(e, row.id) }}>
                                    <i className="fa-solid fa-share-nodes text-primary"></i>
                                </button>
                                <button onClick={() => startMeetingHandler(row.id, "inProgress")} className="btn btn-primary rounded-45 text-nowrap mw-120 text-nowrap">{t('myMeeting.btnStartMeeting')}
                                </button>
                            </div>
                        </>
                    );
                }
                else if (row.status === "started" || row.status === "inProgress" && row.status !== "ended") {
                    return (
                        <div className='d-flex justify-content-end'>
                            <button data-id={row.id} className="btn btn-primary-light rounded-1 size-38 mx-2" onClick={(e) => { shareMeeting(e, row.id) }}>
                                <i className="fa-solid fa-share-nodes text-primary"></i>
                            </button>
                            <button onClick={() => joinMeetingHandler(row.id, "inProgress")} className="btn btn-primary text-nowrap rounded-45 mw-120">{t('myMeeting.btnJoinMeeting')}
                            </button>
                        </div>
                    )

                } else if (row.status === "ended") {
                    return (
                        <div className='d-flex justify-content-end gap-2'>
                            <button
                                className="btn btn-outline-primary d-flex align-items-center gap-2"
                                onClick={() => btnMeetingQuestionSendViaEmail(row.id)}
                                title={t('pastMeetingDetails.btnSendEmail')}
                            >
                                <i className="fas fa-envelope"></i>
                                <span className="d-none d-sm-inline text-nowrap">{t('pastMeetingDetails.btnSendEmail')}</span>
                            </button>
                            <button
                                className="btn btn-primary rounded-45 d-flex align-items-center gap-2"
                                onClick={() => getMeetingDetails(row.id)}
                            >
                                <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                <span className="d-none d-sm-inline text-nowrap">{t('myMeeting.btnDetailMeeting')}</span>
                            </button>
                        </div>
                    );
                }
            }
        }
    ];
    let body = meetingList;
    return (
        <>
            <DatatableWrapper body={body} headers={header}>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        {/* <PaginationOptions
                            controlledProps={{
                                filteredDataLength: perPage,
                                onRowsPerPageChange: setperPage,
                                rowsPerPageOptions: pageLimitOptions,
                                rowsPerPage: perPage,
                                
                            }}
                        /> */}
                    </div>
                    <div>
                        <Filter placeholder={t('searchplaceholder.placeholder')}
                            controlledProps={{
                                filter: filterValue,
                                onFilterChange: setFilterValue,
                            }}

                        />
                    </div>
                </div>

                <div className='table-responsive'>
                    <table className='table'>
                        <TableHeader controlledProps={{
                            onSortChange: setsortColumn,
                            sortState: sortColumn
                        }} />
                        <TableBody />
                    </table>
                </div>
                <div className="d-flex justify-content-sm-between gap-3 align-items-center flex-wrap justify-content-center">


                    {/* <Pagination controlledProps={{
                            maxPage: totalPage,
                            onPaginationChange: setpageSize,
                            currentPage: pageSize,
                        }} labels={{
                            firstPage: t('pagination.firstPage'),
                            lastPage: t('pagination.lastPage'),
                            prevPage: t('pagination.prevPage'),
                            nextPage: t('pagination.nextPage'),
                        }} />
                         */}
                    <PaginationBox
                        filteredDataLength={perPage}
                        startItem={startItem}
                        totalPage={totalPage}
                        endItem={endItem}
                        currentPage={pageSize}
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        handlePageClick={handlePageClick}
                        rowsPerPageOptions={pageLimitOptions}
                        rowsPerPage={perPage}
                        totalRecord={totalRecord}
                    />

                </div>

            </DatatableWrapper>

            <DialogBox show={showDelete} clickOk={() => { deleteMeetingHandler(meetingDeleteID); setShowDelete(false) }} clickCancel={() => { setShowDelete(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltMeetingquestion')} />
        </>
    )
};

export default MeetingList;