import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { register, login, loginWithGoogle, loginWithMicrosoft } from '../../../services/auth.service'
import { t } from 'i18next'
import { get } from 'lodash'
import * as actions from '../actions/index';
/* eslint-disable @typescript-eslint/no-unused-expressions */

export interface AuthState {
    signUpIsLoading: boolean,
    successful: boolean,
    message: string,
    signInIsLoading: boolean,
    signInSuccesfull: boolean,
    signInMessage: string,
    user: any,
    isUser: boolean
    tourComplete: boolean
    hideBackButton: boolean
}

const initialState: AuthState = {
    signUpIsLoading: false,
    message: '',
    successful: false,
    signInIsLoading: false,
    signInSuccesfull: false,
    signInMessage: '',
    user: {},
    isUser: false,
    tourComplete: false,
    hideBackButton: true

}
export interface IUser {
    id?: any | null,
    firstName: string,
    lastName: string,
    email: string,
    password: string
}

export interface IUserLogin {
    username: string;
    password: string;
}

export const signUpHandler: any = createAsyncThunk(actions.SIGNUP, (data: IUser, { rejectWithValue }) =>
    register(data?.firstName.trim(), data?.lastName.trim(), data?.email.trim(), data?.password.trim()).catch((error) => error && rejectWithValue(error))
);

export const signInHandler: any = createAsyncThunk(actions.LOGIN, (data: IUserLogin, { rejectWithValue }) =>
    login(data?.username.trim(), data?.password.trim()).catch((error) => error && rejectWithValue(error))
)

export const LoginWithGoogle: any = createAsyncThunk(actions.GOOGLELOGIN, (credentialResponse: any, { rejectWithValue }) => loginWithGoogle(credentialResponse).catch((error) => error && rejectWithValue(error))
)
export const MicrosoftLogins: any = createAsyncThunk(actions.MICROSOFTLOGIN, (data: any, { rejectWithValue }) =>
    loginWithMicrosoft(data).catch((error) => error && rejectWithValue(error))
)

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        userHandler: (state, { payload }: PayloadAction<any>) => {
            state.user = payload;
            state.isUser = true;
        },
        logoutHandler: (state) => {
            state.user = {};
            state.isUser = false;
            sessionStorage.clear();
        },
        messageHandler: (state) => {
            state.message = '';
        },
        signInMessageHandler: (state) => {
            state.signInMessage = '';
        },
        tourCompleteHandler: (state, { payload }: PayloadAction<any>) => {
            state.tourComplete = payload;
        },
        hideBackButtonHandler: (state, { payload }: PayloadAction<any>) => {
            state.hideBackButton = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(signUpHandler.pending, (state, action) => {
            state.signUpIsLoading = true;
        }),
        builder.addCase(signUpHandler.fulfilled, (state, action) => {
            state.user = action.payload;
            state.tourComplete = true;
            state.isUser = true;
            state.signUpIsLoading = false;
            state.successful = true;
        }),
        builder.addCase(signUpHandler.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const networkError = get(error, 'payload.message');
            if (errorMessage) {
                state.message = errorMessage;
            } else if (networkError) {
                state.message = networkError;
            }
            state.signUpIsLoading = false;
        }),
        builder.addCase(signInHandler.pending, (state, action) => {
            state.signInMessage = '';
            state.signInIsLoading = true;
        }),
        builder.addCase(signInHandler.fulfilled, (state, action) => {
            state.user = action.payload;
            state.isUser = true;
            state.signInIsLoading = false;
            state.signInSuccesfull = true;
            state.signInMessage = (t('validation.successfullyLoginmsg'));
        }),
        builder.addCase(signInHandler.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const networkError = get(error, 'payload.message');
            if (errorMessage) {
                state.signInMessage = errorMessage;
            } else if (networkError) {
                state.signInMessage = networkError;
            }
            state.signInSuccesfull = false;
            state.signInIsLoading = false;
        }),
        builder.addCase(LoginWithGoogle.pending, (state, action) => {
            state.signInMessage = '';
            state.signInIsLoading = true;
        }),
        builder.addCase(LoginWithGoogle.fulfilled, (state, action) => {
            state.user = action.payload;
            state.isUser = true;
            state.signInIsLoading = false;
            state.signInSuccesfull = true;
        }),
        builder.addCase(LoginWithGoogle.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const networkError = get(error, 'payload.message');
            if (errorMessage) {
                state.signInMessage = errorMessage;
            } else if (networkError) {
                state.signInMessage = networkError;
            }
            state.signInSuccesfull = false;
            state.signInIsLoading = false;
        }),
        builder.addCase(MicrosoftLogins.pending, (state, action) => {
            state.signInMessage = '';
            state.signInIsLoading = true;
        }),
        builder.addCase(MicrosoftLogins.fulfilled, (state, action) => {
            state.user = action.payload;
            state.isUser = true;
            state.signInIsLoading = false;
            state.signInSuccesfull = true;
        }),
        builder.addCase(MicrosoftLogins.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const networkError = get(error, 'payload.message');
            if (errorMessage) {
                state.signInMessage = errorMessage;
            } else if (networkError) {
                state.signInMessage = networkError;
            }
            state.signInSuccesfull = false;
            state.signInIsLoading = false;
        })
    }
})

export const { messageHandler, signInMessageHandler, logoutHandler, userHandler, tourCompleteHandler, hideBackButtonHandler } = authSlice.actions;
export default authSlice.reducer;