import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { IUserProfile } from '../../types/user.type';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import * as Yup from "yup";
import { UpdateProfile } from '../store/slices/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { userHandler } from "../store/slices/authSlice";
import { languageHandler } from "../store/slices/settingsSlice";
import * as ACTION from "../store/actions/index";


const Profile = () => {
  const dispatch = useDispatch()
  const { user, isUser } = useSelector((state: RootState) => state.auth)
  const userId = user?.user?.id
  const [profilEdit, setprofilEdit] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const[fileValidationError,setFileValidationError]= useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const[fileSizevalidation,setFileSizevalidation]= useState(false);

  const { message, ProfileImage } = useSelector((state: RootState) => state.profile)

  const { t, i18n } = useTranslation('common');
  let initialValues: IUserProfile = {
    firstName: '',
    lastName: '',
    email: '',
  }

  const [profile, setProfile] = useState(initialValues);

  useEffect(() => {
    if (isUser) {
      if (!!user.user?.usersImageDetails && user.user?.usersImageDetails.length > 0) {
        setProfileImage(user.user?.usersImageDetails[0]?.imageUrl)
      }
      setProfile({
        firstName: user.user.firstName,
        lastName: user.user.lastName,
        email: user.user.email,
      })
    }
  }, []);

  const btnProfileEdit = async (e: any) => {

    setprofilEdit(e)
  }

  const handleprofileUpdate = (formValue: IUserProfile) => {
    const { firstName, lastName } = formValue;
    const data = {
      id: userId,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      files: files
    }
      if(fileValidationError == false && fileSizevalidation == false){
    dispatch(UpdateProfile(data)).then((response: any) => {
      if (response.type === `${ACTION.UPDATEPROFILE}/${ACTION.FULFILLED}`) {
        if (response.payload.status === 201) {
          setProfileImage(ProfileImage);
          dispatch(userHandler({ ...user, user: response.payload.data }));
        }
        setprofilEdit(false);
        toast.success("profile updated successfully!!");
      } else {
        toast.error(message);
      }
    })}
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t('validation.requiredField'))
      .transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      }).test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val.trim() !== ''
      ),
    lastName: Yup.string()
      .required(t('validation.requiredField'))
      .transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      }).test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val.trim() !== ''
      ),
  });

  const changeLanguage = (e: any) => {
    i18n.changeLanguage(e);
    dispatch(languageHandler(e));
  }
  return (
    <div className="container-fluid">
      <div className="font-26 fw-semibold mb-3 mb-md-4">{t('profile.header')}</div>
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="card border-0">
            <div className="card-body">
              {!profilEdit ? (
                <>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    {/* <div className="font-20 fw-semibold">{t('profile.lblProfileData')}</div> */}
                    <div>
                      <button type="button" className="btn btn-danger text-white rounded-45 mw-120 me-2">{t('profile.btnDeleteAccount')}</button>
                      <button type="button" onClick={() => btnProfileEdit(true)} className="btn btn-primary rounded-45 mw-120">{t('profile.btnEdit')}</button>
                    </div>
                  </div>
                </>
              ) : (
                <>

                </>
              )}

              <Formik
                enableReinitialize={true}
                initialValues={profile}
                onSubmit={handleprofileUpdate}
                validationSchema={validationSchema}
              >
                <Form>

                  {!profilEdit ? (
                    <>
                    </>
                  ) : (
                    <>
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        {/* <div className="font-20 fw-semibold">{t('profile.lblProfileData')}</div> */}
                        <div>
                          <button type="button" onClick={() => btnProfileEdit(false)} className="btn btn-outline-danger rounded-45 me-2 mw-120">{t('profile.btnCancel')}</button>
                          <button type="submit" className="btn btn-primary rounded-45 mw-120">{t('profile.btnSave')}</button>
                        </div>
                      </div>

                    </>
                  )}

                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="form-label">{t('profile.lblProfileImage')}</label>
                      <div className="">
                        {(!!profileImage) ? (
                          <>
                            <img src={profileImage} className="img-thumbnail image-profile-page" />
                          </>
                        ) : (
                          <>
                            <i className="fa-solid fa-circle-user fa-3x"></i>
                          </>
                        )}
                      </div>
                    </div>
                    {!profilEdit ? (
                      <></>
                    ) : (<>
                      <div className="col-md-6">
                        <label className="form-label">{t('profile.lblUploadImage')}</label>
                        <div className="mb-4">
                          <div className='d-flex align-items-center justify-content-between'>
                          </div>
                          <Field onChange={(event: any) => {
                            const files = event.target.files;
                            let myFiles:any = Array.from(files);
                            if(myFiles.length == 0){
                              setFileValidationError(false)
                              setFileSizevalidation(false)
                            }
                            for (let i = 0; i < myFiles.length; i++) {
                              const fileType = myFiles[i].type;
                              const fileSize = myFiles[i].size; // in bytes4
                            // Check file size (2MB limit)
                             if (fileSize > 2 * 1024 * 1024) {
                              setFileSizevalidation(true)
                                    }
                                    else{
                                      setFileSizevalidation(false)
                                    }

                              if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg'  ) {
                                setFileValidationError(true)
                                }else{
                                  setFileValidationError(false)
                                }
                            }
                            setFiles(myFiles);
                          }} className="form-control" name="photo" type="file" id="meeting-host-formFile"  accept=".jpg, .jpeg, .png"
                          />
                            <span style={{ color: "red" }}>{fileValidationError ? t("validation.FormatNotAllowed") : fileSizevalidation? t("validation.FileSizeValidation") : ""}</span>
                        </div>
                      </div></>)}
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="form-label">{t('profile.lblFirstName')}</label>
                      <div className="">
                        <Field type="text" className="form-control" name="firstName" disabled={profilEdit ? false : true} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">{t('profile.lblLastName')}</label>
                      <div className="">
                        <Field type="text" className="form-control" name="lastName" disabled={profilEdit ? false : true} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="form-label">{t('profile.lblEmail')}</label>
                      <div className="">
                        <Field type="text" name="email" className="form-control" readOnly disabled />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center gap-3'>
                    <button className='btn p-0 size-30' type="button" onClick={() => changeLanguage('de')}>
                      <img className='img-fluid' src="/images/germany-flag.svg" alt="germany-icon" />
                    </button>
                    <button className='btn p-0 size-30' type="button" onClick={() => changeLanguage('en')}>
                      <img className='img-fluid' src="/images/usa-flag.svg" alt="usa-icon" />
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile;
