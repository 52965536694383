import type { CSSProperties, FC } from 'react'
import { useDrop } from 'react-dnd'
import { DragItemTypes } from '../../types/dragItemTypes'

const style: CSSProperties = {
    height: '12rem',
    width: '100%',
    color: 'white',
    padding: '1rem',
    display: 'grid',
    placeItems: 'center'
}

export const DropArea: FC = () => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: DragItemTypes.QUESTION,
        drop: () => ({ name: 'DropArea' }),
        collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))

    const isActive = canDrop && isOver
    let backgroundColor = '#222'
    if (isActive) {
        backgroundColor = 'darkgreen'
    }

    return (
        <div
            ref={drop}
            style={{ ...style, backgroundColor }}
            data-testid={DragItemTypes.DROPAREA}
        >
            {isActive ? 'Release to drop' : 'Drag a Question here'}
        </div>
    )
}
