import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { dashboardCount } from '../../../services/dashbord.service';
import * as actions from '../actions/index';

/* eslint-disable @typescript-eslint/no-unused-expressions */

export interface DashboardState {
    dashboardDataCount: number[]

}

const initialState: DashboardState = {
    dashboardDataCount: [0,0,0,0,0]
}


export const dashboardHandler: any = createAsyncThunk(actions.DASHBOARD, (data)=>
dashboardCount(data)
);

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(dashboardHandler.pending, (state, action) => {}),
        builder.addCase(dashboardHandler.fulfilled, (state, action) => {
            state.dashboardDataCount = action.payload?.data;
        }),
        builder.addCase(dashboardHandler.rejected, (state, action) => {
            console.error('Dashboard request failed:', action.error.message);
        });
    },
})

export const { } = dashboardSlice.actions;
export default dashboardSlice.reducer;