import { store } from "../pages/store/store";

const authHeader = () => {
  const state = store.getState();
  const { user, isUser } = state.auth;
  const { language } = state.settings;

  if (isUser) {
    return {
      Authorization: 'Bearer ' + user.token, 'Accept-Language': `${language}`
    }; // for Spring Boot back-end
  } else {
    return { Authorization: '', 'Accept-Language': "en" }; // for Spring Boot back-end
  }
}
export default authHeader