import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addMeetingQuestion,
  addMeetingQuestionReply,
  deleteMeetingQuestion,
  deleteMeetingQuestionReply,
  editMeetingQuestion,
  getAllUpvoteMeetingQuestionListrByMeetingId,
  getJoinedMeetingUsersByMeetingId,
  getMeetingIntrestedUsers,
  getMeetingQuestions,
  meetingGetByCode,
  meetingQuestionSendViaEmail,
  meetingStatusUpdate,
  saveUpvoteForMeetingQuestion,
  saveUpvoteForMeetingQuestionReply,
  setMeetingIntrestedUser,
  updateMeetingQuestion,
} from "../../../services/meeting.service";
/* eslint-disable @typescript-eslint/no-unused-expressions */

export interface IJoinMeeting {}

const initialState: IJoinMeeting = {};

export const addMeetingQuestionHandler: any = createAsyncThunk(
  "addMeetingQuestion",
  (data: any, { rejectWithValue }) =>
    addMeetingQuestion(
      data.queData, data.currentMeetingId
    ).catch((error:any) => error && rejectWithValue(error))
);

export const addMeetingQuestionReplyHandler: any = createAsyncThunk(
  "addMeetingQuestionReply",
  (data: any, { rejectWithValue }) =>
    addMeetingQuestionReply(
      data?.newquestionReply,
      data?.meetingQuestionId,
    ).catch((error) => error && rejectWithValue(error))
);

export const deleteMeetingQuestionHandler: any = createAsyncThunk(
  "deleteMeetingQuestion",
  (data: any, { rejectWithValue }) =>
    deleteMeetingQuestion(
      data,
    ).catch((error) => error && rejectWithValue(error))
);

export const deleteMeetingQuestionReplyHandler: any = createAsyncThunk(
  "deleteMeetingQuestionReply",
  (data: any, { rejectWithValue }) =>
    deleteMeetingQuestionReply(
      data,
    ).catch((error) => error && rejectWithValue(error))
);

export const editMeetingQuestionHandler: any = createAsyncThunk(
  "editMeetingQuestion",
  (data: any, { rejectWithValue }) =>
    editMeetingQuestion(
      data?.queData,
      data?.editMeetingQuestionId,
      true,
    ).catch((error) => error && rejectWithValue(error))
);

export const getAllUpvoteMeetingQuestionListrByMeetingIdHandler: any =
  createAsyncThunk(
    "getAllUpvoteMeetingQuestionListrByMeetingId",
    (data: any, { rejectWithValue }) =>
      getAllUpvoteMeetingQuestionListrByMeetingId(
        data,
      ).catch((error) => error && rejectWithValue(error))
  );

export const getJoinedMeetingUsersByMeetingIdHandler: any = createAsyncThunk(
  "getJoinedMeetingUsersByMeetingId",
  (data: any, { rejectWithValue }) =>
    getJoinedMeetingUsersByMeetingId(
      data,
    ).catch((error) => error && rejectWithValue(error))
);

export const getMeetingIntrestedUsersHandler: any = createAsyncThunk(
  " getMeetingIntrestedUsers",
  (data: any, { rejectWithValue }) =>
    getMeetingIntrestedUsers(
      data,
    ).catch((error) => error && rejectWithValue(error))
);

export const getMeetingQuestionsHandler: any = createAsyncThunk(
  "getMeetingQuestions",
  (data: any, { rejectWithValue }) =>
    getMeetingQuestions(data?.currentMeetingId, data?.selectedUser).catch(
      (error) => error && rejectWithValue(error)
    )
);


export const meetingGetByCodeHandler: any = createAsyncThunk(
  " meetingGetByCode",
  (data: any, { rejectWithValue }) =>
    meetingGetByCode(
      data?.code,
      data?.userId,
      0,
    ).catch((error) => error && rejectWithValue(error))
);

export const meetingQuestionSendViaEmailHandler: any = createAsyncThunk(
  " meetingQuestionSendViaEmail",
  (data: any, { rejectWithValue }) =>
    meetingQuestionSendViaEmail(
      data?.meetingId,
    ).catch((error) => error && rejectWithValue(error))
);
export const meetingStatusUpdateHandler: any = createAsyncThunk(
  "meetingStatusUpdate",
  (data: any, { rejectWithValue }) =>
    meetingStatusUpdate(
      data?.id,
      data?.status,
    ).catch((error) => error && rejectWithValue(error))
);
export const saveUpvoteForMeetingQuestionHandler: any = createAsyncThunk(
  "saveUpvoteForMeetingQuestion",
  (data: any, { rejectWithValue }) =>
    saveUpvoteForMeetingQuestion(
      data?.currentMeetingId,
      data?.meetingQuestionId,
    ).catch((error) => error && rejectWithValue(error))
);

export const saveUpvoteForMeetingQuestionReplyHandler: any = createAsyncThunk(
  "saveUpvoteForMeetingQuestionReply",
  (data: any, { rejectWithValue }) =>
    saveUpvoteForMeetingQuestionReply(
      data?.meetingQuestionId,
      data?.meetingQuestionsReplyId,
    ).catch((error) => error && rejectWithValue(error))
);

export const setMeetingIntrestedUserHandler: any = createAsyncThunk(
  "setMeetingIntrestedUser",
  (data: any, { rejectWithValue }) =>
    setMeetingIntrestedUser(
      data
    ).catch((error) => error && rejectWithValue(error))
);

export const updateMeetingQuestionHandler: any = createAsyncThunk(
  "updateMeetingQuestion",
  (data: any, { rejectWithValue }) =>
    updateMeetingQuestion(
      data?.id,
      data?.isArchive,
    ).catch((error) => error && rejectWithValue(error))
);

export const joinMeetingSlice = createSlice({
  name: "joinMeetingSlice",
  initialState,
  reducers: {},
});

export const { } = joinMeetingSlice.actions;

export default joinMeetingSlice.reducer;