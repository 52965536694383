import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import authReducer from './slices/authSlice'
import dashboardReducer from './slices/dashboardSlice'
import meetingReducer from './slices/meetingSlice'
import profileReducer from './slices/profileSlice'
import settingsReducer from './slices/settingsSlice'
import guestUserReducer from './slices/guestUserSlice'
import joinMeetingReducer from './slices/joinMeetingSlice'
import pollReducer from './slices/pollSlice'
import quizReducer from './slices/quizSlice'
import questionReducer from './slices/questionSlice'
import modalReducer from './slices/modalSlice'
import persistReducer from 'redux-persist/es/persistReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  meeting: meetingReducer,
  profile: profileReducer,
  settings: settingsReducer,
  guestUser: guestUserReducer,
  joinMeeting: joinMeetingReducer,
  poll: pollReducer,
  quiz: quizReducer,
  question: questionReducer,
  modal: modalReducer
})

const persistConfig: any = {
  key: 'root',
  storage: storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
})
export const persistor = persistStore(store)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

