import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { moveQuestionHandler } from "../../store/slices/pollSlice";
import { Accordion } from 'react-bootstrap'
import { SelectedQuestion } from './SelectedQuestion';
interface SelectedQuestionListProps {
    meetingsLength: number
}

const SelectedQuestionList: FC<SelectedQuestionListProps> = ({ meetingsLength }) => {
    const dispatch = useDispatch()
    const { selectedQuestion } = useSelector((state: RootState) => state.poll)

    const moveQuestion = useCallback((dragIndex: number, hoverIndex: number) => {
        dispatch(moveQuestionHandler({ dragIndex, hoverIndex }));
    }, [dispatch]);

    const renderQuestion = useCallback(
        (question: any, index: number) => {
            return (
                <SelectedQuestion
                    key={`SelectedQuestion${question.id}`}
                    index={index}
                    question={question}
                    meetingsLength={meetingsLength}
                    moveQuestion={moveQuestion}
                />
            )
        },
        [],
    )

    return (
        <>
            {selectedQuestion?.length > 1 && <div className='mt-4 px-3 fw-medium text-center'>You can drag these questions to reorder</div>}
            <Accordion defaultActiveKey={['0']} alwaysOpen className="question-list p-3">
                {
                    selectedQuestion[0] !== undefined &&
                    selectedQuestion[0] !== null &&
                    selectedQuestion.map((question, index) => renderQuestion(question, index))
                }
            </Accordion>
        </>
    )
}

export default SelectedQuestionList